import { gql } from '@apollo/client';

export const GET_ENROLLED_DEVICES_FOR_CUSTOMER = gql`
  query customer_devices_v2(
    $customerId: String!
    $after: String
    $orderBy: String
    $order: String
    $filterByOS: String
    $filterByHealth: [String]
  ) {
    devices(
      customer_id: $customerId
      after: $after
      order_by: $orderBy
      order: $order
      filter_by_os: $filterByOS
      filter_by_health: $filterByHealth
    ) {
      count
      after
      total
      devices {
        assigned_to
        customer_id
        traceable
        id
        serial
        source
        source_last_check_in
        last_active
        hostname
        username
        os_name
        os_type
        os_version
        gatekeeper
        firewall
        filevault
        filevault_encryption_percent
        bitlocker
        bitlocker_encryption_percent
        total_ram
        total_hard_drive_space
        free_hard_drive_space
        hardware_model
        hardware_vendor
        hardware_description
        lock_status
        is_apple_silicon
      }
    }
  }
`;

export const GET_DARWIN_UNLOCK_PASSCODE = gql`
  query getDarwinUnlockPasscode($customerId: String!, $deviceId: String!) {
    darwin_unlock_passcode(customer_id: $customerId, device_id: $deviceId)
  }
`;

export const GET_STORED_DEVICES_FOR_DEVICE_MANAGEMENT_TABLE = gql`
  query getInventoryDevices($customerId: String!) {
    inventory_devices(customer_id: $customerId) {
      devices {
        id
        hardware
        status
        electric_asset_tag
        electric_onboarding_id
        condition
        secondary_condition
        accessories
        disk_space
        ram
        processor
        warranty_status
        location
        serial
        assigned_employee
        date_added
        brand
      }
    }
  }
`;
