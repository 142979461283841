import { useEffect } from 'react';

/**
 * Sets a history listener that reloads the current page
 * if a new app version was found in the global state
 * Note: only runs in non-development environments
 */
export const useRouteReloader = (
  history: any /* eslint-disable-line @typescript-eslint/no-explicit-any */,
  nodeEnv: string | undefined
) => {
  useEffect(() => {
    if (nodeEnv !== 'development') {
      // clear alert on location change
      const unlisten = history.listen(() => {
        if (window._env_?.NEW_VERSION) window.location.reload();
      });
      // stop the listener when component unmounts
      return unlisten;
    }
  }, [history, nodeEnv]);
};
