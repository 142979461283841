import {
  USER_REVOKE_FETCHED,
  USER_REVOKE_FETCH_FAILED,
  USER_REVOKE_FETCH_SUCCEEDED,
  USER_REVOKE_RESET,
  ONE_USER_REMOVED,
  ONE_FILTERED_USER_REMOVED,
} from '../types/revokeUserTypes';
import { closeModal, openModal } from './modalActions';
import { usersSelector } from '../selectors/usersSelector';
import { RESET_TURBINE_USER_V2 } from '@turbine/graphql/mutations';

export const userRevokeFetched = {
  type: USER_REVOKE_FETCHED,
};

export const userRevokeFetchFailed = {
  type: USER_REVOKE_FETCH_FAILED,
};

export const userRevokeFetchSucceeded = {
  type: USER_REVOKE_FETCH_SUCCEEDED,
};

export const resetUserRevoke = {
  type: USER_REVOKE_RESET,
};

export const oneUserRemovedFromState = indexOfUserToRemove => ({
  type: ONE_USER_REMOVED,
  payload: { indexOfUserToRemove },
});

export const oneFilteredUserRemovedFromState = indexOfFilteredUserToRemove => ({
  type: ONE_FILTERED_USER_REMOVED,
  payload: { indexOfFilteredUserToRemove },
});

export const removeOneUserFromState = (users, userID) => dispatch =>
  dispatch(
    oneUserRemovedFromState(users.findIndex(user => user.id === userID))
  );

export const removeOneFilteredUserFromState =
  (filteredUsers, userID) => dispatch =>
    dispatch(
      oneFilteredUserRemovedFromState(
        filteredUsers.findIndex(user => user.id === userID)
      )
    );

function handleSuccess(dispatch, { users, filteredUsers, userID, name }) {
  const messageText = `You successfully revoked ${name}'s access. They no longer have sign in privileges.`;

  dispatch(userRevokeFetchSucceeded);
  dispatch(closeModal());
  dispatch(
    openModal({
      modalType: 'SUCCESS',
      modalProps: {
        buttonText: 'Dismiss',
        onClose: () => {
          dispatch(removeOneUserFromState(users, userID));
          if (filteredUsers.findIndex(user => user.id === userID) >= 0)
            dispatch(removeOneFilteredUserFromState(filteredUsers, userID));
        },
        message: () => messageText,
        title: () => 'User Revoked',
        checkMark: true,
      },
    })
  );
}

export const fetchRevokeUserFromProxyFlare =
  ({ userID, name, customerId }) =>
  async (dispatch, getState, { client }) => {
    const state = getState();
    const { users, filteredUsers } = usersSelector(state);

    dispatch(userRevokeFetched);

    const response = client()
      .mutate({
        mutation: RESET_TURBINE_USER_V2,
        variables: {
          employee_id: userID,
        },
      })
      .then(() => {
        dispatch(
          handleSuccess(dispatch, {
            users,
            filteredUsers,
            userID,
            name,
            customerID: customerId,
          })
        );
        return true;
      })
      .catch(error => {
        window?.DD_RUM?.addError(error);
        dispatch(userRevokeFetchFailed);
        return false;
      });
    return response;
  };
