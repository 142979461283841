import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from '@electricjs/arc';
import ModalHeader from '@turbine/components/common/Modal/ModalTypes/common/ModalHeader';
import { RevokeUserModalMessage } from './RevokeUserModalMessage';
import { fetchRevokeUserFromProxyFlare } from '@turbine/redux/actions/revokeUserActions';
import { useSession } from '@turbine/helpers/hooks';

export const RevokeUserModalComponent = props => {
  const {
    bemClasses,
    CloseButton,
    modalProps: { id, first_name, email, sideEffectMessage },
    processing,
  } = props;
  const dispatch = useDispatch();
  const { customerId } = useSession();
  const variables = {
    userID: id,
    name: first_name || email,
    customerId,
  };
  const revokeUser = () =>
    dispatch(
      fetchRevokeUserFromProxyFlare({
        ...variables,
      })
    );

  const revokeUserModalComponentClasses = (modifier = '') =>
    bemClasses('revoke-user-modal', modifier);

  return (
    <div className={revokeUserModalComponentClasses('inner')}>
      <ModalHeader CloseButton={CloseButton} title="Revoke User" />
      <div className={revokeUserModalComponentClasses('body')}>
        <div className={revokeUserModalComponentClasses('message-container')}>
          <span className={revokeUserModalComponentClasses('check')}>
            &#10003;
          </span>
          <RevokeUserModalMessage
            bemClasses={revokeUserModalComponentClasses}
            name={first_name || email}
            sideEffectMessage={sideEffectMessage}
          />
        </div>
        <div className={revokeUserModalComponentClasses('sticky-bottom')}>
          <Button
            intent="danger"
            variant="outline"
            onClick={revokeUser}
            loading={processing}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

RevokeUserModalComponent.defaultProps = {
  processing: false,
  modalProps: {
    first_name: '',
    email: 'this user',
  },
};

RevokeUserModalComponent.propTypes = {
  bemClasses: PropTypes.func.isRequired,
  CloseButton: PropTypes.func.isRequired,
  modalProps: PropTypes.object,
  reset: PropTypes.func.isRequired,
  processing: PropTypes.bool,
};
