import * as types from '../types/customerPlanTypes';

export function customerPlan(state = '', action) {
  switch (action.type) {
    case types.SET_CUSTOMER_PLAN:
      return action.payload;
    default:
      return state;
  }
}
