import PropTypes from 'prop-types';
import { Button } from '@electricjs/arc';
import ModalHeader from '@turbine/components/common/Modal/ModalTypes/common/ModalHeader';

export const SuccessModalComponent = ({
  bemClasses,
  CloseButton,
  modalProps,
  handleCloseModal,
}) => {
  const successModalComponentClasses = (modifier = '') =>
    bemClasses('success-modal', modifier);

  const handleOnCloseModal = () => {
    if (modalProps.onClose) {
      modalProps.onClose();
    }
    handleCloseModal();
  };

  return (
    <div className={successModalComponentClasses('inner')}>
      <ModalHeader
        CloseButton={CloseButton}
        closeButtonProps={{ onClick: handleOnCloseModal }}
        title={modalProps.title()}
      />
      <div className={successModalComponentClasses('body')}>
        <div className={successModalComponentClasses('message-container')}>
          {modalProps.checkMark && (
            <span className={successModalComponentClasses('check')}>
              &#10003;
            </span>
          )}
          {modalProps.message()}
        </div>

        <div className={successModalComponentClasses('sticky-bottom')}>
          <Button
            onClick={handleOnCloseModal}
            intent="success"
            {...modalProps.submiButtonProps}>
            {modalProps.buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

SuccessModalComponent.defaultProps = {
  modalProps: {
    checkMark: true,
    submiButtonProps: {},
  },
};

SuccessModalComponent.propTypes = {
  bemClasses: PropTypes.func.isRequired,
  CloseButton: PropTypes.func.isRequired,
  modalProps: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
    checkMark: PropTypes.bool,
    message: PropTypes.func.isRequired,
    submiButtonProps: PropTypes.object,
    title: PropTypes.func.isRequired,
  }).isRequired,
};
