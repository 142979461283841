import { gql } from '@apollo/client';

export const UPDATE_IT_SCORE_CARD_CONFIGURATION = gql`
  mutation UpdateITScoreCardConfiguration(
    $customerId: String!
    $googleWorkspaceEnabled: Boolean!
    $o365Enabled: Boolean!
  ) {
    update_itsc_configuration(
      customer_id: $customerId
      google_workspace_enabled: $googleWorkspaceEnabled
      o365_enabled: $o365Enabled
    ) {
      itsc_configuration {
        o365_enabled
        google_workspace_enabled
      }
    }
  }
`;

export const SEND_DISK_SPACE_EMAILS = gql`
  mutation sendDiskSpaceEmails(
    $employees: [ITSCDiskSpaceEmailEmployee]
    $companyName: String!
    $requesterName: String!
    $subject: String!
    $additionalMessage: String
  ) {
    send_disk_space_emails(
      employees: $employees
      company_name: $companyName
      requester_name: $requesterName
      subject: $subject
      additional_message: $additionalMessage
    ) {
      recipients {
        name
        email
        device_os_type
        id
      }
    }
  }
`;

export const FIREWALL_ENABLEMENT_REQUEST = gql`
  mutation FirewallEnablementRequest($input: requestBody!) {
    firewallEnablementRequest(input: $input)
      @rest(
        type: "Post"
        path: "/v1/user-requests"
        method: "POST"
        bodyKey: "input"
      ) {
      data {
        customer_id
        device_id
        serial_number
        hostname
        requester_id
        request_key
        request_type
        request_status
      }
    }
  }
`;
