import {
  matchPath,
  NavLink,
  type RouteProps,
  withRouter,
} from 'react-router-dom';
import { hasSomePermission } from '@electricjs/utils/helpers';
import { useUserPermissions } from '@electricjs/utils/hooks';
import { type Location as HistoryLocationProps } from 'history';
import PropTypes from 'prop-types';
import { Icon, type IconNames, getToken, ColorsV2 } from '@electricjs/arc';
import { type NavItemPropsWithRoute } from './types';
import styled from 'styled-components';

const isActive =
  (options: RouteProps) =>
  (_match: unknown, location: HistoryLocationProps): boolean => {
    if (location.pathname === '/') {
      const match = matchPath(location.pathname, options);
      return match ? true : false;
    } else {
      return !!(
        options &&
        options?.path &&
        options.path.includes(location.pathname)
      );
    }
  };

const renderIcon = ({ icon }: { icon: IconNames; highlight: boolean }) => {
  return <Icon icon={icon} size="medium" />;
};

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 4.5rem;
  line-height: 4.5rem;
  color: ${getToken(ColorsV2.TEXT)};
  background: transparent;
  transition: v('transition');
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 0;
    background: transparent;
    transition: v('transition');
  }

  span {
    font-size: 1.6rem;
    font-weight: 400;
  }

  div[data-testid='arc-icon'] {
    margin-right: 1.5rem;
    margin-left: 1rem;
    margin-bottom: 0.1rem;

    svg {
      width: 20px;
    }
  }

  &:hover {
    background: ${getToken(ColorsV2.BACKGROUND_ALT)};
    color: ${getToken(ColorsV2.PRIMARY)};
  }

  &.active {
    background: ${getToken(ColorsV2.BACKGROUND_ALT)};
    color: ${getToken(ColorsV2.PRIMARY)};
  }
`;

const NavItemComponent = ({
  text,
  path: routeTo,
  initiallyRouteTo,
  icon,
  permissionsNeeded,
  location,
}: NavItemPropsWithRoute) => {
  const userPermissions = useUserPermissions();
  const hasPermission = hasSomePermission(userPermissions, permissionsNeeded);
  const path = initiallyRouteTo || routeTo;

  if (!hasPermission) return null;
  const highlight = (location && location.pathname.includes(path)) || false;

  return (
    <StyledNavLink
      className="nav-item-turbine"
      exact={path === '/'}
      isActive={isActive({ path: routeTo, exact: path === '/' })}
      to={path}>
      {icon ? renderIcon({ icon, highlight }) : null}
      <span data-testid="nav-item">{text}</span>
    </StyledNavLink>
  );
};

NavItemComponent.defaultProps = {
  initiallyRouteTo: '',
  permissionsNeeded: null,
};

const permissionsNeededType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.string),
  PropTypes.oneOf([null]),
]);

renderIcon.propTypes = {
  icon: PropTypes.string,
  highlight: PropTypes.bool,
};

NavItemComponent.propTypes = {
  icon: PropTypes.string,
  permissionsNeeded: permissionsNeededType,
  initiallyRouteTo: PropTypes.string,
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

// TypeScript for withRouter is being very difficult, using "any" for the return type for now.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withRouter<NavItemPropsWithRoute, any>(NavItemComponent);
