import { subDays, isBefore } from 'date-fns';
import { OS_TYPE, SOURCE } from '@turbine/constants/devices';
import { type Device } from '@turbine/types/DeviceManagement';
import {
  MINIMUM_FREE_DISK_SPACE_PERCENTAGE,
  MINIMUM_OS_REQUIREMENT_FOR_MAC,
  MINIMUM_OS_REQUIREMENT_FOR_WINDOWS,
  MINIMUM_RAM_REQUIREMENT_IN_GB,
  isFreeDiskSpaceAboveMinimumPercent,
} from './itScoreCard';

type ITScoreCardDeviceData = {
  mac: {
    stats: MacStats & AddedStats;
  };
  pc: {
    stats: PCStats & AddedStats;
  };
};

type PCOnlyStats = {
  bitlocker: number;
};

type MacOnlyStats = {
  filevault: number;
  gatekeeper: number;
};

type CommonStats = {
  enrolled: number;
  total: number;
  warning: number;
  os: number;
  ram: number;
  disk: number;
  firewall: number;
};

type AddedStats = {
  osSettings: string;
  ramSettings: string;
  diskSettings: string;
};

type PCStats = PCOnlyStats & CommonStats;
type MacStats = MacOnlyStats & CommonStats;

export const isLastActiveMissing = (device: Device): boolean => {
  return !device.last_active;
};

export const isLastActiveInOver21Days = (device: Device): boolean => {
  if (device.last_active) {
    const lastActive = Date.parse(device.last_active);
    const twentyOneDaysAgo = subDays(Date.now(), 21);
    return isBefore(lastActive, twentyOneDaysAgo);
  } else {
    return false;
  }
};

export const isMacOSUpdated = (macDevice: Device): boolean => {
  if (!macDevice?.os_version) return false;

  const MAJOR = 0;
  const MINOR = 1;
  const PATCH = 2;
  const installed = macDevice.os_version
    .split('.')
    .map(numberString => parseInt(numberString));
  const required = MINIMUM_OS_REQUIREMENT_FOR_MAC.split('.').map(numberString =>
    parseInt(numberString)
  );

  if (required[MAJOR] > installed[MAJOR]) return false;
  if (required[MAJOR] < installed[MAJOR]) return true;

  if (required[MINOR] > installed[MINOR]) return false;
  if (required[MINOR] < installed[MINOR]) return true;

  if (required[PATCH] > installed[PATCH]) return false;
  if (required[PATCH] < installed[PATCH]) return true;

  return true;
};

const isPCOSUpdated = (pcDevice: Device) => {
  const osValue = pcDevice.os_name;
  return MINIMUM_OS_REQUIREMENT_FOR_WINDOWS.some(
    supportedOS => osValue && osValue.indexOf(supportedOS) >= 0
  );
};

const isOsTypeMissing = (device: Device) => {
  return !device.os_type;
};

export const isNonReporting = (device: Device): boolean => {
  return (
    isOsTypeMissing(device) ||
    isLastActiveMissing(device) ||
    isLastActiveInOver21Days(device)
  );
};

const hasRequiredRam = (device: Device) =>
  Math.round(device.total_ram) >= MINIMUM_RAM_REQUIREMENT_IN_GB;

const hasRequiredFreeDiskSpace = (device: Device) =>
  isFreeDiskSpaceAboveMinimumPercent(
    device.total_hard_drive_space,
    device.free_hard_drive_space
  );

export const formatAPIDevicesData = (
  devices: Device[]
): ITScoreCardDeviceData => {
  const initialValue = {
    mac: {
      filevault: 0,
      gatekeeper: 0,
      firewall: 0,
      os: 0,
      ram: 0,
      disk: 0,
      enrolled: 0,
      total: 0,
      warning: 0,
    },
    pc: {
      firewall: 0,
      bitlocker: 0,
      os: 0,
      ram: 0,
      disk: 0,
      enrolled: 0,
      total: 0,
      warning: 0,
    },
  };
  const { mac, pc } = devices.reduce((acc, device) => {
    let currentOS: 'mac' | 'pc';
    if (device.os_type === OS_TYPE.MAC || device.source === SOURCE.JAMF) {
      currentOS = 'mac';
    } else if (
      device.os_type === OS_TYPE.WINDOWS ||
      device.source === SOURCE.KASEYA
    ) {
      currentOS = 'pc';
    } else {
      return acc;
    }
    const currentOSStats = acc[currentOS];

    const updatedCommonTotals = {
      total: currentOSStats.total + 1,
      enrolled: currentOSStats.enrolled + (isNonReporting(device) ? 0 : 1),
      warning: currentOSStats.warning + (isNonReporting(device) ? 1 : 0),
    };

    if (isNonReporting(device)) {
      if (currentOS === 'pc') {
        acc[currentOS] = {
          ...(currentOSStats as PCStats),
          ...updatedCommonTotals,
        };
      } else {
        acc[currentOS] = {
          ...(currentOSStats as MacStats),
          ...updatedCommonTotals,
        };
      }
      return acc;
    }

    const osValidator = currentOS === 'mac' ? isMacOSUpdated : isPCOSUpdated;

    const updatedCommonStats = {
      ram: currentOSStats.ram + (hasRequiredRam(device) ? 1 : 0),
      disk: currentOSStats.disk + (hasRequiredFreeDiskSpace(device) ? 1 : 0),
      firewall: currentOSStats.firewall + (device.firewall ? 1 : 0),
      os: currentOSStats.os + (osValidator(device) ? 1 : 0),
    };

    let updatedOSSpecificStats;
    if (currentOS === 'mac') {
      updatedOSSpecificStats = {
        filevault:
          (currentOSStats as MacStats).filevault + (device.filevault ? 1 : 0),
        gatekeeper:
          (currentOSStats as MacStats).gatekeeper + (device.gatekeeper ? 1 : 0),
      };
      acc[currentOS] = {
        ...updatedCommonTotals,
        ...updatedCommonStats,
        ...(updatedOSSpecificStats as MacOnlyStats),
      };
    }
    if (currentOS === 'pc') {
      updatedOSSpecificStats = {
        bitlocker:
          (currentOSStats as PCStats).bitlocker + (device.bitlocker ? 1 : 0),
      };

      acc[currentOS] = {
        ...updatedCommonTotals,
        ...updatedCommonStats,
        ...(updatedOSSpecificStats as PCOnlyStats),
      };
    }
    return acc;
  }, initialValue);
  return {
    mac: {
      stats: {
        ...mac,
        osSettings: MINIMUM_OS_REQUIREMENT_FOR_MAC,
        ramSettings: `${MINIMUM_RAM_REQUIREMENT_IN_GB}`,
        diskSettings: `${MINIMUM_FREE_DISK_SPACE_PERCENTAGE}`,
      },
    },
    pc: {
      stats: {
        ...pc,
        osSettings: '10 Pro or Enterprise',
        ramSettings: `${MINIMUM_RAM_REQUIREMENT_IN_GB}`,
        diskSettings: `${MINIMUM_FREE_DISK_SPACE_PERCENTAGE}`,
      },
    },
  };
};

const emptyAPIDevicesData = {
  mac: {
    stats: {
      filevault: 0,
      gatekeeper: 0,
      firewall: 0,
      os: 0,
      ram: 0,
      disk: 0,
      enrolled: 0,
      total: 0,
      warning: 0,
      osSettings: MINIMUM_OS_REQUIREMENT_FOR_MAC,
      ramSettings: `${MINIMUM_RAM_REQUIREMENT_IN_GB}`,
      diskSettings: `${MINIMUM_FREE_DISK_SPACE_PERCENTAGE}`,
    },
  },
  pc: {
    stats: {
      firewall: 0,
      bitlocker: 0,
      os: 0,
      ram: 0,
      disk: 0,
      enrolled: 0,
      total: 0,
      warning: 0,
      osSettings: '10 Pro or Enterprise',
      ramSettings: `${MINIMUM_RAM_REQUIREMENT_IN_GB}`,
      diskSettings: `${MINIMUM_FREE_DISK_SPACE_PERCENTAGE}`,
    },
  },
};

export const extractDataFromAPIDevices = (
  devices: Device[] | null
): ITScoreCardDeviceData | null =>
  devices
    ? devices.length > 0
      ? formatAPIDevicesData(devices)
      : emptyAPIDevicesData
    : null;
