const initialState = {
  isVisibleAddedSupportedAppBanner: false,
  isVisibleUpdatedSupportedAppBanner: false,
};

export function applicationBanners(state = initialState, { type }) {
  switch (type) {
    case 'SHOW_ADDED_SUPPORTED_APP_BANNER':
      return {
        ...state,
        isVisibleAddedSupportedAppBanner: true,
      };
    case 'HIDE_ADDED_SUPPORTED_APP_BANNER':
      return {
        ...state,
        isVisibleAddedSupportedAppBanner: false,
      };
    case 'SHOW_UPDATED_SUPPORTED_APP_BANNER':
      return {
        ...state,
        isVisibleUpdatedSupportedAppBanner: true,
      };
    case 'HIDE_UPDATED_SUPPORTED_APP_BANNER':
      return {
        ...state,
        isVisibleUpdatedSupportedAppBanner: false,
      };
    case 'HIDE_ALL_BANNERS':
      return {
        ...state,
        isVisibleAddedSupportedAppBanner: false,
        isVisibleUpdatedSupportedAppBanner: false,
      };
    default:
      return state;
  }
}
