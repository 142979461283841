import { connect } from 'react-redux';
import { revokeUserSelector } from '@turbine/redux/selectors/revokeUserSelector';
import { resetUserRevoke } from '@turbine/redux/actions/revokeUserActions';
import { RevokeUserModalComponent } from './RevokeUserModalComponent';

const mapStateToProps = state => ({
  processing: revokeUserSelector(state).fetching,
});

const mapDispatchToProps = dispatch => {
  return {
    reset: () => dispatch(resetUserRevoke),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RevokeUserModalComponent);
