import {
  type DeprovisionHardware,
  type OffboardingDataProps,
} from '@turbine/graphql/types/boardingTypes';
import { arrayHasLength } from '@turbine/helpers/arrayHelpers';
import {
  DEPROVISIONING_DESTINATION,
  DEPROVISIONING_LOCATION_OPTIONS,
  DEPROVISIONING_RETURN_SHIPPING,
  DEPROVISIONING_QR_CODE_RETURN_SHIPPING,
  DEPROVISION_DEVICE_OPTIONS,
  YesNoOptions,
} from '@turbine/lib/xboarding/constants';
import { type ArrayElementType } from '@turbine/types/utils';
import { convertSnakeKeysToCamelCase } from '@turbine/helpers/convertSnakeKeysToCamelCase';
import { asSelectOptions } from '@turbine/lib/xboarding/helpers';
import { defaultDeprovisioningLocation } from './taskListSlice';

const getDeviceField = <
  Data extends OffboardingDataProps,
  Field extends keyof ArrayElementType<
    OffboardingDataProps['deprovisioningHardware']
  >,
  DefaultValue = undefined
>(
  data: Data,
  field: Field,
  defaultValue?: DefaultValue
) => data?.deprovisioningHardware?.[0]?.[field] ?? defaultValue;

const getDeprovisionDeviceSelected = (
  data: OffboardingDataProps
): YesNoOptions => {
  const hasDevices =
    arrayHasLength(data.deprovisioningHardware) ||
    !!(getDeviceField(data, 'name') && getDeviceField(data, 'serial'));

  const isDeprovisionDeviceSelected = hasDevices
    ? YesNoOptions.Yes
    : YesNoOptions.No;

  return isDeprovisionDeviceSelected;
};

const getDevicesInformation = (
  data: OffboardingDataProps
): DeprovisionHardware[] => {
  const deprovisioningHardwares = data.deprovisioningHardware;
  const devices = deprovisioningHardwares || [];

  return devices;
};

const getDeprovisionDeviceShippingFromAddress = (
  data: OffboardingDataProps
) => {
  const normalizedAddress = convertSnakeKeysToCamelCase(
    getDeviceField(data, 'shippingFrom')
  );
  return normalizedAddress;
};

const getDeprovisionDeviceShippingToAddress = (data: OffboardingDataProps) => {
  const normalizedAddress = convertSnakeKeysToCamelCase(
    getDeviceField(data, 'shippingTo')
  );
  return asSelectOptions(normalizedAddress?.name, normalizedAddress);
};

const getDeprovisionDeviceType = (data: OffboardingDataProps) => {
  const deviceType = DEPROVISION_DEVICE_OPTIONS.find(
    option => option.value === getDeviceField(data, 'deprovisioningType')
  );
  return deviceType || null;
};

const getDeprovisionDeviceLocation = (data: OffboardingDataProps) => {
  const location = DEPROVISIONING_LOCATION_OPTIONS.find(
    option => option.value === getDeviceField(data, 'deprovisioningLocation')
  );
  return location || defaultDeprovisioningLocation;
};

const getDeprovisionReturnDestination = (data: OffboardingDataProps) => {
  const destination = DEPROVISIONING_DESTINATION.find(
    option => option.value === getDeviceField(data, 'returnDestination')
  );
  return destination || null;
};

const DEPROVISIONING_OPTIONS = [
  ...DEPROVISIONING_RETURN_SHIPPING,
  ...DEPROVISIONING_QR_CODE_RETURN_SHIPPING,
];
const getDeprovisionReturnShipping = (data: OffboardingDataProps) => {
  const returnShipping = DEPROVISIONING_OPTIONS.find(
    option => option.value === getDeviceField(data, 'returnShipping')
  );
  return returnShipping || null;
};

export {
  getDeprovisionDeviceSelected,
  getDeviceField,
  getDevicesInformation,
  getDeprovisionDeviceType,
  getDeprovisionDeviceLocation,
  getDeprovisionReturnShipping,
  getDeprovisionReturnDestination,
  getDeprovisionDeviceShippingFromAddress,
  getDeprovisionDeviceShippingToAddress,
};
