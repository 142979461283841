import { gql } from '@apollo/client';

// We use a graphQL alias below to convert the snake case query properties
// to camel case properties. This also allowed us to update this query to v2
// without changing the consumers of this query.
const GET_USER_DETAILS = gql`
  query UserDetails($employeeId: String!, $customerId: String!) {
    employees: employee_v2(employee_id: $employeeId) {
      first_name
      last_name
      email
      avatar
      customer_id
      auth0_id
    }
    customers: customer_v2(customer_id: $customerId) {
      id
      name
      sf_account_id
    }
  }
`;

/**
 * Formats user details for launch darkly user attributes
 * @param object userDetails data returned from GET_USER_DETAILS query
 */
export const formatUserDetails = userDetails => {
  const employeeDetails = userDetails?.employees;
  const customerDetails = userDetails?.customers;

  if (!employeeDetails && !customerDetails) return undefined;

  const { auth0_id, first_name, last_name, name, email, avatar } =
    employeeDetails;

  return {
    firstName: first_name,
    lastName: last_name,
    name,
    email,
    avatar,
    custom: {
      auth0Id: auth0_id,
      'Customer Id': customerDetails.id,
      'Customer Name': customerDetails.name,
    },
  };
};

export default GET_USER_DETAILS;
