import * as React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { bugsnagKey } from '@turbine/config';
import store from '@turbine/redux/store';
import { currentUserInfoSelector } from '@turbine/redux/selectors/sessionSelector';
import { currentCustomerSelector } from '@turbine/redux/selectors/customersSelector';
import { useSession } from '@turbine/helpers/hooks';

export function getUserAndCustomerInfo(customerId) {
  const state = store.getState();
  const { email, sub } = currentUserInfoSelector(state);
  const { name, id } = currentCustomerSelector(customerId)(state);

  return {
    user: { email, auth0Id: sub },
    customer: { name, id },
  };
}

export default function initBugsnag(customerId) {
  const bugsnagClient = bugsnag({
    apiKey: bugsnagKey,
    autoCaptureSessions: true,
    notifyReleaseStages: ['production'],
    beforeSend: report => {
      const { user, customer } = getUserAndCustomerInfo(customerId);
      report.user = user;
      report.metaData = {
        customer,
      };
    },
  });

  bugsnagClient.use(bugsnagReact, React);
  return bugsnagClient.getPlugin('react');
}

export const BugsnagErrorBoundary = ({ children }) => {
  const { customerId } = useSession();
  const BugSnagErrorBoundaryComponent = initBugsnag(customerId);

  return (
    <BugSnagErrorBoundaryComponent>{children}</BugSnagErrorBoundaryComponent>
  );
};
