/* eslint-disable @typescript-eslint/no-explicit-any */
const tryJSONParse = (jsonString: any) => {
  let json: Record<string, any> = {};
  try {
    json = JSON.parse(jsonString);
  } catch (err) {
    //console.log(err);
    return jsonString;
  }
  return json;
};

export { tryJSONParse };
