import { createSelector } from '@reduxjs/toolkit';
import { getSafeDate } from '@turbine/lib/xboarding/utils';
import { type RootState } from '@turbine/redux/store';
import {
  type StoredDevice,
  ALLOWED_DEVICES,
  Condition,
} from '@turbine/redux/devices/storedDevices/storedDevicesSlice';
import { type FiltersState } from '@turbine/pages/NewOnboarding/components/InventoryDevicesList/filtersReducer';

const allowedDevicesFilter = (device: any) => {
  return ALLOWED_DEVICES.includes(device.status);
};
export const selectStoredDevicesLoading = (state: any): boolean =>
  state.storedDevices.loading;
export const selectStoredDevicesFailed = (state: any): boolean =>
  state.storedDevices.failed;
export const selectStoredDevicesDate = (state: RootState) =>
  getSafeDate(state.storedDevices.date);
export const selectStoredDevicesData = (state: any): StoredDevice[] | null =>
  state.storedDevices.data
    ? state.storedDevices.data.filter(allowedDevicesFilter)
    : null;

export const selectStoredDevicesDataFilteredBy =
  (filters: FiltersState) => (state: any) => {
    const enabledConditionFilters = [] as Condition[];
    const storedDevices = selectStoredDevicesData(state);
    if (!storedDevices) {
      return null;
    }
    filters.new && enabledConditionFilters.push(Condition.New);
    filters.used && enabledConditionFilters.push(Condition.Used);
    const areConditionFiltersEnabled = enabledConditionFilters.length > 0;
    const isTextFilterEnabled = filters.searchText.length > 0;

    // We simply add the hidden class to determine whether or not to show the device.
    // We don't actually filter the list. This is so that our modal doesn't actually remove
    // the devices when displayed which would cause the modal to change size in a jarring way.
    let filteredStoredDevices = areConditionFiltersEnabled
      ? storedDevices.map(device => {
          return {
            ...device,
            hidden: !enabledConditionFilters.includes(device.condition),
          };
        })
      : storedDevices;

    filteredStoredDevices = isTextFilterEnabled
      ? filteredStoredDevices.map(device => {
          const hidden = !device.searchString
            ?.toLowerCase()
            .includes(filters.searchText.toLowerCase());
          return {
            ...device,
            hidden,
          };
        })
      : filteredStoredDevices;

    // We want to sort so that all the non-hidden ones appear first
    // This prevents devices that should be shown from appearing below the
    // scroll window.
    filteredStoredDevices = filteredStoredDevices.sort((a, b) => {
      return a.hidden === b.hidden ? 0 : a.hidden ? 1 : -1;
    });

    return filteredStoredDevices;
  };

const getAvailableStoredDevices = (devices: StoredDevice[] | null) => {
  if (!devices) {
    return null;
  }
  const unavailableConditions = new Set<string>([
    'Broken',
    'Firmware Locked',
    'Damaged',
  ]);
  const unavailableStatuses = new Set<string>(['Reserved', 'Device Received']);
  return devices?.filter(
    device =>
      !unavailableConditions.has(device.condition) &&
      !unavailableStatuses.has(device.status)
  );
};

export const selectAvailableStoredDevices = createSelector(
  selectStoredDevicesData,
  devices => getAvailableStoredDevices(devices)
);

export const selectAvailableStoredDevicesDataFilteredBy = (
  filters: FiltersState
) =>
  createSelector(selectStoredDevicesDataFilteredBy(filters), devices =>
    getAvailableStoredDevices(devices)
  );

export const selectSelectedStoredDeviceID = (state: any): string =>
  state.storedDevices.selectedDeviceID;

export const selectStoredDevicesBySerials =
  (serials: string[]) =>
  (state: any): StoredDevice[] =>
    state.storedDevices.data.filter((storedDevice: StoredDevice) =>
      serials.includes(storedDevice.serial)
    );

export const selectStoredDeviceBySerial =
  (serial: string) =>
  (state: any): StoredDevice =>
    state.storedDevices.data.find(
      (storedDevice: StoredDevice) => serial === storedDevice.serial
    );

export const selectStoredDevicesState = createSelector(
  selectStoredDevicesLoading,
  selectStoredDevicesFailed,
  selectStoredDevicesData,
  (loading, failed, devices) => ({
    loading,
    failed,
    devices,
  })
);

export const selectStoredDevicesStateFilteredBy = (filters: FiltersState) =>
  createSelector(
    selectStoredDevicesLoading,
    selectStoredDevicesFailed,
    selectStoredDevicesDataFilteredBy(filters),
    (loading, failed, devices) => ({
      loading,
      failed,
      devices,
    })
  );

export const selectAvailableStoredDevicesState = createSelector(
  selectStoredDevicesLoading,
  selectStoredDevicesFailed,
  selectAvailableStoredDevices,
  (loading, failed, devices) => ({
    loading,
    failed,
    devices,
  })
);

export const selectSelectedDeviceSerials = (state: any): Array<string> =>
  state.storedDevices.selectedDeviceSerials;

export const selectReservationErrorSerials = (state: any): Array<string> =>
  state.storedDevices.reservationErrorSerials;

export const selectAvailableStoredDevicesStateFilteredBy = (
  filters: FiltersState
) =>
  createSelector(
    selectStoredDevicesLoading,
    selectStoredDevicesFailed,
    selectAvailableStoredDevicesDataFilteredBy(filters),
    (loading, failed, devices) => ({
      loading,
      failed,
      devices,
    })
  );
