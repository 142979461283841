import { createSelector } from 'reselect';
import { get } from 'lodash';
import { CUSTOMER_ID_PATH } from '@turbine/constants/sessionConstants';

const sessionSelector = createSelector(
  [state => state.session],
  (session = {}) => session
);

export const isAuthenticated = (session = {}) =>
  Object.keys(session).length > 0;

export function isImpersonating(session, customerID) {
  if (!isAuthenticated(session)) return false;
  return customerID !== get(session, CUSTOMER_ID_PATH);
}

export const isImpersonatingSelector = createSelector(
  [state => state.session],
  (session = {}) => isImpersonating(session, session.customerID)
);

export const currentUserInfoSelector = createSelector(
  sessionSelector,
  session => get(session, 'idTokenPayload', session)
);
