import { gql } from '@apollo/client';

export const ADD_CUSTOMER_SOFTWARE_APPLICATION = gql`
  mutation CreateCustomerSoftwareApplication(
    $customerId: String!
    $id: String
    $state: String
    $supported: Boolean
    $applicationAdminId: String
    $requesterId: String
    $softwareName: String
    $softwareUrl: String
    $softwareInternal: Boolean
    $isPreApproved: Boolean
  ) {
    createCustomerSoftwareApplication(
      customer_id: $customerId
      software_application_id: $id
      state: $state
      supported: $supported
      requester_id: $requesterId
      application_admin_id: $applicationAdminId
      software_name: $softwareName
      software_url: $softwareUrl
      software_internal: $softwareInternal
      is_preapproved: $isPreApproved
    ) {
      customer_software_applications {
        id
        customer_id
        state
        supported
        application_admin_id
        is_preapproved
        application_admin {
          id
          name
          email
          avatar
        }
        software_application {
          name
        }
      }
    }
  }
`;

export interface UpdateCustomerSoftwareApplicationResponse {
  customer_software_applications: {
    id: string;
    customer_id: string;
    state: string;
    supported: boolean;
    application_admin_id: string;
    is_preapproved: boolean;
    application_admin: {
      id: string;
      name: string;
      email: string;
      avatar: string;
    };
  };
}

export const UPDATE_CUSTOMER_SOFTWARE_APPLICATION = gql`
  mutation UpdateCustomerSoftwareApplication(
    $customerId: String!
    $id: String!
    $state: String
    $supported: Boolean
    $requesterId: String
    $applicationAdminId: String
    $isPreApproved: Boolean
  ) {
    updateCustomerSoftwareApplication(
      customer_id: $customerId
      software_application_id: $id
      state: $state
      supported: $supported
      requester_id: $requesterId
      application_admin_id: $applicationAdminId
      is_preapproved: $isPreApproved
    ) {
      customer_software_applications {
        id
        customer_id
        state
        supported
        application_admin_id
        is_preapproved
        application_admin {
          id
          name
          email
          avatar
        }
      }
    }
  }
`;

export const TRANSFER_APPLICATION_OWNERSHIP = gql`
  mutation TransferApplicationOwnership(
    $customerId: String!
    $fromAdmin: String
    $toAdmin: String!
    $state: String
    $supported: Boolean
  ) {
    transferApplicationOwnership(
      customer_id: $customerId
      from_admin: $fromAdmin
      to_admin: $toAdmin
      state: $state
      supported: $supported
    ) {
      customer_software_applications {
        id
        state
        supported
        application_admin_id
        application_admin {
          id
          name
          email
          avatar
        }
      }
    }
  }
`;

export const SELECT_CUSTOMER_APPLICATION = gql`
  mutation SelectCustomerApplication(
    $customerId: String!
    $requesterId: String!
    $id: String!
  ) {
    createCustomerSoftwareApplication(
      customer_id: $customerId
      requester_id: $requesterId
      software_application_id: $id
    ) {
      customer_software_applications {
        id
        customer_id
        state
        supported
        application_admin_id
        application_admin {
          id
          name
          email
          avatar
        }
        software_application {
          id
          parent_id
          name
          icon
          supported
          developer
          description
        }
      }
    }
  }
`;

export const DESELECT_CUSTOMER_APPLICATION = gql`
  mutation DeselectCustomerApplication($customerId: String!, $id: String!) {
    deselectCustomerSoftwareApplication(
      customer_id: $customerId
      software_application_id: $id
    ) {
      customer_software_applications {
        id
        customer_id
        state
        supported
        application_admin_id
        application_admin {
          id
          name
          email
          avatar
        }
        software_application {
          id
          parent_id
          name
          icon
          supported
          developer
          description
        }
      }
    }
  }
`;

export const CONFIRM_CUSTOMER_APPLICATIONS = gql`
  mutation ConfirmCustomer($customerId: String!, $requesterId: String!) {
    confirmCustomerSoftwareApplication(
      customer_id: $customerId
      requester_id: $requesterId
    ) {
      customer_software_applications {
        id
        customer_id
        state
        software_application {
          id
          parent_id
          name
          icon
          supported
          developer
          description
        }
      }
    }
  }
`;
