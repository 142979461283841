import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { fetchStoredDevicesByCustomerID } from './storedDevicesSliceThunks';
import {
  persistDeviceIdInURL,
  removeDeviceIdFromURL,
  getDeviceIDFromURL,
} from '../enrolledDevices/enrolledDevicesSliceHelpers';

export enum Condition {
  New = 'New',
  Used = 'Used',
  Damaged = 'Damaged',
  ActivationLocked = 'Activation Locked',
  FirmwareLocked = 'Firmware Locked',
}

export enum Status {
  InStorage = 'In Storage',
  Processing = 'Processing',
  Reserved = 'Reserved',
  Repair = 'Repair',
  Audit = 'Audit',
  Missing = 'Missing',
  Disposed = 'Disposed',
  Recycle = 'Recycle',
}

export const ALLOWED_DEVICES: Array<string> = [
  Status.InStorage,
  Status.Processing,
  Status.Reserved,
  Status.Repair,
  Status.Audit,
];

export type StoredDevice = {
  id: string;
  hardware: string;
  electric_asset_tag: string;
  condition: Condition;
  secondary_condition: string | null;
  accessories: string;
  disk_space: string | null;
  ram: string;
  processor: string;
  warranty_status: string;
  location: string | null;
  serial: string;
  date_added: string;
  status: string;
  assigned_employee: string | null;
  electric_onboarding_id: string | null;
  searchString?: string;
  hidden?: boolean;
};

type StoredDevicesState = {
  data: StoredDevice[] | null;
  date: string;
  loading: boolean;
  failed: boolean;
  selectedDeviceID: string | null;
  selectedDeviceSerials: string[];
  reservationErrorSerials: string[];
};

const initialState: StoredDevicesState = {
  loading: false,
  failed: false,
  date: new Date().toISOString(),
  data: null,
  selectedDeviceID: getDeviceIDFromURL(),
  selectedDeviceSerials: [],
  reservationErrorSerials: [],
};

export const storedDevicesSlice = createSlice({
  name: 'storedDevices',
  initialState,
  reducers: {
    selectedInventoryDeviceForOnboarding(
      state,
      action: PayloadAction<{ serial: string }>
    ) {
      const { serial } = action.payload;
      const updatedSelectedDeviceSerials = [
        ...state.selectedDeviceSerials,
        serial,
      ];
      state.selectedDeviceSerials = updatedSelectedDeviceSerials;
    },
    removedInventoryDeviceFromOnboarding(
      state,
      action: PayloadAction<{ serial: string }>
    ) {
      const { serial } = action.payload;
      const updatedSelectedDeviceSerials = [
        ...state.selectedDeviceSerials,
      ].filter(num => num !== serial);
      state.selectedDeviceSerials = updatedSelectedDeviceSerials;

      // also clear serial from errorSerials if it had a reservation error
      if (state.reservationErrorSerials.includes(serial)) {
        const newErrorSerials = [...state.reservationErrorSerials].filter(
          error => error !== serial
        );
        state.reservationErrorSerials = newErrorSerials;
      }
    },
    deviceSaveReservationError(
      state,
      action: PayloadAction<{ deviceSerial: string }>
    ) {
      const { deviceSerial } = action.payload;
      const newErrorSerials = [...state.reservationErrorSerials, deviceSerial];
      state.reservationErrorSerials = newErrorSerials;
    },
    deviceDeleteReservationError(
      state,
      action: PayloadAction<{ deviceSerial: string }>
    ) {
      const { deviceSerial } = action.payload;
      const newErrorSerials = [...state.reservationErrorSerials].filter(
        serial => serial !== deviceSerial
      );
      state.reservationErrorSerials = newErrorSerials;
    },
    resetInventoryOnboardingState(state) {
      state.selectedDeviceSerials = [];
      state.reservationErrorSerials = [];
    },
    clickedStoredDeviceDetailsForDeviceID: {
      reducer: (state, action: PayloadAction<string>) => {
        state.selectedDeviceID = action.payload;
      },
      prepare: (payload?: any) => ({
        payload,
        meta: {
          updateUrl: () => persistDeviceIdInURL(payload),
        },
      }),
    },
    clickedHideStoredDeviceDetailsForDeviceID: {
      reducer: state => {
        state.selectedDeviceID = null;
      },
      prepare: (payload?: any) => ({
        payload,
        meta: {
          updateUrl: removeDeviceIdFromURL,
        },
      }),
    },
    resetStoredDevicesState: {
      reducer: () => ({ ...initialState, selectedDeviceID: null }),
      prepare: (payload?: any) => ({
        payload,
        meta: {
          updateUrl: removeDeviceIdFromURL,
        },
      }),
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchStoredDevicesByCustomerID.fulfilled,
      (state, action) => {
        state.loading = false;
        state.failed = false;
        state.date = new Date().toISOString();
        state.data = action.payload;
      }
    );
    builder.addCase(fetchStoredDevicesByCustomerID.pending, state => {
      state.loading = true;
      state.failed = false;
    });
    builder.addCase(fetchStoredDevicesByCustomerID.rejected, state => {
      state.loading = false;
      state.failed = true;
    });
  },
});

export const {
  removedInventoryDeviceFromOnboarding,
  selectedInventoryDeviceForOnboarding,
  deviceSaveReservationError,
  deviceDeleteReservationError,
  resetInventoryOnboardingState,
  clickedStoredDeviceDetailsForDeviceID,
  clickedHideStoredDeviceDetailsForDeviceID,
  resetStoredDevicesState,
} = storedDevicesSlice.actions;
