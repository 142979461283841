import TimedAlert from './TimedAlert';
import ongoingAlerts from './ongoingAlerts';

const TimedAlertContainer = () => {
  return (
    <>
      {ongoingAlerts.map(alert => (
        <TimedAlert key={alert.id} {...alert} />
      ))}
    </>
  );
};

export default TimedAlertContainer;
