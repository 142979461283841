import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { COUNTRIES } from '@turbine/lib/addresses/constants';
import {
  CREATE_NEW_EMAIL_OPTION,
  YesNoOptions,
} from '@turbine/lib/xboarding/constants';
import {
  type ReplaceDatesWithISO,
  replaceDatesWithISO,
} from '@turbine/helpers/replaceDatesWithISO';
import {
  type OnboardingDataProps,
  parseEmployeeInformation,
} from '@turbine/pages/NewOnboarding/utils/parseOnBoardingData';
import { type SelectOption } from '@turbine/types/SelectOption';
import { fetchOnboardingDraft } from './draftOnboardingActions';
import { createEmail } from '@turbine/lib/xboarding/helpers';
import { type Employee } from '../employees';
import { resetNewOnboarding } from './newOnboardingThunks';

export type WelcomeEmailFormValues = {
  email?: string;
  date?: string;
  time?: SelectOption<string>;
  timeZone?: SelectOption<string>;
  notes?: string;
};

export type EmployeeInformationFormValues = {
  firstName: string;
  lastName: string;
  scheduledAt?: string;
  employmentType: string;
  department?: SelectOption;
  address: string;
  remoteAddress?: Address;
  emailFormats: string[];
  professionalEmail?: SelectOption;
  professionalEmailFormat?: string;
  professionalEmailDomain?: SelectOption<string>;
  emailFormatsNotes: string;
  notifyWhenOnboardingComplete: boolean;
  hasWelcomeEmail: string;
  welcomeEmail: WelcomeEmailFormValues;
  jobTitle: string;
  manager?: Employee | null;
};

export type Address = {
  city: string;
  country: string;
  name: string;
  officeId?: string;
  state: string;
  streetAddress1: string;
  streetAddress2: string;
  zip: string;
  phone?: string;
  officePhone?: string;
};

export const defaultAddress: Address = {
  city: '',
  country: '',
  name: '',
  officeId: '',
  state: '',
  streetAddress1: '',
  streetAddress2: '',
  zip: '',
};

export const defaultRemoteAddress: Address = {
  city: '',
  country: COUNTRIES.USA,
  name: '',
  officeId: '',
  state: '',
  streetAddress1: '',
  streetAddress2: '',
  zip: '',
};

export type EmployeeInformation = {
  firstName: string;
  lastName: string;
  scheduledAt?: string;
  startDate?: string;
  employmentType: string;
  department?: SelectOption<string>;
  address: string;
  remoteAddress?: Address;
  professionalEmail?: SelectOption<string>;
  professionalEmailFormat?: string;
  professionalEmailDomain?: SelectOption<string>;
  emailAlreadyExists?: boolean;
  allowSubmitExistingEmail?: string;
  emailFormatsNotes: string;
  notifyWhenOnboardingComplete: boolean;
  hasWelcomeEmail?: string;
  welcomeEmail: WelcomeEmail;
  jobTitle: string;
  manager?: Employee | null;
};

export type WelcomeEmail = {
  email?: string;
  date?: string;
  time?: SelectOption<string>;
  timeZone?: SelectOption<string>;
  zonedDate?: string;
  notes?: string;
};

const initialState: EmployeeInformation = {
  firstName: '',
  lastName: '',
  scheduledAt: undefined,
  employmentType: '',
  department: undefined,
  address: '',
  remoteAddress: defaultRemoteAddress,
  professionalEmail: undefined,
  professionalEmailFormat: undefined,
  professionalEmailDomain: undefined,
  emailAlreadyExists: false,
  allowSubmitExistingEmail: YesNoOptions.No,
  emailFormatsNotes: '',
  hasWelcomeEmail: YesNoOptions.Yes,
  notifyWhenOnboardingComplete: true,
  welcomeEmail: {
    email: '',
    date: '',
    time: undefined,
    timeZone: undefined,
    notes: '',
  },
  jobTitle: '',
  manager: undefined,
};

const employeeInformation = createSlice({
  name: 'employeeInformation',
  initialState,
  reducers: {
    employeeInformationSet: {
      prepare(payload: EmployeeInformationFormValues) {
        return { payload: replaceDatesWithISO(payload) };
      },
      reducer(
        state,
        action: PayloadAction<
          ReplaceDatesWithISO<EmployeeInformationFormValues>
        >
      ) {
        const {
          professionalEmail,
          professionalEmailFormat,
          professionalEmailDomain,
        } = action.payload;

        if (CREATE_NEW_EMAIL_OPTION.isOption(professionalEmail?.value)) {
          state = action.payload;

          const email = createEmail(
            professionalEmailFormat,
            professionalEmailDomain?.value
          );

          state.professionalEmail = { label: email, value: email };

          return state;
        }
        return (state = action.payload);
      },
    },
    emailAccountUpdated: (
      state,
      action: PayloadAction<EmployeeInformation>
    ) => {
      state.professionalEmail = action.payload.professionalEmail;
      state.emailFormatsNotes = action.payload.emailFormatsNotes;
    },
    emailAlreadyExistsUpdate: (state, action: PayloadAction<boolean>) => {
      state.emailAlreadyExists = action.payload;
    },
    allowSubmitExistingEmailUpdate: (state, action: PayloadAction<string>) => {
      state.allowSubmitExistingEmail = action.payload;
    },
    welcomeEmailUpdated: {
      prepare(payload: Pick<EmployeeInformationFormValues, 'welcomeEmail'>) {
        return { payload: replaceDatesWithISO(payload) };
      },
      reducer(state, action: PayloadAction<{ welcomeEmail: WelcomeEmail }>) {
        state.welcomeEmail = {
          ...state.welcomeEmail,
          ...action.payload.welcomeEmail,
        };
      },
    },
    resetEmployeeInformation: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(resetNewOnboarding, () => initialState);
    builder.addCase(
      fetchOnboardingDraft.fulfilled,
      (state, action: PayloadAction<OnboardingDataProps>) => {
        if (action?.payload) {
          const payload = action?.payload;
          const employeeInformation = parseEmployeeInformation(payload);

          return employeeInformation;
        }
        return initialState;
      }
    );
  },
});

export const employeeInformationInitialState = initialState;

export const {
  emailAccountUpdated,
  employeeInformationSet,
  welcomeEmailUpdated,
  resetEmployeeInformation,
  emailAlreadyExistsUpdate,
  allowSubmitExistingEmailUpdate,
} = employeeInformation.actions;
export default employeeInformation.reducer;
