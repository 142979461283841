import { gql } from '@apollo/client';
import { type XboardingStatusValues } from '@turbine/lib/xboarding/constants';
import { type OffboardingDataProps } from '../types/boardingTypes';

export const FETCH_OFFBOARDING = gql`
  query Offboarding($customerId: String!, $id: String!) {
    offboarding(customer_id: $customerId, id: $id) {
      created_at
      updated_at
      customer_id
      requester_id
      requester_email
      status
      id
      scheduled_at
      scheduledAt
      scheduled_at_iso8601
      scheduleTimeZone
      canceled_at
      tasks {
        created_at
        updated_at
        id
        kind
        title
        issue_id
        taskable_type
        taskable_id
        status
        seq
      }
      internal_tasks {
        created_at
        updated_at
        id
        kind
        title
        issue_id
        taskable_type
        taskable_id
        status
        seq
      }
      softwares {
        id
        delete
        transferTo
        additionalInfo
        additionalNotes
        accountDeletionDelay
      }
      accountInfo {
        OOOMessage
        forwardEmailsTo
        decomissionDevice
        shareCalendarWith
        transferGoogleDriveTo
        accountRemovalType
      }
      notes {
        oooMessageNotes
        shareCalendarNotes
        forwardEmailsToNotes
        transferGoogleDriveToNotes
        archiveEmailNotes
      }
      employeeInfo {
        lastName
        firstName
        department
        managerEmail
        professionalEmail
      }
      notifications {
        alertBeforeOffboarding
        notifyWhenOffboardingComplete
      }
      departmentHead
      deprovisioningHardware {
        name
        serial
        deprovisioningType
        returnDestination
        deprovisioningLocation
        returnShipping
        shippingFrom {
          name
          street_address_1
          street_address_2
          city
          state
          zip
          country
          office_id
          email_address
          phone
        }
        shippingTo {
          name
          street_address_1
          street_address_2
          city
          state
          country
          zip
          office_id
          email_address
          phone
        }
        deprovisioningAdditionalNotes
      }
      requestTimezone
      requestDateTime
    }
  }
`;

export const FETCH_BASIC_OFFBOARDINGS = gql`
  query BasicOffboardings(
    $customerId: String!
    $number: Int
    $size: Int
    $status: String
  ) {
    offboardings: basic_offboardings(
      customer_id: $customerId
      number: $number
      size: $size
      status: $status
    ) {
      firstName
      id
      lastName
      professionalEmail
      progress
      scheduledAt
      status
    }
  }
`;

export interface FetchBasicOffboardingsResponse {
  offboardings: FetchBasicOffboardingsData[] | null;
}

export interface FetchBasicOffboardingsData {
  firstName: string;
  id: string;
  lastName: string;
  professionalEmail: string;
  progress: number;
  scheduledAt: string | null;
  status: XboardingStatusValues;
}

export interface FetchOffboarding {
  offboarding: OffboardingDataProps;
}
