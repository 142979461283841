import { gql } from '@apollo/client';

export interface CustomerSoftwareApplication {
  id: string;
  customer_id: string;
  state: string;
  supported: boolean;
  application_admin_id?: string | null;
  software_application?: SoftwareApplication[] | null;
  application_admin?: ApplicationAdmin | null;
}

interface ApplicationAdmin {
  id: string;
  name: string;
  email: string;
  avatar?: string | null;
}
export interface SoftwareApplication {
  id: string;
  parent_id?: null;
  name: string;
  icon: string;
  description?: null;
  developer: string;
  supported: boolean;
  verified: boolean;
  internal: boolean;
  allows_deletion: boolean;
  allows_transfer: boolean;
  requires_account: boolean;
  requires_installation: boolean;
  created_at?: null;
  children?: (SoftwareApplicationsChildren | null)[] | null;
}
interface SoftwareApplicationsChildren {
  id: string;
  parent_id: string;
  name: string;
  icon: string;
  developer: string;
  description?: null;
}

export interface CustomerChatAccount {
  application_name: string;
  application_id: string;
  discarded_at: string;
  is_chat_source: string;
}

export const FETCH_CUSTOMER_APPLICATIONS = gql`
  query CustomerApplications($customerId: String!) {
    customer_software_applications(customer_id: $customerId) {
      id
      customer_id
      state
      software_application {
        id
        parent_id
        name
        icon
        supported
        developer
        description
      }
      application_admin {
        id
        name
        email
        avatar
      }
    }
    software_applications(supported: true) {
      id
      parent_id
      name
      icon
      description
      developer
      supported
      children {
        id
        parent_id
        name
        icon
        developer
        description
      }
    }
  }
`;

export interface FetchAllCustomerAndSoftwareApplications {
  customer_software_applications?: CustomerSoftwareApplication[] | null;
  software_applications?: SoftwareApplication[] | null;
}

export const FETCH_ALL_CUSTOMER_AND_SOFTWARE_APPLICATIONS = gql`
  query AllCustomerAndSoftwareApplications(
    $customerId: String!
    $states: [String]
  ) {
    customer_software_applications(customer_id: $customerId, states: $states) {
      id
      customer_id
      state
      supported
      application_admin_id
      software_application {
        id
        parent_id
        name
        icon
        description
        developer
        supported
        verified
        internal
        allows_deletion
        allows_transfer
        requires_account
        requires_installation
        created_at
      }
      application_admin {
        id
        name
        email
        avatar
      }
    }
    software_applications {
      id
      parent_id
      name
      icon
      description
      developer
      supported
      verified
      internal
      allows_deletion
      allows_transfer
      requires_account
      requires_installation
      created_at
      children {
        id
        parent_id
        name
        icon
        developer
        description
      }
    }
  }
`;

/**
 * @ToDo: once Multiple app admins feature is fully tested and released to all Turbine segments, legacy code should be removed.
 * https://electricops.atlassian.net/browse/IA1-2538
 */
export const FETCH_ALL_CUSTOMER_AND_SOFTWARE_APPLICATIONS_V2 = gql`
  query AllCustomerAndSoftwareApplications(
    $customerId: String!
    $states: [String]
  ) {
    customer_software_applications(customer_id: $customerId, states: $states) {
      id
      customer_id
      state
      supported
      application_admin_id
      software_application {
        id
        parent_id
        name
        icon
        description
        developer
        supported
        verified
        internal
        allows_deletion
        allows_transfer
        requires_account
        requires_installation
        created_at
      }
      application_admin {
        id
        name
        email
        avatar
      }
      application_admins {
        id
        name
        email
        avatar
      }
    }
    software_applications {
      id
      parent_id
      name
      icon
      description
      developer
      supported
      verified
      internal
      allows_deletion
      allows_transfer
      requires_account
      requires_installation
      created_at
      children {
        id
        parent_id
        name
        icon
        developer
        description
      }
    }
  }
`;

export const FETCH_CUSTOMER_APPLICATIONS_BY_STATE = gql`
  query CustomerApplicationsByState($customerId: String!, $states: [String]) {
    customer_software_applications(customer_id: $customerId, states: $states) {
      id
      customer_id
      state
      supported
      is_preapproved
      application_admin {
        id
        name
        email
      }
      software_application {
        id
        parent_id
        name
        icon
        description
        developer
        supported
        verified
        internal
        allows_deletion
        allows_transfer
        requires_account
        requires_installation
        created_at
      }
    }
  }
`;

export const GET_APP_ACCOUNT = gql`
  query CUSTOMER_APP_ACCOUNTS($customerId: String!) {
    customer_app_accounts(customer_id: $customerId) {
      application_name
      application_id
      discarded_at
      is_chat_source
    }
  }
`;
