async function loadIntersectionObserver() {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
}

function loadPolyfills() {
  loadIntersectionObserver();
  return true;
}

loadPolyfills();
