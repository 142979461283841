import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { i18nConfig } from './i18nConfig';

i18n
  .use(Backend)
  .use(initReactI18next) // bind react-i18next to the instance
  .init(i18nConfig);

export default i18n;
