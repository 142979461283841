import {
  SET_CUSTOMER_ACTIVATION,
  SET_CUSTOMER_IS_ACTIVE,
  SET_CUSTOMER_ACTIVATED_AT,
} from '../types/customerActivationTypes';

export const setCustomerActivation = ({ isActive, activatedAt }) => ({
  type: SET_CUSTOMER_ACTIVATION,
  payload: {
    isActive,
    activatedAt,
  },
});

export const setCustomerIsActive = isActive => ({
  type: SET_CUSTOMER_IS_ACTIVE,
  payload: isActive,
});

export const setCustomerActivatedOn = activatedAt => ({
  type: SET_CUSTOMER_ACTIVATED_AT,
  payload: activatedAt,
});
