import { gql } from '@apollo/client';

const SAVE_POLICY_CONFIGURATION = gql`
  mutation savePolicyConfiguration(
    $customerId: String!
    $configurationType: String!
    $configuration: String!
    $customerIsLive: Boolean!
    $requestorEmail: String!
  ) {
    savePolicyConfiguration(
      customer_id: $customerId
      configuration_type: $configurationType
      configuration: $configuration
      customer_is_live: $customerIsLive
      requester_email: $requestorEmail
    ) {
      response
    }
  }
`;

export default SAVE_POLICY_CONFIGURATION;
