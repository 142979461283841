import { useState } from 'react';
import { useQuery, type ApolloError } from '@apollo/client';

import GET_USER_DETAILS from '@turbine/graphql/queries/initializationQueries';

type userDetails = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  error?: ApolloError;
  loading: boolean;
};

export const useUserDetails = (customerId: string, employeeId: string) => {
  const [userDetails, setUserDetails] = useState<userDetails | null>(null);

  const { data, loading, error } = useQuery(GET_USER_DETAILS, {
    variables: { customerId, employeeId },
    onCompleted: () => {
      setUserDetails({ data, error, loading });
    },
  });

  return userDetails;
};
