import { type FC } from 'react';
import {
  Text as ArcText,
  type TextProps as ArcTextProps,
} from '@electricjs/arc';
import styled from 'styled-components';

const Text: FC<TooltipTextProps> = ({ ...restProps }) => (
  <ArcText {...restProps} variant="label-small" />
);

const TooltipText = styled(Text)``;

export type TooltipTextProps = Omit<ArcTextProps, 'variant'>;

export { TooltipText };
