import { memo } from 'react';
import { useSelector } from 'react-redux';

import { type IconNames } from '@electricjs/arc';
import { useBreakpoint } from '@electricjs/utils/hooks';
import { useFeatureFlags } from '@electricjs/utils/hooks';
import { PLANS } from '@turbine/constants/customerPlans';
import {
  TURBINE_MARKETPLACE,
  TURBINE_APPROVALS_PAGE,
  TURBINE_FRONTEND_HARDWARE_DATA,
  TURBINE_REPORTS_PAGE_MALWAREBYTES,
  TURBINE_REPORTS_ANNUAL_SUMMARY,
  TURBINE_REPORTS_PAGE_XBOARDING,
  TURBINE_REPORTS_SUPPORTED_EMPLOYEES,
  ELECTRIC_DEMO_DASHBOARD,
  TURBINE_ADHOC_REQUESTS,
} from '@turbine/constants/featureFlags';
import { customerPlanSelector } from '@turbine/redux/selectors/customerPlanSelector';
import { areUserPermissionsEmpty } from '@turbine/redux/selectors/userPermissionsSelector';
import { customerEnrollmentSelector } from '@turbine/redux/selectors/customerEnrollmentSelector';
import { selectHasChatAppState } from '@turbine/redux/applications';

import SideNavItem from './SideNavItem';
import CollapsibleSubNavWrapper from './CollapsibleSubNavWrapper';
import Nav from './SideNavBase';
import NavLogo from './NavLogo';

const SideNav = () => {
  const featureFlags = useFeatureFlags();
  const { md } = useBreakpoint();
  const { loaded: enrollmentLoaded, customerEnrollmentCompleted } = useSelector(
    customerEnrollmentSelector
  );
  const { hasChatApp: hasChatApp } = useSelector(selectHasChatAppState);
  const isNavLoading =
    useSelector(areUserPermissionsEmpty) || !enrollmentLoaded;
  // we need to do a strict comparison here, otherwise, we could take undefined as false and get into
  // an undesired behavior.
  const isCustomerInEnrollment =
    enrollmentLoaded && customerEnrollmentCompleted === false;
  const plan = useSelector(customerPlanSelector);
  const hasMarketplace = featureFlags && featureFlags[TURBINE_MARKETPLACE];
  const hasReportingXBoarding =
    featureFlags && featureFlags[TURBINE_REPORTS_PAGE_XBOARDING];
  const hasMalwarebytes =
    featureFlags && featureFlags[TURBINE_REPORTS_PAGE_MALWAREBYTES];
  const hasAnnualSummary =
    featureFlags && featureFlags[TURBINE_REPORTS_ANNUAL_SUMMARY];
  const hasSupportedEmployeesReport =
    featureFlags && featureFlags[TURBINE_REPORTS_SUPPORTED_EMPLOYEES];
  const hasApprovals = featureFlags && featureFlags[TURBINE_APPROVALS_PAGE];
  const hasHardwareData =
    featureFlags && featureFlags[TURBINE_FRONTEND_HARDWARE_DATA];
  const hasDemoDashboardFeature =
    featureFlags && featureFlags[ELECTRIC_DEMO_DASHBOARD];
  const hasAdHocRequests = featureFlags && featureFlags[TURBINE_ADHOC_REQUESTS];

  const reportingNavItem: { text: string; icon: IconNames } = {
    text: 'Reporting',
    icon: 'chart-bar',
  };

  const reporting = hasHardwareData ? (
    <CollapsibleSubNavWrapper
      icon={reportingNavItem.icon}
      text={reportingNavItem.text}
      isLoading={isNavLoading}>
      <SideNavItem itemType="RequestsV2" isLoading={isNavLoading} />
      {hasReportingXBoarding && (
        <SideNavItem itemType="XBoarding" isLoading={isNavLoading} />
      )}
      <SideNavItem itemType="Hardware" isLoading={isNavLoading} />
      {hasMalwarebytes && (
        <SideNavItem itemType="Malwarebytes" isLoading={isNavLoading} />
      )}
      {hasAnnualSummary && (
        <SideNavItem itemType="AnnualSummary" isLoading={isNavLoading} />
      )}
      {hasSupportedEmployeesReport && !hasDemoDashboardFeature && (
        <SideNavItem itemType="SupportedEmployees" isLoading={isNavLoading} /> //Don't show on Demo account.
      )}
    </CollapsibleSubNavWrapper>
  ) : (
    <SideNavItem itemType="Requests" isLoading={isNavLoading} />
  );

  const LiteSideNav = () => (
    <>
      <SideNavItem itemType="ITScoreCard" isLoading={isNavLoading} />
      {hasMarketplace && (
        <SideNavItem itemType="Marketplace" isLoading={isNavLoading} />
      )}
      <SideNavItem itemType="People" isLoading={isNavLoading} />
      <SideNavItem itemType="DeviceManagement" isLoading={isNavLoading} />
      {hasAdHocRequests && (
        <SideNavItem itemType="ShopHardware" isLoading={isNavLoading} />
      )}
    </>
  );
  const BasicSideNav = () => (
    <>
      <SideNavItem itemType="ITScoreCard" isLoading={isNavLoading} />
      {hasMarketplace && (
        <SideNavItem itemType="Marketplace" isLoading={isNavLoading} />
      )}
      <SideNavItem itemType="People" isLoading={isNavLoading} />
      <SideNavItem itemType="Applications" isLoading={isNavLoading} />
      {hasAdHocRequests && (
        <SideNavItem itemType="ShopHardware" isLoading={isNavLoading} />
      )}
      <SideNavItem itemType="DeviceManagement" isLoading={isNavLoading} />
      {reporting}
    </>
  );
  const CoreSideNav = () => (
    <>
      <SideNavItem itemType="ITScoreCard" isLoading={isNavLoading} />
      {hasMarketplace && (
        <SideNavItem itemType="Marketplace" isLoading={isNavLoading} />
      )}
      <SideNavItem itemType="People" isLoading={isNavLoading} />
      <SideNavItem itemType="Applications" isLoading={isNavLoading} />
      {hasAdHocRequests && (
        <SideNavItem itemType="ShopHardware" isLoading={isNavLoading} />
      )}
      {hasApprovals && (
        <SideNavItem itemType="Approvals" isLoading={isNavLoading} />
      )}
      <SideNavItem itemType="DeviceManagement" isLoading={isNavLoading} />
      {reporting}
    </>
  );
  const PremiumSideNav = () => (
    <>
      <SideNavItem itemType="People" isLoading={isNavLoading} />
      <SideNavItem itemType="Applications" isLoading={isNavLoading} />
      {hasAdHocRequests && (
        <SideNavItem itemType="ShopHardware" isLoading={isNavLoading} />
      )}
    </>
  );
  if (isCustomerInEnrollment && hasChatApp) {
    return <Nav />;
  }
  // Only show the side nav once loading is done to prevent UI shifts as states are resolved
  return !isNavLoading ? (
    <Nav>
      <>
        {md && <NavLogo logoLink="/" />}
        {plan === PLANS.LITE && <LiteSideNav />}
        {plan === PLANS.BASIC && <BasicSideNav />}
        {plan === PLANS.CORE && <CoreSideNav />}
        {plan === PLANS.PREMIUM && <PremiumSideNav />}
      </>
    </Nav>
  ) : null;
};

export default memo(SideNav);
