import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_LOCATIONS } from '@turbine/graphql/queries/locations';
import { type AppThunkConfigExtra } from '../store';

export type FetchLocationsParams = {
  customerID: string;
};

export const fetchLocations = createAsyncThunk(
  'locations/fetchLocations',
  async ({ customerID }: FetchLocationsParams, thunkConfig) => {
    const { extra } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const locationsQuery = {
      query: FETCH_LOCATIONS,
      variables: {
        customerId: customerID,
      },
    };

    try {
      const result = await client().query(locationsQuery);
      return result.data?.offices_v2;
    } catch (error) {
      window?.DD_RUM?.addError(error);
    }
  }
);
