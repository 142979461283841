import PropTypes from 'prop-types';
import {
  BreakpointProvider,
  UserPermissionProvider,
} from '@electricjs/utils/hooks';
import QUERIES from '@turbine/constants/queries';
import { useSelector, shallowEqual } from 'react-redux';
import { userPermissionsSelector } from '@turbine/redux/selectors/userPermissionsSelector';
import NavigationProvider from './NavigationProvider';

export default function ContextProviders({
  enrollmentCompleted,
  hasChatApp,
  children,
}) {
  const { userPermissions = [] } =
    useSelector(userPermissionsSelector, shallowEqual) || {};
  return (
    <BreakpointProvider queries={QUERIES}>
      <UserPermissionProvider value={userPermissions}>
        <NavigationProvider
          enrollmentCompleted={enrollmentCompleted}
          hasChatApp={hasChatApp}>
          {children}
        </NavigationProvider>
      </UserPermissionProvider>
    </BreakpointProvider>
  );
}

ContextProviders.propTypes = {
  children: PropTypes.node.isRequired,
  enrollmentCompleted: PropTypes.bool,
  hasChatApp: PropTypes.bool,
};
