import styled from 'styled-components';
import { Text, getToken, ColorsV2 } from '@electricjs/arc';
import i18next from 'i18next';

const StyledSubheading = styled('div')`
  margin-bottom: 18px;
`;

const StyledParagraph = styled(Text)`
  font-size: 16px;
  color: ${getToken(ColorsV2.TEXT)};
  margin-bottom: 14px;
`;

const StyledName = styled.span`
  font-weight: 500;
`;

const StyledDesc = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

type getOwnerModalProps = {
  action: string;
  currentOwner: string | null;
  isTraceable: boolean | null;
  username: string;
  deviceSerial?: string;
  deviceHostname?: string;
};

type OwnerModalText = {
  title: string;
  continueText: string;
  subheading: JSX.Element;
};

const getOwnerModalText = ({
  action,
  currentOwner,
  isTraceable,
  username,
  deviceSerial,
  deviceHostname,
}: getOwnerModalProps): OwnerModalText => {
  const assignHeader = i18next.t('deviceManagement:ownerModal.assignHeader', {
    defaultValue: 'Who should this device be assigned to?',
  });
  const assignContinue = i18next.t(
    'deviceManagement:ownerModal.assignContinue',
    {
      defaultValue: 'Assign',
    }
  );
  const unassignHeader = i18next.t(
    'deviceManagement:ownerModal.unassignHeader',
    {
      currentOwner,
      defaultValue: 'Remove {{currentOwner}} from this device?',
    }
  );
  const unassignOwnerHeader = i18next.t(
    'deviceManagement:ownerModal.unassignOwnerHeader',
    { currentOwner, defaultValue: 'Remove this device from {{currentOwner}}?' }
  );
  const unassignContinue = i18next.t(
    'deviceManagement:ownerModal.unassignContinue',
    {
      defaultValue: 'Unassign',
    }
  );
  const unassignOwnerContinue = i18next.t(
    'deviceManagement:ownerModal.unassignOwnerContinue',
    { defaultValue: 'Remove device' }
  );
  const ExistingUsername = () => {
    return (
      <StyledParagraph>
        {i18next.t('deviceManagement:ownerModal.existingUsername', {
          defaultValue: 'The current user profile on this device is ',
        })}
        <StyledName>{currentOwner || username}</StyledName>
        {'.'}
      </StyledParagraph>
    );
  };
  const ReassignUsername = () => {
    return (
      <StyledParagraph>
        {i18next.t('deviceManagement:ownerModal.reassignUsername', {
          defaultValue: "We'll remove ",
        })}
        <StyledName>{currentOwner}</StyledName>
        {i18next.t('deviceManagement:ownerModal.reassignUsername2', {
          defaultValue: ' and assign it to the employee selected below.',
        })}
      </StyledParagraph>
    );
  };
  const serialDisclaimer = i18next.t(
    'deviceManagement:ownerModal.serialDisclaimer',
    {
      defaultValue:
        'This device doesn’t have a serial number; this is common with Virtual Machines or custom-built computers. In this situation, Electric uses the MDM ID for tracking purposes. If the MDM software is removed or reinstalled, the MDM ID will change, and the assigned user will be removed.',
    }
  );
  const unassignDisclaimer = i18next.t(
    'deviceManagement:ownerModal.unassignDisclaimer',
    {
      defaultValue:
        'The device will still be registered in our system and you can assign a new user at any time.',
    }
  );
  switch (action) {
    case 'Assign':
      return {
        title: assignHeader,
        continueText: assignContinue,
        subheading: (
          <StyledSubheading>
            {username && ExistingUsername()}
            {!isTraceable && (
              <StyledParagraph>{serialDisclaimer}</StyledParagraph>
            )}
          </StyledSubheading>
        ),
      };
    case 'Reassign':
      return {
        title: assignHeader,
        continueText: assignContinue,
        subheading: (
          <StyledSubheading>
            {username && ReassignUsername()}
            {!isTraceable && (
              <StyledParagraph>{serialDisclaimer}</StyledParagraph>
            )}
          </StyledSubheading>
        ),
      };
    case 'Unassign':
      return {
        title: unassignHeader,
        continueText: unassignContinue,
        subheading: (
          <StyledSubheading>
            <StyledParagraph>{unassignDisclaimer}</StyledParagraph>
          </StyledSubheading>
        ),
      };
    case 'Unassign-Owner':
      return {
        title: unassignOwnerHeader,
        continueText: unassignOwnerContinue,
        subheading: (
          <>
            <StyledParagraph mt={3}>
              Serial:
              <StyledDesc>{deviceSerial}</StyledDesc>
            </StyledParagraph>
            <StyledParagraph>
              Hostname:
              <StyledDesc>{deviceHostname}</StyledDesc>
            </StyledParagraph>
          </>
        ),
      };
    default:
      return {
        title: 'Unkwown Action',
        continueText: 'Unkwown Action:',
        subheading: <StyledParagraph>Unkwown Action</StyledParagraph>,
      };
  }
};

export default getOwnerModalText;
