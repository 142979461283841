import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { bemify } from '@turbine/helpers/bemClassHelper';
import InnerModal from './ModalTypes';

const modalComponentClasses = bemify('et-modal');

export default class ModalComponent extends PureComponent {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalProps: PropTypes.shape({
      className: PropTypes.string.isRequired,
      overlayClassName: PropTypes.string.isRequired,
    }).isRequired,
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    Modal.setAppElement('#root');
  }

  handleCloseModal = e => {
    e.preventDefault();
    this.props.closeModal();
  };

  render() {
    const { modalType, modalProps, closeModal, isOpen } = this.props;
    const CloseModalButton = ({ modifierClass = '', ...otherProps }) => (
      <button
        className={modalComponentClasses('close', modifierClass)}
        onClick={this.handleCloseModal}
        {...otherProps}>
        &#10005;
      </button>
    );

    return (
      <Modal
        isOpen={isOpen}
        overlayClassName={modalProps.overlayClassName}
        className={modalProps.className}
        onRequestClose={this.handleCloseModal}>
        <InnerModal
          bemClasses={modalComponentClasses}
          modalType={modalType}
          modalProps={modalProps}
          handleCloseModal={closeModal}
          CloseButton={props => <CloseModalButton {...props} />}
        />
      </Modal>
    );
  }
}
