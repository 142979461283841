import { createSelector } from 'reselect';
import { get, getOr } from 'lodash/fp';

export const selectCustomers = get('customers');

export const customersSelector = createSelector(
  selectCustomers,
  (customers = {}) => customers
);

export const currentCustomerSelector = customerID =>
  createSelector(customersSelector, customers =>
    getOr({}, customerID, customers)
  );

/** For impersonate customer dropdown/select list */
export const customerOptionsSelector = createSelector(
  customersSelector,
  customers =>
    Object.values(customers).map(({ id = '', name = '' }) => ({
      value: id,
      label: name,
    }))
);
