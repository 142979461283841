import {
  type MouseEventHandler,
  type ReactChild,
  type ReactElement,
  useLayoutEffect,
  useState,
} from 'react';
import { useBreakpoint } from '@electricjs/utils/hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getToken, Icon, ColorsV2 } from '@electricjs/arc';

const StyledNav = styled.nav<{ $md: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 270px;
  min-width: 270px;
  background-color: ${getToken(ColorsV2.BACKGROUND_CONTENT)};
  box-shadow: 0px 0px 8px ${getToken(ColorsV2.GRAY_LIGHT)};
  overflow-x: hidden;
  border-left-style: solid;
  border-left-width: 6px;
  border-image-slice: 1;
  border-image-source: ${getToken(ColorsV2.GRADIENT_NAV)};
  // z-index puts this above the top nav
  z-index: 3;

  // Electric Logo for desktop/tablet, first nav item for mobile
  & > :first-child {
    margin: ${({ $md }) => ($md ? '2rem 0 2rem 2rem' : '3rem 0 0')};
  }

  > a.nav-item-turbine {
    margin-bottom: 2rem;
  }

  // First nav item for desktop/tablet gets more top margin
  ${({ $md }) =>
    $md &&
    `
    & > :nth-child(2) {
      margin-top: 2rem;
    }
  `};

  .sidenav-turbine__arrow-button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: auto 3rem 2rem auto;
  }

  // If the side nav has the collapsed class, apply these styles
  ${({ className }) =>
    className &&
    className.includes('sidenav-turbine--collapsed') &&
    `
      width: 7.6rem;
      min-width: 7.6rem;

      // All children nav item divs
      & > div {
        padding: 0 1.2rem;
      }

      div a.nav-item-turbine,
      div.collapsible-subnav > .collapsible-subnav__section {
        div[data-testid='arc-icon'] {
          margin-left: 1.3rem;
        }

        > :not(:first-child) {
          display: none;
        }
      }

      div.collapsible-subnav {
        &__section {
          padding-top: 0.8rem;
        }

        &__items {
          display: none;
          transition: none;
        }
      }

      .sidenav-turbine__arrow-button {
        margin-right: auto;
      }

      // Electric logo clipping
      .nav-logo-turbine {
        clip-path: inset(0 75px 0 0);
      }
  `}
`;

const SideNavComponent = ({
  children,
}: {
  children?: ReactChild | ReactChild[];
}): ReactElement => {
  const { md, lg } = useBreakpoint();
  const isCollapsible = md && !lg;
  const [collapsed, setCollapsed] = useState(false);
  useLayoutEffect(() => setCollapsed(isCollapsible), [isCollapsible]);
  const toggleSideNav = () =>
    isCollapsible && setCollapsed(previousState => !previousState);
  const shouldTriggerToggle = (target: EventTarget | null) => {
    if (target instanceof Element) {
      return (
        (target && target.closest('.collapsible-subnav') && collapsed) ||
        (target && target.tagName === 'NAV')
      );
    }
    return false;
  };
  const triggerToggleIfNeeded: MouseEventHandler<HTMLElement> = ({ target }) =>
    shouldTriggerToggle(target) && toggleSideNav();
  type ArrowIconProps = {
    icon: 'chevron-right' | 'chevron-left';
    iconSize: number;
    color: string;
  };
  const arrowIcon: ArrowIconProps = {
    icon: collapsed ? 'chevron-right' : 'chevron-left',
    iconSize: 16,
    color: 'var(--colorV2-primary)',
  };

  return (
    <StyledNav
      data-testid="sidenav"
      onClick={triggerToggleIfNeeded}
      role="presentation"
      $md={md}
      className={`sidenav-turbine${
        collapsed ? ' sidenav-turbine--collapsed' : ''
      }`}>
      {children}
      {isCollapsible && (
        <button
          onClick={toggleSideNav}
          className="sidenav-turbine__arrow-button"
          data-testid={'arrow-button'}>
          <Icon icon={arrowIcon.icon} color={ColorsV2.TEXT} size="small" />
        </button>
      )}
    </StyledNav>
  );
};

SideNavComponent.propTypes = {
  children: PropTypes.node,
};

export default SideNavComponent;
