import { Pane, Spinner, Text } from '@electricjs/arc';
import { browserHistory as history } from '@electricjs/utils/helpers';
import { useSession } from '@turbine/helpers/hooks';
import { lazy, Suspense } from 'react';
import { Router as RouterDOM, Switch } from 'react-router-dom';
import { GuardedRoute, GuardProvider } from 'react-router-guards';
import {
  requireCustomerEnrolled,
  requireLogin,
  requirePermissions,
  requirePlans,
  requireFeatureFlags,
} from './guards';
import { mapRoutes } from './helpers';
import { routes as noAuthRoutes } from './noAuthRoutes';
import RenderApp from './RenderApp';

const PageNotFound = lazy(() => import('@turbine/components/PageNotFound'));

const GLOBAL_GUARDS = [
  requireLogin,
  requireCustomerEnrolled,
  requirePermissions,
  requirePlans,
  requireFeatureFlags,
];

const Loading = () => (
  <Pane
    withPadding
    width="100vw"
    height="100vh"
    vertical="center"
    horizontal="center">
    <Spinner size="large" />
  </Pane>
);
// TODO: define a loading error
const Error = props => (
  <Pane withPadding stack>
    <Text intent="error" variant="heading-1">
      Routing error...
    </Text>
    <Text intent="error" variant="subheading">
      {props.error}
    </Text>
    {process.env.NODE_ENV === 'development' && (
      <Pane withPadding elevated mt={2}>
        <Text intent="error" tag="pre">
          {JSON.stringify(props, null, 2)}
        </Text>
      </Pane>
    )}
  </Pane>
);

const Router = () => {
  const session = useSession();

  return (
    <RouterDOM history={history}>
      <GuardProvider guards={GLOBAL_GUARDS} loading={Loading} error={Error}>
        <Suspense fallback={<Loading />}>
          <Switch>
            {session.isAuthenticated ? (
              <RenderApp />
            ) : (
              mapRoutes(noAuthRoutes, { meta: { session } })
            )}
            <GuardedRoute
              path="*"
              component={PageNotFound}
              meta={{ auth: true, session }}
            />
          </Switch>
        </Suspense>
      </GuardProvider>
    </RouterDOM>
  );
};

export default Router;
