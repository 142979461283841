import { GuardedRoute } from 'react-router-guards';

export const mapRoutes = (routes, { meta: globalMeta } = {}) =>
  routes.map(
    ({
      path,
      exact = true,
      component: Component,
      meta,
      loading,
      error,
      ignoreGlobal,
      guards,
      ...routeProps
    }) => (
      <GuardedRoute
        exact={exact}
        key={path}
        path={path}
        meta={{ ...globalMeta, ...meta }}
        loading={loading}
        error={error}
        guards={guards}
        ignoreGlobal={ignoreGlobal}
        render={renderProps => <Component {...routeProps} {...renderProps} />}
      />
    )
  );
