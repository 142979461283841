import { combineReducers } from '@reduxjs/toolkit';
import employeeInformation from './employeeInformationSlice';
import formState from './formStateSlice';
import taskList from './taskListSlice';

export const newOffboardingReducer = combineReducers({
  employeeInformation,
  taskList,
  formState,
});
