import isPlainObject from 'lodash/isPlainObject';

export type ReplaceDatesWithISO<MaybeDate> = MaybeDate extends Date
  ? string
  : MaybeDate extends Array<infer Item>
  ? Array<ReplaceDatesWithISO<Item>>
  : MaybeDate extends Record<string, unknown>
  ? { [Key in keyof MaybeDate]: ReplaceDatesWithISO<MaybeDate[Key]> }
  : MaybeDate;

export function replaceDatesWithISO<T>(value: T): ReplaceDatesWithISO<T> {
  if (value instanceof Date) {
    return value.toISOString() as ReplaceDatesWithISO<T>;
  }

  if (isPlainObject(value)) {
    return Object.entries(value as Record<string, unknown>).reduce(
      (acum, [key, value]) => ({
        ...acum,
        [key]: replaceDatesWithISO(value),
      }),
      {}
    ) as ReplaceDatesWithISO<T>;
  }

  if (Array.isArray(value)) {
    return value.map(replaceDatesWithISO) as ReplaceDatesWithISO<T>;
  }

  return value as ReplaceDatesWithISO<T>;
}
