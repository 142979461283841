import HistorySubscription from '@turbine/services/subscriptions/historySubscription';
import { PAGE_UPDATED } from '../types/historyTypes';

export const pageUpdated = ({ page, searchQuery }) => ({
  type: PAGE_UPDATED,
  page,
  searchQuery,
});

export const updatePage = () => dispatch => {
  const history = new HistorySubscription();
  history.subscribe(({ page, searchQuery }) =>
    dispatch(pageUpdated({ page, searchQuery }))
  );
};
