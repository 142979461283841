import queryString from 'query-string';

type Filter = { checked: boolean; disabled: boolean };
type SoftwareChecks = {
  firewall: Filter;
  filevault: Filter;
  gatekeeper: Filter;
  bitlocker: Filter;
};

export const updateUrl = (state: any) => {
  const parsedHash = queryString.parse(window.location.hash);
  const { os, status } = state.deviceManagement?.filters || {};
  const statusParams = [];
  if (status.good.checked) {
    statusParams.push('good');
  }
  if (status.nonReporting.checked) {
    statusParams.push('non-reporting');
  }

  const softwareCheckParams = Object.entries(
    status.softwareChecks as SoftwareChecks
  ).reduce((acc: Array<string>, [key, value]) => {
    if (value.checked) {
      return [...acc, key];
    }
    return acc;
  }, []);
  if (os === 'ALL') {
    delete parsedHash.osFilter;
  }

  const osFilterParams =
    os !== 'ALL'
      ? {
          osFilter: os.toLowerCase(),
        }
      : {};
  const updatedParams = {
    ...parsedHash,
    ...osFilterParams,
    statusFilters: [...statusParams, ...softwareCheckParams],
  };

  const newhash = queryString.stringify(updatedParams, {
    arrayFormat: 'comma',
  });
  window.location.hash = newhash;
};
