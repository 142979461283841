import { isValid, parseISO } from 'date-fns';

const getSafeDate = <K = undefined>(value: unknown, defaultValue?: K) => {
  if (value instanceof Date) {
    return value;
  }

  if (typeof value === 'string' && isValid(parseISO(value))) {
    return parseISO(value);
  }

  return defaultValue as K;
};

export { getSafeDate };
