import omit from 'lodash/omit';
import { createSelector } from '@reduxjs/toolkit';
import { REMOTE_ADDRESS_OPTION } from '@turbine/lib/addresses/constants';
import { type SelectOption } from '@turbine/types/SelectOption';
import { type RootState } from '../store';
import { type LocationsData } from './locationsSlice';

export const selectLocationRequestState = (state: RootState) =>
  state.locations.locationRequestState;
export const selectLocationsLoading = createSelector(
  selectLocationRequestState,
  locationRequestState => locationRequestState === 'pending'
);
export const selectLocationsFailed = createSelector(
  selectLocationRequestState,
  locationRequestState => locationRequestState === 'rejected'
);
export const selectLocationsFulfilled = createSelector(
  selectLocationRequestState,
  locationRequestState => locationRequestState === 'fulfilled'
);

export const selectLocations = (state: RootState): LocationsData | undefined =>
  state.locations.data;

export const selectLocationsState = createSelector(
  selectLocationsLoading,
  selectLocationsFailed,
  selectLocationsFulfilled,
  selectLocations,
  (loading, failed, fulfilled, locations) => ({
    loading,
    failed,
    fulfilled,
    locations,
  })
);

export const selectLocationOptions = createSelector(
  selectLocationsState,
  ({ loading, failed, fulfilled, locations }) => {
    const locationOptions = locations?.map<SelectOption<string, string>>(
      ({ id, name }) => ({
        value: id,
        label: name,
      })
    );
    locationOptions?.push({
      value: REMOTE_ADDRESS_OPTION.value,
      label: REMOTE_ADDRESS_OPTION.label,
    });
    return { loading, failed, fulfilled, locationOptions };
  }
);

export const selectLocationById = createSelector(
  [selectLocationsState, (_state: RootState, locationId: string) => locationId],
  ({ loading, failed, fulfilled, locations }, locationId) => {
    const location =
      locationId === REMOTE_ADDRESS_OPTION.value
        ? { id: REMOTE_ADDRESS_OPTION.value }
        : locations?.filter(location => location.id === locationId)[0];

    return { loading, failed, fulfilled, location };
  }
);
