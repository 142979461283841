import { useState, useLayoutEffect, useCallback } from 'react';
import { useBreakpoint } from '@electricjs/utils/hooks';
import TimedAlertContainer from '../TimedAlert/TimedAlertContainer';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import SideNav from '../SideNav';
import TopNav from '../TopNav';
import { useFullPageLayout } from '@turbine/helpers/hooks/useFullPageLayout';

const NavigationContainer = styled.div`
  height: 100vh;
  display: flex;
  // If mobile or full page, use fully expanded top nav styles
  ${ifProp(
    '$showFullTopNav',
    'flex-direction: column;',
    'overflow: auto; height: 100%;'
  )}
`;

const Content = styled.div`
  display: flex;
  // If mobile or full page, use fully expanded top nav styles
  ${ifProp(
    '$showFullTopNav',
    'overflow: auto; height: 100%;',
    'flex-direction: column; width: 100%; min-width: 0;'
  )}
`;

const NavigationProvider = ({ children, enrollmentCompleted, hasChatApp }) => {
  const { md } = useBreakpoint();
  const [showSideNav, setShowSideNav] = useState(true);
  const toggleSideNav = useCallback(
    () => setShowSideNav(prevState => !prevState),
    []
  );
  const isFullPageLayout = useFullPageLayout();
  useLayoutEffect(() => {
    setShowSideNav(md);
  }, [md]);
  const showFullTopNav = !md || isFullPageLayout || !enrollmentCompleted;
  // RL-2083: show the side nav if this is a non-chat customer, since
  // non-chat custumers won't have enrollmentCompleted.
  const shouldShowSideNav =
    showSideNav && !isFullPageLayout && (!hasChatApp || enrollmentCompleted);

  return (
    <NavigationContainer $showFullTopNav={showFullTopNav}>
      {showFullTopNav ? (
        <>
          <TopNav
            onMenuClick={toggleSideNav}
            enrollmentCompleted={enrollmentCompleted}
          />
          <Content $showFullTopNav={showFullTopNav}>
            {shouldShowSideNav && <SideNav />}
            <TimedAlertContainer />
            {children}
          </Content>
        </>
      ) : (
        <>
          {shouldShowSideNav && <SideNav />}
          <Content $showFullTopNav={showFullTopNav}>
            <TopNav
              onMenuClick={toggleSideNav}
              enrollmentCompleted={enrollmentCompleted}
            />
            <TimedAlertContainer />
            {children}
          </Content>
        </>
      )}
    </NavigationContainer>
  );
};

export default NavigationProvider;

NavigationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  enrollmentCompleted: PropTypes.bool,
  hasChatApp: PropTypes.bool,
};
