import mergeWith from 'lodash/mergeWith';
/* Based on Ruby Enumerable.partition method:
partition(array, callback(arrayElement): boolean) : [[trueElements], [otherElements]]
Being:
  callback: Predicate Function, to test each element of the array. It receives:
    arrayElement: The current element being processed from array
Return value:
  A new Array with 2 arrays.
    The first array contains all the elements that pass the test
    The second array contains all the other cases.
Usage example:
  const isEven = value => value % 2 === 0;
  partition([5, 2, 4, 7, 8, 3], isEven)   => [[2, 4, 8], [5, 7, 3]]
  partition([], isEven)                   => [[], []]
  partition([1, 2, 3], () => {})          => [[], [1, 2, 3]]
*/
export const partition = <T = unknown>(
  array: T[],
  predicate: (value: T) => unknown
): Array<T[]> => {
  return array.reduce(
    ([pass, fail], value) =>
      predicate(value) ? [[...pass, value], fail] : [pass, [...fail, value]],
    [[], []] as Array<T[]>
  );
};

export const updateObjectInArray = <T = unknown>(
  array: T[],
  action: { item: T; index: number }
): Array<T> =>
  array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item,
    };
  });

/* 
 checks that the array length is greather than zero
 e.g: arrayHasLength(['value']) => true
 e.g: arrayHasLength([]) => false
 */
export const arrayHasLength = (
  array: unknown[] | null | undefined
): boolean => {
  return Array.isArray(array) && array.length > 0;
};

export function mergeReplacingArrays<TObject, TSource>(
  object: TObject,
  source: TSource
): TSource {
  return mergeWith(object, source, (_, b) =>
    Array.isArray(b) ? b : undefined
  );
}
