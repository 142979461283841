import { type ChangeEvent, type ReactElement, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Text,
  ModalV2,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  TextInput,
} from '@electricjs/arc';
import styled from 'styled-components';
import { useSession } from '@turbine/helpers/hooks';
import { impersonateCustomer } from '@turbine/redux/actions/impersonateCustomerActions';
import {
  customerOptionsSelector,
  customersSelector,
} from '@turbine/redux/selectors/customersSelector';

const StyledTextInput = styled(TextInput)`
  margin-bottom: 1rem;
`;

const StyledDiv = styled.div`
  overflow-y: scroll;
  max-height: 350px;
`;

type SwitchCompanyModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SwitchCompanyModal = ({
  isOpen,
  onClose,
}: SwitchCompanyModalProps): ReactElement => {
  const dispatch = useDispatch();
  const {
    isAdmin,
    token: accessToken,
    customerId,
    setCustomerId,
    userId: employeeId,
  } = useSession();
  const switchCustomerOptions = useSelector(customerOptionsSelector);
  const currentCustomer = useSelector(customersSelector)[customerId];
  const selectCustomer = (value: string) => {
    dispatch(
      impersonateCustomer(
        value,
        isAdmin,
        accessToken,
        employeeId,
        setCustomerId
      )
    );
  };

  const [searchValue, setSearchValue] = useState('');
  const [filteredCustomerOptions, setFilteredCustomerOptions] = useState(
    switchCustomerOptions
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const result = switchCustomerOptions.filter(customer =>
      customer.label.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredCustomerOptions(result);
    setSearchValue(e.target.value);
  };

  return (
    <ModalV2 visible={isOpen} hide={onClose} ariaLabelledby="switch-company">
      <ModalHeader showCloseButton={true}>
        <Text variant="heading-2">Switch Company</Text>
      </ModalHeader>
      <ModalBody>
        <StyledTextInput
          placeholder="Search here"
          icon="search"
          iconPosition="left"
          value={searchValue}
          onChange={handleSearch}
        />
        <StyledDiv>
          {filteredCustomerOptions
            .sort((a, b) => a.label.localeCompare(b.label))
            .map(customer => (
              <Button
                key={customer.value}
                iconAfter="arrow-right-circle"
                variant="text"
                onClick={() => {
                  if (customer.value !== currentCustomer.id)
                    selectCustomer(customer.value);
                  onClose();
                }}>
                {customer.label}
              </Button>
            ))}
        </StyledDiv>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </ModalV2>
  );
};

export default SwitchCompanyModal;
