import customersActionsFromProxyFlare from './customersActionsFromProxyFlare';
import { fetchPolicy } from './policyActions';

export default function initApp({
  dispatch,
  accessToken,
  customerID,
  isAdmin,
  hasSaasAppNotes,
}) {
  const customerAction = isAdmin
    ? customersActionsFromProxyFlare.fetch(accessToken)
    : customersActionsFromProxyFlare.fetchSingleCustomer(
        accessToken,
        customerID
      );
  dispatch(customerAction);
  dispatch(fetchPolicy({ customerID, hasSaasAppNotes }));
}
