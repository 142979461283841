import { useState } from 'react';
import { ColorsV2, getToken } from '@electricjs/arc';
import {
  ModalV2,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalAction,
  Text,
  Spinner,
  RadioGroup,
  CopyButton,
  Flex,
  Radio,
  Link,
  Pane,
  Checkbox,
} from '@electricjs/arc';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useSession } from '@turbine/helpers/hooks';
import { useToast } from '@electricjs/arc';
import { CREATE_TEMP_CREDENTIALS } from '@turbine/graphql/mutations/tempCredentialsMutations';
import { NOTIFY_CUSTOMER_CONFIRMED_EMAIL } from '@turbine/graphql/mutations/selfServiceNotificationMutations';
import {
  GOOGLE_WORKSPACE_ADMIN_URL,
  MICROSOFT_365_ADMIN_URL,
} from '@turbine/config';
import { type SoftwareApplication } from '@turbine/redux/applications';

export const StyledRadioGroup = styled(RadioGroup)`
  align-content: center;
  label {
    border-style: solid;
    border-radius: 0.4rem;
    border-width: 2px;
    border-color: ${getToken(ColorsV2.PRIMARY_DARK)};
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0.5rem 0 0.5rem 0;
  }
  label span {
    margin-right: 2rem;
  }
  label input {
    padding-top: 1rem;
  }
`;

export const StyledRadio = styled(Radio)`
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 10rem;
`;

export const StyledFlex = styled(Flex)`
  padding-top: 1.6rem;
  padding-bottom: 1rem;
`;

export const StyledText = styled(Text)`
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 1rem;
`;

export const StyledTextData = styled(Text)`
  margin-left: 5rem;
  margin-right: 1rem;
`;

export const StyledFlexLoginData = styled(Flex)`
  padding-left: 10rem;
`;

export const StyledPane = styled(Pane)`
  margint-top: 2rem;
`;

const CheckboxFlex = styled(Flex)`
  margin-top: 0.625rem;
`;

export type AddEmailProviderModalProps = {
  visible: boolean;
  show: (visible: boolean) => void;
  allAppsLoading: boolean;
  allApps: SoftwareApplication[];
};

export enum EmailProviderModalSteps {
  selectEmailProvider = 'Select Email Provider',
  linkToEmailProviderSite = 'Link to Email Provider Site',
}

// Enum for email providers.
// IMPORTANT: These enum values must match the values stored in the
// software_applications table in the database. Any changes made to the
// corresponding database must be reflected in this enum to ensure consistency.
export enum EmailProviderEnum {
  notSelected = '',
  microsoft = 'Microsoft 365',
  google = 'Google Workspace',
}

const NEXT_BUTTON_LABEL: Record<string, string> = {
  'Select Email Provider': 'Continue',
  'Link to Email Provider Site': 'Confirm account',
};

const AddEmailProviderModal = ({
  visible,
  show,
  allAppsLoading,
  allApps,
}: AddEmailProviderModalProps): JSX.Element => {
  const { customerId } = useSession();
  const emailProviderRole = 'Super user';

  const [step, setStep] = useState<string>(
    EmailProviderModalSteps.selectEmailProvider
  );

  const [emailProvider, setEmailProvider] = useState<EmailProviderEnum>(
    EmailProviderEnum.notSelected
  );

  const [accountIsCreated, setAccountIsCreated] = useState<boolean>(false);

  const [emailProviderAccount, setEmailProviderAccount] = useState('');
  const [emailProviderPwd, setEmailProviderPwd] = useState('');

  const { toast, showToast } = useToast({
    intent: 'success',
    message: 'Email provider confirmed.',
  });

  const [
    sendCreateTemporaryCredentials,
    { loading: temporaryCredentialsLoading },
  ] = useMutation(CREATE_TEMP_CREDENTIALS);

  const [customerCreatedEmailAccount] = useMutation(
    NOTIFY_CUSTOMER_CONFIRMED_EMAIL,
    {
      onCompleted: () => {
        showToast();
      },
      onError: () => {
        showToast({
          intent: 'error',
          message: 'Something went wrong. Please try again.',
        });
      },
    }
  );

  const handleOnHide = () => {
    setStep(EmailProviderModalSteps.selectEmailProvider);
    show(false);
  };

  /**
   * Returns the application ID for a given email provider.
   * @param emailProvider The email provider for which to retrieve the application ID.
   * @param allApps An array of objects containing the name and application ID of each
   * software application retrivied from the database (Redux store).
   * @returns The application ID of the specified email provider, or an empty string if
   * the provider is not found.
   */
  const getEmailProviderApplicationId = (
    emailProvider: EmailProviderEnum,
    allApps: SoftwareApplication[] | null
  ) => {
    const app = allApps?.find(obj => obj.name === emailProvider);
    return app ? app.id : '';
  };

  const handleOnSubmit = async () => {
    const applicationId = getEmailProviderApplicationId(emailProvider, allApps);
    await customerCreatedEmailAccount({
      variables: {
        customerId,
        data: { application_id: applicationId },
      },
    });
    handleOnHide();
  };

  const onBack = () => {
    setStep(EmailProviderModalSteps.selectEmailProvider);
  };

  const stepIs = (step: string, activeStep: string) => step === activeStep;

  const onNext = () => {
    if (stepIs(EmailProviderModalSteps.selectEmailProvider, step)) {
      // Make request to api-signup for temp credentials
      sendCreateTemporaryCredentials().then(({ data }) => {
        setEmailProviderAccount(data.createTempCredentials.email);
        setEmailProviderPwd(data.createTempCredentials.temp_pass);
      });

      setStep(EmailProviderModalSteps.linkToEmailProviderSite);
    }
    if (stepIs(EmailProviderModalSteps.linkToEmailProviderSite, step)) {
      // Send customer confirmed notification
      handleOnSubmit();
    }
  };

  const handleEmailProviderChange = (e: any) => {
    setEmailProvider(e.target.value);
  };

  const buildModalHeader = () => {
    if (step === EmailProviderModalSteps.selectEmailProvider) {
      return (
        <Flex vertical>
          <Text variant="heading-2">Let&apos;s Get Started</Text>
          <Text>Choose your email provider</Text>
        </Flex>
      );
    } else if (step === EmailProviderModalSteps.linkToEmailProviderSite) {
      return (
        <Flex vertical>
          <Text variant="heading-2">Add Electric to your account</Text>
          <Text>
            In order for us to easily add or remove applications for your
            organization, you need to create an admin account for us in your
            provider&apos;s workspace.
          </Text>
        </Flex>
      );
    }
  };

  const buildEmailProviderSelector = () => {
    return (
      <StyledRadioGroup
        aria-label="email-providers"
        stack
        onChange={handleEmailProviderChange}>
        <StyledRadio
          value={EmailProviderEnum.google}
          checked={emailProvider === EmailProviderEnum.google}>
          {' '}
          <StyledFlex vertical>
            <Text variant="subheading">Google Workspace</Text>
            <Text variant="legal">May include Gmail, Calendar, etc.</Text>
          </StyledFlex>
        </StyledRadio>
        <StyledRadio
          value={EmailProviderEnum.microsoft}
          checked={emailProvider === EmailProviderEnum.microsoft}>
          {' '}
          <Flex vertical>
            <Text variant="subheading">Microsoft 365</Text>
            <Text variant="legal">May include Outlook, One Drive, etc.</Text>
          </Flex>
        </StyledRadio>
      </StyledRadioGroup>
    );
  };

  const buildProviderDescription = () => {
    if (emailProvider === EmailProviderEnum.google) {
      <StyledText variant="subheading">
        1. Login to your{' '}
        <Link
          href={GOOGLE_WORKSPACE_ADMIN_URL || ''}
          target="_blank"
          variant="subheading"
          rel="noreferrer">
          Google Workspace admin account
        </Link>
      </StyledText>;
    }
    if (emailProvider === EmailProviderEnum.microsoft) {
      <StyledText variant="subheading">
        1. Login to your{' '}
        <Link
          href={MICROSOFT_365_ADMIN_URL || ''}
          target="_blank"
          variant="subheading"
          rel="noreferrer">
          Microsoft 365 admin account
        </Link>
      </StyledText>;
    }
  };

  const buildCredentialsPane = () => {
    return (
      <>
        <StyledText variant="subheading">
          2. Create a new user with the following credentials and admin
          permissions:
        </StyledText>
        <StyledPane elevated="true" withPadding="true">
          {temporaryCredentialsLoading ? (
            <Pane horizontal="center">
              <Spinner />
            </Pane>
          ) : (
            <StyledFlexLoginData vertical hAlignContent="left">
              <Flex vAlignContent="center">
                <Text>Email account: </Text>
                <Text loading={temporaryCredentialsLoading}>
                  {emailProviderAccount}
                </Text>
                <CopyButton
                  textToCopy={emailProviderAccount}
                  successMsg={`Copied: ${emailProviderAccount}`}
                />
              </Flex>
              <Flex vAlignContent="center">
                <Text>Password: </Text>
                <Text>{emailProviderPwd}</Text>
                <CopyButton
                  textToCopy={emailProviderPwd}
                  successMsg={`Copied: ${emailProviderPwd}`}
                />
              </Flex>
              <Flex vAlignContent="center">
                <Text>Role: </Text>
                <Text>{emailProviderRole}</Text>
                <CopyButton
                  textToCopy={emailProviderRole}
                  successMsg={`Copied: ${emailProviderRole}`}
                />
              </Flex>
            </StyledFlexLoginData>
          )}
        </StyledPane>
      </>
    );
  };

  const buildEmailCreatedCheckbox = () => {
    return (
      <>
        <CheckboxFlex hAlignContent="center">
          <Checkbox
            checked={accountIsCreated}
            onChange={() => {
              setAccountIsCreated(!accountIsCreated);
            }}>
            <Text>
              I have completed the creation of Electric’s email account.
            </Text>
          </Checkbox>
        </CheckboxFlex>
      </>
    );
  };

  const buildCredentialsForm = () => {
    return (
      <>
        {buildProviderDescription()}
        {buildCredentialsPane()}
        {buildEmailCreatedCheckbox()}
      </>
    );
  };

  const buildModalBody = () => {
    return (
      <>
        {step === EmailProviderModalSteps.selectEmailProvider &&
          buildEmailProviderSelector()}
        {step === EmailProviderModalSteps.linkToEmailProviderSite &&
          buildCredentialsForm()}
      </>
    );
  };

  const buildModalFooter = () => {
    return (
      <>
        <ModalFooterActions justify="end">
          {step !== EmailProviderModalSteps.selectEmailProvider && (
            <ModalAction
              testId="add-email-provider-back"
              variant="outline"
              onClick={onBack}>
              Back
            </ModalAction>
          )}
          <ModalAction
            testId={`add-email-provider-submit-${step}`}
            aria-label={`add-email-provider-submit-${step}`}
            type="submit"
            disabled={
              allAppsLoading ||
              (step === EmailProviderModalSteps.selectEmailProvider &&
                emailProvider === EmailProviderEnum.notSelected) ||
              (step === EmailProviderModalSteps.linkToEmailProviderSite &&
                !accountIsCreated)
            }
            iconAfter="arrow-right-circle"
            onClick={onNext}>
            {NEXT_BUTTON_LABEL[step]}
          </ModalAction>
        </ModalFooterActions>
      </>
    );
  };

  return (
    <>
      {toast}
      <ModalV2
        testId="modal-add-email-provider"
        visible={visible}
        hide={handleOnHide}
        ariaLabelledby="add-email-provider">
        <ModalHeader showCloseButton={true}>{buildModalHeader()}</ModalHeader>
        <ModalBody>{buildModalBody()}</ModalBody>
        <ModalFooter>{buildModalFooter()}</ModalFooter>
      </ModalV2>
    </>
  );
};

export { AddEmailProviderModal };
