import reduxCrud from 'redux-crud';

const baseReducers = reduxCrud.Map.reducersFor('customers');

export default function reducer(state = {}, action) {
  switch (action.type) {
    default:
      return baseReducers(state, action);
  }
}
