import { configureStore } from '@reduxjs/toolkit';
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import graphqlClient from '@turbine/graphql';
import { middleware } from './middleware';
import rootReducer from './reducers';
import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client';

const configuredStore = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: { extraArgument: { client: graphqlClient } },
      immutableCheck: false,
    }).concat(middleware),
});

// Used mostly for driver tests
export const store = (preloadedState: Partial<RootState> = {}) =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: { extraArgument: { client: graphqlClient } },
        immutableCheck: false,
      }).concat(middleware),
    preloadedState,
  });

export type AppThunkConfigExtra = {
  client: () => ApolloClient<NormalizedCacheObject>;
};
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof configuredStore.dispatch;
export type AppThunkConfig = {
  state: RootState;
  extra: AppThunkConfigExtra;
  dispatch: AppDispatch;
};
// https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default configuredStore;
