import { convertSnakeKeysToCamelCase } from '@turbine/helpers/convertSnakeKeysToCamelCase';
import {
  type BoardingCustomerSoftware,
  type BoardingCustomerSoftwareDict,
} from '@turbine/types/BoardingCustomerSoftware';
import { find, get } from 'lodash';
import { getOr } from 'lodash/fp';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { type RootState } from '../store';
import { policyDataSelector } from '../selectors/policySelector';

export const selectedDepartmentSelector = (state: RootState) => {
  return state.newOffboarding?.employeeInformation?.department?.value;
};

export const offboardEmployeeFormSelector = (state: RootState) =>
  getFormValues('offboardEmployeeForm')(state) as any;

export const softwaresSelector = (state: RootState) =>
  state.customerSoftwares?.software;

// All Customer Software - Also every CSA has a reference to the Software.
export const customerSoftwareApplicationsSelector = createSelector(
  (state: RootState) => state.customerSoftwares?.customerSoftwareApplications,
  customerSoftwareApplications => customerSoftwareApplications || []
);

// All selectable and legacy Software by All Customers
export const allSoftwareApplicationsSelector = createSelector(
  (state: RootState) => state.customerSoftwares,
  customerSoftware => customerSoftware?.software ?? []
);

// Useless. Kept only because to remember to remove all references first
export const allSoftwareSelector = createSelector(
  customerSoftwareApplicationsSelector,
  customersSoftwares => customersSoftwares
);

// Try to find a Software, if not possible, try to find a Customer Novel App
export const softwareSelector = createSelector(
  allSoftwareSelector,
  allSoftwareApplicationsSelector,
  (software, allSoftware) => (id: string) =>
    find(software, { id }) || find(allSoftware, { id })
);

// Find a Software (No Novel Apps here)
export const softwareDetailsSelector = createSelector(
  allSoftwareApplicationsSelector,
  software => (id: string) => find(software, { id })
);

// Select list of departments
export const departmentsSelector = createSelector(
  policyDataSelector,
  getOr([], 'departments')
);

// Select software info from default policy and selected-department
export const policyAndDepartmentSoftwareSelector = createSelector(
  [
    departmentsSelector,
    policyDataSelector,
    selectedDepartmentSelector,
    allSoftwareSelector,
  ],
  (
    departments,
    customerPolicy,
    name, // selected-department name
    allSoftware
  ) => {
    const policyDefaultSaasIds = Object.values(
      get(customerPolicy, 'default.saasApplications', [])
    );
    const selectedDepartment = find(departments, { name }) || departments[0];
    const departmentSaasIds = get(selectedDepartment, 'saasApplications', []);
    // policy default and department-specific app IDs
    const defaultAndDeptAppIDs = [
      ...new Set(policyDefaultSaasIds.concat(departmentSaasIds)),
    ];

    const defaultAndDeptApps = allSoftware.filter(software =>
      defaultAndDeptAppIDs.includes(software.id)
    );

    return defaultAndDeptApps;
  }
);

// Provisionable software getter
export function getAllProvisionableSoftware(
  softwares: BoardingCustomerSoftware[]
) {
  return softwares.filter(
    software =>
      software.allowsDeletion || software.allowsTransfer || software.internal
  );
}

/**
 * Select all selected-department (and default-policy) software
 * that "allowsTransfer" and "allowsDeletion"
 */
export const departmentProvisionableSoftwareSelector = createSelector(
  policyAndDepartmentSoftwareSelector,
  getAllProvisionableSoftware
);

/**
 * Select all selected-department (and default-policy) software
 * that "allowsTransfer" or "allowsDeletion" or "internal"
 */
export const allDepartmentProvisionableSoftwareSelector = createSelector(
  policyAndDepartmentSoftwareSelector,
  getAllProvisionableSoftware
);

/**
 * Select all software that "allowsTransfer" and "allowsDeletion"
 */
export const provisionableSoftwareSelector = createSelector(
  allSoftwareSelector,
  getAllProvisionableSoftware
);

/**
 * Select all software that "allowsTransfer" or "allowsDeletion" or "internal"
 */
export const allProvisionableSoftwareSelector = createSelector(
  allSoftwareSelector,
  getAllProvisionableSoftware
);

// Select, list and sort all softwares not already selected in offboarding form
export const remainingSoftwareSelector = createSelector(
  provisionableSoftwareSelector,
  departmentProvisionableSoftwareSelector,
  (provisionableSoftware, departmentProvisionableSoftware = []) => {
    const ignoreTheseSoftwares = departmentProvisionableSoftware.map(
      s => s.name
    );
    const remainingSoftware = provisionableSoftware
      .filter(s => !ignoreTheseSoftwares.includes(s.name))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    return remainingSoftware;
  }
);

// Select, list and sort all softwares not already selected in offboarding form
export const allRemainingSoftwareSelector = createSelector(
  customerSoftwareApplicationsSelector,
  allDepartmentProvisionableSoftwareSelector,
  (customerSoftwareApplications, departmentProvisionableSoftware = []) => {
    const ignoreTheseSoftwares = departmentProvisionableSoftware.map(
      s => s.name
    );

    return customerSoftwareApplications
      .filter(s => !ignoreTheseSoftwares.includes(s.name))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
  }
);

/**
 * Additional software selected in offboard employee from,
 * from the remaining software list
 */

// Selects the software id's, selected in the offboard employee form
export const selectedAdditionalSoftwareSelector = createSelector(
  offboardEmployeeFormSelector,
  getOr([], 'softwares.additionalSoftware')
);

// Selects the selected additional-software's data objects
export const additionalSoftwareSelector = createSelector(
  [remainingSoftwareSelector, selectedAdditionalSoftwareSelector],
  (remainingSoftware, selectedAdditionalSoftware) =>
    remainingSoftware.filter(software =>
      selectedAdditionalSoftware.includes(software.id)
    )
);

// Selects the selected additional-software's data objects
export const allAdditionalSoftwareSelector = createSelector(
  [allRemainingSoftwareSelector, selectedAdditionalSoftwareSelector],
  (remainingSoftware, selectedAdditionalSoftware) =>
    remainingSoftware.filter(software =>
      selectedAdditionalSoftware.includes(software.id)
    )
);

// Customer software applications with provisionable attributes
// for xboarding forms
export const boardingCustomerSoftwaresDictSelector = createSelector(
  customerSoftwareApplicationsSelector,
  softwareSelector,
  (customerSoftwareApplications, softwareSelector) => {
    const customerSoftwareOptionsDict = customerSoftwareApplications.reduce(
      (csas, software) => {
        csas[software.id] = {
          ...software,
          value: software.id,
          label: software.name,
        };
        if (software.parentId) {
          // softwareSelector returns BoardingCustomerSoftware | SoftwareApplication types
          // camelcased boolean fields such as requiresAccount might also be in snake_case format
          const parentSoftware = convertSnakeKeysToCamelCase(
            softwareSelector(software.parentId)
          );
          if (parentSoftware) {
            csas[parentSoftware.id] = {
              internal: software.internal,
              requiresAccount: parentSoftware.requiresAccount,
              requiresInstallation: parentSoftware.requiresInstallation,
              allowsDeletion: parentSoftware.allowsDeletion,
              allowsTransfer: parentSoftware.allowsTransfer,
              id: parentSoftware.id,
              name: parentSoftware.name,
              value: parentSoftware.id,
              label: parentSoftware.name,
              state: software.state,
              supported: software.supported,
            };
          }
        }
        return csas;
      },
      {} as BoardingCustomerSoftwareDict
    );
    return customerSoftwareOptionsDict;
  }
);

export const boardingCustomerSoftwaresSelector = createSelector(
  boardingCustomerSoftwaresDictSelector,
  customerSoftwareOptionsDict => {
    return Object.values(customerSoftwareOptionsDict).sort((app1, app2) => {
      const nameA = app1?.label?.toUpperCase() ?? ''; // ignore upper and lowercase
      const nameB = app2?.label?.toUpperCase() ?? ''; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      } // names must be equal
      return 0;
    });
  }
);

export const selectAllCustomerSoftwaresStatus = (state: RootState) =>
  state.customerSoftwares.status;

export const selectAllCustomerSoftwaresHasPreviousData = (state: RootState) =>
  state.customerSoftwares.hasPreviousData;

const selectAdminId = (_: RootState, adminId: string) => adminId;
export const selectInternalCustomerSoftwaresByAdminId = createSelector(
  [customerSoftwareApplicationsSelector, selectAdminId],
  (csa, adminId) => {
    return csa.filter(csa => csa.adminId == adminId && csa.internal);
  }
);

export const selectUnsupportedCustomerSoftwaresOptions = createSelector(
  customerSoftwareApplicationsSelector,
  customerSoftwareApplications =>
    customerSoftwareApplications
      .filter(({ supported }) => !supported)
      .map(({ id, name, icon, state, supported }) => ({
        label: name,
        value: id,
        icon,
        state,
        supported,
      }))
);
