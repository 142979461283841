import { gql } from '@apollo/client';
import { type FetchEmployeeByIdData } from '../queries/employeeQueries';

export interface UpdateEmployeeV2Response {
  updateEmployeeV2: {
    result: FetchEmployeeByIdData;
  };
}

export interface UpdateEmployeeV2MutationParams {
  employeeId: string;
  roles: Array<{ id: string; value: boolean }>;
  avatar: string;
  department: string;
  employmentType: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  managerId: string;
  officeLocation: string;
  phone: string;
}

export const UPDATE_EMPLOYEE_V2 = gql`
  mutation UpdateEmployeeV2(
    $employeeId: String!
    $roles: [RoleToUpdate]
    $avatar: String
    $department: String
    $employmentType: String
    $firstName: String
    $lastName: String
    $jobTitle: String
    $managerId: String
    $officeLocation: String
    $phone: String
  ) {
    updateEmployeeV2(
      employee_id: $employeeId
      roles: $roles
      avatar: $avatar
      department: $department
      employment_type: $employmentType
      first_name: $firstName
      last_name: $lastName
      job_title: $jobTitle
      manager_id: $managerId
      office_location: $officeLocation
      phone: $phone
    ) {
      result {
        id
        last_name
        first_name
        customer_id
        email
        phone
        avatar
        roles {
          id
          name
        }
        manager_id
        office_location
        employment_type
        department
        job_title
      }
    }
  }
`;

export interface DiscardEmployeeV2Response {
  updateEmployeeV2: {
    result: {
      id: string;
      customer_id: string;
      discarded_at: string;
    };
  };
}
export const DISCARD_EMPLOYEE_V2 = gql`
  mutation UpdateEmployeeV2($employeeId: String!, $discardedAt: String!) {
    updateEmployeeV2(employee_id: $employeeId, discarded_at: $discardedAt) {
      result {
        id
        customer_id
        discarded_at
      }
    }
  }
`;

export interface UpdateEmployeeRolesV2Response {
  updateEmployeeV2: {
    result: {
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      roles: Array<{ id: string; name: string }>;
    };
  };
}

export interface UpdateEmployeeRolesV2MutationParams {
  employeeId: string;
  roles: Array<{ id: string; value: boolean }>;
}

export const UPDATE_EMPLOYEE_ROLES_V2 = gql`
  mutation UpdateEmployeeV2($employeeId: String!, $roles: [RoleToUpdate]) {
    updateEmployeeV2(employee_id: $employeeId, roles: $roles) {
      result {
        id
        first_name
        last_name
        email
        roles {
          id
          name
        }
      }
    }
  }
`;

export const ASSIGN_EMPLOYEE_TO_DEVICE = gql`
  mutation AssignEmployeeToDevice(
    $customerId: String!
    $assignedBy: String!
    $assignedTo: String!
    $deviceId: String!
  ) {
    assignUserToDevice(
      customer_id: $customerId
      assigned_by: $assignedBy
      assigned_to: $assignedTo
      device_id: $deviceId
    ) {
      device_assignment {
        host_identifier
        assigned_to
        assigned_at
        assigned_by
      }
    }
  }
`;

export const UNASSIGN_EMPLOYEE_FROM_DEVICE = gql`
  mutation UnassignEmployeeFromDevice(
    $customerId: String!
    $deviceId: String!
  ) {
    unassignUserFromDevice(customer_id: $customerId, device_id: $deviceId) {
      ok
    }
  }
`;

export const RESET_TURBINE_USER = gql`
  mutation ResetCustomerUser($employee_id: String) {
    resetTurbineUser(employee_id: $employee_id) {
      employee {
        id
      }
    }
  }
`;

export const RESET_TURBINE_USER_V2 = gql`
  mutation ResetCustomerUserV2($employee_id: String) {
    resetTurbineUserV2(employee_id: $employee_id) {
      employee_id
    }
  }
`;

export const BULK_UPDATE_EMPLOYEE_V2 = gql`
  mutation BulkUpdateEmployeeV2($employeeUpdates: [EmployeeArguments]!) {
    bulkUpdateEmployeeV2(employee_updates: $employeeUpdates) {
      result
    }
  }
`;

export const BULK_CREATE_EMPLOYEE_V2 = gql`
  mutation bulkCreateEmployeeV2(
    $customer_id: String!
    $employees: [EmployeeCreate]!
  ) {
    bulkCreateEmployeeV2(customer_id: $customer_id, employees: $employees) {
      results {
        result
        id
      }
      errors {
        error
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_PERSONAL_DEVICE = gql`
  mutation UpdateEmployeeV2(
    $employeeId: String!
    $has_personal_device: Boolean
  ) {
    updateEmployeeV2(
      employee_id: $employeeId
      has_personal_device: $has_personal_device
    ) {
      result {
        id
        has_personal_device
      }
    }
  }
`;
