import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_STORED_DEVICES_FOR_DEVICE_MANAGEMENT_TABLE } from '@turbine/graphql/queries/deviceQueries';
import { type AppThunkConfigExtra } from '@turbine/redux/store';
import { type StoredDevice } from '.';

export type fetchStoredDevicesByCustomerIDParams = {
  customerID: string;
};

export const fetchStoredDevicesByCustomerID = createAsyncThunk(
  'storedDevices/fetchStoredDevices',
  async ({ customerID }: fetchStoredDevicesByCustomerIDParams, thunkConfig) => {
    const { extra, rejectWithValue } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const devicesQuery = {
      query: GET_STORED_DEVICES_FOR_DEVICE_MANAGEMENT_TABLE,
      variables: {
        customerId: customerID,
      },
    };
    try {
      const result = await client().query(devicesQuery);
      return result.data?.inventory_devices?.devices.map(
        (device: StoredDevice) => {
          const searchDeviceFields = [
            device.hardware,
            device.condition,
            device.status,
            device.serial,
            device.ram,
            device.processor,
            device.disk_space,
          ];
          return {
            ...device,
            searchString: searchDeviceFields.join(' ').toLowerCase(),
          } as StoredDevice;
        }
      );
    } catch (error) {
      window?.DD_RUM?.addError(error);
      return rejectWithValue(null);
    }
  }
);
