import camelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';
import { type CamelCasedPropertiesDeep } from 'type-fest';

function convertSnakeKeysToCamelCase<T>(value: T): CamelCasedPropertiesDeep<T> {
  if (isPlainObject(value)) {
    return Object.entries(value as Record<string, unknown>).reduce(
      (acum, [key, value]) => {
        // omit apollo's __typename property
        if (key === '__typename') {
          return acum;
        }
        return {
          ...acum,
          [camelCase(key)]: convertSnakeKeysToCamelCase(value),
        };
      },
      {} as CamelCasedPropertiesDeep<T>
    );
  }

  if (Array.isArray(value)) {
    return value.map(
      convertSnakeKeysToCamelCase
    ) as CamelCasedPropertiesDeep<T>;
  }

  return value as CamelCasedPropertiesDeep<T>;
}

export { convertSnakeKeysToCamelCase };
