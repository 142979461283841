import { COUNTRIES } from '@turbine/lib/addresses/constants';
import { type Nullish } from '@turbine/types/utils';
import { asSelectOptions } from '../helpers';
import i18n from 'i18next';

export const DEFAULT_SHIPPING_COUNTRY = COUNTRIES.USA;
export const ELECTRIC_SHIPPING_ADDRESS = {
  name: 'Electric c/o Techvera',
  streetAddress1: '625 Dallas Drive #450',
  streetAddress2: '',
  city: 'Denton',
  state: 'Texas',
  zip: '76205',
  country: COUNTRIES.USA,
} as const;

export enum HardwareFieldName {
  OffBoarding = 'deprovisionDevice.devices',
  OnBoarding = 'hardware.selectedHardware',
}

export enum XboardingStatusValues {
  Canceled = 'Canceled',
  Completed = 'Done',
  Draft = 'Draft',
  InProgress = 'In progress',
  Scheduled = 'Pending',
}

export enum XboardingStatusLabels {
  Canceled = 'Canceled',
  Completed = 'Completed',
  Draft = 'Draft',
  InProgress = 'In Progress',
  Scheduled = 'Scheduled',
}

export const STATUSES = {
  [XboardingStatusValues.Canceled]: XboardingStatusLabels.Canceled,
  [XboardingStatusValues.Completed]: XboardingStatusLabels.Completed,
  [XboardingStatusValues.Draft]: XboardingStatusLabels.Draft,
  [XboardingStatusValues.InProgress]: XboardingStatusLabels.InProgress,
  [XboardingStatusValues.Scheduled]: XboardingStatusLabels.Scheduled,
};

export enum AccountRemovalTypes {
  ResetPassword = 'RESET_PASSWORD',
  SuspendAccount = 'SUSPEND_ACCOUNT',
  ArchiveAccount = 'ARCHIVE_ACCOUNT',
  DisableAccount = 'DISABLE_ACCOUNT',
  DeleteAccount = 'DELETE_ACCOUNT',
}

export const GOOGLE_ACCOUNT_REMOVAL_TYPES = [
  {
    radioId: 'change-password',
    toolTipId: 'tooltip-change-password',
    label:
      'Revoke access (Reset Password + Remove Recovery Q’s + Login Cookies)',
    value: AccountRemovalTypes.ResetPassword,
    tooltip: () => {
      return i18n.t(
        'newOffboarding:removeFromEmail.accountRemovalType.resetPassword.tooltip',
        {
          defaultValue:
            'Access to account is completely revoked, however account is kept active. Selecting this option enables "Set Up OOO Message" option.',
        }
      );
    },
    disabledTooltip: undefined,
  },
  {
    radioId: 'suspend-account',
    toolTipId: 'tooltip-suspend-account',
    label: 'Suspend',
    value: AccountRemovalTypes.SuspendAccount,
    tooltip: () => {
      return i18n.t(
        'newOffboarding:removeFromEmail.accountRemovalType.suspendAccount.tooltip',
        {
          defaultValue:
            'Access to account is completely revoked and account is suspended. Account remains fully licensed.',
        }
      );
    },
    disabledTooltip: undefined,
  },
  {
    radioId: 'archive-account',
    toolTipId: 'tooltip-archive-account',
    label: 'Archive',
    value: AccountRemovalTypes.ArchiveAccount,
    tooltip: () => {
      return i18n.t(
        'newOffboarding:removeFromEmail.accountRemovalType.archiveAccount.tooltip',
        {
          defaultValue:
            'Access to account is completely revoked and account is archived. Google Archive license required.',
        }
      );
    },
    disabledTooltip: undefined,
  },
  {
    radioId: 'delete-account',
    toolTipId: 'tooltip-delete-account',
    label: 'Delete',
    value: AccountRemovalTypes.DeleteAccount,
    tooltip: () => {
      return i18n.t(
        'newOffboarding:removeFromEmail.accountRemovalType.deleteAccount.tooltip',
        {
          defaultValue: 'Permanently deletes account.',
        }
      );
    },
    disabledTooltip: undefined,
  },
] as const;

export const MICROSOFT_ACCOUNT_REMOVAL_TYPES = [
  {
    radioId: 'change-password',
    toolTipId: 'tooltip-change-password',
    label: 'Revoke access (Reset Password + Initiate sign out of 365 sessions)',
    value: AccountRemovalTypes.ResetPassword,
    tooltip: () => {
      return i18n.t(
        'newOffboarding:removeFromEmail.accountRemovalType.resetPassword.tooltip',
        {
          defaultValue:
            'Access to account is completely revoked, however account is kept active. Selecting this option enables "Set Up OOO Message" option.',
        }
      );
    },
    disabledTooltip: undefined,
  },
  {
    radioId: 'disable-account',
    toolTipId: 'tooltip-disable-account',
    label:
      'Disable (Reset password + Initiate sign out of 365 sessions + Block Sign in + Remove License)',
    value: AccountRemovalTypes.DisableAccount,
    tooltip: () => {
      return i18n.t(
        'newOffboarding:removeFromEmail.accountRemovalType.disableAccount.tooltip',
        {
          defaultValue:
            'Access to account is completely revoked and account is disabled+unlicensed',
        }
      );
    },
    disabledTooltip: undefined,
  },
  {
    radioId: 'delete-account',
    toolTipId: 'tooltip-delete-account',
    label: 'Delete',
    value: AccountRemovalTypes.DeleteAccount,
    tooltip: () => {
      return i18n.t(
        'newOffboarding:removeFromEmail.accountRemovalType.deleteAccount.tooltip',
        {
          defaultValue: 'Permanently deletes account.',
        }
      );
    },
    disabledTooltip: undefined,
  },
] as const;

/** Status Filter Select Typings */
enum PrivateStatusFilterLabels {
  All = 'All',
}

export enum StatusFilterValues {
  All = '',
  Canceled = 'CANCELED',
  Completed = 'DONE',
  Draft = 'DRAFT',
  InProgress = 'IN PROGRESS',
  Scheduled = 'PENDING',
}

export enum BoardingAction {
  Cancel = 'cancel',
  DeleteDraft = 'deleteDraft',
  NewDraft = 'draft',
  Edit = 'edit',
  New = 'new',
}

export type BoardingParams = {
  action: BoardingAction;
  boardingId: string;
};

export const BASE_STATUS_FILTER_OPTIONS = [
  {
    value: StatusFilterValues.All,
    label: PrivateStatusFilterLabels.All,
  },
  {
    value: StatusFilterValues.Canceled,
    label: XboardingStatusLabels.Canceled,
  },
  {
    value: StatusFilterValues.Completed,
    label: XboardingStatusLabels.Completed,
  },
  {
    value: StatusFilterValues.InProgress,
    label: XboardingStatusLabels.InProgress,
  },
  {
    value: StatusFilterValues.Scheduled,
    label: XboardingStatusLabels.Scheduled,
  },
];

const ONBOARDING_ONLY_STATUS_FILTER_OPTIONS = [
  {
    value: StatusFilterValues.Draft,
    label: XboardingStatusLabels.Draft,
  },
];

export const ONBOARDING_STATUS_FILTER_OPTIONS = [
  ...BASE_STATUS_FILTER_OPTIONS,
  ...ONBOARDING_ONLY_STATUS_FILTER_OPTIONS,
];

type StatusFilterLabels = PrivateStatusFilterLabels | XboardingStatusLabels;

export type StatusFilterOption = {
  value?: StatusFilterValues;
  label?: StatusFilterLabels;
};

export enum BoardingTypes {
  OnBoarding = 'onboarding',
  OffBoarding = 'offboarding',
}

export const PEOPLE_TABLES = {
  EMPLOYEES: 'employees',
  ONBOARDINGS: 'onboardings',
  OFFBOARDINGS: 'offboardings',
};

/**
 * DEPROVISION / PROVISIONING
 */

export const REMOTE_AVAILABILITY_MESSAGE =
  'Remote sessions are available to Core package subscribers. Contact your Customer Success Manager to learn more.';

export const REMOTE_DEPROVISION_MESSAGE =
  'If you would like to deprovision on-site at Electric HQ, please select a different final destination for this device.';

/**
 * Scheduling
 */
export enum OffboardingSchedulingType {
  ASAP = 'ASAP',
  Scheduled = 'SCHEDULED',
}

export const OFFBOARDING_SCHEDULING_OPTIONS = [
  {
    label: 'ASAP',
    value: OffboardingSchedulingType.ASAP,
  },
  {
    label: 'Scheduled',
    value: OffboardingSchedulingType.Scheduled,
  },
] as const;

/**
 * Stepper
 */

export enum StepLabels {
  'People' = 0,
  'Employee Information' = 1,
  'Task Details' = 2,
  'Task List' = 3,
  'Submit' = 4,
}

export const CREATE_NEW_EMAIL_OPTION = {
  label: 'Create a new email',
  value: 'CUSTOM_EMAIL',
  isOption(value: Nullish<string>): boolean {
    return this.value === value;
  },
  asSelectOptions() {
    return asSelectOptions(this.label, this.value);
  },
} as const;

export const SERVICE_DESK_BUSINESS_HOURS_START = 9;
export const SERVICE_DESK_BUSINESS_HOURS_END = 21;

export const EAST_COAST_TIME_ZONE = 'America/New_York';
