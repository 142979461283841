import queryString from 'query-string';

export const persistDeviceIdInURL = (selectedDeviceID: string) => {
  const parsedHash = queryString.parse(window.location.hash);
  parsedHash.deviceId = selectedDeviceID;
  const updatedHash = queryString.stringify(parsedHash, {
    arrayFormat: 'comma',
  });
  window.location.hash = updatedHash;
};

export const removeDeviceIdFromURL = () => {
  const parsedHash = queryString.parse(window.location.hash);
  delete parsedHash.deviceId;
  const updatedHash = queryString.stringify(parsedHash, {
    arrayFormat: 'comma',
  });
  window.location.hash = updatedHash;
};

export const getDeviceIDFromURL = () => {
  const parsedHash = queryString.parse(window.location.hash);
  return parsedHash.deviceId as string;
};
