import { useLocation } from 'react-router-dom';

/**
 * @todo import routes from @turbine/routes
 */
const FULL_PAGE_ROUTES: Record<string, boolean> = {
  '/onoffboardings/onboarding/new': true,
  '/onoffboardings/offboarding/new': true,
};

export const useFullPageLayout = (): boolean => {
  const location = useLocation();

  if (
    FULL_PAGE_ROUTES[location?.pathname] ||
    location?.pathname.startsWith('/applications/configure/')
  ) {
    return true;
  }
  return false;
};
