import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import formatISO from 'date-fns/formatISO';
import getDate from 'date-fns/getDate';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import isValid from 'date-fns/isValid';

function getDateFromDateAndTime(date: Date, time: Date): Date {
  let hours = 0;
  let minutes = 0;
  const seconds = 0;
  if (isValid(time)) {
    hours = getHours(time);
    minutes = getMinutes(time);
  }
  const year = getYear(date);
  const month = getMonth(date);
  const dateOfMonth = getDate(date);

  return new Date(year, month, dateOfMonth, hours, minutes, seconds);
}
const getIsoDateFromDateAndTime = (
  date?: Date,
  time?: Date,
  timeZone?: string
): string => {
  if (!date || !time || !timeZone) return formatISO(new Date());

  const dateForUTC = getDateFromDateAndTime(date, time);
  const utcDate = zonedTimeToUtc(dateForUTC, timeZone);
  return formatISO(utcDate);
};

export { getIsoDateFromDateAndTime, getDateFromDateAndTime };
