export const convertCSAToCustomerSoftwareRequested = (csaList = []) => {
  return csaList.reduce((software, csa) => {
    const { id, parent_id: parentId } = csa['software_application'][0] || {};
    if (id) {
      return {
        [id]: true,
        [parentId || id]: true,
        ...software,
      };
    }
    return software;
  }, {});
};

export const decorateCSAValues = (csaList = [], software = {}) => {
  const customerSupportedDict = csaList.reduce(
    (
      csas,
      {
        software_application: softwareApplication,
        supported,
        state,
        application_admin,
        application_admins,
      }
    ) => {
      const {
        id,
        parent_id: parentId,
        allows_deletion: allowsDeletion,
        allows_transfer: allowsTransfer,
        requires_installation: requiresInstallation,
        requires_account: requiresAccount,
        ...attrs
      } = softwareApplication[0] || {};
      csas[id] = {
        id,
        parentId,
        ...attrs,
        requiresAccount,
        requiresInstallation,
        allowsDeletion,
        allowsTransfer,
        supported,
        state,
        internal: !supported,
        adminName: application_admin?.name,
        adminEmail: application_admin?.email,
        adminId: application_admin?.id,
        adminsEmails: application_admins?.map(({ email }) => email),
      };
      if (parentId && software[parentId]) {
        const {
          allows_deletion: allowsDeletion,
          allows_transfer: allowsTransfer,
          requires_installation: requiresInstallation,
          requires_account: requiresAccount,
          ...parentSoftwareAttrs
        } = software[parentId];
        csas[parentId] = {
          parentId: null,
          ...parentSoftwareAttrs,
          requiresAccount,
          requiresInstallation,
          allowsDeletion,
          allowsTransfer,
          supported,
          state,
          internal: !supported,
        };
      }
      return csas;
    },
    {}
  );
  return Object.values(customerSupportedDict);
};

export function mergeSoftwares(software, newSoftware) {
  function reduceArrayToObjectById(merged, element) {
    if (!merged[element.id]) {
      merged[element.id] = element;
    }
    return merged;
  }
  const mergedSoftware = {};
  software.reduce(reduceArrayToObjectById, mergedSoftware);
  newSoftware.reduce(reduceArrayToObjectById, mergedSoftware);
  return Object.values(mergedSoftware);
}
