import { BREAKPOINTS } from '@electricjs/arc';

const { md, lg, xl } = BREAKPOINTS;
const QUERIES = {
  md: `(min-width: ${md})`,
  lg: `(min-width: ${lg})`,
  xl: `(min-width: ${xl})`,
};

export default QUERIES;
