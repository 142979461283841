import { gql } from '@apollo/client';

export const FETCH_LOCATIONS = gql`
  query OfficeLocations($customerId: String!) {
    offices_v2(customer_id: $customerId, exclude_discarded: true) {
      id
      name
      address1: address_1
      address2: address_2
      city
      country
      state
      zip: postcode
      phone
    }
  }
`;

export type Location = {
  id: string;
  name: string;
  address1: string;
  address2?: string;
  city: string;
  country: string;
  state?: string;
  zip?: string;
  phone?: string;
};
