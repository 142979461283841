import store from '@turbine/redux/store';

const serializeObject = value =>
  value ? JSON.parse(JSON.stringify(value)) : value;

/**
 * Action creators
 */
const guardPending = (name, { to, from }) => ({
  type: `${name}/pending`,
  payload: serializeObject({ to, from }),
  meta: serializeObject(to.meta),
});
const guardCompleted = (name, { to, from, redirected = false }) => ({
  type: `${name}/completed`,
  payload: serializeObject({ to, from }),
  meta: serializeObject({ ...to.meta, redirected }),
});

export const createGuard =
  (prefix = '', fn) =>
  (to, from, next) => {
    store.dispatch(guardPending(prefix, { to, from }));

    const logNext = () => {
      store.dispatch(guardCompleted(prefix, { to, from }));
      return next();
    };

    const logRedirect = (...args) => {
      store.dispatch(guardCompleted(prefix, { to, from, redirected: true }));
      return next.redirect(...args);
    };

    const nextParam = Object.assign(logNext, next, { redirect: logRedirect });

    return store.dispatch(fn(to, from, nextParam));
  };
