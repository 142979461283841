import { createAsyncThunk } from '@reduxjs/toolkit';
import { type AppThunkConfigExtra } from '@turbine/redux/store';
import {
  IT_SCORE_CARD_GSUITE_DATA,
  REAL_IT_SCORE_CARD_O365_DATA,
  IT_SCORE_CARD_GSUITE_CSV_DATA_FROM_PROXY_FLARE,
  IT_SCORE_CARD_CONFIGURATION,
  GET_ITSC_USER_REQUESTS,
} from '@turbine/graphql/queries/itScoreCardQueries';
import {
  UPDATE_IT_SCORE_CARD_CONFIGURATION,
  FIREWALL_ENABLEMENT_REQUEST,
} from '@turbine/graphql/mutations/itScoreCardMutations';
import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client';
import { itScoreCardSlice } from './itScoreCardSlice';

export type getITScoreCardDataParams = {
  customerId: string;
};

export enum ITSCUserRequestType {
  FirewallEnable = 'firewall.enable',
}

export type getITSCUserRequestParams = {
  customerId: string;
  requestType?: string;
};

type ITScoreCardConfigurationQueryResult = {
  data: {
    update_itsc_configuration: {
      itsc_configuration: {
        google_workspace_enabled: boolean;
        o365_enabled: boolean;
      };
    };
  };
  error?: {
    message: string;
  };
};

export type UpdateITScoreCardConfigurationParams = {
  customerId: string;
  googleWorkspaceEnabled?: boolean;
  o365Enabled?: boolean;
  client: () => ApolloClient<NormalizedCacheObject>;
  rejectWithValue: (value: unknown) => unknown;
};

export type FirewallEnablementRequestDevice = {
  serial_number: string;
  mdm: string;
  hostname: string;
  device_id: string;
};

export type SendFirewallEnablementRequestParams = {
  customerId: string;
  client: () => ApolloClient<NormalizedCacheObject>;
  requesterId: string;
  requestType: string;
  devices: FirewallEnablementRequestDevice[];
  rejectWithValue: (value: unknown) => unknown;
};

export const getITScoreCardGSuiteData = createAsyncThunk(
  'itScoreCard/getITScoreCardGSuiteData',
  async ({ customerId }: getITScoreCardDataParams, thunkConfig) => {
    const { extra, rejectWithValue } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const itScoreCardGSuiteDataQuery = {
      query: IT_SCORE_CARD_GSUITE_DATA,
      variables: {
        customerId,
      },
    };
    try {
      const result = await client().query(itScoreCardGSuiteDataQuery);
      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
      return result.data?.it_scorecard_gsuite;
    } catch (error) {
      window?.DD_RUM?.addError(error);
      return rejectWithValue(null);
    }
  }
);

export const getITScoreCardOffice365Data = createAsyncThunk(
  'itScoreCard/getITScoreCardOffice365Data',
  async ({ customerId }: getITScoreCardDataParams, thunkConfig) => {
    const { extra, rejectWithValue } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const itScoreCardOffice365DataQuery = {
      query: REAL_IT_SCORE_CARD_O365_DATA,
      variables: {
        customerId,
      },
    };
    try {
      const result = await client().query(itScoreCardOffice365DataQuery);
      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
      return result.data?.it_scorecard_microsoft;
    } catch (error) {
      window?.DD_RUM?.addError(error);
      return rejectWithValue(error);
    }
  }
);

export const getITScoreCardGSuiteCSVData = createAsyncThunk(
  'itScoreCard/getITScoreCardGSuiteCSVData',
  async ({ customerId }: getITScoreCardDataParams, thunkConfig) => {
    const { extra } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const itScoreCardGSuiteCSVDataQuery = {
      query: IT_SCORE_CARD_GSUITE_CSV_DATA_FROM_PROXY_FLARE,
      variables: {
        customerId,
      },
    };
    try {
      const result = await client().query(itScoreCardGSuiteCSVDataQuery);
      return result.data;
    } catch (error) {
      window?.DD_RUM?.addError(error);
    }
  }
);

export const getITScoreCardConfiguration = createAsyncThunk(
  'itScoreCard/getITScoreCardConfiguration',
  async ({ customerId }: getITScoreCardDataParams, thunkConfig) => {
    const { extra, rejectWithValue } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const itScoreCardConfigurationQuery = {
      query: IT_SCORE_CARD_CONFIGURATION,
      variables: {
        customerId,
      },
    };
    try {
      const result = await client().query(itScoreCardConfigurationQuery);
      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
      return result.data?.itsc_configuration;
    } catch (error) {
      window?.DD_RUM?.addError(error);
      return rejectWithValue(null);
    }
  }
);

export const getInProgressFirewallRequests = createAsyncThunk(
  'itScoreCard/getInProgressFirewallRequests',
  async ({ customerId }: getITSCUserRequestParams, thunkConfig) => {
    const { extra, rejectWithValue } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const getITSCUserRequestQuery = {
      query: GET_ITSC_USER_REQUESTS,
      variables: {
        customerId,
        requestType: ITSCUserRequestType.FirewallEnable,
      },
    };
    try {
      const result = await client().query(getITSCUserRequestQuery);
      return result.data?.itscUserRequest?.data;
    } catch (error) {
      console.log(error);
      window?.DD_RUM?.addError(error);
      return rejectWithValue(null);
    }
  }
);

const updateITScoreCardConfiguration = async ({
  customerId,
  googleWorkspaceEnabled,
  o365Enabled,
  client,
  rejectWithValue,
}: UpdateITScoreCardConfigurationParams) => {
  const updateITScoreCardConfigurationMutation = {
    mutation: UPDATE_IT_SCORE_CARD_CONFIGURATION,
    variables: {
      customerId,
      googleWorkspaceEnabled,
      o365Enabled,
    },
  };
  try {
    return await client().mutate(updateITScoreCardConfigurationMutation);
  } catch (error) {
    window?.DD_RUM?.addError(error);
    return rejectWithValue('Failed to update IT Score Card Configuration');
  }
};

export const updateITScoreCardConfigurationThunk = createAsyncThunk(
  'itScoreCard/updateITScoreCardConfiguration',
  async (params: UpdateITScoreCardConfigurationParams, thunkConfig: any) => {
    const { extra, dispatch, rejectWithValue } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;

    try {
      const result = (await updateITScoreCardConfiguration({
        ...params,
        client,
        rejectWithValue,
      })) as ITScoreCardConfigurationQueryResult;
      // call setItScoreCardConfigurationData with the updatedConfiguration if the request was successfull
      const { google_workspace_enabled, o365_enabled } =
        result.data.update_itsc_configuration.itsc_configuration;
      dispatch(
        itScoreCardSlice.actions.setItScoreCardConfigurationData({
          google_workspace_enabled,
          o365_enabled,
        })
      );
      return result;
    } catch (_error) {
      return {
        error: {
          message:
            'There was an error when fetching the ITScoreCardConfiguration',
        },
      };
    }
  }
);

const sendFirewallEnablementRequest = async ({
  client,
  customerId,
  requesterId,
  requestType,
  devices,
  rejectWithValue,
}: SendFirewallEnablementRequestParams) => {
  const firewallRequestMutation = {
    mutation: FIREWALL_ENABLEMENT_REQUEST,
    variables: {
      input: {
        customer_id: customerId,
        requester_id: requesterId,
        request_type: requestType,
        devices: devices,
      },
    },
  };
  try {
    const result = await client().mutate(firewallRequestMutation);
    return result?.data?.firewallEnablementRequest;
  } catch (error) {
    window?.DD_RUM?.addError(error);
    return rejectWithValue('Failed to send firewall enablement request');
  }
};

export const sendFirewallEnablementRequestThunk = createAsyncThunk(
  'itScoreCard/sendFirewallEnablementRequest',
  async (params: SendFirewallEnablementRequestParams, thunkConfig: any) => {
    const { extra, dispatch, rejectWithValue } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;

    try {
      const result = await sendFirewallEnablementRequest({
        ...params,
        client,
        rejectWithValue,
      });
      dispatch(
        itScoreCardSlice.actions.setFirewallEnablementRequestData(result.data)
      );
      return result;
    } catch (_error) {
      return {
        error: {
          message: 'There was an error sending firewall enablement request',
        },
      };
    }
  }
);
