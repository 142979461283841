import * as types from '../types/modalTypes';

const initialState = {
  isOpen: false,
  modalType: '',
  modalProps: {
    className: 'et-modal',
    overlayClassName: 'et-modal__overlay',
  },
};

export function modal(state = initialState, { type, payload = {} }) {
  switch (type) {
    case types.MODAL_OPENED:
      return {
        isOpen: true,
        modalType: payload.modalType,
        modalProps: {
          ...state.modalProps,
          ...payload.modalProps,
        },
      };
    case types.MODAL_CLOSED:
      return initialState;
    default:
      return state;
  }
}
