export const hasPermission = (
  userPermissions: string[] = [],
  requiredPermissions: string[] | undefined = undefined
): boolean =>
  requiredPermissions
    ? requiredPermissions.every(permission =>
        userPermissions.includes(permission)
      )
    : true;

export const hasSomePermission = (
  userPermissions: string[] = [],
  requiredPermissions: string[] | null = null
): boolean =>
  requiredPermissions
    ? requiredPermissions.some(permission =>
        userPermissions.includes(permission)
      )
    : true;
