import { lazy } from 'react';
const SelfServiceRegistration = lazy(() =>
  import('@turbine/components/Registration/SelfServiceRegistration')
);

export const SELF_SERVICE_REGISTRATION_PATH = '/self-service-registration';

export const routes = [
  {
    component: SelfServiceRegistration,
    path: SELF_SERVICE_REGISTRATION_PATH,
  },
];
