import getHours from 'date-fns/getHours';
import set from 'date-fns/set';
import addDays from 'date-fns/addDays';
import nextMonday from 'date-fns/nextMonday';
import isWeekend from 'date-fns/isWeekend';
import isFriday from 'date-fns/isFriday';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  SERVICE_DESK_BUSINESS_HOURS_START,
  SERVICE_DESK_BUSINESS_HOURS_END,
  EAST_COAST_TIME_ZONE,
} from '@turbine/lib/xboarding/constants';

const setTimeToStartOfBusiness = (date: Date): Date => {
  return zonedTimeToUtc(
    set(date, {
      hours: SERVICE_DESK_BUSINESS_HOURS_START,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    }),
    EAST_COAST_TIME_ZONE
  );
};

const getTimeInEst = (date: Date): Date => {
  return utcToZonedTime(date, EAST_COAST_TIME_ZONE);
};

const getNextAvailableOffboardTime = (): Date => {
  const currentTime = new Date();
  const currentTimeInEst = getTimeInEst(currentTime);
  const currentHourInEst = getHours(currentTimeInEst);

  // If it's currently Friday after business hours or the weekend
  if (
    (isFriday(currentTimeInEst) &&
      currentHourInEst > SERVICE_DESK_BUSINESS_HOURS_END - 1) ||
    isWeekend(currentTimeInEst)
  ) {
    // The next available time is Monday morning at the start of business
    return setTimeToStartOfBusiness(nextMonday(currentTimeInEst));
    // If it's currently after business hours
  } else if (currentHourInEst > SERVICE_DESK_BUSINESS_HOURS_END - 1) {
    // The next available time is tomorrow morning at the start of business
    return setTimeToStartOfBusiness(addDays(currentTimeInEst, 1));
    // If it's currently before business hours
  } else if (currentHourInEst < SERVICE_DESK_BUSINESS_HOURS_START) {
    // The next available time is later this morning at the start of business
    return setTimeToStartOfBusiness(currentTimeInEst);
  } else {
    return currentTime;
  }
};

export { getNextAvailableOffboardTime };
