import { uniq, uniqBy } from 'lodash';

export const mapCalendarsData = calendarsData =>
  uniqBy(
    calendarsData?.map(({ id, name, ...moreProps }) => ({
      id: id || name,
      name,
      ...moreProps,
    })),
    'id'
  );

export const mapCalendarsDataToIds = calendarsData =>
  uniq(calendarsData?.map(({ id, name }) => id || name));
