import { createReducer } from '@reduxjs/toolkit';
import { fetchPolicy, updatePolicy } from '../actions/policyActions';

export default createReducer({}, builder => {
  builder
    .addCase('IMPERSONATE_CUSTOMER_STARTED', () => {})
    .addCase(fetchPolicy.pending, state => state)
    .addCase(fetchPolicy.fulfilled, (state, action) => {
      state[action.payload.id] = action.payload;
    })
    .addCase(fetchPolicy.rejected, (state, action) => {
      state.error = action.error;
    })
    .addCase(updatePolicy.pending, (state, action) => {
      const policyId = action.meta.arg.policy.id;
      state[policyId].busy = true;
      state[policyId].pendingUpdate = true;
    })
    .addCase(updatePolicy.fulfilled, (state, action) => {
      const policyId = action.payload.id;
      state[policyId] = action.payload;
    })
    .addCase(updatePolicy.rejected, (state, action) => {
      state.error = action.error;
    });
});
