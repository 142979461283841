import { gql } from '@apollo/client';

const GET_CUSTOMER_IS_LIVE = gql`
  query customers($customer_id: String!) {
    customer_v2(customer_id: $customer_id) {
      discarded_at
      activated_at
    }
  }
`;

export default GET_CUSTOMER_IS_LIVE;

export const GET_ACTIVITIES_ISSUE = gql`
  query ($id: String) {
    ticket(id: $id) {
      ticket_type
      application_type
      action
      application_name
    }
    triage_form(issue_id: $id) {
      id
      issue_id
      external_id
      selected_category
      is_blocking_users
      problem_description
      submitted_at
      created_at
      updated_at
    }
    issue(id: $id) {
      id
      creator_id
      creator_avatar
      creator_name
      created_at
      closed_at
      group_code
      average_response_time
      met_sla
      ttr
      issue_detail {
        display_name
      }
      survey {
        rate
        feedback_at
        feedback
      }
      activities {
        id
        sourced_at
        creator_avatar
        creator_id
        creator_name
        message
        kind
        status
        root_source_id
        source_id
        attachments {
          attachment_name
          payload
        }
      }
    }
  }
`;

export const FETCH_CUSTOMERS = gql`
  query FetchCustomer {
    customers: customers_v2 {
      id
      name
      plan
    }
  }
`;

export const FETCH_SINGLE_CUSTOMER = gql`
  query FetchSingleCustomer($customer_id: String!) {
    customers: customer_v2(customer_id: $customer_id) {
      id
      name
      plan
    }
  }
`;

export const GET_EMAIL_PROVIDER = gql`
  query ($customer_id: String!) {
    customer_email_provider(customer_id: $customer_id) {
      id
      name
    }
  }
`;
