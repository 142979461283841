import type { UseToastParams } from '@electricjs/arc';
import { type BannerIntent } from '@electricjs/arc/components/Banner/Banner';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RequestState } from '@turbine/types/RequestState';
import type { Nullish } from '@turbine/types/utils';
import type { Toast } from '../devices/enrolledDevices';
import {
  discardEmployeeV2Thunk,
  fetchEmployeeById,
  FetchEmployeesByCustomerErrors,
  fetchEmployeesByCustomerID,
  subscribeEmployeeWithID,
  excludeEmployeeWithID,
  switchApproverForEmployeeID,
  updateEmployeeV2Thunk,
  addPersonalDevice,
  removePersonalDevice,
} from './employeesSliceThunks';
import { upsertManyEmployeesData } from './helpers';

// OfficeLocation is the type of the office_location field (string) from Employee type
// once it's parsed as a JSON object
export type OfficeLocation = {
  name: string;
  street_address_1: string;
  street_address_2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  office_id: string;
};

export type Employee = {
  id: string;
  last_name: string;
  first_name: string;
  email: string;
  subscribed: boolean;
  excluded: boolean;
  is_bot?: boolean;
  created_at?: string;
  avatar: string | null;
  slack_data?: string | null;
  name: string;
  device_serial_number?: string;
  device_id?: string;
  device_hostname?: string;
  has_personal_device?: boolean;
  subscribed_at?: string | null;
  excluded_at?: string | null;
  approver: boolean;
  roles: Array<{ id: string; name: string }>;
  office_location?: string | null;
  job_title?: string | null;
  employment_type?: string | null;
  department?: string | null;
  manager?: {
    id: string;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
  } | null;
  service_level?: string | null;
  num_devices?: number;
};

export type EmployeesData = Employee[] | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PayloadMetaAction = PayloadAction<any> & { meta: any };

export type EmployeesState = {
  loading: boolean;
  data: EmployeesData;
  failed: boolean;
  employeeRequestState: RequestState;
  employeeUpdateState: RequestState;
  subscriptionFailed: boolean;
  subscriptionLoading: boolean;
  exclusionFailed: boolean;
  exclusionLoading: boolean;
  switchApproverFailed: boolean;
  switchApproverLoading: boolean;
  discardEmployeeLoading: boolean;
  discardEmployeeFailed: boolean;
  showDiscardEmployeeToast: boolean;
  discardEmployeeToastParams: Nullish<UseToastParams>;
  personalDeviceLoading: boolean;
  personalDeviceFailed: boolean;
  personalDeviceToast: Toast;
};

const initialToast = {
  intent: 'success' as BannerIntent,
  hideAfter: 2000,
};

const initialState: EmployeesState = {
  loading: false,
  failed: false,
  data: null,
  employeeRequestState: 'initial',
  employeeUpdateState: 'initial',
  subscriptionFailed: false,
  subscriptionLoading: false,
  exclusionFailed: false,
  exclusionLoading: false,
  switchApproverFailed: false,
  switchApproverLoading: false,
  discardEmployeeLoading: false,
  discardEmployeeFailed: false,
  showDiscardEmployeeToast: false,
  discardEmployeeToastParams: null,
  personalDeviceLoading: false,
  personalDeviceFailed: false,
  personalDeviceToast: initialToast,
};

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    resetEmployeesState: () => initialState,
    resetPersonalDeviceToast(state) {
      state.personalDeviceToast = initialToast;
    },
    showDiscardEmployeeToast: (state, action) => {
      state.showDiscardEmployeeToast = true;
      state.discardEmployeeToastParams = action.payload;
    },
    hideDiscardEmployeeToast: state => {
      state.showDiscardEmployeeToast = false;
      state.discardEmployeeToastParams = null;
    },
    addLocalPersonalDevice: (
      state,
      action: PayloadAction<{
        employeeId: string;
      }>
    ) => {
      const { employeeId } = action.payload;
      const updatedEmployees = state.data
        ? state.data.map(employee =>
            employee.id === employeeId
              ? {
                  ...employee,
                  has_personal_device: true,
                }
              : employee
          )
        : null;
      state.data = updatedEmployees;
    },
    removeLocalPersonalDevice: (
      state,
      action: PayloadAction<{
        employeeId: string;
      }>
    ) => {
      const { employeeId } = action.payload;
      const updatedEmployees = state.data
        ? state.data.map(employee =>
            employee.id === employeeId
              ? {
                  ...employee,
                  has_personal_device: false,
                }
              : employee
          )
        : null;
      state.data = updatedEmployees;
    },
  },
  // Curious about the below "builder" syntax / why these go in "extraReducers"?
  // Check out this documentation https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers

  // The reducers property both creates an action creator function and responds to
  //  that action in the slice reducer.
  // The extraReducers allows you to respond to an action in your slice reducer but
  // does not create an action creator function.
  extraReducers: builder => {
    builder.addCase(fetchEmployeesByCustomerID.fulfilled, (state, action) => {
      state.data = upsertManyEmployeesData(state.data, action.payload);
      state.loading = false;
      state.failed = false;
    });
    builder.addCase(fetchEmployeesByCustomerID.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchEmployeesByCustomerID.rejected, (state, action) => {
      // If we failed because of launch darkly, we don't want to update
      // the failed action since we want the UI to retry.
      if (action.payload === FetchEmployeesByCustomerErrors.LaunchDarkly) {
        state.failed = false;
        state.data = null;
      } else {
        state.failed = true;
        state.data = [];
      }
      state.loading = false;
    });
    builder.addCase(subscribeEmployeeWithID.fulfilled, state => {
      state.subscriptionLoading = false;
      state.subscriptionFailed = false;
    });
    builder.addCase(subscribeEmployeeWithID.pending, state => {
      state.subscriptionLoading = true;
    });
    builder.addCase(subscribeEmployeeWithID.rejected, state => {
      state.subscriptionFailed = true;
      state.subscriptionLoading = false;
    });
    builder.addCase(excludeEmployeeWithID.fulfilled, state => {
      state.exclusionLoading = false;
      state.exclusionFailed = false;
    });
    builder.addCase(excludeEmployeeWithID.pending, state => {
      state.exclusionLoading = true;
    });
    builder.addCase(excludeEmployeeWithID.rejected, state => {
      state.exclusionFailed = true;
      state.exclusionLoading = false;
    });
    builder.addCase(switchApproverForEmployeeID.fulfilled, state => {
      state.switchApproverFailed = false;
      state.switchApproverLoading = false;
    });
    builder.addCase(switchApproverForEmployeeID.pending, state => {
      state.switchApproverLoading = true;
    });
    builder.addCase(switchApproverForEmployeeID.rejected, state => {
      state.switchApproverFailed = true;
      state.switchApproverLoading = false;
    });
    // single employee
    builder.addCase(fetchEmployeeById.pending, state => {
      state.employeeRequestState = 'pending';
    });
    builder.addCase(fetchEmployeeById.fulfilled, (state, action) => {
      const { payload } = action;
      state.employeeRequestState = 'fulfilled';

      // TODO: https://electricops.atlassian.net/browse/IA1-2713
      // this logic is already included in redux toolkit's createEntityAdapter
      const employeeIndex = Number(
        state.data?.findIndex(employee => employee.id === payload.id)
      );
      // check if data is initialized
      if (Array.isArray(state.data)) {
        // update entry if employee exists
        if (employeeIndex > -1) {
          Object.assign(state.data[employeeIndex], action.payload);
        }
      } else {
        state.data = [{ ...payload }];
      }
    });
    builder.addCase(fetchEmployeeById.rejected, state => {
      state.employeeRequestState = 'rejected';
    });
    builder.addCase(updateEmployeeV2Thunk.fulfilled, state => {
      state.employeeUpdateState = 'fulfilled';
    });
    builder.addCase(updateEmployeeV2Thunk.pending, state => {
      state.employeeUpdateState = 'pending';
    });
    builder.addCase(updateEmployeeV2Thunk.rejected, state => {
      state.employeeUpdateState = 'rejected';
    });
    builder.addCase(discardEmployeeV2Thunk.fulfilled, state => {
      state.discardEmployeeLoading = false;
      state.discardEmployeeFailed = false;
    });
    builder.addCase(discardEmployeeV2Thunk.pending, state => {
      state.discardEmployeeLoading = true;
    });
    builder.addCase(discardEmployeeV2Thunk.rejected, state => {
      state.discardEmployeeFailed = true;
      state.discardEmployeeLoading = false;
    });
    builder.addCase(
      addPersonalDevice.fulfilled,
      (state, action: PayloadMetaAction) => {
        state.personalDeviceLoading = false;
        state.personalDeviceFailed = false;
        state.personalDeviceToast = {
          intent: 'success',
          message: `${action.meta.arg.employeeName} is using a personal device.`,
          stack: true,
          hideAfter: 2000,
        };
      }
    );
    builder.addCase(addPersonalDevice.pending, state => {
      state.personalDeviceLoading = true;
    });
    builder.addCase(addPersonalDevice.rejected, state => {
      state.personalDeviceLoading = false;
      state.personalDeviceFailed = true;
      state.personalDeviceToast = {
        intent: 'error',
        message:
          'Something went wrong while adding a personal device. Try adding again.',
        stack: true,
        hideAfter: 2000,
      };
    });
    builder.addCase(
      removePersonalDevice.fulfilled,
      (state, action: PayloadMetaAction) => {
        state.personalDeviceLoading = false;
        state.personalDeviceFailed = false;
        state.personalDeviceToast = {
          intent: 'success',
          message: `${action.meta.arg.employeeName} is no longer using a personal device.`,
          stack: true,
          hideAfter: 2000,
        };
      }
    );
    builder.addCase(removePersonalDevice.pending, state => {
      state.personalDeviceLoading = true;
    });
    builder.addCase(removePersonalDevice.rejected, state => {
      state.personalDeviceLoading = false;
      state.personalDeviceFailed = true;
      state.personalDeviceToast = {
        intent: 'error',
        message:
          'Something went wrong while removing a personal device. Try removing again.',
        stack: true,
        hideAfter: 2000,
      };
    });
  },
});

export const {
  resetEmployeesState,
  resetPersonalDeviceToast,
  showDiscardEmployeeToast,
  hideDiscardEmployeeToast,
  addLocalPersonalDevice,
  removeLocalPersonalDevice,
} = employeesSlice.actions;
