import { TooltipText } from '@turbine/@arc/recipes/TooltipText';
import {
  REMOTE_DEPROVISION_MESSAGE,
  REMOTE_AVAILABILITY_MESSAGE,
} from './common';

enum DeprovisionDeviceAdditionTypes {
  Email = 'EMAIL',
  Serial = 'SERIAL_NUMBER',
}

const DEPROVISION_DEVICE_ADDITION_LABELS = {
  [DeprovisionDeviceAdditionTypes.Email]: 'Search devices by email',
  [DeprovisionDeviceAdditionTypes.Serial]: 'Add with Name and Serial Number',
};

enum DeprovisionDeviceTypes {
  LOCK_AND_WIPE_DEVICE = 'LOCK_AND_WIPE_DEVICE',
  WIPE_DEVICE = 'WIPE_DEVICE',
  LOCK_DEVICE = 'LOCK_DEVICE',
  DELETE_USER_PROFILE = 'DELETE_USER_PROFILE',
  DELETE_USER_PROFILE_AND_LOCK_DEVICE = 'DELETE_USER_PROFILE_AND_LOCK_DEVICE',
  REMOVE_RMM = 'REMOVE_MDM',
}

const DEPROVISION_DEVICE_OPTIONS = [
  {
    label: 'Lock and wipe device (Electric’s recommendation)',
    value: DeprovisionDeviceTypes.LOCK_AND_WIPE_DEVICE,
    tooltip:
      'Electric will lock all users out of the device and restore the device to factory settings. The operating system will need to be reinstalled. Once the command is executed we will be unable to recover any data from this device and only you will be provided with code to unlock the device.',
    display: false,
  },
  {
    label: 'Lock device (Electric’s recommendation)',
    value: DeprovisionDeviceTypes.LOCK_DEVICE,
    tooltip:
      'Electric will lock all users out of the device and provide only you with the code to unlock the device. User data will remain on the device.',
    display: true,
  },
  {
    label: 'Wipe device',
    value: DeprovisionDeviceTypes.WIPE_DEVICE,
    tooltip:
      'Electric will restore the device to factory settings and will require that the operating system be reinstalled. Once the command is executed we will be unable to recover any data from this device.',
    display: true,
  },
  {
    label: 'Delete user profile and lock device',
    value: DeprovisionDeviceTypes.DELETE_USER_PROFILE_AND_LOCK_DEVICE,
    tooltip:
      'Electric will delete your offboarded user’s profile from this machine and lock all users out of it. Only you will be provided with the code to unlock the device however, user data for this profile cannot be recovered.',
    display: false,
  },
  {
    label: 'Delete user profile',
    value: DeprovisionDeviceTypes.DELETE_USER_PROFILE,
    tooltip:
      'Electric will delete your offboarded user’s profile from this machine. User data for this profile cannot be recovered.',
    display: false,
  },
  {
    label: 'Remove Remote Monitoring and Management (RMM) Profile',
    value: DeprovisionDeviceTypes.REMOVE_RMM,
    tooltip:
      'Electric will remove the remote monitoring and management software on this device. Once removed, Electric will no longer be able to manage this device unless it is reinstalled. Only select this option if the device will no longer be company property following this offboarding.',
    display: true,
  },
] as const;

enum DeprovisioningLocationTypes {
  Remote = 'REMOTE',
  AtElectric = 'AT_ELECTRIC',
}

const DEPROVISIONING_LOCATION_OPTIONS = [
  {
    label: 'Remote session',
    value: DeprovisioningLocationTypes.Remote,
    disabledTooltip: REMOTE_AVAILABILITY_MESSAGE,
  },
  {
    label: 'On-site at Electric',
    value: DeprovisioningLocationTypes.AtElectric,
    disabledTooltip: REMOTE_DEPROVISION_MESSAGE,
  },
] as const;

// Return destination
enum DeprovisioningReturnDestination {
  ElectricInventory = 'ELECTRIC_INVENTORY',
  YourOwnInventory = 'OWN_INVENTORY',
  OtherDestination = 'OTHER_DESTINATION',
  NoActionNecessary = 'NO_ACTION_NECESSARY',
}
const DEPROVISIONING_DESTINATION = [
  {
    label: 'Electric inventory',
    value: DeprovisioningReturnDestination.ElectricInventory,
    tooltip: (
      <>
        <TooltipText mb={4}>
          Electric makes it simple to store devices with us. We will hold onto
          your new and used devices so they can be set up and shipped out
          quickly from one convenient and safe location. Electric only stores
          laptops and their associated chargers at this time. The pricing will
          be updated and invoiced every month based on the following tiers:
        </TooltipText>
        <TooltipText mb={4}>
          1 device = $0/month
          <br />
          2-15 devices = $100/month
          <br />
          16-50 devices = $250/month
          <br />
          50 devices (no max) = $300/month
        </TooltipText>
        <TooltipText>
          If you have questions, feel free to reach out to your Customer Success
          Manager.
        </TooltipText>
      </>
    ),
  },
  {
    label: 'Your own inventory',
    value: DeprovisioningReturnDestination.YourOwnInventory,
    tooltip: null,
  },
  {
    label: 'Other destination',
    value: DeprovisioningReturnDestination.OtherDestination,
    tooltip: null,
  },
  {
    label: 'I do not need Electric´s assistance with shipping',
    value: DeprovisioningReturnDestination.NoActionNecessary,
    tooltip: null,
  },
] as const;

// Return Shipping
enum DeprovisioningReturnShipping {
  SendBoxAndLabel = 'SEND_BOX_AND_LABEL',
  SendLabel = 'SEND_LABEL',
  SendQRCode = 'SEND_QR_CODE',
}
enum DEPROVISIONING_RETURN_SHIPPING_TEXT_OPTIONS {
  EMAIL_ME_QR_CODE = 'Email me a QR Code',
  SEND_ME_LABEL_ONLY = 'Send me a label only',
}

const DEPROVISIONING_RETURN_SHIPPING = [
  {
    label: DEPROVISIONING_RETURN_SHIPPING_TEXT_OPTIONS.EMAIL_ME_QR_CODE,
    value: DeprovisioningReturnShipping.SendQRCode,
  },
  {
    label: DEPROVISIONING_RETURN_SHIPPING_TEXT_OPTIONS.SEND_ME_LABEL_ONLY,
    value: DeprovisioningReturnShipping.SendLabel,
  },
] as const;

const DEPROVISIONING_QR_CODE_RETURN_SHIPPING = [
  {
    label: DEPROVISIONING_RETURN_SHIPPING_TEXT_OPTIONS.EMAIL_ME_QR_CODE,
    value: DeprovisioningReturnShipping.SendQRCode,
  },
] as const;

export {
  DeprovisionDeviceTypes,
  DEPROVISION_DEVICE_OPTIONS,
  DeprovisioningLocationTypes,
  DEPROVISIONING_LOCATION_OPTIONS,
  DeprovisioningReturnDestination,
  DEPROVISIONING_DESTINATION,
  DeprovisioningReturnShipping,
  DEPROVISIONING_RETURN_SHIPPING,
  DEPROVISIONING_QR_CODE_RETURN_SHIPPING,
  DeprovisionDeviceAdditionTypes,
  DEPROVISION_DEVICE_ADDITION_LABELS,
};
