import { find } from 'lodash';
import { get, getOr } from 'lodash/fp';
import { createSelector } from '@reduxjs/toolkit';
import { arrayHasLength } from '@turbine/helpers/arrayHelpers';
import {
  OffboardingSchedulingType,
  YesNoOptions,
} from '@turbine/lib/xboarding/constants';
import { isEmptyObject } from '@turbine/lib/xboarding/helpers';
import {
  getSafeDate,
  getIsoDateFromDateAndTime,
} from '@turbine/lib/xboarding/utils';
import { type BoardingCustomerSoftware } from '@turbine/types/BoardingCustomerSoftware';
import createDeepEqualSelector from '../selectors/createDeepEqualSelector';
import {
  allSoftwareSelector,
  boardingCustomerSoftwaresDictSelector,
  boardingCustomerSoftwaresSelector,
  departmentsSelector,
  getAllProvisionableSoftware,
} from '../customerSoftwares';
import { policyDataSelector } from '../selectors/policySelector';
import { type RootState } from '../store';
import { type EmployeeInformation } from './employeeInformationSlice';
import {
  type DeprovisionDevice,
  type Device,
  type TaskList,
} from './taskListSlice';
import { getTrayAppsIDs } from '@turbine/pages/NewOnboarding/utils/helpers';
import { traySolutionInstanceDataSelector } from '../selectors/traySelector';
import { getNextAvailableOffboardTime } from './helpers/getNextAvailableOffboardTime';
import { selectEmployeesState } from '@turbine/redux/employees';
import { Roles } from '@turbine/constants/roles';
export const selectEmployeeInformation = (state: RootState) => {
  return state.newOffboarding.employeeInformation;
};

export const employeeInformationSelector = selectEmployeeInformation;

export const selectLastSuperEmail = createSelector(
  [selectEmployeesState],
  employeesState => {
    const employeesList = employeesState.employees;
    if (employeesList) {
      // Find out how many employees are super admins
      const superAdmins = employeesList.filter(em =>
        em.roles.some(r => r.name == Roles.SuperAdmin)
      );
      // If there's only one, that's the last one.
      if (superAdmins.length == 1) {
        return superAdmins[0].email;
      }
    }
    return null;
  }
);

const getTaskList = createSelector(
  get('newOffboarding.taskList'),
  fields => fields
);

const getRequestTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const getRequestDateTime = () => {
  const currentDate = new Date();
  return currentDate.toISOString();
};

export const taskListSelector = createSelector<any, TaskList, TaskList>(
  getTaskList,
  data => data
);

/** Task List Selectors */
export type TaskListField = keyof TaskList;

const getTaskListField = <T extends TaskListField>(field: T) => {
  return createSelector(
    get(['newOffboarding', 'taskList', field]),
    (fields: TaskList[T]) => fields
  );
};
export const taskListFieldValueSelector = <T extends TaskListField>(field: T) =>
  createSelector(getTaskListField(field), value => value);

export const selectCurrentStep = (state: RootState) =>
  state.newOffboarding.formState.currentStep;

export const selectIsLoadingOffboarding = (state: RootState) =>
  state.newOffboarding.formState.isLoading;

export const selectIsEditing = (state: RootState) =>
  state.newOffboarding.formState.isEditing;

export const selectNewOffboardingIsDuplicating = (state: RootState) =>
  state.newOffboarding.formState.isDuplicating;

export const selectNewOffboardingValidationFailed = (state: RootState) =>
  state.newOffboarding.formState.hasValidationErrors;

export const selectNewOffboardingSoftwares = createDeepEqualSelector(
  (state: RootState) => state.newOffboarding.taskList.saasApps?.softwares,
  softwares => softwares || {}
);

export const selectNewOffboardingSoftwaresValues = createSelector(
  selectNewOffboardingSoftwares,
  softwares => Object.values(softwares)
);

export const selectNewOffboardingSoftwaresAsCustomerApps = createSelector(
  [boardingCustomerSoftwaresSelector, selectNewOffboardingSoftwaresValues],
  (customerSoftwareApplications, loadedSoftwareValues) => {
    return loadedSoftwareValues?.map(({ id }) =>
      customerSoftwareApplications.find(csa => csa.id === id)
    );
  }
);

export const selectNewOffboardingSaasAppsStatus = (state: RootState) =>
  state.newOffboarding.taskList.saasApps.status;

export const currentDepartmentSelector = createSelector(
  employeeInformationSelector,
  departmentsSelector,
  (employeeInfo, departments) => {
    return departments.find(
      (dep: any) => dep.name === employeeInfo.department?.value
    );
  }
);

// Select software info from default policy and selected-department
export const policyAndDepartmentSoftwareSelector = createSelector(
  [
    departmentsSelector,
    policyDataSelector,
    employeeInformationSelector,
    allSoftwareSelector,
  ],
  (
    departments,
    customerPolicy,
    selectedDepartmentObj, // selected-department name
    allSoftware: BoardingCustomerSoftware[]
  ) => {
    const name = selectedDepartmentObj?.department?.value;
    const policyDefaultSaasIds = Object.values(
      getOr([], 'default.saasApplications', customerPolicy)
    );

    const selectedDepartment = find(departments, { name });
    const departmentSaasIds = getOr([], 'saasApplications', selectedDepartment);
    // policy default and department-specific app IDs
    const defaultAndDeptAppIDs = [
      ...new Set(policyDefaultSaasIds.concat(departmentSaasIds)),
    ];

    const defaultAndDeptApps = allSoftware.filter(software =>
      defaultAndDeptAppIDs.includes(software.id)
    );

    return defaultAndDeptApps;
  }
);

// Select software info from default policy, selected-department and Tray Solution Instances
export const policyAndDepartmentAndTraySoftwareSelector = createSelector(
  [
    departmentsSelector,
    policyDataSelector,
    employeeInformationSelector,
    allSoftwareSelector,
    traySolutionInstanceDataSelector,
  ],
  (
    departments,
    customerPolicy,
    selectedDepartmentObj, // selected-department name
    allSoftware: BoardingCustomerSoftware[],
    trayIOSolutionsInstances
  ) => {
    const departmentName = selectedDepartmentObj?.department?.value;
    const selectedDepartment = find(departments, { name: departmentName });
    const departmentSaasIds = getOr([], 'saasApplications', selectedDepartment);
    const policyDefaultSaasIds = Object.values(
      getOr([], 'default.saasApplications', customerPolicy)
    );

    // If there are Tray Solution Instances to the department, concat them with departmentSaasIds
    const allSoftwareIds = allSoftware.map(({ id }) => id);
    const saasWithTrayIOIds = departmentName
      ? [
          ...new Set(
            departmentSaasIds.concat(
              getTrayAppsIDs(
                departmentName,
                trayIOSolutionsInstances,
                allSoftwareIds
              )
            )
          ),
        ]
      : [];

    // policy default and department-specific app IDs
    const defaultAndDeptAppIDs = [
      ...new Set(
        policyDefaultSaasIds.concat(
          saasWithTrayIOIds.length ? saasWithTrayIOIds : departmentSaasIds
        )
      ),
    ];

    const defaultAndDeptApps = allSoftware.filter(software =>
      defaultAndDeptAppIDs.includes(software.id)
    );

    return defaultAndDeptApps;
  }
);

export const selectProvisionablePolicySoftwares = createSelector<
  any,
  BoardingCustomerSoftware[],
  BoardingCustomerSoftware[]
>(policyAndDepartmentSoftwareSelector, getAllProvisionableSoftware);

export const selectProvisionablePolicyAndTraySoftwares = createSelector<
  any,
  BoardingCustomerSoftware[],
  BoardingCustomerSoftware[]
>(policyAndDepartmentAndTraySoftwareSelector, getAllProvisionableSoftware);

export const getScheduledAtDateTime = (employeeInfo: EmployeeInformation) => {
  if (employeeInfo.scheduling?.type === OffboardingSchedulingType.ASAP) {
    return getNextAvailableOffboardTime().toISOString();
  }

  return getIsoDateFromDateAndTime(
    getSafeDate(employeeInfo.scheduling?.date),
    getSafeDate(employeeInfo.scheduling?.time?.value),
    employeeInfo.scheduling?.timeZone?.value
  );
};

enum ShippingDirection {
  To = 'shippingTo',
  From = 'shippingFrom',
}

const getShippingInformation = (
  data: DeprovisionDevice,
  direction = ShippingDirection.From
) => {
  if (isEmptyObject(data[direction])) return null;

  return {
    name: data[direction]?.name,
    streetAddress1: data[direction]?.streetAddress1,
    streetAddress2: data[direction]?.streetAddress2,
    city: data[direction]?.city,
    state: data[direction]?.state,
    zip: data[direction]?.zip,
    country: data[direction]?.country,
    officeId: data[direction]?.officeId,
    email_address: data[direction]?.emailAddress,
    phone: data[direction]?.phone,
  };
};

const getDeviceInformation = (data: DeprovisionDevice) => {
  if (isEmptyObject(data)) return null;

  return {
    name: data.name,
    serial: data.serialNumber,
    deprovisioningAdditionalNotes: data.notes,
    deprovisioningType: data.type?.value,
    returnDestination: data.returnDestination?.value,
    deprovisioningLocation: data.location?.value,
    returnShipping: data.returnShipping?.value || 'N/A',
    shippingFrom: getShippingInformation(data, ShippingDirection.From),
    shippingTo: getShippingInformation(data, ShippingDirection.To),
  };
};

const getDeprovisionInformation = (data: DeprovisionDevice) => {
  if (data?.selected === YesNoOptions.No) {
    return [];
  }

  const devices = get('devices', data) || [];
  const hasMultipleDevices = arrayHasLength(devices);
  const deviceInformation = hasMultipleDevices
    ? Object.values(devices).map((device: Device) => ({
        ...getDeviceInformation(data),
        name: device.name,
        serial: device.serial,
      }))
    : [getDeviceInformation(data)];

  return deviceInformation;
};

const getDecomissionDevice = (data: DeprovisionDevice) => {
  if (data?.selected !== YesNoOptions.Yes) {
    return '';
  }

  return data.serialNumber || data.devices;
};

export const selectNewOffboardingInfo = createSelector(
  boardingCustomerSoftwaresDictSelector,
  employeeInformationSelector,
  taskListSelector,
  currentDepartmentSelector,
  getRequestTimezone,
  (
    customerApps,
    employeeInfo,
    taskListInfo,
    currentDepartment,
    requestTimezone
  ) => {
    const removeFromEmail = taskListInfo.removeFromEmail;
    const deprovisionDevice = taskListInfo.deprovisionDevice;
    return {
      get scheduledAt(): string {
        return getScheduledAtDateTime(employeeInfo);
      },
      scheduleTimeZone: employeeInfo.scheduling?.timeZone?.value,
      employeeInfo: {
        offboardASAP:
          employeeInfo.scheduling?.type === OffboardingSchedulingType.ASAP,
        firstName: employeeInfo.firstName,
        lastName: employeeInfo.lastName,
        professionalEmail: employeeInfo.employeeEmail,
        managerEmail: employeeInfo.managerEmail,
        department: employeeInfo.department?.value,
      },
      accountInfo: {
        forwardEmailsTo: removeFromEmail.forwardEmail.isChecked
          ? removeFromEmail.forwardEmail?.forwardTo
          : '',
        shareCalendarWith: removeFromEmail.shareCalendar.isChecked
          ? removeFromEmail.shareCalendar?.shareCalendarWith
          : '',
        transferGoogleDriveTo: removeFromEmail.transferDrive.isChecked
          ? removeFromEmail.transferDrive?.transferDriveContentsTo
          : '',
        decomissionDevice: getDecomissionDevice(deprovisionDevice),
        OOOMessage: removeFromEmail.setupOOOMessage.isChecked
          ? removeFromEmail.setupOOOMessage?.OOOMessage
          : '',
        accountRemovalType: removeFromEmail.accountRemovalType,
      },
      notes: {
        oooMessageNotes: removeFromEmail.setupOOOMessage?.notes,
        shareCalendarNotes: removeFromEmail.shareCalendar?.notes,
        forwardEmailsToNotes: removeFromEmail.forwardEmail?.notes,
        transferGoogleDriveToNotes: removeFromEmail.transferDrive?.notes,
        archiveEmailNotes: removeFromEmail.archiveEmail?.notes,
      },
      notifications: {
        alertBeforeOffboarding: employeeInfo.alertBeforeOffboarding?.selected
          ? [employeeInfo.alertBeforeOffboarding?.email]
          : [],
        notifyWhenOffboardingComplete: employeeInfo
          .notifyWhenOffboardingComplete?.selected
          ? [employeeInfo.notifyWhenOffboardingComplete?.email]
          : [],
      },
      softwares: Object.values(taskListInfo?.saasApps?.softwares)
        .filter(app => !!customerApps[app.id])
        .map(app => ({
          id: app.id,
          name: customerApps[app.id]?.name,
          delete: app.deleteAccount,
          selectedTransfer: app.selectedTransfer,
          additionalNotes: app.notes,
          transferTo: app.transferTo ? [app.transferTo] : undefined,
          accountDeletionDelay: app.accountDeletionDelay?.value || 0,
        })),
      departmentHead: currentDepartment?.approvers,
      requestTimezone: requestTimezone,
      get requestDateTime(): string {
        return getRequestDateTime();
      },
      deprovisioningHardware: getDeprovisionInformation(deprovisionDevice),
    };
  }
);
