import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { tryJSONParse } from '@turbine/helpers/tryJSONParse';
import { type Nullish, type RecursivePartial } from '@turbine/types/utils';
import { fetchOffboardingForEdit } from './editNewOffboardingActions';
import { parseTaskListOffBoardingData } from './parseOffboardingForEdit';
import {
  DEFAULT_SHIPPING_COUNTRY,
  type DeprovisionDeviceAdditionTypes,
  DeprovisioningReturnDestination,
  type DeprovisioningReturnShipping,
  ELECTRIC_SHIPPING_ADDRESS,
  DeprovisioningLocationTypes,
  DEPROVISIONING_LOCATION_OPTIONS,
  type AccountRemovalTypes,
} from '@turbine/lib/xboarding/constants';
import { type SelectOption } from '@turbine/types/SelectOption';
import { type RequestState } from '@turbine/types/RequestState';
import { resetNewOffboarding } from './newOffboardingThunks';

export type ShippingAddress = {
  name?: string;
  emailAddress?: string;
  phone?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  remoteAddress?: string;
  city?: string;
  country?: string;
  officeId?: string;
  state?: string;
  zip?: string;
  label?: string;
  value?: ShippingAddress | string;
};

export const defaultShippingAddress: ShippingAddress = {
  name: '',
  emailAddress: '',
  phone: '',
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  country: '',
  officeId: '',
  state: '',
  zip: '',
};

export type OffBoardingSoftware = {
  id: string;
  deleteAccount: boolean;
  selectedTransfer: boolean;
  transferTo?: string;
  isNew?: boolean;
  name?: string;
  notes?: string;
  selectedDeletionDelay?: boolean;
  accountDeletionDelay?: SelectOption<number> | null;
};

// https://electricops.atlassian.net/browse/IA1-2771
// TODO: consider renaming this with a more specific name since there's already a common Device type with different fields
export type Device = {
  id?: string;
  name: string;
  serial: string;
  additionType?: DeprovisionDeviceAdditionTypes;
};

// https://electricops.atlassian.net/browse/IA1-2772
// TODO: move shared types to a common location
export type DeprovisionDevice = {
  devices?: Device[];
  selected?: string;
  name?: string;
  serialNumber?: string;
  type: SelectOption<string> | null;
  location: SelectOption<string> | null;
  returnDestination: SelectOption<DeprovisioningReturnDestination> | null;
  shippingTo: Nullish<ShippingAddress>;
  shippingFrom: Nullish<ShippingAddress>;
  returnShipping: SelectOption<DeprovisioningReturnShipping> | null;
  notes?: string;
};

export type TaskList = {
  deprovisionDevice: DeprovisionDevice;
  removeFromEmail: {
    setupOOOMessage: { isChecked: boolean; OOOMessage: string; notes: string };
    shareCalendar: {
      isChecked: boolean;
      shareCalendarWith: string;
      notes: string;
    };
    transferDrive: {
      isChecked: boolean;
      transferDriveContentsTo: string;
      notes: string;
    };
    forwardEmail: {
      isChecked: boolean;
      forwardTo: string;
      notes: string;
    };
    archiveEmail: { isChecked: boolean; notes: string };
    accountRemovalType: AccountRemovalTypes | null;
  };
  saasApps: {
    status: RequestState;
    softwares: Record<string, OffBoardingSoftware>;
  };
};

// https://electricops.atlassian.net/browse/IA1-2836
// Deprovisioning location will default to remote
export const defaultDeprovisioningLocation =
  DEPROVISIONING_LOCATION_OPTIONS.find(
    ({ value }) => value === DeprovisioningLocationTypes.Remote
  ) || null;

const initialState: TaskList = {
  deprovisionDevice: {
    selected: '',
    name: '',
    serialNumber: '',
    type: null,
    location: defaultDeprovisioningLocation,
    returnDestination: null,
    returnShipping: null,
    shippingFrom: defaultShippingAddress,
    shippingTo: null,
    notes: '',
  },
  removeFromEmail: {
    setupOOOMessage: { isChecked: false, OOOMessage: '', notes: '' },
    shareCalendar: {
      isChecked: true,
      shareCalendarWith: '',
      notes: '',
    },
    transferDrive: {
      isChecked: true,
      transferDriveContentsTo: '',
      notes: '',
    },
    forwardEmail: {
      isChecked: true,
      forwardTo: '',
      notes: '',
    },
    archiveEmail: { isChecked: true, notes: '' },
    accountRemovalType: null,
  },
  saasApps: {
    status: 'initial',
    softwares: {},
  },
};

const newOffboarding = createSlice({
  name: 'taskList',
  initialState,
  reducers: {
    taskDetailsUpdated: (state, action: PayloadAction<TaskList>) => {
      state = action.payload;
    },
    deprovisionDeviceUpdated: {
      prepare(payload: Partial<TaskList['deprovisionDevice']>) {
        if (payload.shippingFrom && !payload.shippingFrom.country) {
          payload.shippingFrom.country = DEFAULT_SHIPPING_COUNTRY;
        }

        return {
          payload,
        };
      },
      reducer(
        state,
        { payload }: PayloadAction<Partial<TaskList['deprovisionDevice']>>
      ) {
        merge(state.deprovisionDevice, payload);

        state.deprovisionDevice.devices = payload?.devices;

        const isReturnDestinationFromOwnInventory =
          payload.returnDestination?.value ===
          DeprovisioningReturnDestination.YourOwnInventory;

        if (
          isReturnDestinationFromOwnInventory &&
          !!payload.shippingTo?.value
        ) {
          const { officePhone, contactEmail, ...shippingToAddress } =
            tryJSONParse(payload.shippingTo.value);

          state.deprovisionDevice.shippingTo = {
            ...payload.shippingTo,
            ...shippingToAddress,
            emailAddress: contactEmail,
            phone: officePhone,
          };
        }

        const isReturnDestinationFromElectricInventory =
          payload.returnDestination?.value ===
          DeprovisioningReturnDestination.ElectricInventory;

        if (isReturnDestinationFromElectricInventory) {
          state.deprovisionDevice.shippingTo = ELECTRIC_SHIPPING_ADDRESS;
        }
      },
    },
    newOffBoardingSoftwaresAdded(
      state,
      { payload }: PayloadAction<OffBoardingSoftware['id'][]>
    ) {
      payload.forEach(id => {
        state.saasApps.softwares[id] = {
          id: id,
          deleteAccount: true,
          selectedTransfer: false,
          isNew: true,
          selectedDeletionDelay: false,
          accountDeletionDelay: null,
        };
      });

      state.saasApps.status = 'fulfilled';
    },
    newOffBoardingSoftwareAdded(
      state,
      {
        payload,
      }: PayloadAction<{
        id: OffBoardingSoftware['id'];
        software: OffBoardingSoftware;
      }>
    ) {
      state.saasApps.softwares[payload.id] = payload.software;
    },
    newOffBoardingSoftwareUpdated(
      state,
      {
        payload,
      }: PayloadAction<{
        id: OffBoardingSoftware['id'];
        software: Partial<OffBoardingSoftware>;
      }>
    ) {
      merge(state.saasApps.softwares[payload.id], payload.software);
    },
    newOffBoardingSoftwareRemoved(state, { payload }: PayloadAction<string>) {
      delete state.saasApps.softwares[payload];
    },
    resetSaasApps(state) {
      state.saasApps = initialState.saasApps;
    },
    removeFromEmailUpdated(
      state,
      { payload }: PayloadAction<RecursivePartial<TaskList['removeFromEmail']>>
    ) {
      merge(state.removeFromEmail, payload);
    },
    saasAppsUpdated(
      state,
      { payload }: PayloadAction<RecursivePartial<TaskList['saasApps']>>
    ) {
      merge(state.saasApps, payload);
    },
    resetTaskList: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(resetNewOffboarding, (_state, action) => ({
      ...initialState,
      ...action?.payload?.[newOffboarding.name],
    }));
    builder.addCase(fetchOffboardingForEdit.fulfilled, (state, action) => {
      if (action?.payload) {
        const payload = action?.payload;
        const taskList = parseTaskListOffBoardingData(payload);
        return taskList;
      }
      return initialState;
    });
  },
});

export const {
  taskDetailsUpdated,
  removeFromEmailUpdated,
  resetTaskList,
  deprovisionDeviceUpdated,
  newOffBoardingSoftwareAdded,
  newOffBoardingSoftwaresAdded,
  newOffBoardingSoftwareUpdated,
  newOffBoardingSoftwareRemoved,
  resetSaasApps,
  saasAppsUpdated,
} = newOffboarding.actions;

export default newOffboarding.reducer;
