import { type Employee } from './employeesSlice';

// TODO: https://electricops.atlassian.net/browse/IA1-2713
function upsertManyEmployeesData<Data extends Employee[] | null>(
  existingData: Data,
  newData: Data
) {
  if (!Array.isArray(newData)) return newData;

  return newData.map(item => {
    const employee = existingData?.find(({ id }) => id === item.id);
    if (employee) {
      return { ...employee, ...item };
    }
    return item;
  });
}

export { upsertManyEmployeesData };
