import PropTypes from 'prop-types';
import { Button } from '@electricjs/arc';
import ModalHeader from '@turbine/components/common/Modal/ModalTypes/common/ModalHeader';

const OffboardEmployeeModalComponent = ({
  bemClasses,
  CloseButton,
  modalProps: {
    offboardEmployee,
    title,
    buttonText,
    processingText,
    modalMessage,
  },
  employeeName,
  processing,
}) => {
  const offboardEmployeeModalClasses = (modifier = '') =>
    bemClasses('offboard-employee-modal', modifier);

  return (
    <div className={offboardEmployeeModalClasses('inner')}>
      <ModalHeader CloseButton={CloseButton} title={title} />
      <OffboardEmployeeModalBody
        bemClasses={offboardEmployeeModalClasses}
        employeeName={employeeName}
        offboardEmployee={offboardEmployee}
        processing={processing}
        buttonText={buttonText}
        processingText={processingText}
        modalMessage={modalMessage}
      />
    </div>
  );
};

OffboardEmployeeModalComponent.defaultProps = {
  processing: false,
};

OffboardEmployeeModalComponent.propTypes = {
  bemClasses: PropTypes.func.isRequired,
  CloseButton: PropTypes.func.isRequired,
  employeeName: PropTypes.string.isRequired,
  modalProps: PropTypes.shape({
    offboardEmployee: PropTypes.func.isRequired,
  }),
  processing: PropTypes.bool,
};

export default OffboardEmployeeModalComponent;

function OffboardEmployeeModalBody({
  employeeName,
  bemClasses,
  offboardEmployee,
  processing,
  buttonText,
  processingText,
  modalMessage,
}) {
  return (
    <div className={bemClasses('body')}>
      <div className={bemClasses('message-container')}>
        <span className={bemClasses('check')}>&#10003;</span>
        {modalMessage} {employeeName}?
      </div>
      <div className={bemClasses('sticky-bottom')}>
        <Button
          intent="success"
          onClick={offboardEmployee}
          loading={processing}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}
