import { type HardwareInfo } from '@turbine/graphql/types/boardingTypes';
import { REMOTE_ADDRESS_OPTION } from '@turbine/lib/addresses/constants';
import {
  CREATE_NEW_EMAIL_OPTION,
  DEFAULT_SHIPPING_COUNTRY,
  type YesNoOptions,
} from '@turbine/lib/xboarding/constants';
import { asSelectOptions } from '@turbine/lib/xboarding/helpers';
import { type Employee } from '@turbine/redux/employees';
import {
  type Address,
  type ChatApplicationAccountType,
  type OnboardingStep,
  type Software,
  type TaskList,
} from '@turbine/redux/newOnboarding';
import { type SelectOption } from '@turbine/types/SelectOption';
import {
  getAddresses,
  getCalendarPermissions,
  getHasWelcomeEmail,
  getISOStringFromTime,
  getSaasApps,
  getTimezone,
  getWelcomeEmailTime,
} from './helpers';
import { utcToZonedTime } from 'date-fns-tz';

export type TimeZoneOption = SelectOption<string> | undefined;

export type AddressProps = {
  street_address_1: string;
  street_address_2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  office_id: string;
  name: string;
  phone: string;
};

export type WelcomeEmailProps = {
  email: string;
  email_date: string;
  timezone: string;
  additional_notes: string;
};

type ChatAppProps = {
  slack_groups: string[];
  slack_channels: string[];
  slack_account_type: ChatApplicationAccountType;
  chat_application_notes: string;
};

type EmailGroupsProps = {
  email_groups: string[];
  email_groups_notes: string;
};

type CalendarsProps = {
  calendars: string[];
  calendar_permissions: string;
  calendar_additional_notes: string;
};

type EmployeeInfoProps = {
  first_name: string;
  last_name: string;
  address: AddressProps;
  employment_type: string;
  department: string;
  start_date: string;
  job_title: string;
  manager?: Employee;
};

type EmailInfoProps = {
  email_formats: string[];
  email_formats_notes: string;
  welcomeEmail: WelcomeEmailProps;
};

type HardwareProps = {
  selected_hardware: HardwareInfo[] | null;
  additional_instructions: string;
};

type EmployeeInformation = EmployeeInfoProps &
  EmailInfoProps & {
    notify_when_onboarding_complete: boolean;
  };

type TaskDetails = HardwareProps &
  EmailGroupsProps &
  CalendarsProps &
  ChatAppProps & {
    softwares: Software[];
  };

export type OnboardingDataProps = EmployeeInformation &
  TaskDetails & {
    draft_step_progress?: OnboardingStep;
  };

type WelcomeEmailReturnValue = {
  timeZone: TimeZoneOption;
  email: WelcomeEmailProps['email'];
  date: WelcomeEmailProps['email_date'];
  time: SelectOption<string> | undefined;
  notes: WelcomeEmailProps['additional_notes'];
};

export type EmployeeInfoReturnValue = {
  firstName: string;
  lastName: string;
  scheduledAt: string;
  employmentType: string;
  department: SelectOption<string> | undefined;
  address: string;
  remoteAddress: Address | undefined;
  professionalEmail: SelectOption<string> | undefined;
  emailFormatsNotes: string;
  notifyWhenOnboardingComplete: boolean;
  hasWelcomeEmail: YesNoOptions;
  welcomeEmail: WelcomeEmailReturnValue;
  jobTitle: string;
  manager: Employee | undefined;
};

const parseEmployeeInformation = (
  data: OnboardingDataProps
): EmployeeInfoReturnValue => {
  const time = getWelcomeEmailTime(data?.welcomeEmail);
  const hasWelcomeEmail = getHasWelcomeEmail(data?.welcomeEmail);
  const timeZone = getTimezone(data?.welcomeEmail);
  const { address, remoteAddress } = getAddresses(data?.address);
  const welcomeEmailTime =
    asSelectOptions(time, getISOStringFromTime(time)) || undefined;

  const emailFormat = data?.email_formats[0];
  const professionalEmail = CREATE_NEW_EMAIL_OPTION.isOption(emailFormat)
    ? CREATE_NEW_EMAIL_OPTION.asSelectOptions()
    : asSelectOptions<string, string>(emailFormat);

  const employeeInformation = {
    firstName: data?.first_name || '',
    lastName: data?.last_name || '',
    notifyWhenOnboardingComplete:
      data?.notify_when_onboarding_complete || false,
    employmentType: data?.employment_type || '',
    address,
    remoteAddress,
    emailFormatsNotes: data?.email_formats_notes || '',
    hasWelcomeEmail,
    welcomeEmail: {
      email: data?.welcomeEmail?.email || '',
      // Show in the destination timezone to prevent errors with GMT+xx timezones
      // The widget will show the date that's passed in, so we force it to the
      // destination timezone to prevent the date from being off by a day in
      // some cases.
      date:
        data?.welcomeEmail?.email_date && timeZone
          ? utcToZonedTime(
              data?.welcomeEmail?.email_date,
              timeZone.value
            ).toISOString()
          : data?.welcomeEmail?.email_date ?? '',
      time: welcomeEmailTime,
      timeZone,
      notes: data?.welcomeEmail?.additional_notes || '',
    },
    professionalEmail: professionalEmail || undefined,
    scheduledAt: data?.start_date || '',
    department: asSelectOptions<string, string>(data?.department) || undefined,
    jobTitle: data?.job_title || '',
    manager: data?.manager,
  };
  return employeeInformation;
};

const parseTaskListInformation = (data: OnboardingDataProps): TaskList => {
  const saasApps = getSaasApps(data?.softwares);
  const calendar_permissions = getCalendarPermissions(
    data?.calendar_permissions
  );
  const {
    city = '',
    country = DEFAULT_SHIPPING_COUNTRY,
    name = '',
    office_id: officeId = '',
    state = '',
    street_address_1: streetAddress1 = '',
    street_address_2: streetAddress2 = '',
    zip = '',
    phone = '',
  } = data?.selected_hardware?.[0]?.shippingTo || {};
  const shippingToInformation = {
    city,
    country,
    name,
    officeId,
    state,
    streetAddress1,
    streetAddress2,
    zip,
    phone,
  };

  const taskListInformation = {
    chatApplication: {
      applicationId: '',
      name: '',
      groups: data?.slack_groups || [],
      channels: data?.slack_channels || [],
      accountType: data?.slack_account_type || '',
      notes: data?.chat_application_notes || '',
    },
    calendars: {
      calendars: data?.calendars || [],
      notes: data?.calendar_additional_notes || '',
      permissions: calendar_permissions,
    },
    emailGroups: {
      groups: data?.email_groups || [],
      notes: data?.email_groups_notes || '',
    },
    saasApps,
    hardware: {
      selectedHardware: data?.selected_hardware || [],
      provisioningType:
        data?.selected_hardware?.[0]?.provisioningType || undefined,
      address: !officeId
        ? REMOTE_ADDRESS_OPTION
        : asSelectOptions(name, shippingToInformation) || null,
      shippingTo: shippingToInformation,
      notes: data?.additional_instructions || '',
    },
  };

  return taskListInformation;
};

export { parseEmployeeInformation, parseTaskListInformation };
