import { type ChangeEvent, useEffect, useState } from 'react';
import { Controller, type UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Field,
  FieldHelperText,
  Select,
  RadioGroup,
  Radio,
} from '@electricjs/arc';
import { FieldLabel } from '@turbine/@arc/recipes/FieldLabel';
import { has } from 'lodash';
import { YesNoOptions } from '@turbine/lib/xboarding/constants';

const { Yes, No } = YesNoOptions;

/** Generate Options from 1 to n hours */
const DELAY_DELETION_OPTIONS = Array.from({ length: 10 }, (_, i) => i + 1).map(
  key => ({
    label: key === 1 ? `${key} hour` : `${key} hours`,
    value: key,
  })
);

const EditRemovalTime = ({ appId, methods }: EditRemovalTimeProps) => {
  const { control, errors, setValue, register, watch } = methods;
  const { t } = useTranslation(['validation', 'newOffboarding']);
  const selectedDeletionDelayPath = `softwares[${appId}].selectedDeletionDelay`;
  const accountDeletionDelayPath = `softwares[${appId}].accountDeletionDelay`;
  const selectedDeletionDelayPathEnabled = watch(selectedDeletionDelayPath);

  const [radioGroupValue, setRadioGroupValue] = useState<String>(
    selectedDeletionDelayPathEnabled ? Yes : No
  );
  const errorAccountDeletionDelay = has(errors, accountDeletionDelayPath);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setRadioGroupValue(value);
    register(selectedDeletionDelayPath);
    setValue(selectedDeletionDelayPath, value === Yes, {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (!selectedDeletionDelayPathEnabled) {
      register(accountDeletionDelayPath);
      setValue(accountDeletionDelayPath, null, { shouldValidate: true });
    }
  }, [
    selectedDeletionDelayPathEnabled,
    accountDeletionDelayPath,
    register,
    setValue,
  ]);

  return (
    <>
      <Flex paddingTop="10px" paddingLeft="20px">
        <Field>
          <RadioGroup onChange={handleChange}>
            <Radio value={No} checked={radioGroupValue === No}>
              {t(
                'newOffboarding:editSaasApp.editRemovalTime.offboardTimeOption',
                {
                  defaultValue: 'Offboard Time',
                }
              )}
            </Radio>
            <Radio value={Yes} checked={radioGroupValue === Yes}>
              {t('newOffboarding:editSaasApp.editRemovalTime.laterOption', {
                defaultValue: 'Later',
              })}
            </Radio>
          </RadioGroup>
        </Field>
      </Flex>
      {radioGroupValue === Yes && (
        <Flex>
          <Field>
            <FieldLabel required>
              {t(
                'newOffboarding:editSaasApp.editRemovalTime.accountDeletionDelayLabel',
                {
                  defaultValue: 'Delete account after',
                }
              )}
            </FieldLabel>
            <Controller
              name={accountDeletionDelayPath}
              control={control}
              render={props => (
                <Select
                  {...props}
                  options={DELAY_DELETION_OPTIONS}
                  error={errorAccountDeletionDelay}
                />
              )}
            />
            <FieldHelperText
              error={errorAccountDeletionDelay}
              errorMessage={t('validation:required', {
                defaultValue: 'This field is required.',
              })}></FieldHelperText>
          </Field>
        </Flex>
      )}
    </>
  );
};

type EditRemovalTimeProps = {
  methods: Pick<
    UseFormMethods,
    'control' | 'errors' | 'setValue' | 'register' | 'watch'
  >;
  appId: string;
};

export { EditRemovalTime, DELAY_DELETION_OPTIONS };
