import { colorsV2, ColorsV2 } from '@electricjs/arc';
import { STATUS } from '@turbine/constants/itScoreCard';
import { datadogRum } from '@datadog/browser-rum';
import i18n from 'i18next';

export const MINIMUM_RAM_REQUIREMENT_IN_GB = 8;
export const MINIMUM_FREE_DISK_SPACE_PERCENTAGE = 15;
export const MINIMUM_OS_REQUIREMENT_FOR_MAC = '12.7.2';
export const MINIMUM_OS_REQUIREMENT_FOR_WINDOWS = [
  'Windows 10 Pro',
  'Windows 10 Enterprise',
  'Windows 10 Business',
  'Windows 11 Pro',
];

const SCORE_CARD_GRADES = {
  mac: {
    filevault: 0.3,
    firewall: 0.3,
    gatekeeper: 0.3,
    osUpdate: 0.08,
    diskSpace: 0.01,
    ram: 0.01,
  },
  pc: {
    bitlocker: 0.6,
    diskSpace: 0.01,
    ram: 0.01,
    osUpdate: 0.08,
    firewall: 0.3,
  },
  devices: 0.8,
  softwares: 0.2,
  gsuite: 0.1,
  office: 0.1,
};

const getAreasToAddressText = ({
  enableGoogleWorkspace = true,
  enableOffice365 = true,
}) => {
  let areasToAddress = {
    jamf: {
      errorWording: i18n.t('itScorecard:overview.jamf', {
        defaultValue: 'of your Macs are not properly enrolled in JAMF',
      }),
      category: 'mac',
      total: 'total',
      value: 'enrolled',
    },
    kaseya: {
      errorWording: i18n.t('itScorecard:overview.kaseya', {
        defaultValue: 'of your PCs are not properly enrolled in Kaseya',
      }),
      category: 'pc',
      total: 'total',
      value: 'enrolled',
    },
    filevault: {
      errorWording: i18n.t('itScorecard:overview.filevault', {
        defaultValue: 'devices do not have Filevault enabled ',
      }),
      category: 'mac',
      total: 'enrolled',
      value: 'filevault',
    },
    firewall: {
      errorWording: i18n.t('itScorecard:overview.firewall', {
        defaultValue: 'devices do not have Firewall enabled ',
      }),
      category: 'mac',
      total: 'enrolled',
      value: 'firewall',
    },
    gatekeeper: {
      errorWording: i18n.t('itScorecard:overview.gatekeeper', {
        defaultValue: 'devices do not have Gatekeeper enabled ',
      }),
      category: 'mac',
      total: 'enrolled',
      value: 'gatekeeper',
    },
    macOs: {
      errorWording: i18n.t('itScorecard:overview.macOs', {
        defaultValue: 'Macs need an OS upgrade',
      }),
      category: 'mac',
      total: 'enrolled',
      value: 'os',
    },
    macDisk: {
      errorWording: i18n.t('itScorecard:overview.macDisk', {
        amount: 15,
        defaultValue:
          'Macs have less than {{ amount, percent }} of disk space free',
      }),
      category: 'mac',
      total: 'enrolled',
      value: 'disk',
    },
    macRam: {
      errorWording: i18n.t('itScorecard:overview.macRam', {
        defaultValue: 'Macs have less than 8 GB of RAM',
      }),
      category: 'mac',
      total: 'enrolled',
      value: 'ram',
    },
    bitlocker: {
      errorWording: i18n.t('itScorecard:overview.bitlocker', {
        defaultValue: 'devices do not have Bitlocker enabled ',
      }),
      category: 'pc',
      total: 'enrolled',
      value: 'bitlocker',
    },
    pcOs: {
      errorWording: i18n.t('itScorecard:overview.pcOs', {
        defaultValue: 'PCs need an OS upgrade',
      }),
      category: 'pc',
      total: 'enrolled',
      value: 'os',
    },
    pcDisk: {
      errorWording: i18n.t('itScorecard:overview.pcDisk', {
        amount: '15',
        defaultValue:
          'PCs have less than {{ amount, percent }} of disk space free',
      }),
      category: 'pc',
      total: 'enrolled',
      value: 'disk',
    },
    pcRam: {
      errorWording: i18n.t('itScorecard:overview.pcRam', {
        defaultValue: 'PCs have less than 8 GB of RAM',
      }),
      category: 'pc',
      total: 'enrolled',
      value: 'ram',
    },
  };

  if (enableGoogleWorkspace) {
    areasToAddress = {
      ...areasToAddress,
      gsuite2FA: {
        errorWording: i18n.t('itScorecard:overview.gsuite2FA', {
          defaultValue: 'users are not using MFA for Google Workspace',
        }),
        category: 'gsuite',
        total: 'activeUsers',
        value: 'twoStepVerifications',
        showIf: 'is2faEnabled',
      },
      inactiveUsers: {
        errorWording: i18n.t('itScorecard:overview.inactiveUsers', {
          defaultValue:
            'users of Google Workspace have not been active for 30+ days',
        }),
        category: 'gsuite',
        total: 'activeUsers',
        value: 'recentLogins',
      },
    };
  }

  if (enableOffice365) {
    areasToAddress = {
      ...areasToAddress,
      office2FA: {
        errorWording: i18n.t('itScorecard:overview.office2FA', {
          defaultValue: 'users are not using MFA for Office 365',
        }),
        category: 'office',
        total: 'total',
        value: 'twoStepVerifications',
      },
    };
  }
  return areasToAddress;
};

const getPercentage = (stats, totalProperty, valueProperty) => {
  if (stats[totalProperty] && stats[valueProperty]) {
    return stats[valueProperty] / stats[totalProperty];
  }
  return 0;
};

const getAreaToAddressValue = (stats, totalProperty, valueProperty) => {
  if (
    (stats[totalProperty] || stats[totalProperty] === 0) &&
    (stats[valueProperty] || stats[valueProperty] === 0)
  ) {
    return stats[totalProperty] - stats[valueProperty];
  }
  return 0;
};

export const hasData = data => !!data?.stats?.total || !!data?.stats?.warning;

export const getAreasToAddress = ({
  enableGoogleWorkspace,
  enableOffice365,
  ...stats
}) => {
  const areasToAddress = [];
  Object.keys(
    getAreasToAddressText({ enableGoogleWorkspace, enableOffice365 })
  ).forEach(key => {
    const { errorWording, category, total, value, showIf } =
      getAreasToAddressText({ enableGoogleWorkspace, enableOffice365 })[key];
    if (stats[category]) {
      const categoryStats = stats[category]?.stats || {};
      // Check the showIf value to see if we actually want to show this area to address
      // if not, skip.
      if (showIf && !categoryStats[showIf]) {
        return;
      }
      const percentage = getPercentage(categoryStats, total, value);
      const valueToShow = getAreaToAddressValue(categoryStats, total, value);
      if (hasData(stats[category] || {}) && percentage < 0.9) {
        areasToAddress.push({
          type: percentage > 0.6 ? STATUS.warning.value : STATUS.danger.value,
          message: `${valueToShow} ${errorWording}`,
        });
      }
    }
  });
  return areasToAddress;
};
export const getMacScore = ({
  totalMacDevices,
  totalDevices,
  filevault,
  firewall,
  gatekeeper,
  os,
  ram,
  disk,
}) => {
  if (totalMacDevices > 0) {
    const { mac } = SCORE_CARD_GRADES;
    const macPercentage = totalMacDevices / totalDevices;
    const filevaultScore = (filevault / totalMacDevices) * mac.filevault;
    const gatekeeperScore = (gatekeeper / totalMacDevices) * mac.gatekeeper;
    const firewallScore = (firewall / totalMacDevices) * mac.firewall;
    const osScore = (os / totalMacDevices) * mac.osUpdate;
    const ramScore = (ram / totalMacDevices) * mac.ram;
    const diskScore = (disk / totalMacDevices) * mac.diskSpace;
    return (
      (filevaultScore +
        gatekeeperScore +
        firewallScore +
        osScore +
        ramScore +
        diskScore) *
      macPercentage
    );
  }
  return 0;
};

export const getPCScore = ({
  totalDevices,
  totalPcDevices,
  bitlocker,
  os,
  ram,
  disk,
  firewall,
}) => {
  if (totalPcDevices > 0) {
    const { pc } = SCORE_CARD_GRADES;
    const pcPercentage = totalPcDevices / totalDevices;
    const bitlockerScore = (bitlocker / totalPcDevices) * pc.bitlocker;
    const firewallScore = (firewall / totalPcDevices) * pc.firewall;
    const osScore = (os / totalPcDevices) * pc.osUpdate;
    const ramScore = (ram / totalPcDevices) * pc.ram;
    const diskScore = (disk / totalPcDevices) * pc.diskSpace;
    return (
      (bitlockerScore + osScore + ramScore + diskScore + firewallScore) *
      pcPercentage
    );
  }
  return 0;
};

export const getGsuiteScore = ({
  total,
  twoStepVerifications,
  is2faAvailable,
}) => {
  // if 2fa is not available we don't want it to negatively impact the score.
  // That just wouldn't be fair.
  if (!is2faAvailable) {
    return 1;
  } else if (total > 0) {
    return twoStepVerifications / total;
  }
  return 0;
};

export const getOfficeScore = ({
  total,
  two_step_verifications: twoStepVerificationsLegacy,
  twoStepVerifications,
}) => {
  if (total > 0) {
    return (twoStepVerifications ?? twoStepVerificationsLegacy) / total;
  }
  return 0;
};
const getSoftwaresScore = (
  gsuiteScore,
  officeScore,
  enableGoogleWorkspace,
  enableOffice365
) => {
  const { gsuite, office, softwares } = SCORE_CARD_GRADES;
  const finalGSuiteScore = enableGoogleWorkspace ? gsuiteScore : 1;
  const finalOfficeScore = enableOffice365 ? officeScore : 1;
  if (finalGSuiteScore && finalOfficeScore) {
    return finalGSuiteScore * gsuite + finalOfficeScore * office;
  }
  return (finalGSuiteScore + finalOfficeScore) * softwares;
};

export const getItScore = ({
  mac,
  pc,
  office,
  gsuite,
  enableGoogleWorkspace = true,
  enableOffice365 = true,
}) => {
  if (
    !mac ||
    !pc ||
    (!office && enableOffice365) ||
    (!gsuite && enableGoogleWorkspace)
  ) {
    return 0;
  }
  const { stats: macStats } = mac;
  const { stats: pcStats } = pc;
  const { stats: gsuiteStats } = gsuite;
  const { stats: officeStats } = office;
  const totalDevices = (macStats?.enrolled || 0) + (pcStats?.enrolled || 0);
  const macScore = getMacScore({
    totalDevices,
    totalMacDevices: macStats?.enrolled || 0,
    ...macStats,
  });
  const pcScore = getPCScore({
    totalDevices,
    totalPcDevices: pcStats?.enrolled || 0,
    ...pcStats,
  });
  const gsuiteScore = getGsuiteScore({
    ...gsuiteStats,
    total: gsuiteStats?.activeUsers || 0,
  });
  const officeScore = getOfficeScore({
    ...officeStats,
  });
  const softwaresScore = getSoftwaresScore(
    gsuiteScore,
    officeScore,
    enableGoogleWorkspace,
    enableOffice365
  );
  const devicesFinalGrade = softwaresScore ? SCORE_CARD_GRADES.devices : 1;
  return (
    Math.round(
      (devicesFinalGrade * (macScore + pcScore) + softwaresScore) * 100
    ) / 100
  );
};

export const isFreeDiskSpaceAboveMinimumPercent = (total, free) => {
  const freeSpacePercent = (free * 100) / total;

  return freeSpacePercent > MINIMUM_FREE_DISK_SPACE_PERCENTAGE;
};

export const extractAutomatedGSuiteData = data => {
  if (!data) return { stats: {} };

  return {
    stats: {
      total: data.total_users,
      gsuite: data.g_suite_business,
      teamStorage: data.team_storage,
      activeUsers: data.active_users,
      twoStepVerifications: data.users_with_two_step_verification,
      recentLogins: data.recent_logins,
      is2faAvailable: data.is_2fa_available,
    },
    authenticated: data.authenticated,
  };
};

export const extractAutomatedO365Data = data => {
  if (!data) return { stats: {} };

  return {
    stats: {
      licensedUsers: data.licensed_users,
      twoStepVerifications: data.users_with_two_step_verification,
      totalLicenses: data.total_licenses,
      total: data.total_users,
    },
    authenticated: data.authenticated,
  };
};

export const getStatus = progress => {
  if (progress >= 90) return STATUS.success;
  if (progress >= 70) return STATUS.warning;
  return STATUS.danger;
};

export const colorFromStatus = status => {
  if (status === 'success') return colorsV2[ColorsV2.SUCCESS];
  if (status === 'warning') return colorsV2[ColorsV2.WARNING];
  return colorsV2[ColorsV2.ERROR];
};

export const getGSuiteUsersForCSV = ({ data, without2FA }) => {
  const filteredData = without2FA
    ? data.it_scorecard_gsuite_csv.users.filter(
        user => user['is_2sv_enrolled'] === false
      )
    : data.it_scorecard_gsuite_csv.users;
  return filteredData;
};

export const prepareGSuiteUserDataForCSV = users => {
  return users.map(currentUser => {
    const initialValue = {
      'email address': '',
      first_name: '',
      last_name: '',
      status: '',
      'email usage (GB)': 0,
      'drive usage (GB)': 0,
      '2sv enrolled': '',
      'total storage (GB)': '',
      last_sign_in: '',
    };
    return Object.entries(currentUser).reduce((acc, [currKey, currValue]) => {
      switch (currKey) {
        case '__typename':
          break;
        case 'email':
          currKey = 'email address';
          acc[currKey] = currValue;
          break;
        case 'is_active':
          currKey = 'status';
          acc[currKey] = currValue ? 'Active' : 'Suspended';
          break;
        case 'is_2sv_enrolled':
          currKey = '2sv enrolled';
          acc[currKey] = currValue;
          break;
        case 'email_usage_in_gb':
          currKey = 'email usage (GB)';
          acc[currKey] = currValue;
          break;
        case 'drive_usage_in_gb':
          currKey = 'drive usage (GB)';
          acc[currKey] = currValue;
          break;
        case 'total_storage_in_gb':
          currKey = 'total storage (GB)';
          acc[currKey] = currValue === -1 ? 'Unlimited' : currValue;
          break;
        case 'last_sign_in':
          acc[currKey] = currValue
            ? formatCSVDate(currValue)
            : 'Never logged in';
          break;
        default: {
          acc[currKey] = currValue;
        }
      }
      return acc;
    }, initialValue);
  });
};

const formatCSVDate = date => {
  const localeDate = new Date(date).toLocaleString();
  return localeDate.replace(',', '');
};

export const trackDismissalEvent = (
  softwareReportType,
  { reason, customerId }
) => {
  // Track via Datadog Rum since it works.
  datadogRum?.addAction(
    `Removing Software Report From IT Scorecard: ${softwareReportType}`,
    {
      reason,
      customerId,
      software: softwareReportType,
    }
  );
  // "Try" to track with Pendo knowing very well it may not work.
  const pendoReady = pendo?.isReady && pendo?.isReady();
  pendoReady &&
    pendo?.track(
      `Removing Software Report From IT Scorecard: ${softwareReportType}`,
      {
        reason,
        software: softwareReportType,
      }
    );
};

export const trackAddEvent = (softwareReportType, { customerId }) => {
  // Track via Datadog Rum since it works.
  datadogRum?.addAction(
    `Adding Software Report to IT Scorecard: ${softwareReportType}`,
    {
      software: softwareReportType,
      customerId,
    }
  );
  // "Try" to track with Pendo knowing very well it may not work.
  const pendoReady = pendo?.isReady && pendo?.isReady();
  pendoReady &&
    pendo?.track(
      `Adding Software Report to IT Scorecard: ${softwareReportType}`,
      {
        software: softwareReportType,
      }
    );
};
