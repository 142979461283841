import { browserHistory as history } from '@electricjs/utils/helpers';

const parseQueryStringToObject = searchQuery => {
  const query =
    searchQuery.indexOf('?') === 0 ? searchQuery.substr(1) : searchQuery;
  return query.split('&').reduce((queryObj, param) => {
    const [key, val] = param.split('=');
    return {
      ...queryObj,
      [key]: val,
    };
  }, {});
};

export default class HistorySubscription {
  subscribe = (callback = () => {}) => {
    const { pathname: page, search } = history.location;
    const searchQuery = parseQueryStringToObject(search);
    callback({ page, searchQuery }); // set initial page in history
    return history.listen(location => {
      const { pathname: page, search } = location;
      const searchQuery = parseQueryStringToObject(search);
      callback({ page, searchQuery });
    });
  };
  // To stop listening, call the function returned from listen().
  unsubscribe = () => this.subscribe();
}
