import { MODAL_OPENED, MODAL_CLOSED } from '../types/modalTypes';

export const openModal = ({ modalProps, modalType }) => ({
  type: MODAL_OPENED,
  payload: { modalProps, modalType },
});

export const closeModal = () => ({
  type: MODAL_CLOSED,
});
