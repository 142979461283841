import i18n from 'i18next';

export const STATUS = {
  success: {
    value: 'success',
    text: i18n.t('itScorecard:status.success', {
      defaultValue: 'Excellent',
    }),
  },
  warning: {
    value: 'warning',
    text: i18n.t('itScorecard:status.warning', { defaultValue: 'Fair' }),
  },
  danger: {
    value: 'danger',
    text: i18n.t('itScorecard:status.danger', {
      defaultValue: 'Critical',
    }),
  },
};

export const getTooltips = () => {
  return {
    overallITGrade: i18n.t('itScorecard:tooltips.overallITGrade', {
      defaultValue:
        "This overall score is a weighted average of the individual scores below. Weighting is based on each item's relative importance to your overall IT environment. For more details please contact your account manager.",
    }),
    enrolled: i18n.t('itScorecard:tooltips.enrolled', {
      defaultValue:
        'Device management software such as JAMF for Macs and Kaseya for PCs enables you to apply security policies, monitor and even lock each of your devices remotely. \n' +
        '\n' +
        'If the number of properly enrolled devices is different from the total number it is likely that some devices have an incomplete or problematic install of device management: it should be reinstalled immediately.',
    }),
    filevault: i18n.t('itScorecard:tooltips.filevault', {
      defaultValue:
        'Filevault 2 is full-disk encryption to prevent unauthorized access to information on your startup disks. It uses XTS-AES-128 encryption with a 256-bit key.',
    }),
    gatekeeper: i18n.t('itScorecard:tooltips.gatekeeper', {
      defaultValue:
        'Gatekeeper is a technology for Mac OS that ensures only trusted programs are installed and run on your devices.',
    }),
    firewall: i18n.t('itScorecard:tooltips.firewall', {
      defaultValue:
        'Firewall, included with OS X, controls the connections made to your devices from other devices on your network.',
    }),
    windowsFirewall: i18n.t('itScorecard:tooltips.windowsFirewall', {
      defaultValue:
        "A firewall can protect your device from unwanted contact initiated by other computers when you're connected to the internet or a network.",
    }),
    os: i18n.t('itScorecard:tooltips.os', {
      defaultValue:
        'Operating system upgrades and periodic patches introduce enhanced security and prevent against newly discovered vulnerabilities.',
    }),
    ram: i18n.t('itScorecard:tooltips.ram', {
      defaultValue:
        'Sufficient random access memory (RAM) ensures that your devices can run software programs and system functions at the same time without degraded performance or lags.',
    }),
    disk: i18n.t('itScorecard:tooltips.disk', {
      defaultValue:
        'Free disk space helps computers perform system functions, run applications and manage memory use with optimum speed and efficiency.',
    }),
    bitlocker: i18n.t('itScorecard:tooltips.bitlocker', {
      defaultValue:
        'Bitlocker is full-volume encryption to prevent unauthorized access to information on your startup disks for Windows devices. it uses the AES encryption algorithm with a 128-bit or 256-bit key.',
    }),
    gsuite: i18n.t('itScorecard:tooltips.gsuite', {
      defaultValue:
        "Google Workspace for business offers Google's suite of productivity apps with enhanced security and support, along with admin controls that Electric uses to support your employees.",
    }),
    teamStorage: i18n.t('itScorecard:tooltips.teamStorage', {
      defaultValue:
        'Cloud storage allows your employees to securely store, access and collaborate on files from anywhere, with industry-leading security and safe backups.',
    }),
    activeUsers: i18n.t('itScorecard:tooltips.activeUsers', {
      defaultValue:
        'Active users have current access to Google Workspace applications, i.e. they have not been suspended.',
    }),
    twoStepVerifications: i18n.t('itScorecard:tooltips.twoStepVerifications', {
      defaultValue:
        'Multi-factor authentication helps to protect accounts from unauthorized access by requiring users to provide something they know, like a password, with something they have, like a token or authentication key on another device.',
    }),
    recentLogins: i18n.t('itScorecard:tooltips.recentLogins', {
      defaultValue: 'Recent is defined as a login within the last 30 days.',
    }),
    licenses: i18n.t('itScorecard:tooltips.licenses', {
      defaultValue:
        'Licenses reflect the number of seats for a given software that you have purchased, compared to how many of those seats are currently occupied/in-use.',
    }),
  };
};

export const getRecommendations = () => {
  return {
    noRecommendations: i18n.t('itScorecard:recommendations.noRecommendations', {
      defaultValue: 'No recommendations at this time. Great job!',
    }),
    macs: {
      filevault: i18n.t('itScorecard:recommendations.macs.filevault', {
        defaultValue:
          'Enable Filevault on remaining devices. This requires reboot.',
      }),
      gatekeeper: i18n.t('itScorecard:recommendations.macs.gatekeeper', {
        defaultValue: 'Enable Gatekeeper on remaining devices.',
      }),
      firewall: i18n.t('itScorecard:recommendations.macs.firewall', {
        defaultValue: 'Enable Firewall on remaining devices.',
      }),
    },
    pc: {
      bitlocker: i18n.t('itScorecard:recommendations.pc.bitlocker', {
        defaultValue:
          'Enable/push Bitlocker to remaining devices. This requires reboot.',
      }),
      firewall: i18n.t('itScorecard:recommendations.pc.firewall', {
        defaultValue: 'Enable Firewall on remaining devices.',
      }),
    },
    gsuite: {
      activeUsers: i18n.t('itScorecard:recommendations.gsuite.activeUsers', {
        defaultValue:
          'Audit, suspend and then archive or delete inactive users.',
      }),
      twoStepVerifications: i18n.t(
        'itScorecard:recommendations.gsuite.twoStepVerifications',
        { defaultValue: 'Enable and enforce MFA for all users.' }
      ),
      recentLogins: i18n.t('itScorecard:recommendations.gsuite.recentLogins', {
        defaultValue:
          'Audit, suspend and then archive or delete inactive users.',
      }),
    },
    office: {
      licenses: i18n.t('itScorecard:recommendations.office.licenses', {
        defaultValue:
          'Audit, suspend and then archive or delete inactive users.',
      }),
      twoStepVerifications: i18n.t(
        'itScorecard:recommendations.office.twoStepVerifications',
        { defaultValue: 'Enable and enforce MFA for all users.' }
      ),
    },
  };
};
