import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { parseFormStateOffBoardingData } from './parseOffboardingForEdit';
import { fetchOffboardingForEdit } from './editNewOffboardingActions';
import { resetNewOffboarding } from './newOffboardingThunks';

export type OffboardingStep = 1 | 2 | 3;

export type FormState = {
  isDuplicating: boolean;
  isEditing: boolean;
  currentStep: OffboardingStep;
  isLoading?: boolean;
  hasValidationErrors: boolean;
};

const initialState: FormState = {
  isDuplicating: false,
  isEditing: false,
  currentStep: 1,
  isLoading: false,
  hasValidationErrors: false,
};

const formStateSlice = createSlice({
  name: 'formState',
  initialState,
  reducers: {
    stepUpdated: (state, action: PayloadAction<OffboardingStep>) => {
      state.currentStep = action.payload;
    },
    offboardingDuplicated: state => {
      state.isDuplicating = true;
    },
    stepFormValidationFailed: (
      state,
      action: PayloadAction<OffboardingStep>
    ) => {
      state.currentStep = action.payload;
      state.hasValidationErrors = true;
    },
    resetFormState: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(resetNewOffboarding, (_state, action) => ({
      ...initialState,
      ...action?.payload?.[formStateSlice.name],
    }));
    builder.addCase(fetchOffboardingForEdit.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchOffboardingForEdit.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchOffboardingForEdit.fulfilled, (state, action) => {
      if (action?.payload) {
        const payload = action?.payload;
        const formState = parseFormStateOffBoardingData(payload);
        return formState;
      }
      return initialState;
    });
  },
});

export const { resetFormState, stepUpdated, stepFormValidationFailed } =
  formStateSlice.actions;
export default formStateSlice.reducer;
