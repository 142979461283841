import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { fetchOnboardingDraft } from './draftOnboardingActions';
import { resetNewOnboarding } from './newOnboardingThunks';

export interface Question {
  title: string;
  question_code: string;
  question_id: string;
  software_name: string;
  type: string;
  tooltip: string | null;
  placeholder: string | null;
  default_value: string | null;
  required: boolean;
  format: string | null;
  options: string[];
  parent_question_code: string | null;
  children: Question[];
}

export type Questions = Record<string, Question[]>;

export enum OnboardingStep {
  'NotStarted' = 0,
  'EmployeeInfo' = 1,
  'TaskDetails' = 2,
  'TaskList' = 3,
}

export type NewOnboardingState = {
  isDuplicating: boolean;
  isDraftCreated: boolean;
  isUnsaved: boolean;
  currentDraftId: string;
  currentStep: OnboardingStep;
  stepProgress: OnboardingStep;
  draftStepProgress: OnboardingStep;
  status: 'initial' | 'pending' | 'failed' | 'fulfilled';
  hasValidationErrors: boolean;
};

/**
 * `draftStepProgress` state is used to track the last step where the onboarding form was saved as a draft. It's also persisted on the BE.
 */
/**
 * https://electricops.atlassian.net/browse/IA1-2773
 * TODO: check if `isDuplicating` can be removed from the codebase
 * since its value never changes  */
const initialState: NewOnboardingState = {
  isDuplicating: false,
  isDraftCreated: false,
  isUnsaved: false,
  currentDraftId: '',
  currentStep: 1,
  stepProgress: 1,
  draftStepProgress: 0,
  status: 'initial',
  hasValidationErrors: false,
};

const formStateSlice = createSlice({
  name: 'formState',
  initialState,
  reducers: {
    stepUpdated: (state, action: PayloadAction<OnboardingStep>) => {
      state.currentStep = action.payload;
      if (action.payload > state.stepProgress) {
        state.stepProgress = (action.payload - 1) as OnboardingStep;
      }
    },
    draftStepUpdated: (state, action: PayloadAction<OnboardingStep>) => {
      state.draftStepProgress = action.payload;
    },
    setDraftUnsaved: (state, action: PayloadAction<boolean>) => {
      state.isUnsaved = action.payload;
    },
    setCurrentDraftId: (
      state,
      action: PayloadAction<NewOnboardingState['currentDraftId']>
    ) => {
      state.isDraftCreated = true;
      state.currentDraftId = action.payload;
    },
    stepFormValidationFailed: (
      state,
      action: PayloadAction<OnboardingStep>
    ) => {
      state.currentStep = action.payload;
      state.hasValidationErrors = true;
    },
    resetFormProgress: state => {
      state.stepProgress =
        state.currentStep > 1
          ? ((state.currentStep - 1) as OnboardingStep)
          : initialState.stepProgress;
    },
    resetFormState: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(resetNewOnboarding, () => initialState);
    builder.addCase(fetchOnboardingDraft.pending, (state, action) => {
      state.status = 'pending';
      state.currentDraftId = action.meta.arg.onboardingId;
      state.isDraftCreated = true;
    });
    builder.addCase(fetchOnboardingDraft.fulfilled, (state, action) => {
      if (action?.payload) {
        state.draftStepProgress = action.payload
          ?.draft_step_progress as OnboardingStep;
        state.status = 'fulfilled';
        return state;
      }
      return initialState;
    });
  },
});

export const {
  setCurrentDraftId,
  resetFormProgress,
  resetFormState,
  stepUpdated,
  draftStepUpdated,
  setDraftUnsaved,
  stepFormValidationFailed,
} = formStateSlice.actions;

export default formStateSlice.reducer;
