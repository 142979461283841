const updateUrlMiddleware = storeAPI => next => action => {
  const result = next(action);
  if (typeof action.meta?.updateUrl === 'function') {
    const state = storeAPI.getState();
    action.meta.updateUrl(state);
  }
  return result;
};

export default updateUrlMiddleware;
