import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  type NormalizedCacheObject,
} from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import {
  REACT_APP_PROXY_FLARE_PUBLIC_URL,
  REACT_APP_PROXY_FLARE_URL,
  REACT_APP_ELECTRIC_API_URL,
} from '@turbine/config';
import { tryJSONParse } from '@turbine/helpers/tryJSONParse';
import { type Dispatch, type SetStateAction } from 'react';

export type ApolloClientInstance = ApolloClient<NormalizedCacheObject> | null;

const authLink = (accessToken?: string) =>
  setContext(async (_, { headers }) => {
    const token = accessToken ? accessToken : null;
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

// Calls prefixed with @rest will use this link
const restLink = new RestLink({
  uri: REACT_APP_ELECTRIC_API_URL || '',
  // other link options...
});

const PUBLIC_OPERATIONS = {
  SelfServiceSignUp: true,
};

let clientInstance: ApolloClientInstance = null;

export default function graphqlClient(accessToken?: string) {
  if (clientInstance) {
    return clientInstance;
  }
  let customFetch = fetch;
  if (window.Cypress) {
    customFetch = (uri, options) => {
      const { operationName } = tryJSONParse(options?.body);
      return fetch(`${uri}?${operationName}`, options);
    };
  }
  // Create a split so that public operations go to the
  // /public route and other operations go to /graphql
  const proxyFlareLink = ApolloLink.split(
    ({ operationName }) =>
      !!PUBLIC_OPERATIONS[operationName as keyof typeof PUBLIC_OPERATIONS],
    createUploadLink({
      uri: REACT_APP_PROXY_FLARE_PUBLIC_URL,
      fetch: customFetch,
    }) as unknown as ApolloLink,
    createUploadLink({
      uri: REACT_APP_PROXY_FLARE_URL,
      fetch: customFetch,
    }) as unknown as ApolloLink
  );
  // Compose our links together.
  // authLink adds the authentication bits
  // onError adds an error handler to all links.
  // restLink is our way of communicating with the Electric API (via REST)
  // proxyFlareLink is our way of communicating with Proxy Flare (via GraphQL)
  clientInstance = new ApolloClient({
    link: ApolloLink.from([
      authLink(accessToken),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            window?.DD_RUM?.addError(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError)
          window?.DD_RUM?.addError(`[Network error]: ${networkError}`);
      }),
      restLink,
      proxyFlareLink,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        CalendarsData: {
          keyFields: ['id', 'name'],
        },
        IdentityProviderUser: {
          keyFields: ['identity_provider_id'],
        },
      },
    }),
    connectToDevTools: true,
  });
  return clientInstance;
}

/**
 *
 * @param {string} accessToken access token from Auth0
 * @param {function} setState local state function to set apollo client
 *
 */
export const initApolloWithToken = (
  accessToken: string,
  setState: Dispatch<SetStateAction<ApolloClientInstance>>
) => setState(graphqlClient(accessToken));
