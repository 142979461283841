import { createSlice } from '@reduxjs/toolkit';
import {
  getAllSoftwareApps,
  getCustomerApps,
  getAppAccount,
} from './applicationsSliceThunks';

export type Application = {};

export type CustomerSoftwareApplication = {
  id: string;
  customer_id: string;
  state: string;
  supported: boolean;
  application_admin_id?: string | null;
  software_application?: SoftwareApplication[] | null;
  application_admin?: ApplicationAdmin | null;
};

export type AddCustomerAppResponse = {
  data: {
    createCustomerSoftwareApplication: {
      customer_software_applications: Pick<
        CustomerSoftwareApplication,
        Exclude<keyof CustomerSoftwareApplication, 'software_application'>
      > &
        { software_application: SoftwareApplication[] }[];
    };
  };
};

export type UpdateCustomerAppResponse = {
  data: {
    updateCustomerSoftwareApplication: {
      customer_software_applications: CustomerSoftwareApplication[];
    };
  };
};

type ApplicationAdmin = {
  id: string;
  name: string;
  email: string;
  avatar?: string | null;
};
export type SoftwareApplication = {
  id: string;
  parent_id?: null;
  name: string;
  icon: string;
  description?: null;
  developer: string;
  supported: boolean;
  verified: boolean;
  internal: boolean;
  allows_deletion: boolean;
  allows_transfer: boolean;
  requires_account: boolean;
  requires_installation: boolean;
  created_at?: null;
  children?: (SoftwareApplicationsChildren | null)[] | null;
};
type SoftwareApplicationsChildren = {
  id: string;
  parent_id: string;
  name: string;
  icon: string;
  developer: string;
  description?: null;
};

type ApplicationsState = {
  customerSoftwareAppsLoading: boolean;
  customerSoftwareAppsFailed: boolean;
  customerSoftwareAppsData: CustomerSoftwareApplication[] | null;
  allAppsLoading: boolean;
  allAppsFailed: boolean;
  allAppsData: SoftwareApplication[] | null;
  hasChatAppLoading: boolean;
  hasChatAppFailed: boolean;
  hasChatApp: boolean | undefined | null;
};

const initialState: ApplicationsState = {
  customerSoftwareAppsLoading: false,
  customerSoftwareAppsFailed: false,
  customerSoftwareAppsData: null,
  allAppsLoading: false,
  allAppsFailed: false,
  allAppsData: null,
  hasChatAppLoading: false,
  hasChatAppFailed: false,
  hasChatApp: null,
};

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    resetApplicationsState: _state => initialState,
    addApplicationLocally: (state, action) => {
      state.customerSoftwareAppsData?.push(action.payload);
    },
    removeApplicationLocally: (state, action) => {
      state.customerSoftwareAppsData =
        state.customerSoftwareAppsData?.filter(
          app => app.id !== action.payload.id
        ) || [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllSoftwareApps.fulfilled, (state, action) => {
      state.allAppsLoading = false;
      state.allAppsFailed = false;
      state.allAppsData = action.payload?.allApps;
    });
    builder.addCase(getAllSoftwareApps.pending, state => {
      state.allAppsLoading = true;
      state.allAppsFailed = false;
    });
    builder.addCase(getAllSoftwareApps.rejected, state => {
      state.allAppsLoading = true;
      state.allAppsFailed = false;
    });
    builder.addCase(getCustomerApps.fulfilled, (state, action) => {
      state.customerSoftwareAppsLoading = false;
      state.customerSoftwareAppsFailed = false;
      state.customerSoftwareAppsData = action.payload;
    });
    builder.addCase(getCustomerApps.pending, state => {
      state.customerSoftwareAppsLoading = true;
      state.customerSoftwareAppsFailed = false;
    });
    builder.addCase(getCustomerApps.rejected, state => {
      state.customerSoftwareAppsLoading = true;
      state.customerSoftwareAppsFailed = false;
    });
    builder.addCase(getAppAccount.fulfilled, (state, action) => {
      state.hasChatAppLoading = false;
      state.hasChatAppFailed = false;
      state.hasChatApp = action.payload;
    });
    builder.addCase(getAppAccount.pending, state => {
      state.hasChatAppLoading = true;
      state.hasChatAppFailed = false;
    });
    builder.addCase(getAppAccount.rejected, state => {
      state.hasChatAppLoading = true;
      state.hasChatAppFailed = false;
    });
  },
});

export const {
  resetApplicationsState,
  addApplicationLocally,
  removeApplicationLocally,
} = applicationsSlice.actions;
