import { gql } from '@apollo/client';

export const IT_SCORE_CARD_GSUITE_DATA = gql`
  query it_scorecard_gsuite($customerId: String!) {
    it_scorecard_gsuite(customer_id: $customerId) {
      authenticated
      active_users
      recent_logins
      team_storage
      total_users
      users_with_two_step_verification
      g_suite_business
      is_2fa_available
    }
  }
`;

export const REAL_IT_SCORE_CARD_O365_DATA = gql`
  query it_scorecard_microsoft($customerId: String!) {
    it_scorecard_microsoft(customer_id: $customerId) {
      authenticated
      total_users
      users_with_two_step_verification
      licensed_users
      total_licenses
      available_licenses
    }
  }
`;

export const IT_SCORE_CARD_GSUITE_CSV_DATA_FROM_PROXY_FLARE = gql`
  query RealITScoreCardStats($customerId: String!) {
    it_scorecard_gsuite_csv(customer_id: $customerId) {
      users {
        email
        first_name
        last_name
        last_sign_in
        email_usage_in_gb
        drive_usage_in_gb
        total_storage_in_gb
        is_active
        is_2sv_enrolled
      }
    }
  }
`;

export const IT_SCORE_CARD_O365_CSV_DATA = gql`
  query RealITScoreCardStats($customerId: String!) {
    itScoreCardReports(customer_id: $customerId) {
      o365 {
        licenses {
          auth_2fa_enabled
          created_at
          email_address
          first_name
          last_name
          status
          email_usage_gb
          drive_usage_gb
          two_step_verification_enrolled
          total_storage
          last_sign_in
          id
          saas_type
        }
      }
    }
  }
`;

export const IT_SCORE_CARD_O365_WITHOUT_2FA_CSV_DATA = gql`
  query RealITScoreCardStats($customerId: String!) {
    itScoreCardReports(customer_id: $customerId) {
      o365 {
        licenses_without_2fa {
          auth_2fa_enabled
          created_at
          email_address
          first_name
          last_name
          status
          email_usage_gb
          drive_usage_gb
          two_step_verification_enrolled
          total_storage
          last_sign_in
          id
          saas_type
        }
      }
    }
  }
`;

export const IT_SCORE_CARD_CONFIGURATION = gql`
  query itsc_configuration($customerId: String!) {
    itsc_configuration(customer_id: $customerId) {
      google_workspace_enabled
      o365_enabled
    }
  }
`;

export const GET_ITSC_USER_REQUESTS = gql`
  query ITSCUserRequest($customerId: String!, $requestType: String!) {
    itscUserRequest(customer_id: $customerId, request_type: $requestType)
      @rest(
        type: "ITSCUserRequest"
        path: "/v1/user-requests?customer_id={args.customer_id}&request_type={args.request_type}"
      ) {
      data {
        customer_id
        device_id
        serial_number
        hostname
        requester_id
        request_key
        request_type
        request_status
      }
    }
  }
`;
