import type * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  useCollapse,
  Icon,
  type IconNames,
  getToken,
  ColorsV2,
} from '@electricjs/arc';

type SubNavToggleProps = {
  icon?: IconNames;
  text?: string;
  toggle: () => void;
  isVisible: boolean;
};

const SubNavToggle = ({
  icon,
  text,
  toggle,
  isVisible,
}: SubNavToggleProps): React.ReactElement => (
  <div
    data-testid="subnav-toggle"
    className="collapsible-subnav__section"
    role="button"
    onKeyPress={toggle}
    id="react-collapsed-toggle-nav"
    aria-controls="react-collapsed-panel-nav"
    tabIndex={0}
    onClick={toggle}>
    {icon && <Icon icon={icon} color={ColorsV2.TEXT} />}
    <span className={`collapsible-subnav__text${isVisible ? ' expanded' : ''}`}>
      {text}
    </span>
    <Icon
      icon={isVisible ? 'chevron-up' : 'chevron-down'}
      size="small"
      color={ColorsV2.TEXT}
      testId="nav-collapsible-icon-arrow"
    />
  </div>
);

SubNavToggle.propTypes = {
  toggle: PropTypes.func.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

const StyledCollapsibleSubNav = styled.div`
  width: 100%;
  padding: 0 1.6rem;
  min-height: 45px;
  margin-bottom: 1.2rem;

  .collapsible-subnav__section {
    cursor: pointer;
    -webkit-appearance: none;
    outline: 0;
    display: flex;
    align-items: center;

    // Collapsible nav arrow indicator icon
    div[data-testid='nav-collapsible-icon-arrow'] {
      display: flex;
      justify-content: center;
      margin-right: 1.5rem;
      margin-left: 2.6rem;
      width: 26px;
      fill: ${getToken(ColorsV2.TEXT)};

      &:first {
        svg {
          width: 20px;
        }
      }
    }

    // Collapsible nav icon
    div[data-testid='arc-icon'] {
      margin-right: 1.5rem;
      margin-left: 1rem;

      svg {
        width: 20px;
      }
    }

    .collapsible-subnav__text {
      font-size: 1.6rem;
      font-weight: 400;
      width: calc(100% - 4rem);
      height: 4rem;
      line-height: 4rem;
      cursor: pointer;

      &.expanded {
        color: ${getToken(ColorsV2.TEXT_LIGHT)};
      }
    }
  }

  .collapsible-subnav__items {
    width: 100%;

    div:first-child {
      margin-top: 1.2rem;
    }

    .nav-item-turbine {
      display: block;
      width: 100%;
      padding-left: 1rem;
      margin-top: 0;
      margin-bottom: 1rem;
      width: 100%;
      box-sizing: border-box;

      span {
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
`;

type CollapsibleSubNavProps = {
  children: React.ReactNode;
  icon: IconNames;
  text: string;
  isLoading: boolean;
};

const CollapsibleSubNav = ({
  children,
  ...otherProps
}: CollapsibleSubNavProps): React.ReactElement => {
  const { toggle, Collapsible, visible } = useCollapse({ visible: false });

  return (
    <StyledCollapsibleSubNav
      className="collapsible-subnav"
      data-testid={'collapsible-subnav'}>
      <SubNavToggle {...otherProps} toggle={toggle} isVisible={visible} />
      <div
        data-testid={'subnav-items'}
        className="collapsible-subnav__items"
        id="react-collapsed-panel-nav">
        <Collapsible>{children}</Collapsible>
      </div>
    </StyledCollapsibleSubNav>
  );
};

CollapsibleSubNav.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CollapsibleSubNav;
