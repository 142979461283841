import BEMHelper from 'react-bem-helper';
import pickBy from 'lodash/pickBy';

function isObject(obj) {
  return obj === Object(obj) && !Array.isArray(obj);
}

function manufactureModifiers(modifiers) {
  return isObject(modifiers) ? pickBy(modifiers) : modifiers;
}

export const bemify = (...classes) => {
  classes = Array.prototype.concat(...classes).filter(item => item);

  const BEMClasses = classes.map(
    className =>
      new BEMHelper({
        name: className,
        outputIsString: true,
      })
  );
  return (element, modifier) =>
    BEMClasses.map(BEMClass =>
      BEMClass(element, manufactureModifiers(modifier))
    ).join(' ');
};
