import { type ValueOf } from 'type-fest';
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import {
  hasPermission,
  hasSomePermission,
} from '@turbine/helpers/hasPermissionHelper';
import { updateUserRolesSelector as unTypedUpdateUserRolesSelector } from '../userRoles/userRolesSelector';
import createDeepEqualSelector from './createDeepEqualSelector';
import { type RootState } from '../store';
import { type Role } from '@turbine/types/UserManagement';
import { Roles } from '@turbine/constants/roles';

// https://electricops.atlassian.net/browse/IA1-2775
// TODO: remove when refactoring user management slice to TypeScript
const updateUserRolesSelector = unTypedUpdateUserRolesSelector as unknown as (
  state: RootState
) => {
  initialRoles: Record<string, boolean>;
};

const getUserPermissions = (state: RootState) => state.userPermissions;
const getPermissions = (state: RootState) => state.userPermissions.permissions;

export const areUserPermissionsEmpty = createSelector(
  getUserPermissions,
  ({ permissions = {} }) => isEmpty(permissions)
);

export const userPermissionsSelector = createSelector(
  getUserPermissions,
  ({ permissions = {} }) => ({
    userPermissions: Object.keys(permissions).filter(
      permission => !!permissions[permission]
    ),
  })
);

export const permissionsSelector = createDeepEqualSelector(
  getPermissions,
  permissions => permissions
);

export const permissionToCreateOffboardingSelector = createSelector(
  userPermissionsSelector,
  ({ userPermissions }) =>
    hasPermission(userPermissions, ['turbine:offboardings:create'])
);

export const permissionToReadOffboardingsSelector = createSelector(
  userPermissionsSelector,
  ({ userPermissions }) =>
    hasPermission(userPermissions, ['turbine:offboardings:read'])
);

export const permissionToCreateOnboardingSelector = createSelector(
  userPermissionsSelector,
  ({ userPermissions }) =>
    hasPermission(userPermissions, ['turbine:onboardings:create'])
);

export const permissionToReadOnboardingsSelector = createSelector(
  userPermissionsSelector,
  ({ userPermissions }) =>
    hasPermission(userPermissions, ['turbine:onboardings:read'])
);

export const permissionToInviteUserSelector = createSelector(
  userPermissionsSelector,
  ({ userPermissions }) =>
    hasPermission(userPermissions, ['turbine:users:create'])
);

export const permissionToEditUserSelector = createSelector(
  userPermissionsSelector,
  ({ userPermissions }) =>
    hasPermission(userPermissions, ['turbine:users:edit'])
);

export const permissionToEditEmployeeProfile = createSelector(
  userPermissionsSelector,
  ({ userPermissions }) =>
    hasPermission(userPermissions, ['employee_profile:update'])
);

const getRolesOfUserBeingEdited = (_state: RootState, roles: Role[]) => roles;

function canRevokeLowTierUsers(userPermissions: string[]) {
  return hasPermission(userPermissions, ['turbine:users:delete']);
}

// At this moment the permissions ruling this functionality depend on the ability to edit profiles, they may be specific to devices in the future. Keeping it separated for clarity.
export const permissionToChangeDeviceStatus = createSelector(
  userPermissionsSelector,
  ({ userPermissions }) =>
    hasPermission(userPermissions, ['employee_profile:update'])
);

function canRevokeSuperAdmins(userPermissions: string[]) {
  return hasPermission(userPermissions, ['turbine:owners:edit']);
}

function canRevokeAdmins(userPermissions: string[]) {
  return hasPermission(userPermissions, ['turbine:admins:edit']);
}

// Consider refactoring
function getRevokePermission(
  userPermissions: string[],
  rolesOfUserBeingEdited: Role[]
) {
  const hasRole = (roleName: ValueOf<Roles>) =>
    rolesOfUserBeingEdited.find(p => p.name === roleName) ? true : false;

  if (hasRole(Roles.SuperAdmin) && hasRole(Roles.Admin)) {
    return (
      canRevokeSuperAdmins(userPermissions) && canRevokeAdmins(userPermissions)
    );
  } else if (hasRole(Roles.SuperAdmin)) {
    return canRevokeSuperAdmins(userPermissions);
  } else if (hasRole(Roles.Admin)) {
    return canRevokeAdmins(userPermissions);
  } else return canRevokeLowTierUsers(userPermissions);
}

export const permissionToRevokeUserSelector = createSelector(
  [userPermissionsSelector, getRolesOfUserBeingEdited],
  ({ userPermissions }, rolesOfUserBeingEdited) =>
    getRevokePermission(userPermissions, rolesOfUserBeingEdited)
);

export const permissionToUpdateSpecificUserRolesSelector = createSelector(
  [userPermissionsSelector, updateUserRolesSelector],
  (
    { userPermissions: currentlyLoggedInUserPermissions },
    { initialRoles: initialRolesOfOtherUser }
  ) => {
    const getPermissionToModifyRole = (
      roleName: string,
      permissionRequiredToModifyRole: string,
      checkPermissionToUnassign = false
    ) => {
      const hasPermissionToModify = currentlyLoggedInUserPermissions.includes(
        permissionRequiredToModifyRole
      );

      if (checkPermissionToUnassign) {
        if (!initialRolesOfOtherUser[roleName]) {
          // if role is initially unassigned, they can of course "unassign" the user the role they ALREADY don't have
          return true;
        }
        return hasPermissionToModify;
      }
      if (initialRolesOfOtherUser[roleName]) {
        // if role is initially assigned, they can of course "assign" the user the role they ALREADY have
        return true;
      }

      return hasPermissionToModify;
    };

    return {
      canAssignAdminRole: getPermissionToModifyRole(
        Roles.Admin,
        'turbine:admins:create'
      ),
      canUnassignAdminRole: getPermissionToModifyRole(
        Roles.Admin,
        'turbine:admins:edit',
        true
      ),
      canAssignSuperAdminRole: getPermissionToModifyRole(
        Roles.SuperAdmin,
        'turbine:owners:create'
      ),
      canUnassignSuperAdminRole: getPermissionToModifyRole(
        Roles.SuperAdmin,
        'turbine:owners:edit',
        true
      ),
    };
  }
);

export const permissionToViewChatTranscriptSelector = createSelector(
  userPermissionsSelector,
  ({ userPermissions }) =>
    hasSomePermission(userPermissions, [
      'turbine:admins:create',
      'turbine:reports:read',
    ])
);

export const permissionToSendDiskSpaceEmail = createSelector(
  userPermissionsSelector,
  ({ userPermissions }) =>
    hasSomePermission(userPermissions, [
      'turbine:admins:create',
      'turbine:owners:create',
    ])
);
