import { gql } from '@apollo/client';
import { type XboardingStatusValues } from '@turbine/lib/xboarding/constants';
import { ONBOARDING_FRAGMENT } from '../fragments/onboardingFragments';

export const FETCH_ONBOARDING = gql`
  query Onboarding($customerId: String!, $id: String!) {
    onboarding(customer_id: $customerId, id: $id) {
      ...OnboardingFragment
    }
  }
  ${ONBOARDING_FRAGMENT}
`;

export const FETCH_BASIC_ONBOARDINGS = gql`
  query BasicOnboardings(
    $customerId: String!
    $number: Int
    $size: Int
    $status: String
  ) {
    onboardings: basic_onboardings(
      customer_id: $customerId
      number: $number
      size: $size
      status: $status
    ) {
      created_at
      first_name
      id
      last_name
      personal_email
      progress
      start_date
      status
      updated_at
    }
  }
`;

export interface FetchBasicOnboardingsResponse {
  onboardings: FetchBasicOnboardingsData[] | null;
}

export interface FetchBasicOnboardingsData {
  created_at: string | null;
  first_name: string;
  id: string;
  last_name: string;
  personal_email: string;
  progress: number;
  start_date: string;
  status: XboardingStatusValues;
  updated_at: string | null;
}
