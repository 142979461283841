/**
 * Performs a case insentive string comparison
 * eg: compareStrings('Hello', 'hello') => true
 *     compareStrings('', '', false) => true
 */
export function compareStrings(
  value1: unknown,
  value2: unknown,
  excludeEmptyString = true
): boolean {
  if (typeof value1 !== 'string' || typeof value2 !== 'string') {
    return false;
  }

  const trimmedValue1 = value1.trim();
  const trimmedValue2 = value2.trim();

  if (excludeEmptyString && trimmedValue1 === '' && trimmedValue2 === '') {
    return false;
  }

  return (
    trimmedValue1.localeCompare(trimmedValue2, undefined, {
      sensitivity: 'accent',
    }) === 0
  );
}
