import { browserHistory as history } from '@electricjs/utils/helpers';
import { createAction } from '@reduxjs/toolkit';
import { REDIRECTS } from '@turbine/routes';
import { REACT_APP_END_USER_APPLICATION_URL } from '@turbine/config';
import { type AppDispatch } from '../store';

export const resetNewOnboarding = createAction(
  'newOnboarding/resetNewOnboarding'
);

export const navigateToNewOnboarding =
  (plan: string) => (dispatch: AppDispatch) => {
    // Route PREMIUM user to EUH's new onboarding
    if (plan === 'PREMIUM') {
      window.location.href = `${REACT_APP_END_USER_APPLICATION_URL}/newOnboarding`;
      history.push(REDIRECTS.TO.ON_OFF_BOARDINGS.EMPLOYEES.URL);
    } else {
      dispatch(resetNewOnboarding());
      history.push(REDIRECTS.FROM.NEW_ONBOARDING);
    }
  };
