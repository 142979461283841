import * as userSearchTypes from '../types/userSearchTypes';
import * as userRolesTypes from '../userRoles/userRolesTypes';
import * as revokeUserTypes from '../types/revokeUserTypes';
import * as employeeSearchTypes from '../types/employeeSearchTypes';
import { updateUserRolesV2 } from '../userRoles/userRolesUpdateActions';

const initialState = {
  selectedRoleID: '',
  fetching: false,
  failed: false,
  users: [],
  nonTurbineUsers: {
    fetching: false,
    failed: false,
    list: [],
  },
  count: {
    amountOf: {
      All: 0,
      Admin: 0,
      Offboarder: 0,
      Onboarder: 0,
      Owner: 0,
      Reporter: 0,
    },
    fetching: false,
    failed: false,
  },
  emailFilter: {
    filterBy: '',
    filteredUsers: [],
    fetching: false,
    failed: false,
  },
  updateUserRoles: {
    fetching: false,
    failed: '',
  },
  revokeUser: {
    fetching: false,
    failed: '',
  },
  inviteUsers: {
    fetching: false,
    failed: '',
  },
};

export function users(state = initialState, { type, payload = {} }) {
  switch (type) {
    // search users by role
    case userSearchTypes.SEARCH_USERS_BY_ROLE_FETCHED:
      return {
        ...state,
        failed: false,
        fetching: true,
      };
    case userSearchTypes.SEARCH_USERS_BY_ROLE_FETCH_FAILED:
      return {
        ...state,
        failed: true,
        fetching: false,
      };
    case userSearchTypes.SEARCH_USERS_BY_ROLE_FETCH_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        users:
          (!payload.roleID && !state.selectedRoleID) ||
          (payload.roleID && payload.roleID === state.selectedRoleID)
            ? payload.users
            : state.users,
      };
    case userSearchTypes.SELECTED_ROLE_ID_SET:
      return {
        ...state,
        selectedRoleID: payload.roleID,
      };

    // search employees that aren't turbine users
    case employeeSearchTypes.SEARCH_NON_TURBINE_USERS_FETCHED:
      return {
        ...state,
        nonTurbineUsers: {
          ...state.nonTurbineUsers,
          fetching: true,
          failed: false,
        },
      };
    case employeeSearchTypes.SEARCH_NON_TURBINE_USERS_FETCH_FAILED:
      return {
        ...state,
        nonTurbineUsers: {
          ...state.nonTurbineUsers,
          fetching: false,
          failed: true,
        },
      };
    case employeeSearchTypes.SEARCH_NON_TURBINE_USERS_FETCH_SUCCEEDED:
      return {
        ...state,
        nonTurbineUsers: {
          fetching: false,
          failed: false,
          list: payload.users,
        },
      };

    // search users by email
    case userSearchTypes.SEARCH_USERS_BY_EMAIL_FETCHED:
      return {
        ...state,
        emailFilter: {
          ...state.emailFilter,
          failed: false,
          fetching: true,
          filterBy: payload.email,
        },
      };
    case userSearchTypes.SEARCH_USERS_BY_EMAIL_FETCH_FAILED:
      return {
        ...state,
        emailFilter: {
          ...state.emailFilter,
          failed: true,
          fetching: false,
          filterBy: payload.email,
        },
      };
    case userSearchTypes.SEARCH_USERS_BY_EMAIL_FETCH_SUCCEEDED:
      return {
        ...state,
        emailFilter: {
          fetching: false,
          failed: false,
          filteredUsers: payload.users,
          filterBy: payload.email,
        },
      };
    case userSearchTypes.RESET_SEARCH_USERS_BY_EMAIL:
      return {
        ...state,
        emailFilter: {
          ...initialState.emailFilter,
        },
      };

    // update user roles
    case updateUserRolesV2.pending.type:
      return {
        ...state,
        updateUserRoles: {
          fetching: true,
          failed: '',
        },
      };
    case updateUserRolesV2.rejected.type:
      return {
        ...state,
        updateUserRoles: {
          fetching: false,
          failed: "Something went wrong. Your changes weren't saved.",
        },
      };
    case userRolesTypes.USER_ROLES_UPDATE_RESET:
    case updateUserRolesV2.fulfilled.type:
      return {
        ...state,
        updateUserRoles: {
          fetching: false,
          failed: '',
        },
      };

    // type cases that serve to update UI fast & dynamically
    case revokeUserTypes.ONE_USER_REMOVED:
      return {
        ...state,
        users: [
          ...state.users.slice(0, payload.indexOfUserToRemove),
          ...state.users.slice(payload.indexOfUserToRemove + 1),
        ],
      };
    case revokeUserTypes.ONE_FILTERED_USER_REMOVED:
      return {
        ...state,
        emailFilter: {
          ...state.emailFilter,
          filteredUsers: [
            ...state.emailFilter.filteredUsers.slice(
              0,
              payload.indexOfFilteredUserToRemove
            ),
            ...state.emailFilter.filteredUsers.slice(
              payload.indexOfFilteredUserToRemove + 1
            ),
          ],
        },
      };
    case userRolesTypes.ONE_USER_UPDATED:
      return {
        ...state,
        emailFilter: {
          ...state.emailFilter,
          filteredUsers: payload.filteredUsers,
        },
        users: payload.users,
      };
    case revokeUserTypes.USER_REVOKE_FETCHED:
      return {
        ...state,
        revokeUser: {
          fetching: true,
          failed: '',
        },
      };
    case revokeUserTypes.USER_REVOKE_FETCH_FAILED:
      return {
        ...state,
        revokeUser: {
          fetching: false,
          failed: "Something went wrong. Your changes weren't saved.",
        },
      };
    case revokeUserTypes.USER_REVOKE_RESET:
    case revokeUserTypes.USER_REVOKE_FETCH_SUCCEEDED:
      return {
        ...state,
        revokeUser: {
          fetching: false,
          failed: '',
        },
      };
    default:
      return state;
  }
}
