import { connect } from 'react-redux';
import ModalComponent from './ModalComponent';
import { closeModal } from '@turbine/redux/actions/modalActions';

const mapStateToProps = state => ({
  isOpen: state.modal.isOpen,
  modalType: state.modal.modalType,
  modalProps: state.modal.modalProps,
});

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
