import omit from 'lodash/omit';
import { createSlice } from '@reduxjs/toolkit';
import { type RequestState } from '@turbine/types/RequestState';
import { fetchLocations } from './locationsSliceThunks';
import { type Location } from '@turbine/graphql/queries/locations';

export type LocationsData = Location[] | null;

type LocationsState = {
  data: LocationsData;
  locationRequestState: RequestState;
};

const initialState: LocationsState = {
  data: null,
  locationRequestState: 'initial',
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    resetLocationsState: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchLocations.fulfilled, (state, action) => {
      state.locationRequestState = 'fulfilled';
      state.data = action.payload?.map((location: Location) =>
        omit(location, '__typename')
      );
    });
    builder.addCase(fetchLocations.pending, state => {
      state.locationRequestState = 'pending';
    });
    builder.addCase(fetchLocations.rejected, state => {
      state.locationRequestState = 'rejected';
      state.data = [];
    });
  },
});

export const { resetLocationsState } = locationsSlice.actions;
