import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import OffboardEmployeeModalComponent from './OffboardEmployeeModalComponent';

const mapStateToProps = state => {
  const { firstName, lastName } = formValueSelector('offboardEmployeeForm')(
    state,
    'employeeInfo'
  );

  return {
    employeeName: `${firstName} ${lastName}`,
    processing:
      state.offboarding.newOffboardingRequest.fetching ||
      state.offboarding.editOffboarding.fetching,
  };
};

export default connect(mapStateToProps)(OffboardEmployeeModalComponent);
