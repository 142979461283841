import { gql } from '@apollo/client';

export const FETCH_ENROLLMENT_COMPLETED = gql`
  query Enrollments($customerId: String!) {
    customers: customer_v2(customer_id: $customerId) {
      id
      name
      activated_at
      plan
    }
  }
`;

export const FETCH_STEP = gql`
  query Enrollments($customerId: String!) {
    enrollments(customer_id: $customerId) {
      customer_id
      onboarding_step
    }
  }
`;

export const FETCH_POC_NAME = gql`
  query getPointOfContact($employeeId: String!) {
    employees(id: $employeeId) {
      first_name
    }
  }
`;

export const FETCH_POC_NAME_V2 = gql`
  query getPointOfContact($employeeId: String!) {
    employee: employee_v2(employee_id: $employeeId) {
      first_name
    }
  }
`;

export const UPDATE_ONBOARDING_STEP = gql`
  mutation updateEnrollment($customer_id: String!, $onboarding_step: String!) {
    updateEnrollment(
      customer_id: $customer_id
      onboarding_step: $onboarding_step
    ) {
      enrollment {
        customer_id
        onboarding_step
      }
    }
  }
`;

export const UPDATE_ENROLLMENT_COMPLETED_AT = gql`
  mutation updateEnrollment(
    $customerId: String!
    $onboardingCompletedAt: String!
  ) {
    updateEnrollment(
      customer_id: $customerId
      onboarding_completed_at: $onboardingCompletedAt
    ) {
      enrollment {
        customer_id
        onboarding_completed_at
      }
    }
  }
`;

export const FETCH_APPROVERS_COUNT = gql`
  query Customers($customerId: String!) {
    customers(id: $customerId) {
      approvers_count
    }
  }
`;

export const FETCH_LOCATIONS = gql`
  query CustomerLocations($customerId: String!, $display_on_policies: Boolean) {
    offices_v2(
      customer_id: $customerId
      display_on_policies: $display_on_policies
      exclude_discarded: true
    ) {
      customer_id
      id
      name
      address1: address_1
      address2: address_2
      city
      country
      state
      postcode
      contact_id
      office_phone: phone
      verified
      display_on_policies
    }
  }
`;

export const UPDATE_LOCATION_V2 = gql`
  mutation UpdateOfficeV2(
    $id: String!
    $address1: String
    $address2: String
    $city: String
    $contact_id: String
    $country: String
    $customer_id: String
    $name: String
    $office_phone: String
    $postcode: String
    $state: String
    $verified: Boolean
    $display_on_policies: Boolean
  ) {
    updateOfficeV2(
      office_id: $id
      address_1: $address1
      address_2: $address2
      city: $city
      contact_id: $contact_id
      country: $country
      customer_id: $customer_id
      name: $name
      phone: $office_phone
      postcode: $postcode
      state: $state
      verified: $verified
      display_on_policies: $display_on_policies
    ) {
      result
    }
  }
`;

export const CREATE_LOCATION_V2 = gql`
  mutation CreateOfficeV2(
    $address1: String!
    $address2: String
    $city: String!
    $contact_id: String
    $country: String!
    $customer_id: String!
    $name: String!
    $office_phone: String
    $postcode: String!
    $state: String!
    $verified: Boolean
    $display_on_policies: Boolean
  ) {
    createOfficeV2(
      address_1: $address1
      address_2: $address2
      city: $city
      contact_id: $contact_id
      country: $country
      customer_id: $customer_id
      name: $name
      phone: $office_phone
      postcode: $postcode
      state: $state
      verified: $verified
      display_on_policies: $display_on_policies
    ) {
      office_id
    }
  }
`;

export const REMOVE_LOCATION_V2 = gql`
  mutation RemoveOfficeV2($office_id: String!) {
    discardOfficeV2(office_id: $office_id) {
      office_id
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateOffice(
    $id: String!
    $address1: String
    $address2: String
    $city: String
    $contact_email: String
    $contact_first_name: String
    $contact_last_name: String
    $contact_phone: String
    $country: String
    $customer_id: String
    $name: String
    $office_phone: String
    $postcode: String
    $state: String
    $verified: Boolean
    $display_on_policies: Boolean
  ) {
    updateOffice(
      id: $id
      address1: $address1
      address2: $address2
      city: $city
      contact_email: $contact_email
      contact_first_name: $contact_first_name
      contact_last_name: $contact_last_name
      contact_phone: $contact_phone
      country: $country
      customer_id: $customer_id
      name: $name
      office_phone: $office_phone
      postcode: $postcode
      state: $state
      verified: $verified
      display_on_policies: $display_on_policies
    ) {
      office {
        id
        address1
        address2
        city
        contact_email
        contact_first_name
        contact_last_name
        contact_phone
        country
        customer_id
        name
        office_phone
        postcode
        state
        verified
        display_on_policies
      }
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation CreateOffice(
    $address1: String
    $address2: String
    $city: String
    $contact_email: String
    $contact_first_name: String
    $contact_last_name: String
    $contact_phone: String
    $country: String
    $customer_id: String
    $name: String
    $office_phone: String
    $postcode: String
    $state: String
    $verified: Boolean
    $display_on_policies: Boolean
  ) {
    createOffice(
      address1: $address1
      address2: $address2
      city: $city
      contact_email: $contact_email
      contact_first_name: $contact_first_name
      contact_last_name: $contact_last_name
      contact_phone: $contact_phone
      country: $country
      customer_id: $customer_id
      name: $name
      office_phone: $office_phone
      postcode: $postcode
      state: $state
      verified: $verified
      display_on_policies: $display_on_policies
    ) {
      office {
        id
        address1
        address2
        city
        contact_email
        contact_first_name
        contact_last_name
        contact_phone
        country
        customer_id
        name
        office_phone
        postcode
        state
        verified
        display_on_policies
      }
    }
  }
`;

export const REMOVE_LOCATION = gql`
  mutation RemoveOffice($office_id: String!) {
    removeOffice(office_id: $office_id) {
      response
    }
  }
`;
