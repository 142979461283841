export const REMOTE_ADDRESS_OPTION = {
  label: 'Remote Location',
  testId: 'remote-location',
  value: 'remoteEmployeeLocation',
  radioValue: JSON.stringify({
    label: 'Remote Location',
    value: 'remoteEmployeeLocation',
  }),
  name: 'Remote Location',
};
