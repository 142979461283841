import { USER_PERMISSIONS_SET } from '../types/userPermissionsTypes';

const initialState = {
  permissions: {},
};

export function userPermissions(state = initialState, { type, permissions }) {
  switch (type) {
    case USER_PERMISSIONS_SET:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          ...permissions,
        },
      };
    default:
      return state;
  }
}
