import { gql } from '@apollo/client';

export const FETCH_APPLICATION_INTEGRATION = gql`
  query Integration($customerId: String!, $appId: String!) {
    automation_settings(customer_id: $customerId, application_id: $appId) {
      integrated
    }
  }
`;

export interface FetchApplicationIntegration {
  automation_settings: {
    integrated: boolean;
  };
}
