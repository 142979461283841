import { format } from 'date-fns';
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

import { type OffboardingDataProps } from '@turbine/graphql/types/boardingTypes';
import {
  type EmployeeInformation,
  type TaskList,
  type FormState,
} from '@turbine/redux/newOffboarding';
import { OffboardingSchedulingType } from '@turbine/lib/xboarding/constants';
import { timezoneOptions } from '@turbine/constants/timezones';
import {
  getDeprovisionDeviceLocation,
  getDeprovisionDeviceSelected,
  getDeprovisionDeviceShippingFromAddress,
  getDeprovisionDeviceShippingToAddress,
  getDeprovisionDeviceType,
  getDeprovisionReturnDestination,
  getDeprovisionReturnShipping,
  getDeviceField,
  getDevicesInformation,
} from './getDevicesInformation';
import { DELAY_DELETION_OPTIONS } from '../../pages/NewOffboarding/components/TaskEditModals/EditRemovalTime';
import { asSelectOptions } from '@turbine/lib/xboarding/helpers';

export function parseFormStateOffBoardingData(
  _data: OffboardingDataProps
): FormState {
  return {
    isDuplicating: false,
    isEditing: true,
    isLoading: false,
    currentStep: 1,
    hasValidationErrors: false,
  };
}

export function parseEmployeeInfoOffBoardingData(
  data: OffboardingDataProps
): EmployeeInformation {
  const {
    employeeInfo: {
      lastName,
      firstName,
      department,
      managerEmail,
      professionalEmail,
    },
    scheduledAt,
    scheduleTimeZone,
    notifications,
  } = data;

  /**
   * @Note: since `getTimezoneOffset` works both
   * for timezone string and offset values,
   * it's not necessary to check if `tz`
   * is legacy data (offset) or the new converted values (timezone name)
   * */
  const timeZone =
    timezoneOptions.find(
      zone =>
        getTimezoneOffset(zone.value) === getTimezoneOffset(scheduleTimeZone)
    ) || timezoneOptions[0];

  const scheduledAtDateZoned = utcToZonedTime(scheduledAt, timeZone.value);

  const timeOption = asSelectOptions(
    format(scheduledAtDateZoned, 'hh:mm a'),
    scheduledAtDateZoned.toISOString()
  );

  return {
    firstName,
    lastName,
    scheduledAt,
    department: asSelectOptions(department) || undefined,
    employeeEmail: professionalEmail,
    managerEmail,
    alertBeforeOffboarding: {
      selected: !!notifications?.alertBeforeOffboarding.length,
      email: notifications?.alertBeforeOffboarding[0],
    },
    notifyWhenOffboardingComplete: {
      selected: !!notifications?.notifyWhenOffboardingComplete.length,
      email: notifications?.notifyWhenOffboardingComplete[0],
    },
    scheduling: {
      type: scheduledAt
        ? OffboardingSchedulingType.Scheduled
        : OffboardingSchedulingType.ASAP,
      date: scheduledAt ? scheduledAtDateZoned.toISOString() : undefined,
      time: timeOption || undefined,
      timeZone,
    },
    deprovisionDevice: {
      selected: getDeprovisionDeviceSelected(data),
      name: getDeviceField(data, 'name', ''),
      serialNumber: getDeviceField(data, 'serial', ''),
      devices: getDevicesInformation(data),
    },
  };
}

export function parseTaskListOffBoardingData(
  data: OffboardingDataProps
): TaskList {
  const { accountInfo, notes, softwares } = data;

  return {
    deprovisionDevice: {
      selected: getDeprovisionDeviceSelected(data),
      name: getDeviceField(data, 'name', ''),
      serialNumber: getDeviceField(data, 'serial', ''),
      type: getDeprovisionDeviceType(data),
      location: getDeprovisionDeviceLocation(data),
      notes: getDeviceField(data, 'deprovisioningAdditionalNotes'),
      returnDestination: getDeprovisionReturnDestination(data),
      returnShipping: getDeprovisionReturnShipping(data),
      shippingFrom: getDeprovisionDeviceShippingFromAddress(data),
      shippingTo: getDeprovisionDeviceShippingToAddress(data),
    },
    removeFromEmail: {
      setupOOOMessage: {
        isChecked: !!accountInfo.OOOMessage,
        OOOMessage: accountInfo.OOOMessage,
        notes: notes.oooMessageNotes,
      },
      shareCalendar: {
        isChecked: !!accountInfo.shareCalendarWith,
        shareCalendarWith: accountInfo.shareCalendarWith,
        notes: notes.shareCalendarNotes,
      },
      transferDrive: {
        isChecked: !!accountInfo.transferGoogleDriveTo,
        transferDriveContentsTo: accountInfo.transferGoogleDriveTo,
        notes: notes.transferGoogleDriveToNotes,
      },
      forwardEmail: {
        isChecked: !!accountInfo.forwardEmailsTo,
        forwardTo: accountInfo.forwardEmailsTo,
        notes: notes.forwardEmailsToNotes,
      },
      archiveEmail: {
        isChecked: false,
        notes: notes.archiveEmailNotes,
      },
      accountRemovalType: accountInfo.accountRemovalType,
    },
    saasApps: {
      status: 'fulfilled',
      softwares: softwares
        .map(s => ({
          notes: s.additionalNotes,
          id: s.id,
          deleteAccount: s.delete,
          selectedTransfer: !!s.transferTo && s.transferTo.length > 0,
          transferTo:
            !!s.transferTo && s.transferTo.length > 0
              ? s.transferTo[0]
              : undefined,
          selectedDeletionDelay: s.accountDeletionDelay > 0,
          accountDeletionDelay: DELAY_DELETION_OPTIONS.find(
            o => o.value === s.accountDeletionDelay
          ),
        }))
        .reduce((softwares: any, current) => {
          softwares[current.id] = current;
          return softwares;
        }, {}),
    },
  };
}
