import { format } from './format';

export const i18nConfig = {
  lng: 'en-US',
  fallbackLng: 'en-US',
  whitelist: ['en-US'],
  supportedLngs: ['en-US'],
  ns: [
    'applications',
    'chatTranscripts',
    'deviceManagement',
    'enrollment',
    'inviteUserSidePane',
    'itScorecard',
    'locations',
    'registration',
    'sideNav',
    'topNav',
    'userManagement',
    'validation',
    'common',
    'people',
    'xboarding',
    'newOnboarding',
    'newOffboarding',
    'oauth',
  ],
  load: 'currentOnly',
  debug: false,
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  interpolation: {
    format,
  },
};
