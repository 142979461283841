import { format as dateFnsFormat, parseISO, isValid } from 'date-fns';
import { enUS } from 'date-fns/locale';
import numbro from 'numbro';

// Function to parse date values
const formatDate = (date, inputFormat) => {
  // Currently only accepting ISO-type values
  const parsedDate = parseISO(date);
  if (isValid(parsedDate)) {
    return dateFnsFormat(parsedDate, inputFormat, {
      locale: enUS,
    });
  }

  // eslint-disable-next-line no-console
  console.warn(
    `The value ${date} is not a valid date and cannot be formatted. You must provide an ISO string`
  );
  return date;
};

// Function to parse numerical values
const formatNumber = (number, options) => {
  if (number === null) return number;
  if (!isNaN(number)) {
    return numbro(number).format({
      thousandSeparated: true,
      ...options,
    });
  }

  // eslint-disable-next-line no-console
  console.warn(
    `The value ${number} is not a valid number and cannot be formatted. You must provide a Number or String that can be parsed as a number`
  );
  return number;
};

// Add date formatting options to this object
const dateFormatOptions = {
  dateLong: inputValue => formatDate(inputValue, 'do MMMM yyyy'), // Example formatting option
  dateShort: inputValue => formatDate(inputValue, 'MM-dd-yyyy'), // Example formatting optio
  dateShortest: inputValue => formatDate(inputValue, 'MM/dd/yy'), // Example formatting optionn
};

// Add number formatting options to this object
const numberFormatOptions = {
  number: inputValue => formatNumber(inputValue),
  fileSize: inputValue =>
    formatNumber(inputValue, {
      output: 'byte',
      base: 'binary',
    }),
  percent: inputValue =>
    formatNumber(inputValue / 100, {
      output: 'percent',
    }),
};

// All the different format option types are merged inside this function that is then passed to the exported 'format' function
const formatMap = (inputValue, format) => {
  const formats = { ...dateFormatOptions, ...numberFormatOptions };
  const formatter = formats[format];

  if (formatter) {
    return formatter(inputValue);
  }

  // eslint-disable-next-line no-console
  console.warn(`Format option ${format} does not exist`);
  return inputValue;
};

export const format = (value, format) => {
  if (format) {
    return formatMap(value, format);
  }

  return value;
};
