// Get roles
export const USER_ROLES_FETCHED = 'USER_ROLES_FETCHED';
export const USER_ROLES_FETCH_FAILED = 'USER_ROLES_FETCH_FAILED';
export const USER_ROLES_FETCH_SUCCEEDED = 'USER_ROLES_FETCH_SUCCEEDED';

// Update User (roles)
export const USER_ROLES_UPDATE_FETCHED = 'USER_ROLES_UPDATE_FETCHED';
export const USER_ROLES_UPDATE_FETCH_FAILED = 'USER_ROLES_UPDATE_FETCH_FAILED';
export const USER_ROLES_UPDATE_FETCH_SUCCEEDED =
  'USER_ROLES_UPDATE_FETCH_SUCCEEDED';
export const USER_ROLES_UPDATE_RESET = 'USER_ROLES_UPDATE_RESET';
export const ONE_USER_UPDATED = 'ONE_USER_UPDATED';
