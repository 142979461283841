import type * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import electricLogo from '@turbine/assets/images/electric_logo_red_nav.png';

const StyledNavLogo = styled(Link)`
  .nav-logo-turbine__img {
    height: 3rem;
    vertical-align: middle;
  }
`;

type NavLogoProps = {
  logoLink: string;
};

const NavLogo = ({ logoLink }: NavLogoProps): React.ReactElement => (
  <StyledNavLogo className="nav-logo-turbine" to={logoLink}>
    <img
      data-testid="nav-logo"
      className="nav-logo-turbine__img"
      src={electricLogo}
      alt="Electric"
      width="102"
      height="30"
    />
  </StyledNavLogo>
);

NavLogo.defaultProps = {
  logoLink: '/',
};

NavLogo.propTypes = {
  logoLink: PropTypes.string,
};

export default NavLogo;
