import { Banner } from '@electricjs/arc';
import { Component, type CSSProperties } from 'react';

const ErrorTypes = {
  UNKNOWN: 'unknown',
  NEW_VERSION: 'newVersion',
};

export default class RouteErrorBoundary extends Component<
  {},
  { hasError: boolean; errorType?: keyof typeof ErrorTypes }
> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    if (
      error?.message.includes('Failed to fetch dynamically imported module:') ||
      error?.message.includes('Importing a module script failed')
    ) {
      return { hasError: true, errorType: ErrorTypes.NEW_VERSION };
    }
    return { hasError: true, errorType: ErrorTypes.UNKNOWN };
  }

  render() {
    if (this.state.hasError) {
      const errorContainerStyles: CSSProperties = {
        textAlign: 'center',
        maxWidth: '600px',
        margin: '3rem auto',
      };
      // Error handling specific to when a new version is deployed mid-session for a user
      if (this.state.errorType === ErrorTypes.NEW_VERSION) {
        window?.DD_RUM?.addAction('stale_resource_reload', {});
        window?.location.reload();

        // While browser is reloading the page, render this temporary warning
        return (
          <div style={errorContainerStyles}>
            <Banner
              isFlat
              intent="warning"
              message="A new version of Turbine was detected, reloading the page..."
            />
          </div>
        );
      }

      // Generic error page for all other uncaught errors
      return (
        <div style={errorContainerStyles}>
          <Banner
            isFlat
            intent="error"
            message="Something went wrong, please refresh the page."
            cta="Refresh"
            onClickCta={() => window?.location.reload()}
          />
        </div>
      );
    }

    return this.props.children;
  }
}
