import {
  SET_CUSTOMER_ACTIVATION,
  SET_CUSTOMER_IS_ACTIVE,
  SET_CUSTOMER_ACTIVATED_AT,
} from '../types/customerActivationTypes';

const initialState = {
  isActive: null,
  activatedAt: null,
};
export function customerActivation(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_ACTIVATION: {
      const { isActive, activatedAt } = action.payload;
      return {
        isActive,
        activatedAt,
      };
    }
    case SET_CUSTOMER_IS_ACTIVE: {
      const isActive = action.payload;
      return {
        ...state,
        isActive,
      };
    }
    case SET_CUSTOMER_ACTIVATED_AT: {
      const activatedAt = action.payload;
      return {
        ...state,
        activatedAt,
      };
    }
    default:
      return state;
  }
}
