import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  SUBSCRIBE_EMPLOYEE,
  EXCLUDE_EMPLOYEE,
  EMPLOYEES_FROM_API_ORGS,
  FETCH_EMPLOYEE_BY_ID,
  SET_EMPLOYEE_AS_APPROVER_2,
} from '@turbine/graphql/queries/employeeQueries';
import { UPDATE_CUSTOMER_SOFTWARE_APPLICATION } from '@turbine/graphql/mutations';
import {
  BULK_UPDATE_EMPLOYEE_V2,
  type UpdateEmployeeV2MutationParams,
  type UpdateEmployeeV2Response,
  UPDATE_EMPLOYEE_V2,
  BULK_CREATE_EMPLOYEE_V2,
  DISCARD_EMPLOYEE_V2,
  UPDATE_EMPLOYEE_PERSONAL_DEVICE,
  type DiscardEmployeeV2Response,
} from '@turbine/graphql/mutations/employeeMutations';
import { type AppDispatch, type AppThunkConfig } from '@turbine/redux/store';
import { resetPersonalDeviceToast, type Employee } from './employeesSlice';
import { type DocumentNode } from 'graphql';
import {
  type ApolloClient,
  type NormalizedCacheObject,
  type QueryOptions,
} from '@apollo/client';
import { fetchAllCustomerSoftwares } from '../customerSoftwares';
import { updateUserRolesV2API } from '../userRoles/userRolesUpdateActions';
import { Roles } from '@turbine/constants/roles';
import { findRoleIDSelector as unTypedFindRoleIDSelector } from '../userRoles/userRolesSelector';
import {
  addLocalPersonalDevice,
  removeLocalPersonalDevice,
} from './employeesSlice';

const findRoleIDSelector = unTypedFindRoleIDSelector as Function;

export type FetchEmployeesByCustomerIDParams = {
  customerID: string;
};

export enum FetchEmployeesByCustomerErrors {
  LaunchDarkly,
  Query,
}

type EmployeesQueryVariables = {
  customerId: string;
  includeDiscarded?: boolean;
  v1?: boolean;
  id?: string;
};

export const fetchEmployeesByCustomerID = createAsyncThunk<
  Employee[] | null,
  FetchEmployeesByCustomerIDParams,
  AppThunkConfig
>(
  'employees/fetchEmployeesByCustomerID',
  async ({ customerID }, thunkConfig) => {
    const { extra, rejectWithValue } = thunkConfig;
    const { client } = extra;

    const employeesQueryVariables: EmployeesQueryVariables = {
      customerId: customerID,
      includeDiscarded: false,
    };
    const employeesQuery = {
      query: EMPLOYEES_FROM_API_ORGS,
      variables: employeesQueryVariables,
      fetchPolicy: 'network-only',
    };
    try {
      const result = await client().query(employeesQuery as QueryOptions);
      return result.data?.employees.map((employee: Employee) => ({
        ...employee,
        name: `${employee?.first_name} ${employee?.last_name}`.trim(),
        subscribed: employee?.subscribed || !!employee.subscribed_at,
        excluded: employee?.excluded || !!employee.excluded_at,
      }));
    } catch (error) {
      window?.DD_RUM?.addError(error);
      return rejectWithValue(FetchEmployeesByCustomerErrors.Query);
    }
  }
);

export type SubscribeEmployeeWithIDParams = {
  employeeID: string;
  subscribed: boolean;
  customerID: string;
  refetchOnSuccess?: boolean;
};

export const subscribeEmployeeWithID = createAsyncThunk<
  Employee[] | null,
  SubscribeEmployeeWithIDParams,
  AppThunkConfig
>(
  'employees/subscribeEmployeeWithID',
  async (
    { employeeID, subscribed, customerID, refetchOnSuccess = false },
    thunkConfig
  ) => {
    const { extra, dispatch } = thunkConfig;
    const { client } = extra;

    const setSubscribedAtVariable = () => {
      return subscribed
        ? { subscribed_at: new Date() }
        : { setNullFields: ['subscribed_at'] };
    };
    const employeesMutation: {
      mutation: DocumentNode;
      variables: object;
    } = {
      mutation: SUBSCRIBE_EMPLOYEE,
      variables: {
        employeeId: employeeID,
        ...setSubscribedAtVariable(),
      },
    };
    try {
      const result = await client().mutate(employeesMutation);
      refetchOnSuccess && dispatch(fetchEmployeesByCustomerID({ customerID }));
      return result.data?.updateEmployee?.employee;
    } catch (error) {
      window?.DD_RUM?.addError(error);
    }
  }
);

export type ExcludeEmployeeWithIDParams = {
  employeeID: string;
  excluded: boolean;
  customerID: string;
  refetchOnSuccess?: boolean;
};

export const excludeEmployeeWithID = createAsyncThunk<
  Employee[] | null,
  ExcludeEmployeeWithIDParams,
  AppThunkConfig
>(
  'employees/excludeEmployeeWithID',
  async (
    { employeeID, excluded, customerID, refetchOnSuccess = false },
    thunkConfig
  ) => {
    const { extra, dispatch } = thunkConfig;
    const { client } = extra;

    const setExcludedAtVariable = () => {
      return excluded
        ? { excluded_at: new Date() }
        : { setNullFields: ['excluded_at'] };
    };
    const employeesMutation: {
      mutation: DocumentNode;
      variables: object;
    } = {
      mutation: EXCLUDE_EMPLOYEE,
      variables: {
        employeeId: employeeID,
        ...setExcludedAtVariable(),
      },
    };
    try {
      const result = await client().mutate(employeesMutation);
      refetchOnSuccess && dispatch(fetchEmployeesByCustomerID({ customerID }));
      return result.data?.updateEmployee?.employee;
    } catch (error) {
      window?.DD_RUM?.addError(error);
    }
  }
);

export type BulkUpdateEmployeeV2Params = {
  customerID: string;
  employees:
    | {
        employee_id: string;
        subscribed_at?: string;
        set_null_fields?: string[];
      }[]
    | undefined;
  refetchOnSuccess?: boolean;
};

export const bulkUpdateEmployeeV2 = createAsyncThunk<
  Employee[] | null,
  BulkUpdateEmployeeV2Params,
  AppThunkConfig
>(
  'employees/bulkUpdateEmployeeV2',
  async ({ customerID, employees, refetchOnSuccess = false }, thunkConfig) => {
    const { extra, dispatch } = thunkConfig;
    const { client } = extra;
    const employeesMutation = {
      mutation: BULK_UPDATE_EMPLOYEE_V2,
      variables: {
        employeeUpdates: employees,
      },
    };
    try {
      const result = await client().mutate(employeesMutation);
      refetchOnSuccess && dispatch(fetchEmployeesByCustomerID({ customerID }));
      return result.data?.updateEmployee?.employee;
    } catch (error) {
      window?.DD_RUM?.addError(error);
    }
  }
);

export type BulkCreateEmployeeV2Params = {
  customerID: string;
  employees:
    | {
        first_name: string;
        last_name: string;
        email: string;
        subscribed_at: string;
      }[]
    | undefined;
  refetchOnSuccess?: boolean;
};

export const bulkCreateEmployeeV2 = createAsyncThunk<
  Employee[] | null,
  BulkCreateEmployeeV2Params,
  AppThunkConfig
>(
  'employees/bulkCreateEmployeeV2',
  async ({ customerID, employees, refetchOnSuccess = false }, thunkConfig) => {
    const { extra, dispatch } = thunkConfig;
    const { client } = extra;
    const employeesMutation = {
      mutation: BULK_CREATE_EMPLOYEE_V2,
      variables: {
        customer_id: customerID,
        employees: employees,
      },
    };
    try {
      const result: any = await client().mutate(employeesMutation); // eslint-disable-line
      refetchOnSuccess && dispatch(fetchEmployeesByCustomerID({ customerID }));
      return result.results;
    } catch (error) {
      window?.DD_RUM?.addError(error);
    }
  }
);

type SwitchApproverForEmployeeIDParams = {
  employeeID: string;
  customerID: string;
  approver: boolean;
  refetchOnSuccess?: boolean;
};

export const switchApproverForEmployeeID = createAsyncThunk<
  Employee[] | null,
  SwitchApproverForEmployeeIDParams,
  AppThunkConfig
>(
  'employees/switchApproverForEmployeeID',
  async (
    { employeeID, customerID, refetchOnSuccess, approver },
    thunkConfig
  ) => {
    const { extra, dispatch } = thunkConfig;
    const { client } = extra;

    const setAsApproverMutation = {
      mutation: SET_EMPLOYEE_AS_APPROVER_2,
      variables: {
        employeeId: employeeID,
        approver,
      },
    };

    try {
      const result = await client().mutate(setAsApproverMutation);
      refetchOnSuccess && dispatch(fetchEmployeesByCustomerID({ customerID }));
      return result.data?.updateEmployee?.employee;
    } catch (error) {
      window?.DD_RUM?.addError(error);
    }
  }
);

type FetchPeopleProfileParams = { customerId: string; employeeId: string };

export const fetchEmployeeById = createAsyncThunk<
  Employee,
  FetchPeopleProfileParams,
  AppThunkConfig
>(
  'employees/fetchEmployeeById',
  async ({ customerId, employeeId }, { extra, dispatch }) => {
    const { client } = extra;

    const fetchEmployeeQuery: QueryOptions = {
      query: FETCH_EMPLOYEE_BY_ID,
      variables: {
        customerId,
        employeeId,
      },
      fetchPolicy: 'network-only',
    };
    try {
      const employeeByIdPromise =
        client().query<{ employee: Employee }>(fetchEmployeeQuery);
      const allCustomerSoftwaresPromise = dispatch(
        fetchAllCustomerSoftwares({ customerID: customerId })
      );
      const [employeeResult] = await Promise.all([
        employeeByIdPromise,
        // wait customer software apps, this is require for internal admin apps
        allCustomerSoftwaresPromise,
      ]);

      const employee = employeeResult.data.employee;

      if (employee) {
        return {
          ...employee,
          name: `${employee?.first_name} ${employee?.last_name}`.trim(),
          subscribed: employee?.subscribed || !!employee.subscribed_at,
        };
      }
      return employee;
    } catch (error) {
      window?.DD_RUM?.addError(error);
      throw error;
    }
  }
);

type UpdateEmployeeV2Params = UpdateEmployeeV2ThunkParams & {
  client: () => ApolloClient<NormalizedCacheObject>;
  rejectWithValue: (value: unknown) => unknown;
  dispatch: AppDispatch;
};

export type SoftwareInformation = {
  softwareId: string;
  requesterId: string;
  applicationAdminId: string;
};

export type UpdateEmployeeV2ThunkParams = {
  customerId: string;
  employeeId: string;
  employeeInformation: Partial<UpdateEmployeeV2MutationParams>;
  softwaresInformation?: SoftwareInformation[] | null;
  newSuperAdminId?: string;
};

export const updateEmployeeV2 = async ({
  customerId,
  employeeId,
  employeeInformation,
  softwaresInformation,
  client,
  dispatch,
  rejectWithValue,
}: UpdateEmployeeV2Params) => {
  const employeesMutation = {
    mutation: UPDATE_EMPLOYEE_V2,
    variables: {
      employeeId,
      ...employeeInformation,
    },
  };

  const softwareMutation = ({
    softwareId,
    requesterId,
    applicationAdminId,
  }: SoftwareInformation) => ({
    mutation: UPDATE_CUSTOMER_SOFTWARE_APPLICATION,
    variables: {
      customerId,
      id: softwareId,
      requesterId,
      applicationAdminId,
    },
  });

  try {
    const employeePromise = client().mutate(employeesMutation);
    const softwarePromises = softwaresInformation?.map(software =>
      client().mutate(softwareMutation(software))
    );

    const [employeeResult] = await Promise.all([
      employeePromise,
      ...(softwarePromises || []),
    ]);

    dispatch(fetchEmployeeById({ customerId, employeeId }));

    return employeeResult.data;
  } catch (error) {
    window?.DD_RUM?.addError(error);
    return rejectWithValue('Failed to update employee profile information.');
  }
};

export const updateEmployeeV2Thunk = createAsyncThunk<
  UpdateEmployeeV2Response,
  UpdateEmployeeV2ThunkParams,
  AppThunkConfig
>(
  'employees/updateEmployeeV2',
  async (
    {
      customerId,
      employeeId,
      employeeInformation,
      softwaresInformation,
      newSuperAdminId,
    },
    { extra, dispatch, rejectWithValue, getState }
  ) => {
    const { client } = extra;
    const result = await updateEmployeeV2({
      customerId,
      employeeId,
      employeeInformation,
      softwaresInformation,
      client,
      dispatch,
      rejectWithValue,
    });

    if (newSuperAdminId) {
      const superAdminRoleID = findRoleIDSelector(getState(), {
        specifiedRoleName: Roles.SuperAdmin,
      });
      const superAdminRoles = [{ id: superAdminRoleID, value: true }];

      try {
        await updateUserRolesV2API({
          employeeId: newSuperAdminId,
          roles: superAdminRoles,
          client,
          dispatch,
        });
      } catch (error) {
        window?.DD_RUM?.addError(error);
      }
    }

    // Refetch employees list since employee data has changed
    dispatch(fetchEmployeesByCustomerID({ customerID: customerId }));

    return result;
  }
);

export type DiscardEmployeeV2ThunkParams = {
  customerId: string;
  employeeId: string;
  discardedAt: string;
};

export const discardEmployeeV2Thunk = createAsyncThunk<
  DiscardEmployeeV2Response,
  DiscardEmployeeV2ThunkParams,
  AppThunkConfig
>(
  'employees/discardEmployeeV2',
  async (
    { customerId, employeeId, discardedAt },
    { extra: { client }, dispatch, rejectWithValue }
  ) => {
    const discardEmployeeV2Mutation = {
      mutation: DISCARD_EMPLOYEE_V2,
      variables: {
        employeeId,
        discardedAt,
      },
    };
    try {
      const result = await client().mutate(discardEmployeeV2Mutation);
      dispatch(fetchEmployeesByCustomerID({ customerID: customerId }));
      return result.data;
    } catch (error) {
      window?.DD_RUM?.addError(error);
      return rejectWithValue('Failed to delete user.');
    }
  }
);

enum PersonalDeviceOption {
  Add = 'Add',
  Remove = 'Remove',
}

export type SetPersonalDeviceParams = {
  employeeId: string;
  employeeName: string;
};

const resetToastState = (dispatch: (actionCreator: any) => void) => {
  setTimeout(() => dispatch(resetPersonalDeviceToast()), 2100);
};

export const setPersonalDeviceThunk =
  (action: PersonalDeviceOption) =>
  async ({ employeeId }: SetPersonalDeviceParams, thunkConfig: any) => {
    const { extra, dispatch, rejectWithValue } = thunkConfig;
    const { client } = extra;
    const mutation = UPDATE_EMPLOYEE_PERSONAL_DEVICE;
    const personalDeviceMutation = {
      mutation,
      variables: {
        employeeId,
        has_personal_device: action === PersonalDeviceOption.Add,
      },
    };
    try {
      const result = await client().mutate(personalDeviceMutation);
      const addedPersonalDevice =
        result.data.updateEmployeeV2.result.has_personal_device;
      const localUpdate = addedPersonalDevice
        ? addLocalPersonalDevice
        : removeLocalPersonalDevice;
      dispatch(localUpdate({ employeeId }));
      resetToastState(dispatch);
      return result;
    } catch (error) {
      window?.DD_RUM?.addError(error);
      resetToastState(dispatch);
      return rejectWithValue(personalDeviceMutation);
    }
  };

export const addPersonalDevice = createAsyncThunk(
  'employees/addPersonalDevice',
  setPersonalDeviceThunk(PersonalDeviceOption.Add)
);

export const removePersonalDevice = createAsyncThunk(
  'employees/removePersonalDevice',
  setPersonalDeviceThunk(PersonalDeviceOption.Remove)
);
