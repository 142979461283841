import { isNonReporting } from '@turbine/helpers/itScoreCardV2';
import { type Device } from '@turbine/types/DeviceManagement';

const arrangeAlgorithm =
  (validator: (device: Device) => boolean) => (devices: Array<Device>) => {
    const { reportingDevices, nonReportingDevices } = devices.reduce(
      (devices, currentDevice) => {
        const modifiedSlice = validator(currentDevice)
          ? 'nonReportingDevices'
          : 'reportingDevices';

        return {
          ...devices,
          [modifiedSlice]: [...devices[modifiedSlice], currentDevice],
        };
      },
      { reportingDevices: [], nonReportingDevices: [] }
    );

    return [...reportingDevices, ...nonReportingDevices] as Device[];
  };

export default arrangeAlgorithm(isNonReporting);
