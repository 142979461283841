import { Fragment } from 'react';
import PropTypes from 'prop-types';

export const RevokeUserModalMessage = ({
  sideEffectMessage,
  name,
  bemClasses,
}) => {
  const contextLanguage =
    sideEffectMessage ||
    'Revoking access means this user will no longer be able to sign in to Turbine.';
  return (
    <Fragment>
      {contextLanguage} Are you sure you want to revoke{' '}
      <span className={bemClasses('important')}>{name}?</span>
    </Fragment>
  );
};

RevokeUserModalMessage.defaultProps = {
  name: 'this user',
  sideEffectMessage: '',
};

RevokeUserModalMessage.propTypes = {
  bemClasses: PropTypes.func.isRequired,
  name: PropTypes.string,
  sideEffectMessage: PropTypes.string,
};
