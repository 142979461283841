import { useQuery } from '@apollo/client';
import { useSession } from '@turbine/helpers/hooks';
import {
  FETCH_APPLICATION_INTEGRATION,
  type FetchApplicationIntegration,
} from '@turbine/graphql/queries/integrationQueries';
import { GET_EMAIL_PROVIDER } from '@turbine/graphql/queries/customerQueries';
import { EmailProviderEnum } from '@turbine/components/Applications/AddEmailProvider/AddEmailProviderModal';

function useHasGoogleEmailProvider() {
  const { customerId } = useSession();

  const { data, loading } = useQuery(GET_EMAIL_PROVIDER, {
    variables: { customer_id: customerId },
  });

  const { customer_email_provider: customerEmailProvider } = data ?? {};

  const hasGoogleEmailProvider =
    customerEmailProvider?.name == EmailProviderEnum.google;

  return {
    customerEmailProvider,
    loading,
    hasGoogleEmailProvider,
  };
}

export function useGoogleAutomationStatus() {
  const { customerId } = useSession();

  const {
    hasGoogleEmailProvider,
    loading: loadingEmailProvider,
    customerEmailProvider,
  } = useHasGoogleEmailProvider();

  const { data, loading: loadingIntegration } =
    useQuery<FetchApplicationIntegration>(FETCH_APPLICATION_INTEGRATION, {
      variables: { customerId, appId: customerEmailProvider?.id },
      fetchPolicy: 'no-cache',
      skip: !hasGoogleEmailProvider, // only fetch status if customer has Google as email provider
    });

  const loading = loadingEmailProvider || loadingIntegration;
  const isGoogleAutomationOn =
    hasGoogleEmailProvider && data?.automation_settings?.integrated;

  return { isGoogleAutomationOn, loading };
}
