import { PAGE_UPDATED } from '../types/historyTypes';

const initialState = {
  page: '',
  searchQuery: {},
};

export function history(
  state = initialState,
  { type, page = '', searchQuery = {} }
) {
  switch (type) {
    case PAGE_UPDATED:
      return {
        page,
        searchQuery,
      };

    default:
      return state;
  }
}
