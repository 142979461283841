import { Component } from 'react';
import { Auth0Context } from '@turbine/helpers/hooks';
import { getUserAndCustomerInfo } from '@turbine/services/bugsnag/bugsnag';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static contextType = Auth0Context;

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    const { customerId, user } = this.context;
    const { customer } = getUserAndCustomerInfo(customerId);
    const currentUser = { email: user?.email, auth0Id: user?.sub };

    window.DD_RUM.addError(error, {
      userInfo: {
        user: currentUser,
        customer,
      },
    });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
