import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import bolt from '@turbine/assets/images/bolt.svg';
import electricIcon from '@turbine/assets/images/bolt_red.svg';
import search from '@turbine/assets/images/search.svg';
import pending from '@turbine/assets/images/pending_clock.svg';
import info from '@turbine/assets/images/info_outlined_circle.svg';
import plusIcon from '@turbine/assets/images/plus.svg';
import minusIcon from '@turbine/assets/images/minus.svg';
import remove from '@turbine/assets/images/remove.svg';
import duplicate from '@turbine/assets/images/duplicate.svg';
import { forwardRef } from 'react';

const StyledSVG = styled(SVG)`
  display: inline-flex;
  align-items: center;
  svg {
    width: ${ifProp('width', prop('width'), '0.9rem')};
    height: ${ifProp('height', prop('height'), '1.7rem')};
  }
`;

const iconCreator = ({ src, className, width, height, innerRef }) => (
  <StyledSVG
    src={src}
    className={className}
    width={width}
    height={height}
    innerRef={innerRef}
  />
);

export const BoltIcon = forwardRef(
  ({ className = 'bolt-icon', width = '0.9rem', height = '1.7rem' }, ref) =>
    iconCreator({ src: bolt, className, width, height, innerRef: ref })
);

export const ElectricIcon = ({ className, width, height }) =>
  iconCreator({ src: electricIcon, className, width, height });

export const SearchIcon = ({ className, width, height }) =>
  iconCreator({ src: search, className, width, height });

export const PendingIcon = ({
  className = '',
  width = '1.2rem',
  height = '1.2rem',
}) => iconCreator({ src: pending, className, width, height });

export const InfoIcon = ({
  className = '',
  width = '1.2rem',
  height = '1.2rem',
}) => iconCreator({ src: info, className, width, height });

export const PlusIcon = ({ className, width = '1.2rem', height = '1.2rem' }) =>
  iconCreator({ src: plusIcon, className, width, height });

export const MinusIcon = ({ className, width = '1.2rem', height = '1.2rem' }) =>
  iconCreator({ src: minusIcon, className, width, height });

export const RemoveIcon = ({
  className,
  width = '1.2rem',
  height = '1.2rem',
}) => iconCreator({ src: remove, className, width, height });

export const DuplicateIcon = ({
  className,
  width = '1.6rem',
  height = '1.4rem',
}) => iconCreator({ src: duplicate, className, width, height });
