import { type Action, type Middleware } from 'redux';
import { type RootState } from '../store';
import updateUrlMiddleware from './updateUrlMiddleware';

type UnTypedMiddleware = Middleware<
  (action: Action<unknown>) => unknown,
  RootState
>;

export const middleware =
  // // Custom middleware that allows actions to indicate a change to be made to the URL following the action being processed
  updateUrlMiddleware as UnTypedMiddleware;
