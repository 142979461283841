import type * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useSession } from '@turbine/helpers/hooks';
import { getNavItemHasNotification } from '@turbine/redux/selectors/notifications/navItemNotificationsSelectors';
import NavItemWithNotification from './NavItemWithNotification';
import SideNavPlaceholder from './SideNavPlaceholder';
import { type NavItemProps } from './types';
import { type IconNames } from '@electricjs/arc';

type SideNavItemType = {
  [key: string]: {
    text: string;
    defaultValue: string;
    path: string;
    icon?: IconNames;
    permissionsNeeded?: string[];
    addBottomMargin: boolean;
  };
};

const SideNavConfig: SideNavItemType = {
  ITScoreCard: {
    text: 'itScorecard',
    defaultValue: 'IT Scorecard',
    path: '/it-score-card',
    icon: 'gauge',
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  ITScoreDashboard: {
    text: 'itScoreDashboard',
    defaultValue: 'IT Score Dashboard',
    path: '/it-score-dashboard',
    icon: 'gauge',
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  ShopHardware: {
    text: 'shopHardware',
    defaultValue: 'Shop Hardware',
    path: '/shop-hardware',
    icon: 'bag',
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  Requests: {
    text: 'requestsData',
    defaultValue: 'Requests Data',
    path: '/dashboard',
    icon: 'chart-bar',
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  RequestsV2: {
    // This will be used when TURBINE_FRONTEND_HARDWARE_DATA feature flag is on
    text: 'requestsData',
    defaultValue: 'Requests Data',
    path: '/dashboard',
    icon: undefined,
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  XBoarding: {
    text: 'xboarding',
    defaultValue: 'On/Offboarding',
    path: '/xboarding',
    icon: undefined,
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  Hardware: {
    text: 'hardwareRequests',
    defaultValue: 'Hardware Requests',
    path: '/hardware',
    icon: undefined,
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  Malwarebytes: {
    text: 'malwarebytes',
    defaultValue: 'Malwarebytes',
    path: '/malwarebytes',
    icon: undefined,
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  AnnualSummary: {
    text: 'annualSummary',
    defaultValue: 'Annual Summary',
    path: '/summary',
    icon: undefined,
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  SupportedEmployees: {
    text: 'supportedEmployees',
    defaultValue: 'Supported Employees',
    path: '/supported',
    icon: undefined,
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  Approvals: {
    text: 'approvals',
    defaultValue: 'Approvals',
    path: '/approvals',
    icon: 'list-check',
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  Applications: {
    text: 'applications',
    defaultValue: 'Applications',
    path: '/applications',
    icon: 'app',
    permissionsNeeded: undefined,
    addBottomMargin: true,
  },
  People: {
    text: 'people',
    defaultValue: 'People',
    path: '/onoffboardings',
    icon: 'user-group',
    permissionsNeeded: [
      'turbine:offboardings:read',
      'turbine:onboardings:read',
    ],
    addBottomMargin: true,
  },
  Marketplace: {
    text: 'Marketplace',
    defaultValue: 'Marketplace',
    path: '/marketplace',
    icon: 'shop',
    permissionsNeeded: ['turbine:reports:read'],
    addBottomMargin: true,
  },
  DeviceManagement: {
    text: 'deviceManagement',
    defaultValue: 'Device Management',
    path: '/device-mgmt',
    icon: 'laptop',
    permissionsNeeded: ['turbine:reports:read'],
    addBottomMargin: true,
  },
  Configuration: {
    text: 'configuration',
    defaultValue: 'Configuration',
    path: '/configuration',
    icon: undefined,
    permissionsNeeded: undefined,
    addBottomMargin: false,
  },
  RoleSettings: {
    text: 'roleSettings',
    defaultValue: 'Role Settings',
    path: '/user-management',
    icon: undefined,
    permissionsNeeded: undefined,
    addBottomMargin: false,
  },
  Locations: {
    text: 'locations',
    defaultValue: 'Locations',
    path: '/locations',
    icon: undefined,
    permissionsNeeded: undefined,
    addBottomMargin: false,
  },
};

type SideNavItemProps = {
  itemType: string;
  isLoading: boolean;
  isInsideDropdown?: boolean;
};

const NavItemWithCustomerQueryString = ({
  text,
  path,
  icon,
  permissionsNeeded,
  addBottomMargin,
  isInsideDropdown = false,
}: NavItemProps) => {
  const { customerId, isAdmin } = useSession();
  const hasNotification = useSelector(getNavItemHasNotification(path));
  const fullPath = isAdmin ? `${path}#customerId=${customerId}` : path;
  return (
    <NavItemWithNotification
      shouldShowNotification={hasNotification}
      text={text}
      path={fullPath}
      icon={icon}
      permissionsNeeded={permissionsNeeded}
      addBottomMargin={addBottomMargin}
      isInsideDropdown={isInsideDropdown}
    />
  );
};

const SideNavItem = ({
  itemType,
  isLoading,
  isInsideDropdown = false,
}: SideNavItemProps): React.ReactElement => {
  const { text, defaultValue, path, icon, permissionsNeeded, addBottomMargin } =
    SideNavConfig[itemType];
  const { t } = useTranslation('sideNav');
  return (
    <ReactPlaceholder
      className={isLoading ? 'cell--loading' : ''}
      ready={!isLoading}
      showLoadingAnimation={true}
      customPlaceholder={<SideNavPlaceholder />}>
      <NavItemWithCustomerQueryString
        text={t(`${text}`, { defaultValue: `${defaultValue}` })}
        path={path}
        permissionsNeeded={permissionsNeeded}
        addBottomMargin={addBottomMargin}
        isInsideDropdown={isInsideDropdown}
        {...(!!icon && { icon })}
      />
    </ReactPlaceholder>
  );
};

export default SideNavItem;
