import queryString from 'query-string';
import { browserHistory as history } from '@electricjs/utils/helpers';
import { getRedirectURLFromConfig, REDIRECTS } from '@turbine/routes';
import { fetchDashboardFromProxyFlare } from '../actions/dashboardActions';
import { fetchPolicy } from '../actions/policyActions';
import customersActionsFromProxyFlare from '../actions/customersActionsFromProxyFlare';
import { fetchUsersByRoleFromProxyFlare } from './userSearchActions';
import { roleIDSelector } from '../userRoles/userRolesSelector';
import {
  IMPERSONATE_CUSTOMER_STARTED,
  IMPERSONATE_CUSTOMER_FINISHED,
} from '../types/impersonateCustomerTypes';
import { resetDevicesState } from '../devices/enrolledDevices';
import { resetEmployeesState } from '../employees';
import { resetStoredDevicesState } from '../devices/storedDevices';
import { resetApplicationsState, getAppAccount } from '../applications';
import { fetchEmployeesByCustomerID } from '../employees';
import { resetITScoreCardState } from '../itScoreCard';

const addCustomerIdToUrlHash = customerId => {
  const parsedHash = queryString.parse(window.location.hash, {
    arrayFormat: 'comma',
  });

  parsedHash.customerId = customerId;

  window.location.hash = queryString.stringify(parsedHash, {
    arrayFormat: 'comma',
  });
};

export const fetchImpersonatedCustomerDataStarted = payload => ({
  type: IMPERSONATE_CUSTOMER_STARTED,
  payload,
});

export const fetchImpersonatedCustomerDataFinished = {
  type: IMPERSONATE_CUSTOMER_FINISHED,
};

export const fetchImpersonatedCustomerDataFromProxyFlare =
  (customerID, accessToken, employeeId) => async (dispatch, getState) => {
    await Promise.all([
      dispatch(fetchImpersonatedCustomerDataStarted({ customerID })),
      dispatch(customersActionsFromProxyFlare.fetch(accessToken)),
      dispatch(fetchPolicy({ customerID })),
      dispatch(
        fetchUsersByRoleFromProxyFlare({
          roleID: roleIDSelector(getState()),
          customerID,
          accessToken,
        })
      ),
      dispatch(
        fetchDashboardFromProxyFlare({ customerID, accessToken, employeeId })
      ),
    ]);
    dispatch(fetchImpersonatedCustomerDataFinished);
    dispatch(fetchEmployeesByCustomerID({ accessToken, customerID }));
  };

const isInPathname = route => history.location?.pathname.includes(route);

export const impersonateCustomer =
  (customerID, isAdmin = false, accessToken, employeeId, setCustomerId) =>
  dispatch => {
    /*
    Policies don't dynamically load/re-render
    so if a user is on the "setup" (onboarding policies)
    redirect them to it-score-card page.
    This is temporary until we refactor company setup/onboarding policies
  */
    /*
    When switching customer from an xBoardings page, the user is redirected to the People table in the default tab.
  */
    const xBoardingsRoutes = [
      REDIRECTS.FROM.OFFBOARDING_STATUS,
      REDIRECTS.FROM.ONBOARDING_STATUS,
      REDIRECTS.FROM.NEW_ONBOARDING,
      REDIRECTS.FROM.NEW_OFFBOARDING,
    ];
    const isAtXBoardings = xBoardingsRoutes.some(route => isInPathname(route));

    if (isInPathname(REDIRECTS.FROM.CONFIGURATION)) {
      history.push(REDIRECTS.TO.IT_SCORECARD);
    } else if (isAtXBoardings) {
      const routeLink = getRedirectURLFromConfig(
        REDIRECTS.TO.ON_OFF_BOARDINGS.ONBOARDINGS
      );
      history.push(routeLink);
    }

    // persisting the customer ID in the url so we can refresh and maintain current impersonated customer
    // TODO: this is something that can be done in Redux middleware if we decide to do it often
    isAdmin && addCustomerIdToUrlHash(customerID);
    dispatch(
      fetchImpersonatedCustomerDataFromProxyFlare(
        customerID,
        accessToken,
        employeeId
      )
    );
    dispatch(resetDevicesState());
    dispatch(resetStoredDevicesState());
    dispatch(resetEmployeesState());
    dispatch(resetApplicationsState());
    dispatch(resetITScoreCardState());
    dispatch(getAppAccount({ customerID }));
    setCustomerId(customerID);
  };
