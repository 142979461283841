type PendoDimensions = {
  userId: string;
  email: string;
  role: string;
  customerId: string;
  customerName: string;
  userEmail: string;
  planLevel: string;
  turbineInviteDate: string;
  activatedAt: string;
};

export const initPendo = ({
  userId,
  role,
  customerId,
  customerName,
  userEmail,
  planLevel,
  turbineInviteDate,
  activatedAt,
}: PendoDimensions): void => {
  pendo.initialize({
    visitor: {
      id: userId,
      email: userEmail,
      role,
      turbineInviteDate,
    },
    account: {
      id: customerId,
      name: customerName,
      planLevel,
      activatedAt,
    },
  });
};

export type TrackEventArgs = {
  visitorId: string;
  accountId: string;
  event: string;
  properties: object;
  context?: string;
};
