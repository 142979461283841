import { createSelector } from '@reduxjs/toolkit';
import type { UseToastParams } from '@electricjs/arc';
import { type Device } from '@turbine/types/DeviceManagement';
import { type Nullish } from '@turbine/types/utils';
import { MINIMUM_RAM_REQUIREMENT_IN_GB } from '@turbine/helpers/itScoreCard';
import { getDevicesWithOwnerInfo } from '@turbine/helpers/getDevicesWithOwnerInfo';
import { isFreeDiskSpaceAboveMinimumPercent } from '@turbine/helpers/itScoreCard';
import { isNonReporting } from '@turbine/helpers/itScoreCardV2';
import { type CheckboxContent } from '@turbine/components/EmployeeCheckbox/EmployeeCheckbox';
import {
  selectDevicesData,
  selectDevicesDictionary,
  selectMultipleDevicesDictionary,
  selectDevicesFailed,
} from '../devices/enrolledDevices';
import { type RootState } from '../store';
import { type Employee, type EmployeesData } from './employeesSlice';
import type { Toast } from '../devices/enrolledDevices';

// TODO: Normalize employees data
// https://electricops.atlassian.net/browse/IA1-2713
export const selectEmployeesLoading = (state: RootState): boolean =>
  state.employees.loading;
export const selectEmployeesFailed = (state: RootState): boolean =>
  state.employees.failed;
export const selectEmployeesData = (state: RootState): EmployeesData =>
  state.employees.data;

export const selectDiscardEmployeeLoading = (state: RootState): boolean =>
  state.employees.discardEmployeeLoading;
export const selectShowDiscardEmployeeToast = (state: RootState): boolean =>
  state.employees.showDiscardEmployeeToast;
export const selectDiscardEmployeeToastParams = (
  state: RootState
): Nullish<UseToastParams> => state.employees.discardEmployeeToastParams;
export const selectPersonalDeviceLoading = (state: RootState): boolean =>
  state.employees.personalDeviceLoading;
export const selectPersonalDeviceFailed = (state: RootState): boolean =>
  state.employees.personalDeviceFailed;
export const selectPersonalDeviceToast = (state: RootState): Toast =>
  state.employees.personalDeviceToast;

/* single employee fetch */
export const selectEmployeeRequestState = (state: RootState) =>
  state.employees.employeeRequestState;
export const selectEmployeeRequestLoading = createSelector(
  selectEmployeeRequestState,
  employeeRequestState => employeeRequestState === 'pending'
);
export const selectEmployeeRequestFailed = createSelector(
  selectEmployeeRequestState,
  employeeRequestState => employeeRequestState === 'rejected'
);

/* single employee update */
export const selectEmployeeUpdateState = (state: RootState) =>
  state.employees.employeeUpdateState;
export const selectEmployeeUpdateLoading = createSelector(
  selectEmployeeUpdateState,
  employeeUpdateState => employeeUpdateState === 'pending'
);
export const selectEmployeeUpdateFailed = createSelector(
  selectEmployeeUpdateState,
  employeeUpdateState => employeeUpdateState === 'rejected'
);

export const selectSubscriptionFailed = (state: RootState): boolean =>
  state.employees.subscriptionFailed;
export const selectExclusionFailed = (state: RootState): boolean =>
  state.employees.exclusionFailed;
export const selectSubscriptionLoading = (state: RootState): boolean =>
  state.employees.subscriptionLoading;
export const selectExclusionLoading = (state: RootState): boolean =>
  state.employees.exclusionLoading;
export const selectSwitchApproverFailed = (state: RootState): boolean =>
  state.employees.switchApproverFailed;
export const selectSwitchApproverLoading = (state: RootState): boolean =>
  state.employees.switchApproverLoading;

// This is a helper selector that allows you to grab related state together.
// It's composed of primitive selectors so is memoized affectively.
export const selectEmployeesState = createSelector(
  selectEmployeesLoading,
  selectEmployeesFailed,
  selectEmployeesData,
  (loading, failed, employees) => ({
    loading,
    failed,
    employees,
  })
);

export const selectSubscriptionState = createSelector(
  selectSubscriptionLoading,
  selectSubscriptionFailed,
  (loading, failed) => ({
    loading,
    failed,
  })
);

export const selectEmployeesTotal = createSelector(
  selectEmployeesData,
  employees => employees?.length
);

export const selectSubscribedEmployees = createSelector(
  selectEmployeesData,
  employees => {
    if (employees === null) {
      return null;
    }
    return employees
      .filter((employee: Employee) => employee.subscribed)
      .sort((a, b) => (a.first_name < b.first_name ? -1 : 1));
  }
);

export const selectEmployeesInRole = createSelector(
  [selectEmployeesData, (state: RootState, role: string) => role],
  (employees, inRole) => {
    if (!employees) {
      return null;
    }

    return employees.filter((employee: Employee) =>
      employee.roles.find(role => role.name === inRole)
    );
  }
);

export const selectNumberOfEmployeesInRole = createSelector(
  (state: RootState, role: string) => selectEmployeesInRole(state, role),
  employees => {
    if (!employees) {
      return 0;
    }
    return employees.length;
  }
);

export const selectEmployeesDictionary = createSelector(
  selectEmployeesData,
  employees => {
    if (!employees) {
      return null;
    }
    return employees.reduce(
      (employees: Record<string, Employee>, employee: Employee) => ({
        ...employees,
        [employee.id]: employee,
      }),
      {}
    );
  }
);

export const selectEmployeeId = (_: RootState, employeeId: Nullish<string>) =>
  employeeId;

export const selectEmployeeById = createSelector(
  [selectEmployeesDictionary, selectEmployeeId],
  (employeesDictionary, employeeId) =>
    employeeId ? employeesDictionary?.[employeeId] : undefined
);

export const selectEmployeesWithoutAssignment = createSelector(
  selectSubscribedEmployees,
  selectDevicesData,
  (employees, devices): EmployeesData => {
    if (!employees || !devices) {
      return null;
    }

    const assignedEmployeesMap = devices.reduce(
      (assignedEmployees: Record<string, boolean>, device: Device) => {
        const assignedTo = device.assigned_to;
        return assignedTo
          ? {
              ...assignedEmployees,
              [assignedTo]: true,
            }
          : assignedEmployees;
      },
      {}
    );
    return employees.filter(
      (employee: Employee) =>
        !assignedEmployeesMap[employee.id] && !employee.has_personal_device
    );
  }
);

type CreateEmployeesWithDevicesParams = {
  devicesFailed: boolean;
  devicesDictionary: Record<string, Device> | null;
  employees: EmployeesData;
  subscribed: boolean;
};
export const createEmployeesWithDevices = ({
  devicesFailed,
  devicesDictionary,
  employees,
  subscribed,
}: CreateEmployeesWithDevicesParams): EmployeesData => {
  // Return null if we don't have devices, but only if the reason is not because
  // the device call failed
  if (employees === null || (devicesDictionary === null && !devicesFailed)) {
    return null;
  }
  let employeesWithDevices = employees;
  // If the device call failed, we can use n/a. This scenario will only happen in the result of a server error
  // so is unlikely, but this will display n/a on the table and when a user clicks it will show the proper
  // error on the enrolled devices tab.
  if (devicesFailed) {
    employeesWithDevices = employees.map(employee => ({
      ...employee,
      device_serial_number: 'n/a',
      device_id: 'n/a',
      device_hostname: 'n/a',
    }));
  } else if (devicesDictionary) {
    employeesWithDevices = employees.map(employee => ({
      ...employee,
      device_serial_number: devicesDictionary[employee.id]?.serial,
      device_id: devicesDictionary[employee.id]?.id,
      device_hostname: devicesDictionary[employee.id]?.hostname,
    }));
  }

  return subscribed
    ? employeesWithDevices.filter(
        employee => employee.subscribed && !employee.excluded
      )
    : employeesWithDevices;
};

// Added to check if employees have multiple assigned devices in People table
type CreateEmployeesWithMultipleDevicesParams = {
  devicesFailed: boolean;
  multipleDevicesDictionary: Record<string, Device[]> | null;
  employees: EmployeesData;
  subscribed: boolean;
};
export const createEmployeesWithMultipleDevices = ({
  devicesFailed,
  multipleDevicesDictionary,
  employees,
  subscribed,
}: CreateEmployeesWithMultipleDevicesParams): EmployeesData => {
  // Return null if we don't have devices, but only if the reason is not because
  // the device call failed
  if (
    employees === null ||
    (multipleDevicesDictionary === null && !devicesFailed)
  ) {
    return null;
  }
  let employeesWithDevices = employees;
  // If the device call failed, we can use n/a. This scenario will only happen in the result of a server error
  // so is unlikely, but this will display n/a on the table and when a user clicks it will show the proper
  // error on the enrolled devices tab.
  if (devicesFailed) {
    employeesWithDevices = employees.map(employee => ({
      ...employee,
      device_serial_number: 'n/a',
      device_id: 'n/a',
      device_hostname: 'n/a',
    }));
  } else if (multipleDevicesDictionary) {
    employeesWithDevices = employees.map(employee => {
      if (multipleDevicesDictionary[employee.id]) {
        return {
          ...employee,
          device_serial_number:
            multipleDevicesDictionary[employee.id][0]?.serial,
          device_id: multipleDevicesDictionary[employee.id][0]?.id,
          device_hostname: multipleDevicesDictionary[employee.id][0]?.hostname,
          num_devices: multipleDevicesDictionary[employee.id].length,
        };
      } else {
        return { ...employee };
      }
    });
  }

  return subscribed
    ? employeesWithDevices.filter(
        employee => employee.subscribed && !employee.excluded
      )
    : employeesWithDevices;
};

// only used in EmployeesPeopleTable
export const selectAllEmployeesWithDevices = createSelector(
  selectDevicesFailed,
  selectDevicesDictionary,
  selectEmployeesData,
  (devicesFailed, devicesDictionary, employees) =>
    createEmployeesWithDevices({
      devicesFailed,
      devicesDictionary,
      employees,
      subscribed: false,
    })
);

export const selectEmployeesWithPersonalDevices = createSelector(
  selectEmployeesData,
  employees => {
    if (!employees) {
      return null;
    }
    return employees.filter(
      (employee: Employee) => employee.has_personal_device
    );
  }
);

// Used for number in People.wrapper and employees in EmployeesPeopleTable
export const selectSubscribedEmployeesWithDevices = createSelector(
  selectDevicesFailed,
  selectMultipleDevicesDictionary,
  selectSubscribedEmployees,
  (devicesFailed, multipleDevicesDictionary, employees) =>
    createEmployeesWithMultipleDevices({
      devicesFailed,
      multipleDevicesDictionary,
      employees,
      subscribed: true,
    })
);

export const selectDevicesWithEmployee = createSelector(
  selectEmployeesDictionary,
  selectDevicesData,
  (employeesDictionary, devices) => {
    if (!employeesDictionary || !devices) {
      return [];
    }

    return getDevicesWithOwnerInfo({
      devices,
      employeesDictionary,
    });
  }
);

export const selectEmployeesWithLowDiskSpace = createSelector(
  selectDevicesWithEmployee,
  devicesWithEmployee =>
    devicesWithEmployee.reduce((acc, currDevice) => {
      if (
        !isFreeDiskSpaceAboveMinimumPercent(
          currDevice.total_hard_drive_space,
          currDevice.free_hard_drive_space
        ) &&
        !isNonReporting(currDevice) &&
        !!currDevice.assigned_to
      ) {
        return [
          ...acc,
          {
            id: currDevice.assigned_to,
            name: currDevice.assignedOwnerName,
            email: currDevice.assignedOwnerEmail,
            deviceId: currDevice.id,
            deviceHostname: currDevice.hostname,
            deviceOSType: currDevice.os_type as string,
            deviceFreeSpace: currDevice.free_hard_drive_space,
            deviceTotalSpace: currDevice.total_hard_drive_space,
          },
        ];
      } else {
        return acc;
      }
    }, [] as CheckboxContent[])
);

export const selectUnassignedDevicesWithLowDiskSpace = createSelector(
  selectDevicesWithEmployee,
  devicesWithEmployee => {
    return devicesWithEmployee.filter(
      device =>
        !isFreeDiskSpaceAboveMinimumPercent(
          device.total_hard_drive_space,
          device.free_hard_drive_space
        ) &&
        !device.assigned_to &&
        !isNonReporting(device)
    );
  }
);

export const selectDevicesWithLowRAM = createSelector(
  selectDevicesWithEmployee,
  devicesWithEmployee => {
    if (!devicesWithEmployee) {
      return [];
    }

    return devicesWithEmployee.filter(
      device => device.total_ram < MINIMUM_RAM_REQUIREMENT_IN_GB
    );
  }
);

export const selectDevicesWithoutFirewall = createSelector(
  selectDevicesWithEmployee,
  devicesWithEmployee =>
    devicesWithEmployee.reduce((acc, currDevice) => {
      if (!currDevice.firewall && !isNonReporting(currDevice)) {
        return [
          ...acc,
          {
            id: currDevice.assigned_to,
            name: currDevice.assignedOwnerName,
            email: currDevice.assignedOwnerEmail,
            deviceHostname: currDevice.hostname,
            deviceOSType: currDevice.os_type as string,
            deviceId: currDevice.id,
            deviceMDM: currDevice.source,
            deviceSerialNumber: currDevice.serial,
          } as CheckboxContent,
        ];
      } else {
        return acc;
      }
    }, [] as CheckboxContent[])
);
