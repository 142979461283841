import omit from 'lodash/omit';
import * as types from './userRolesTypes';

const initialState = {
  fetching: false,
  failed: false,
  isEndUserEnabled: false,
  roles: [],
};

export function userRoles(state = initialState, { type, payload = {} }) {
  switch (type) {
    case types.USER_ROLES_FETCHED:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.USER_ROLES_FETCH_SUCCEEDED:
      return {
        ...state,
        roles: payload.roles.map(item => {
          const role = omit(item, '__typename');
          return role;
        }),
        fetching: false,
        failed: false,
      };
    case types.USER_ROLES_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    default:
      return state;
  }
}
