import { gql } from '@apollo/client';

export interface CreateTempCredentialsResponse {
  customer_id: string;
  email: string;
  temp_pass: string;
  customer_confirmed: boolean;
  created_by: string;
}

export const CREATE_TEMP_CREDENTIALS = gql`
  mutation {
    createTempCredentials {
      customer_id
      email
      temp_pass
      customer_confirmed
      created_by
    }
  }
`;
