import { datadogRum } from '@datadog/browser-rum';
import {
  REACT_APP_DD_CLIENT_ID,
  REACT_APP_DD_APPLICATION_ID,
  appVersion,
} from '@turbine/config';

if (!window.Cypress && REACT_APP_DD_CLIENT_ID && REACT_APP_DD_APPLICATION_ID) {
  const clientToken = REACT_APP_DD_CLIENT_ID;
  const applicationId = REACT_APP_DD_APPLICATION_ID;
  const releaseVersion = appVersion || 'v0.0.0-dev';

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    sampleRate: 100,
    trackInteractions: true,
    version: releaseVersion,
    service: 'app-turbine-frontend',
  });
  datadogRum.addTiming('initial_load');
}
