import { browserHistory as history } from '@electricjs/utils/helpers';
import { createAction } from '@reduxjs/toolkit';
import { REDIRECTS } from '@turbine/routes';
import { type AppDispatch, type RootState } from '../store';
import { type EmployeeInformation } from './employeeInformationSlice';
import { type FormState } from './formStateSlice';
import { type TaskList } from './taskListSlice';
import { REACT_APP_END_USER_APPLICATION_URL } from '@turbine/config';

type ResetNewOnboardingPayload = {
  formState?: Partial<FormState>;
  employeeInformation?: Partial<EmployeeInformation>;
  taskList?: Partial<TaskList>;
};

const resetNewOffboarding = createAction<ResetNewOnboardingPayload | undefined>(
  'newOffboarding/resetNewOffboarding'
);

const navigateToNewOffboarding =
  (payload?: ResetNewOnboardingPayload) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const plan = getState().customerPlan;
    // Route PREMIUM user to EUH's new offboarding
    if (plan === 'PREMIUM') {
      window.location.href = `${REACT_APP_END_USER_APPLICATION_URL}/newOffboarding`;
      history.push(REDIRECTS.TO.ON_OFF_BOARDINGS.EMPLOYEES.URL);
    } else {
      dispatch(resetNewOffboarding(payload));
      history.push(REDIRECTS.FROM.NEW_OFFBOARDING);
    }
  };

export { resetNewOffboarding, navigateToNewOffboarding };
