import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_ONBOARDING } from '@turbine/graphql/queries/onboardingQueries';
import { tryGetArrayFromString } from '@turbine/pages/NewOnboarding/utils/tryGetArrayFromString';
import { type OnboardingDataProps } from '@turbine/pages/NewOnboarding/utils/parseOnBoardingData';
import { type AppThunkConfig } from '@turbine/redux/store';
import { fetchEmployeesByCustomerID, selectEmployeeById } from '../employees';
import type { Software } from './taskListSlice';

export type FetchOnboardingParams = {
  customerID: string;
  onboardingId: string;
};
export const fetchOnboardingDraft = createAsyncThunk<
  OnboardingDataProps,
  FetchOnboardingParams,
  AppThunkConfig
>(
  'newOnboarding/fetchOnboardingDraft',
  async (
    { customerID, onboardingId },
    { extra: { client }, dispatch, getState }
  ) => {
    try {
      const response = await client().query({
        query: FETCH_ONBOARDING,
        variables: {
          customerId: customerID,
          id: onboardingId,
        },
      });

      const onboardingData = { ...response.data.onboarding.data };

      // software questions come with some stringified array answers, which fails to populate the Select component with multi-option enabled; this converts them to proper arrays when relevant
      const parsedSoftwares = onboardingData.softwares.map(
        (software: Software) => ({
          ...software,
          notes: software.notes.map(note => ({
            ...note,
            answer: tryGetArrayFromString(note.answer),
          })),
        })
      );

      onboardingData.softwares = parsedSoftwares;

      // resolve manager data
      // this reduce the risk of selecting unexisting options by default
      if (onboardingData?.manager_id) {
        await dispatch(
          fetchEmployeesByCustomerID({
            customerID,
          })
        );
        const employee = selectEmployeeById(
          getState(),
          onboardingData?.manager_id
        );
        onboardingData.manager = employee;
      }

      return onboardingData;
    } catch (err) {
      window?.DD_RUM?.addError(err);
      throw err;
    }
  }
);
