/* eslint-disable @typescript-eslint/no-unused-vars */
import { createGuard } from './guardsHelpers';
import { hasSomePermission } from '@turbine/helpers/hasPermissionHelper';
import { hasSomePlan } from '@turbine/helpers/hasPlanHelper';

export const requireLogin = createGuard(
  'requireLogin',
  (to, from, next) => (_dispatch, _getState) => {
    const { auth, session } = to.meta;

    if (auth && !session?.isAuthenticated) {
      const newAppState = {
        appState: { targetPath: to.location.pathname },
      };
      // When an Auth0 user doesn't have a Turbine or ITH user, they will be redirected to the error page.
      if (
        window?.location?.search.includes('error=access_denied') &&
        window?.location?.search.includes('error_description=user_not_found')
      ) {
        next.redirect('/error');
        return;
      } else {
        session.loginWithRedirect(newAppState);
      }
      return;
    }

    next();
  }
);

export const requirePermissions = createGuard(
  'requirePermissions',
  (to, _from, next) => (_dispatch, getState) => {
    const { permissionsNeeded } = to.meta;

    // get user permissions object from redux store
    const {
      userPermissions: { permissions },
    } = getState();

    // transform permissions object into an array (filter out false permissions)
    const userPermissions = Object.keys(permissions).filter(
      permission => !!permissions[permission]
    );

    if (permissionsNeeded) {
      hasSomePermission(userPermissions, permissionsNeeded)
        ? next()
        : next.redirect('/error');
    }

    next();
  }
);

export const requirePlans = createGuard(
  'requirePlans',
  (to, _from, next) => (_dispatch, getState) => {
    const { plansNeeded } = to.meta;
    const { customerPlan } = getState();

    if (plansNeeded) {
      hasSomePlan(customerPlan, plansNeeded) ? next() : next.redirect('/error');
    }

    next();
  }
);

/** @TODO: improve the guard to handle the case when only some of the feature flags received need be true.
 * https://electricops.atlassian.net/browse/IA1-2737
 */
export const requireFeatureFlags = createGuard(
  'requireFeatureFlags',
  (to, _from, next) => () => {
    const { featureFlagsNeeded, featureFlags } = to.meta;

    if (featureFlagsNeeded) {
      featureFlagsNeeded.every(featureFlag => featureFlags[featureFlag])
        ? next()
        : next.redirect('/error');
    }

    next();
  }
);

export const requireCustomerEnrolled = createGuard(
  'requireCustomerEnrolled',
  (to, from, next) => (_dispatch, getState) => {
    const {
      customerEnrollment: { customerEnrollmentCompleted },
      applications: { hasChatApp },
    } = getState();
    const { pathname } = from.location;
    const { session } = to.meta;

    // If the user is trying to get to post-registration, we want to allow them.
    // Post registration is where their auth0 metadata gets set so it's important
    // they go through that flow even if the customer has not completed enrollment.
    // Upon a successful post-registration, they will attempt to redirect to the
    // root route at which point this guard will run again and take care of sending them to
    // enrollment.
    if (
      hasChatApp &&
      !customerEnrollmentCompleted &&
      session.isAuthenticated &&
      pathname !== '/post-registration'
    ) {
      next.redirect('/enrollment-setup');
      return;
    }

    if (pathname === '/enrollment-setup') {
      next.redirect('/');
      return;
    }

    next();
  }
);

export const restrictAuthenticated = createGuard(
  'restrictAuthenticated',
  (to, _from, next) => _dispatch => {
    const { session } = to.meta;

    if (session.isAuthenticated) {
      next.redirect('/');
    }
    next();
  }
);
