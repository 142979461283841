import { gql } from '@apollo/client';

export const FETCH_APPLICATION_RESOURCES = gql`
  query CustomerApplicationResources($customerId: String!) {
    application_resources {
      google {
        groups(customer_id: $customerId) {
          id
          email
        }
        calendars(customer_id: $customerId) {
          id
          name: summary
        }
      }
    }
  }
`;

export const FETCH_APPLICATION_RESOURCES_OKTA = gql`
  query CustomerOktaApplicationResources($customerId: String!) {
    application_resources {
      okta {
        groups(customer_id: $customerId) {
          id
          name
        }
      }
    }
  }
`;
