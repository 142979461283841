import { createSlice } from '@reduxjs/toolkit';

type NavItemNotificationsState = {
  [key: string]: boolean;
};

type NavItemPayload = {
  path: string;
  show: boolean;
};

const initialState = {};

const navItemNotificationsSlice = createSlice({
  name: 'navItemNotifications',
  initialState,
  reducers: {
    toggleNotification: {
      reducer(state: NavItemNotificationsState, { payload }: any) {
        const { path, show } = payload;
        state[path] = show;
      },
      prepare: ({ path, show }: NavItemPayload) => {
        return {
          payload: {
            path,
            show,
          },
        };
      },
    },
  },
});

export const { toggleNotification } = navItemNotificationsSlice.actions;

export default navItemNotificationsSlice.reducer;
