import { type IconNames } from '@electricjs/arc';
import { type ReactChild, type ReactElement } from 'react';
import ReactPlaceholder from 'react-placeholder';
import CollapsibleSubNav from './CollapsibleSubNav';
import SideNavPlaceholder from './SideNavPlaceholder';

type CollapsibleSubNavWrapperProps = {
  isLoading: boolean;
  icon: IconNames;
  text: string;
  children: Array<ReactChild> | ReactChild;
};

const CollapsibleSubNavWrapper = ({
  isLoading,
  icon,
  text,
  children,
}: CollapsibleSubNavWrapperProps): ReactElement => {
  return (
    <ReactPlaceholder
      className={isLoading ? 'cell--loading' : ''}
      ready={!isLoading}
      showLoadingAnimation={true}
      customPlaceholder={<SideNavPlaceholder />}>
      <CollapsibleSubNav icon={icon} text={text} isLoading={isLoading}>
        {children}
      </CollapsibleSubNav>
    </ReactPlaceholder>
  );
};

export default CollapsibleSubNavWrapper;
