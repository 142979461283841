import { createAsyncThunk } from '@reduxjs/toolkit';
import { type AppThunkConfigExtra } from '@turbine/redux/store';
import { FETCH_SOLUTION_INSTANCES } from '@turbine/graphql/queries/trayQueries';
import { SOLUTION_INSTANCE_FETCH } from '../types/trayTypes';

export type FetchSolutionInstanceParams = {
  customerID: string;
  onBoardingType: boolean;
};

export const fetchTrayInstances = createAsyncThunk(
  SOLUTION_INSTANCE_FETCH,
  (
    { customerID, onBoardingType }: FetchSolutionInstanceParams,
    thunkConfig
  ) => {
    const { extra } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;

    // Fetching solutions instances
    const solutionInstanceFetch = client()
      .query({
        query: FETCH_SOLUTION_INSTANCES,
        variables: {
          customer_id: customerID,
          on_boarding_type: onBoardingType,
        },
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        window?.DD_RUM?.addError(error);
        throw error;
      });

    return solutionInstanceFetch;
  }
);
