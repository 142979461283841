import { gql } from '@apollo/client';

export const SEND_MARKETPLACE_OPPORTUNITY_EMAIL = gql`
  mutation SendMarketplaceOpportunityEmail(
    $customer_id: String!
    $employee_id: String!
    $application: String!
    $opportunity_type: String!
  ) {
    send_marketplace_opportunity_email(
      customer_id: $customer_id
      employee_id: $employee_id
      application: $application
      opportunity_type: $opportunity_type
    ) {
      timestamp
    }
  }
`;
