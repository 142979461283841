import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FETCH_ALL_CUSTOMER_AND_SOFTWARE_APPLICATIONS,
  FETCH_ALL_CUSTOMER_AND_SOFTWARE_APPLICATIONS_V2,
} from '@turbine/graphql/queries/selectApplicationsQueries';
import { type AppThunkConfigExtra } from '@turbine/redux/store';

type FetchAllCustomerSoftwaresParams = {
  customerID: string;
  allowMultipleAppAdmins?: boolean;
};

export const fetchAllCustomerSoftwares = createAsyncThunk(
  'customerSoftwares/fetchAllCustomerSoftwares',
  async (
    { customerID, allowMultipleAppAdmins }: FetchAllCustomerSoftwaresParams,
    thunkConfig
  ) => {
    const { extra } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const allCustomerAndSoftwareAppsQuery = {
      query: allowMultipleAppAdmins
        ? FETCH_ALL_CUSTOMER_AND_SOFTWARE_APPLICATIONS_V2
        : FETCH_ALL_CUSTOMER_AND_SOFTWARE_APPLICATIONS,
      variables: {
        customerId: customerID,
        states: ['requested', 'fulfilled'],
      },
    };
    try {
      const result = await client().query(allCustomerAndSoftwareAppsQuery);

      return result.data;
    } catch (error) {
      window?.DD_RUM?.addError(error);
      throw error;
    }
  }
);
