import { createSelector } from 'reselect';
import { PLANS } from '@turbine/constants/customerPlans';

export const customerPlanSelector = createSelector(
  [state => state.customerPlan],
  customerPlan => customerPlan
);

export const isPremiumPlanSelector = createSelector(
  [state => state.customerPlan],
  customerPlan => customerPlan === PLANS.PREMIUM
);
