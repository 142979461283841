import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
const enrollmentsSelector = get('enrollments');
const customersSelector = get('customers');
const selectEnrollment = createSelector(
  enrollmentsSelector,
  (enrollments = []) => enrollments[0] || {}
);

export const getOnboardingCompleted = createSelector(
  [selectEnrollment, customersSelector],
  (enrollment, customer) => {
    return !!customer?.activated_at || !!enrollment.onboarding_completed_at;
  }
);
