import { Alert } from '@electricjs/arc';
import PropTypes from 'prop-types';
import isWithinInterval from 'date-fns/isWithinInterval';

const TimedAlert = ({ startDate, endDate, message, id, title }) => {
  let isWithinTimeRange = false;
  try {
    // This function throws in error scenarios such as when the end date is before the start date.
    isWithinTimeRange = isWithinInterval(Date.now(), {
      start: startDate,
      end: endDate,
    });
  } catch (_err) {
    isWithinTimeRange = false;
  }

  return (
    <Alert
      visible={isWithinTimeRange && !localStorage.getItem(`${id}AlertSeen`)}
      title={title}
      message={message}
      width="68rem"
      onDismiss={() => localStorage.setItem(`${id}AlertSeen`, 'true')}
    />
  );
};

TimedAlert.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  message: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default TimedAlert;
