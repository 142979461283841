import PropTypes from 'prop-types';
import { Button } from '@electricjs/arc';
import ModalHeader from '@turbine/components/common/Modal/ModalTypes/common/ModalHeader';

const ConfirmModalComponent = ({
  bemClasses,
  CloseButton,
  modalProps: { checkMark = true, message, submitButtonProps, title },
  processing,
}) => {
  const confirmModalClasses = (modifier = '') =>
    bemClasses('confirm-modal', modifier);
  // "processingText" or loading text is not used in new buttons, if this is needed it can be added.
  // eslint-disable-next-line no-unused-vars
  const { onClick, text, processingText } = submitButtonProps;

  return (
    <div className={confirmModalClasses('inner')}>
      <ModalHeader CloseButton={CloseButton} title={title} />
      <div className={confirmModalClasses('body')}>
        <div className={confirmModalClasses('message-container')}>
          {checkMark && (
            <span className={confirmModalClasses('check')}>&#10003;</span>
          )}
          <span className={confirmModalClasses('message')}>{message}</span>
        </div>

        <div className={confirmModalClasses('sticky-bottom')}>
          <Button
            {...submitButtonProps}
            intent="success"
            loading={processing}
            onClick={onClick}>
            {text}
          </Button>
        </div>
      </div>
    </div>
  );
};

ConfirmModalComponent.defaultProps = {
  modalProps: {
    getProcessingState: () => false,
  },
};

const submitButtonPropsShape = PropTypes.shape({
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  processingText: PropTypes.string.isRequired,
}).isRequired;

ConfirmModalComponent.propTypes = {
  bemClasses: PropTypes.func.isRequired,
  CloseButton: PropTypes.func.isRequired,
  modalProps: PropTypes.shape({
    checkMark: PropTypes.bool,
    getProcessingState: PropTypes.func,
    message: PropTypes.string.isRequired,
    submitButtonProps: submitButtonPropsShape,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default ConfirmModalComponent;
