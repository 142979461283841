import { gql } from '@apollo/client';

export const BULK_UPDATE_SLACK_USERS = gql`
  mutation BulkUpdateSlackUsers(
    $customerId: String!
    $approvers: Boolean!
    $subscribed: Boolean!
    $roles: [String]
    $searchTerm: String
    $searchBy: [String]
    $slackUserSelected: Boolean
  ) {
    bulkUpdateSlackUsers(
      customer_id: $customerId
      approvers: $approvers
      roles: $roles
      search_by: $searchBy
      search_term: $searchTerm
      slack_user_selected: $slackUserSelected
      subscribed: $subscribed
    ) {
      updated
    }
  }
`;

export const FETCH_SLACK_USERS = gql`
  query Customers(
    $customerId: String!
    $supportable: Boolean!
    $page: Int!
    $per_page: Int!
  ) {
    customers(id: $customerId) {
      employees(supportable: $supportable, page: $page, per_page: $per_page) {
        id
        avatar
        name
        slack_username
        email
        guest
        slack_admin
        slack_owner
        is_bot
        subscribed
        approver
        slack_data
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query EmployeeSearch(
    $customerId: String!
    $approvers: Boolean!
    $subscribed: Boolean!
    $roles: [String]
    $searchTerm: String
    $searchBy: [String]
    $page: String!
    $per_page: String!
    $nonSlackCustomer: Boolean
  ) {
    filter_slack_users(
      customer_id: $customerId
      approvers: $approvers
      subscribed: $subscribed
      roles: $roles
      search_term: $searchTerm
      search_by: $searchBy
      page: $page
      per_page: $per_page
      non_slack_customer: $nonSlackCustomer
    ) {
      employees {
        id
        avatar
        name
        slack_username
        email
        guest
        slack_admin
        slack_owner
        is_bot
        subscribed
        approver
        slack_data
      }
      meta_data {
        number_of_employees
        number_of_selected_employees
        number_of_employees_after_filter
        number_of_selected_employees_after_filter
      }
      user_chat_accounts {
        employee_id
        json_profile_data
      }
    }
  }
`;

export const SUBSCRIBE_EMPLOYEE_DEPRECATED = gql`
  mutation updateEmployee($employeeId: String!, $subscribed: Boolean!) {
    updateEmployee(employee_id: $employeeId, subscribed: $subscribed) {
      employee {
        id
        customer_id
        subscribed
      }
    }
  }
`;

export const SUBSCRIBE_EMPLOYEE = gql`
  mutation updateEmployee(
    $employeeId: String!
    $subscribed_at: String
    $setNullFields: [String]
  ) {
    updateEmployeeV2(
      employee_id: $employeeId
      subscribed_at: $subscribed_at
      set_null_fields: $setNullFields
    ) {
      result {
        id
        customer_id
        subscribed_at
      }
    }
  }
`;

export const EXCLUDE_EMPLOYEE = gql`
  mutation updateEmployee(
    $employeeId: String!
    $excluded_at: String
    $setNullFields: [String]
  ) {
    updateEmployeeV2(
      employee_id: $employeeId
      excluded_at: $excluded_at
      set_null_fields: $setNullFields
    ) {
      result {
        id
        customer_id
        excluded_at
      }
    }
  }
`;

export const SET_EMPLOYEE_AS_APPROVER = gql`
  mutation updateEmployee($employeeId: String!, $approver: Boolean!) {
    updateEmployee(employee_id: $employeeId, approver: $approver) {
      employee {
        id
        customer_id
        approver
      }
    }
  }
`;

export const SET_EMPLOYEE_AS_APPROVER_2 = gql`
  mutation updateEmployee($employeeId: String!, $approver: Boolean) {
    updateEmployeeV2(employee_id: $employeeId, approver: $approver) {
      result {
        id
        customer_id
        roles {
          id
          name
        }
      }
    }
  }
`;

export const SEARCH_EMPLOYEE_BY_EMAIL = gql`
  query SearchEmployeesByEmail(
    $customer_id: String!
    $email: String
    $page: Int!
    $per_page: Int!
    $excluded_role_ids: [String!]
  ) {
    employees(
      customer_id: $customer_id
      email: $email
      page: $page
      per_page: $per_page
      excluded_role_ids: $excluded_role_ids
    ) {
      id
      name
      customer_id
      avatar
      email
      electric
      first_name
      gsuite_admin
      guest
      last_name
      phone
      status
      subscribed
      approver
      primary_approver
      slack_id
      slack_admin
      slack_owner
      slack_username
      display_name
      is_bot
      timezone
      office_id
      roles {
        id
        name
        permissions
      }
      permissions
      devices
    }
  }
`;

export const FETCH_EMPLOYEE_BY_ROLE_OR_EMAIL = gql`
  query FetchEmployeesByRoleOrEmail(
    $customer_id: String!
    $email: String
    $page: Int!
    $per_page: Int!
    $role_ids: [String!]
  ) {
    employees(
      customer_id: $customer_id
      email: $email
      page: $page
      per_page: $per_page
      role_ids: $role_ids
      include: ["roles", "devices"]
    ) {
      id
      name
      customer_id
      avatar
      email
      electric
      first_name
      gsuite_admin
      guest
      last_name
      phone
      status
      subscribed
      approver
      primary_approver
      slack_id
      slack_admin
      slack_owner
      slack_username
      display_name
      is_bot
      timezone
      office_id
      roles {
        id
        name
        permissions
      }
      devices
    }
  }
`;

export const EMPLOYEES_FROM_API_ORGS = gql`
  query EmployeeSearch($customerId: String!, $includeDiscarded: Boolean) {
    employees: employees_v2(
      customer_id: $customerId
      include_discarded: $includeDiscarded
      include_selection: false
    ) {
      last_name
      email
      first_name
      id
      customer_id
      subscribed_at
      discarded_at
      excluded_at
      avatar
      approver
      roles {
        id
        name
      }
      department
      manager {
        id
        email
      }
      has_personal_device
      job_title
    }
  }
`;

export const EMPLOYEES_FROM_API_CUSTOMERS = gql`
  query EmployeeSearch(
    $customerId: String!
    $perPage: Int
    $subscribed: Boolean
  ) {
    employees(
      customer_id: $customerId
      per_page: $perPage
      subscribed: $subscribed
      v1: true
      base_only: true
    ) {
      last_name
      email
      first_name
      id
      subscribed
      created_at
    }
  }
`;

export const GET_EMPLOYEE_BY_ID = gql`
  query Employee($id: String!) {
    employee: employee_v2(employee_id: $id) {
      id
      first_name
      last_name
      email
    }
  }
`;

export interface FetchEmployeeByIdData {
  id: string;
  last_name: string;
  first_name: string;
  customer_id: string;
  avatar: string;
  roles: {
    id: string;
    name: string;
  };
  approver: string;
  office_location: string;
  employment_type: string;
  department: string;
  job_title: string;
  manager: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
}

export const FETCH_EMPLOYEE_BY_ID = gql`
  query FetchEmployeeById($employeeId: String!) {
    employee: employee_v2(employee_id: $employeeId) {
      id
      last_name
      first_name
      customer_id
      avatar
      roles {
        id
        name
      }
      approver
      office_location
      employment_type
      department
      job_title
      email
      subscribed_at
      manager {
        id
        first_name
        last_name
        email
      }
      has_personal_device
    }
  }
`;
