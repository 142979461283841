import { arrangeNonReportingDevices } from '@turbine/pages/DeviceManagement/DevicesTable/helpers';
import { type Device } from '@turbine/types/DeviceManagement';
import { type Employee } from '@turbine/redux/employees';

export const getDevicesWithOwnerInfo = ({
  devices,
  employeesDictionary,
}: {
  devices: Device[];
  employeesDictionary: Record<string, Employee> | null;
}) => {
  if (!devices || !employeesDictionary) {
    return [];
  }
  return arrangeNonReportingDevices(
    devices.map(device => {
      if (
        employeesDictionary[
          device.assigned_to as NonNullable<Device['assigned_to']>
        ]
      ) {
        return {
          ...device,
          assignedOwnerName: device.assigned_to
            ? employeesDictionary[device.assigned_to].name
            : null,
          assignedOwnerEmail: device.assigned_to
            ? employeesDictionary[device.assigned_to]?.email
            : null,
        };
      }
      return {
        ...device,
        assignedOwnerName: null,
        assignedOwnerEmail: null,
      };
    })
  );
};
