import { type ReactElement } from 'react';
import styled from 'styled-components';
import {
  Box,
  FieldLabel as ArcFieldLabel,
  type FieldLabelProps as ArcFieldLabelProps,
  Flex,
  Text,
  Tooltip,
  type TooltipProps,
} from '@electricjs/arc';
import { InfoIcon } from '../../../components/Icons';

export const Required = styled(Text)`
  margin-right: 0.4rem;
  color: Red;
`;

export type FieldLabelProps = {
  required?: boolean;
  regularTextSize?: boolean;
  tooltip?: TooltipProps['content'];
  tooltipProps?: TooltipProps;
} & ArcFieldLabelProps;

const FieldLabel = ({
  required = false,
  regularTextSize = false,
  children,
  tooltip,
  tooltipProps,
  ...labelProps
}: FieldLabelProps): ReactElement | null => {
  const LabelComponent = regularTextSize
    ? Text
    : (ArcFieldLabel as ReactElement['type']);
  if (!children) return null;
  return (
    <Flex>
      {required && (
        <LabelComponent>
          <Required as="span">*</Required>
        </LabelComponent>
      )}
      <LabelComponent {...labelProps}>
        {children}
        {tooltip && (
          <Tooltip placement="top" content={tooltip} {...tooltipProps}>
            <Box ml={1}>
              <InfoIcon />
            </Box>
          </Tooltip>
        )}
      </LabelComponent>
    </Flex>
  );
};

export { FieldLabel };
