import { createSelector, type OutputSelector } from 'reselect';

type NavItemNotificationsSlice = {
  [key: string]: boolean;
};

type Store = {
  navItemNotifications: NavItemNotificationsSlice;
};

const getNavItemNotifications = (store: Store) => {
  return store.navItemNotifications;
};

export const getNavItemHasNotification = (
  navItemPath: string
): OutputSelector<Store, boolean, unknown> => {
  return createSelector(
    [getNavItemNotifications],
    (navItemNotifications: NavItemNotificationsSlice) =>
      navItemNotifications[navItemPath]
  );
};
