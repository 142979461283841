import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from '../store';

export const selectITScoreCardConfigurationLoading = (
  state: RootState
): boolean => state.itScoreCard.itScoreCardConfigurationLoading;
export const selectITScoreCardConfigurationFailed = (
  state: RootState
): boolean => state.itScoreCard.itScoreCardConfigurationFailed;
export const selectITScoreCardConfigurationData = (state: RootState) =>
  state.itScoreCard.itScoreCardConfigurationData;

export const selectITScoreCardGSuiteCSVLoading = (state: RootState): boolean =>
  state.itScoreCard.itScoreCardGSuiteCSVLoading;
export const selectITScoreCardGSuiteCSVFailed = (state: RootState): boolean =>
  state.itScoreCard.itScoreCardGSuiteCSVFailed;
export const selectITScoreCardGSuiteCSVData = (state: RootState) =>
  state.itScoreCard.itScoreCardGSuiteCSVData;

export const selectITScoreCardGSuiteLoading = (state: RootState): boolean =>
  state.itScoreCard.itScoreCardGSuiteLoading;
export const selectITScoreCardGSuiteFailed = (state: RootState): boolean =>
  state.itScoreCard.itScoreCardGSuiteFailed;
export const selectITScoreCardGSuiteData = (state: RootState) =>
  state.itScoreCard.itScoreCardGSuiteData;

export const selectITScoreCardOffice365Loading = (state: RootState): boolean =>
  state.itScoreCard.itScoreCardOffice365Loading;
export const selectITScoreCardOffice365Failed = (state: RootState): boolean =>
  state.itScoreCard.itScoreCardOffice365Failed;
export const selectITScoreCardOffice365Data = (state: RootState) =>
  state.itScoreCard.itScoreCardOffice365Data;

export const selectInProgressFirewallRequestLoading = (
  state: RootState
): boolean => state.itScoreCard.inProgressFirewallRequestLoading;
export const selectInProgressFirewallRequestFailed = (
  state: RootState
): boolean => state.itScoreCard.inProgressFirewallRequestFailed;
export const selectInProgressFirewallRequestData = (state: RootState) =>
  state.itScoreCard.inProgressFirewallRequestData;

export const selectITScoreCardConfigurationState = createSelector(
  selectITScoreCardConfigurationLoading,
  selectITScoreCardConfigurationFailed,
  selectITScoreCardConfigurationData,
  (loading, failed, itScoreCardConfiguration) => ({
    loading,
    failed,
    itScoreCardConfiguration,
  })
);

export const selectITScoreCardGSuiteState = createSelector(
  selectITScoreCardGSuiteLoading,
  selectITScoreCardGSuiteFailed,
  selectITScoreCardGSuiteData,
  (loading, failed, itScoreCardGSuite) => ({
    loading,
    failed,
    itScoreCardGSuite,
  })
);

export const selectITScoreCardGSuiteCSVState = createSelector(
  selectITScoreCardGSuiteCSVLoading,
  selectITScoreCardGSuiteCSVFailed,
  selectITScoreCardGSuiteCSVData,
  (loading, failed, itScoreCardGSuiteCSV) => ({
    loading,
    failed,
    itScoreCardGSuiteCSV,
  })
);

export const selectITScoreCardOffice365State = createSelector(
  selectITScoreCardOffice365Loading,
  selectITScoreCardOffice365Failed,
  selectITScoreCardOffice365Data,
  (loading, failed, itScoreCardOffice365) => ({
    loading,
    failed,
    itScoreCardOffice365,
  })
);

export const selectInProgressFirewallRequestState = createSelector(
  selectInProgressFirewallRequestLoading,
  selectInProgressFirewallRequestFailed,
  selectInProgressFirewallRequestData,
  (loading, failed, inProgressFirewallRequest) => ({
    loading,
    failed,
    inProgressFirewallRequest,
  })
);
