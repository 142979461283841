import { createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { type Dispatch } from 'redux';
import { updateUrl as urlUpdateHandler } from './filterActionsUtils';

export type NonDisabledFilter = { checked: boolean; disabled: false };
export type DisabledFilter = { checked: boolean; disabled: true };

export type OSAllFilter = 'ALL';
export type OSMACFilter = 'MAC';
export type OSWindowsFilter = 'WINDOWS';

export type AllState = {
  os: OSAllFilter;
  status: {
    good: NonDisabledFilter;
    nonReporting: NonDisabledFilter;
    softwareChecks: {
      firewall: NonDisabledFilter;
      filevault: NonDisabledFilter;
      gatekeeper: NonDisabledFilter;
      bitlocker: NonDisabledFilter;
    };
  };
};

export type MacState = {
  os: OSMACFilter;
  status: {
    good: NonDisabledFilter;
    nonReporting: NonDisabledFilter;
    softwareChecks: {
      firewall: NonDisabledFilter;
      filevault: NonDisabledFilter;
      gatekeeper: NonDisabledFilter;
      bitlocker: DisabledFilter;
    };
  };
};

export type WindowsState = {
  os: OSWindowsFilter;
  status: {
    good: NonDisabledFilter;
    nonReporting: NonDisabledFilter;
    softwareChecks: {
      firewall: NonDisabledFilter;
      filevault: DisabledFilter;
      gatekeeper: DisabledFilter;
      bitlocker: NonDisabledFilter;
    };
  };
};

export type FilterState = AllState | WindowsState | MacState;

const initialSoftwareChecks = {
  firewall: {
    checked: false,
    disabled: false as false,
  },
  filevault: {
    checked: false,
    disabled: false as false,
  },
  gatekeeper: {
    checked: false,
    disabled: false as false,
  },
  bitlocker: {
    checked: false,
    disabled: false as false,
  },
};

const initialState: FilterState = {
  os: 'ALL' as OSAllFilter,
  status: {
    good: {
      checked: false,
      disabled: false as false,
    },
    nonReporting: {
      checked: false,
      disabled: false as false,
    },
    softwareChecks: initialSoftwareChecks,
  },
};

const MAC_CHECKS = ['firewall', 'gatekeeper', 'filevault'];
const WINDOWS_CHECKS = ['firewall', 'bitlocker'];

function prepare(payload?: any, { updateUrl } = { updateUrl: true }) {
  return {
    payload,
    error: null,
    meta: {
      updateUrl: updateUrl && urlUpdateHandler,
    },
  };
}

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    toggleGoodFilter: {
      reducer(state: FilterState) {
        state.status.good = {
          checked: !state.status.good.checked,
          disabled: false,
        };
      },
      prepare,
    },
    toggleAtRiskFilter: {
      reducer(state: FilterState) {
        const enabledSubOptions = Object.values(
          state.status.softwareChecks
        ).filter(check => !check.disabled);

        const newSoftwareChecks = {} as typeof state.status.softwareChecks;

        let softwareCheck: keyof typeof newSoftwareChecks;
        for (softwareCheck in state.status.softwareChecks) {
          if (
            state.status.softwareChecks[softwareCheck].disabled &&
            softwareCheck !== 'firewall'
          ) {
            newSoftwareChecks[softwareCheck] = {
              checked: state.status.softwareChecks[softwareCheck].checked,
              disabled: true,
            };
          } else {
            newSoftwareChecks[softwareCheck] = {
              checked: enabledSubOptions.every(option => !option.checked),
              disabled: false,
            };
          }
        }

        state.status.softwareChecks = newSoftwareChecks;
      },
      prepare,
    },
    toggleAllOSFilter: {
      reducer(state: FilterState) {
        state.os = 'ALL';
        state.status.softwareChecks = Object.entries(
          state.status.softwareChecks
        ).reduce(
          (enabledChecks, [check, values]) => ({
            ...enabledChecks,
            [check]: { ...values, disabled: false },
          }),
          {} as typeof initialSoftwareChecks
        );
      },
      prepare,
    },
    toggleMacOSFilter: {
      reducer(state: FilterState) {
        state.os = 'MAC';
        state.status.softwareChecks = Object.entries(
          state.status.softwareChecks
        ).reduce(
          (enabledChecks, [check, values]) => ({
            ...enabledChecks,
            [check]: { ...values, disabled: !MAC_CHECKS.includes(check) },
          }),
          {} as typeof initialSoftwareChecks
        );
      },
      prepare,
    },
    toggleWindowsOSFilter: {
      reducer(state: FilterState) {
        state.os = 'WINDOWS';
        state.status.softwareChecks = Object.entries(
          state.status.softwareChecks
        ).reduce(
          (enabledChecks, [check, values]) => ({
            ...enabledChecks,
            [check]: { ...values, disabled: !WINDOWS_CHECKS.includes(check) },
          }),
          {} as typeof initialSoftwareChecks
        );
      },
      prepare,
    },
    toggleFirewallFilter: {
      reducer(state: FilterState) {
        state.status.softwareChecks.firewall = {
          checked: !state.status.softwareChecks.firewall.checked,
          disabled: false,
        };
      },
      prepare,
    },
    toggleFilevaultFilter: {
      reducer(state: FilterState) {
        state.status.softwareChecks.filevault = {
          checked: !state.status.softwareChecks.filevault.checked,
          disabled: false,
        };
      },
      prepare,
    },
    toggleGatekeeperFilter: {
      reducer(state: FilterState) {
        state.status.softwareChecks.gatekeeper = {
          checked: !state.status.softwareChecks.gatekeeper.checked,
          disabled: false,
        };
      },
      prepare,
    },
    toggleBitlockerFilter: {
      reducer(state: FilterState) {
        state.status.softwareChecks.bitlocker = {
          checked: !state.status.softwareChecks.bitlocker.checked,
          disabled: false,
        };
      },
      prepare,
    },
    toggleNonReportingFilter: {
      reducer(state: FilterState) {
        state.status.nonReporting = {
          checked: !state.status.nonReporting.checked,
          disabled: false,
        };
      },
      prepare,
    },
    clearAllFilters: {
      reducer() {
        return initialState;
      },
      prepare,
    },
  },
});

export const loadFiltersFromURL = () => (dispatch: Dispatch) => {
  const parsedHash = queryString.parse(window.location.hash, {
    arrayFormat: 'comma',
  });
  const statusFilters =
    typeof parsedHash?.statusFilters === 'string'
      ? parsedHash.statusFilters.split(',')
      : parsedHash?.statusFilters;
  const { osFilter } = parsedHash;

  const noOpActionArgs = { updateUrl: false };

  // First clear all features.
  dispatch(clearAllFilters(noOpActionArgs));

  if (osFilter === 'windows') dispatch(toggleWindowsOSFilter(noOpActionArgs));
  if (osFilter === 'mac') dispatch(toggleMacOSFilter(noOpActionArgs));

  if (statusFilters) {
    if (statusFilters.includes('good'))
      dispatch(toggleGoodFilter(noOpActionArgs));
    if (statusFilters.includes('firewall'))
      dispatch(toggleFirewallFilter(noOpActionArgs));
    if (statusFilters.includes('filevault'))
      dispatch(toggleFilevaultFilter(noOpActionArgs));
    if (statusFilters.includes('bitlocker'))
      dispatch(toggleBitlockerFilter(noOpActionArgs));
    if (statusFilters.includes('gatekeeper'))
      dispatch(toggleGatekeeperFilter(noOpActionArgs));
    if (statusFilters.includes('non-reporting'))
      dispatch(toggleNonReportingFilter(noOpActionArgs));
  }
};

export const {
  toggleGoodFilter,
  toggleAllOSFilter,
  toggleAtRiskFilter,
  toggleBitlockerFilter,
  toggleFilevaultFilter,
  toggleFirewallFilter,
  toggleGatekeeperFilter,
  toggleMacOSFilter,
  toggleWindowsOSFilter,
  clearAllFilters,
  toggleNonReportingFilter,
} = filtersSlice.actions;

export default filtersSlice.reducer;
