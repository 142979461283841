import countBy from 'lodash/countBy';
import { createSelector } from 'reselect';
import { type ValueOf } from 'type-fest';
import { Roles, ROLES_QUOTA } from '@turbine/constants/roles';
import { type RootState } from '../store';
import { UpdateEmployeeErrors } from '../userRoles/getUpdateEmployeeErrorType';
import { customerPlanSelector } from './customerPlanSelector';

export const usersSelector = createSelector(
  [(state: RootState) => state.users],
  ({
    users = [],
    fetching,
    emailFilter: { filteredUsers = [] },
    nonTurbineUsers = {
      fetching: false,
      failed: false,
      list: [],
    },
  }) => ({
    fetching,
    filteredUsers,
    users,
    nonTurbineUsers,
  })
);

export const selectUsers = (state: RootState) => state.users?.users;
export const selectUpdateUserSelector = (state: RootState) =>
  state.users.updateUserRoles;

export const selectAllUserRolesCountByName = createSelector(
  [selectUsers],
  users => {
    const allUsersRoles = users?.reduce(
      // TODO: https://electricops.atlassian.net/browse/IA1-3058
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (acc: any, { roles }: any) => acc.concat(roles),
      []
    );
    const rolesCountByName = countBy(allUsersRoles, 'name');
    return rolesCountByName;
  }
);

export const selectAdminsQuotaReachedError = createSelector(
  [selectAllUserRolesCountByName, customerPlanSelector],
  (rolesCountByName, customerPlan) => {
    const numberOfAdmins = rolesCountByName[Roles.Admin];
    const planQuota = ROLES_QUOTA[customerPlan];

    const isQuotaExceeded = numberOfAdmins >= planQuota?.Admin;

    if (isQuotaExceeded) {
      return new Error(UpdateEmployeeErrors.BasicCustomerAdminsQuotaReached);
    }

    return undefined;
  }
);

export const selectNumberOfSuperAdmins = createSelector(
  [selectAllUserRolesCountByName],
  rolesCountByName => {
    const numberOfSuperAdmins = rolesCountByName[Roles.SuperAdmin] || 0;
    return numberOfSuperAdmins;
  }
);

const getRoleName = (_: RootState, roleName: ValueOf<Roles>) => roleName;

export const selectIsRoleQuotaReached = createSelector(
  [selectAllUserRolesCountByName, customerPlanSelector, getRoleName],
  (rolesCountByName, customerPlan: string, role) => {
    const planQuota = ROLES_QUOTA[customerPlan];
    return (
      rolesCountByName?.[role] >= planQuota?.[role as keyof typeof planQuota]
    );
  }
);
