import has from 'lodash/has';

export enum UpdateEmployeeErrors {
  SuperAdminRequired = 'SUPER_ADMIN_REQUIRED',
  BasicCustomerAdminsQuotaReached = 'BASIC_ADMIN_QUOTA_REACHED',
}

function isError(error: unknown): error is Error {
  return has(error, 'message');
}

export function isSuperAdminRequiredError(
  error: unknown
): error is Error & boolean {
  return (
    isError(error) &&
    error.message.includes(UpdateEmployeeErrors.SuperAdminRequired)
  );
}

export function isBasicCustomerAdminsQuotaReachedError(
  error: unknown
): error is Error & boolean {
  return (
    isError(error) &&
    error.message.includes(UpdateEmployeeErrors.BasicCustomerAdminsQuotaReached)
  );
}
