import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type SoftwareApplication } from '@turbine/graphql/queries/selectApplicationsQueries';
import {
  applicationsSelector,
  customerApplicationsSelector,
} from '@turbine/graphql/selectors/customerApplicationsSelector';
import {
  decorateCSAValues,
  mergeSoftwares,
} from '@turbine/lib/customerApplications';
import { type BoardingCustomerSoftware } from '@turbine/types/BoardingCustomerSoftware';
import { type RequestState } from '@turbine/types/RequestState';
import { fetchAllCustomerSoftwares } from './customerSoftwaresThunks';

interface InitialState {
  status: RequestState;
  hasPreviousData: boolean;
  customerSoftwareApplications: BoardingCustomerSoftware[];
  software: SoftwareApplication[];
}

const initialState: InitialState = {
  status: 'initial',
  hasPreviousData: false,
  customerSoftwareApplications: [],
  software: [],
};

const customerSoftwares = createSlice({
  name: 'customerSoftwares',
  initialState,
  reducers: {
    setCustomerSoftwares(
      state,
      action: PayloadAction<Record<string, unknown[]>[]>
    ) {
      const software: SoftwareApplication[] = mergeSoftwares(
        state.software,
        action.payload.map(
          ({ software_application }) => software_application?.[0]
        )
      );
      const softwareDict = software.reduce<Record<string, SoftwareApplication>>(
        (dict, app) => {
          dict[app.id] = app;
          return dict;
        },
        {}
      );

      const customerSoftwareApplications: BoardingCustomerSoftware[] =
        decorateCSAValues(action.payload, softwareDict);

      return {
        ...state,
        customerSoftwareApplications,
        software,
      };
    },
    setSoftware(state, action) {
      state.software = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllCustomerSoftwares.pending, state => {
        state.status = 'pending';
      })
      .addCase(fetchAllCustomerSoftwares.rejected, state => {
        state.status = 'rejected';
      })
      .addCase(fetchAllCustomerSoftwares.fulfilled, (state, action) => {
        const customerSoftwares = customerApplicationsSelector(action.payload);
        const software = applicationsSelector(action.payload);

        const allSoftwares = mergeSoftwares(
          state.software,
          customerSoftwares.map(
            ({ software_application }) => software_application?.[0]
          )
        );
        const softwareDict = allSoftwares.reduce((dict, app) => {
          dict[app.id] = app;
          return dict;
        }, {});

        state.customerSoftwareApplications = decorateCSAValues(
          customerSoftwares,
          softwareDict
        );
        state.software = software;
        state.status = 'fulfilled';
        state.hasPreviousData = true;
      });
  },
});

export default customerSoftwares.reducer;
export const { setSoftware, setCustomerSoftwares } = customerSoftwares.actions;
