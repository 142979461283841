import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ENROLLED_DEVICES_FOR_CUSTOMER } from '@turbine/graphql/queries/deviceQueries';
import {
  ASSIGN_EMPLOYEE_TO_DEVICE,
  UNASSIGN_EMPLOYEE_FROM_DEVICE,
} from '@turbine/graphql/mutations/employeeMutations';
import {
  CREATE_WINDOWS_DEVICE_LOCK_ACTION,
  CREATE_DARWIN_DEVICE_LOCK_ACTION,
  CREATE_WINDOWS_DEVICE_UNLOCK_ACTION,
} from '@turbine/graphql/mutations/deviceMutations';
import {
  type ApolloClient,
  type NormalizedCacheObject,
  type QueryOptions,
} from '@apollo/client';
import {
  type AppThunkConfig,
  type AppThunkConfigExtra,
} from '@turbine/redux/store';
import { type Device } from '@turbine/types/DeviceManagement';
import {
  assignLocalDevice,
  hideToast,
  unAssignLocalDevice,
  setLocalDevicePendingUnlockStatus,
  setLocalDevicePendingLockStatus,
} from '.';

export type FetchDevicesByCustomerIDParams = {
  customerID: string;
};

export type AssignEmployeeToDeviceParams = {
  customerID: string;
  deviceID: string | null | undefined;
  employeeID?: string;
  userID: string;
  refetchOnSuccess?: boolean;
  ownerName?: string | null;
};

enum LockOption {
  Windows = 'Windows',
  Darwin = 'Darwin',
  WindowsUnlock = 'WindowsUnlock',
}

type DeviceLockParams = {
  customerID: string;
  deviceID: string | null;
  userID: string;
  ownerName?: string | null;
};

type LockingDeviceParams = {
  action: LockOption;
  client: () => ApolloClient<NormalizedCacheObject>;
  rejectWithValue: (value: unknown) => any;
} & DeviceLockParams;

enum AssignmentOption {
  Assign = 'Assign',
  UnAssign = 'UnAssign',
}

enum AssignmentFailure {
  Assign = 'Failed to assign employee to device.',
  UnAssign = 'Failed to unassign employee from device.',
}

type EmployeeAssignmentParams = {
  action: AssignmentOption;
  client: () => ApolloClient<NormalizedCacheObject>;
  rejectWithValue: (value: unknown) => any;
} & AssignEmployeeToDeviceParams;

const getErrorMessage = (assignmentOption: AssignmentOption) =>
  assignmentOption === AssignmentOption.Assign
    ? AssignmentFailure.Assign
    : AssignmentFailure.UnAssign;

const getLockAction = (action: LockOption) => {
  switch (action) {
    case LockOption.Windows:
      return CREATE_WINDOWS_DEVICE_LOCK_ACTION;
    case LockOption.Darwin:
      return CREATE_DARWIN_DEVICE_LOCK_ACTION;
    case LockOption.WindowsUnlock:
      return CREATE_WINDOWS_DEVICE_UNLOCK_ACTION;
  }
};

const removeToastFromState = (dispatch: (actionCreator: any) => void) => {
  setTimeout(() => dispatch(hideToast()), 6000);
};

const deviceLocking = async ({
  client,
  action,
  customerID,
  deviceID,
  userID,
  rejectWithValue,
}: LockingDeviceParams) => {
  const mutation = getLockAction(action);

  const lockDeviceMutation = {
    mutation,
    variables: {
      customerId: customerID,
      deviceId: deviceID,
      requestedBy: userID,
    },
  };
  try {
    const actionResponseDictionary = {
      [LockOption.Windows]: 'createWindowsDeviceLockAction',
      [LockOption.Darwin]: 'createDarwinDeviceLockAction',
      [LockOption.WindowsUnlock]: 'createWindowsDeviceUnlockAction',
    };
    return await client()
      .mutate(lockDeviceMutation)
      .then(
        res =>
          res.data[actionResponseDictionary[action]].ok === false &&
          rejectWithValue('error')
      );
  } catch (error) {
    window?.DD_RUM?.addError(error);
    return rejectWithValue('lock rejection here'); //TODO figure out if we need this
  }
};

const employeeAssignment = async ({
  customerID,
  employeeID,
  deviceID,
  userID,
  client,
  action,
  rejectWithValue,
}: EmployeeAssignmentParams) => {
  const mutation =
    action === AssignmentOption.Assign
      ? ASSIGN_EMPLOYEE_TO_DEVICE
      : UNASSIGN_EMPLOYEE_FROM_DEVICE;

  const assignEmployeeToDeviceMutation = {
    mutation,
    variables: {
      customerId: customerID,
      deviceId: deviceID,
      assignedBy: userID,
      assignedTo: employeeID,
    },
  };
  try {
    return await client().mutate(assignEmployeeToDeviceMutation);
  } catch (error) {
    window?.DD_RUM?.addError(error);
    return rejectWithValue(getErrorMessage(action));
  }
};

const getDeviceLockingThunk =
  (action: LockOption) =>
  async (params: DeviceLockParams, thunkConfig: any) => {
    const { extra, dispatch, rejectWithValue } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const result = await deviceLocking({
      ...params,
      action,
      client,
      rejectWithValue,
    });
    if (result.payload !== 'error') {
      if (action === LockOption.WindowsUnlock) {
        dispatch(setLocalDevicePendingUnlockStatus(params.deviceID));
      } else {
        dispatch(setLocalDevicePendingLockStatus(params.deviceID));
      }
    }
    removeToastFromState(dispatch);
    return result;
  };

const getAssignmentThunk =
  (action: AssignmentOption) =>
  async (params: AssignEmployeeToDeviceParams, thunkConfig: any) => {
    const { customerID, refetchOnSuccess = false } = params;
    const { extra, dispatch, rejectWithValue } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const result = await employeeAssignment({
      ...params,
      action,
      client,
      rejectWithValue,
    });
    // Refetch devices if needed
    if (refetchOnSuccess) {
      dispatch(fetchDevicesByCustomerID({ customerID }));
      removeToastFromState(dispatch);
      return result;
    }
    const { deviceID, employeeID } = params;
    if (action === AssignmentOption.Assign) {
      dispatch(
        assignLocalDevice({ deviceID, employeeID: employeeID as string })
      );
    } else {
      dispatch(unAssignLocalDevice({ deviceID }));
    }
    removeToastFromState(dispatch);
    return result;
  };

export const fetchDevicesByCustomerID = createAsyncThunk<
  Device[],
  FetchDevicesByCustomerIDParams,
  AppThunkConfig
>('devices/fetchDevicesByCustomerID', async ({ customerID }, thunkConfig) => {
  const { extra, rejectWithValue } = thunkConfig;
  const { client } = extra;
  const devicesQuery: QueryOptions<{ customerId: string }> = {
    query: GET_ENROLLED_DEVICES_FOR_CUSTOMER,
    variables: {
      customerId: customerID,
    },
    fetchPolicy: 'network-only',
  };

  try {
    const result = await client().query(devicesQuery);
    return result.data?.devices?.devices;
  } catch (error) {
    window?.DD_RUM?.addError(error);
    return rejectWithValue(null);
  }
});

export const assignEmployeeToDevice = createAsyncThunk(
  'enrolledDevices/assignEmployeeToDevice',
  getAssignmentThunk(AssignmentOption.Assign)
);

export const unAssignEmployeeFromDevice = createAsyncThunk(
  'enrolledDevices/unAssignEmployeeFromDevice',
  getAssignmentThunk(AssignmentOption.UnAssign)
);

export const lockWindowsDevice = createAsyncThunk(
  'enrolledDevices/lockWindowsDevice',
  getDeviceLockingThunk(LockOption.Windows)
);

export const lockMacDevice = createAsyncThunk(
  'enrolledDevices/lockMacDevice',
  getDeviceLockingThunk(LockOption.Darwin)
);

export const unlockWindowsDevice = createAsyncThunk(
  'enrolledDevices/unlockWindowsDevice',
  getDeviceLockingThunk(LockOption.WindowsUnlock)
);
