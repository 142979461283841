import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type HardwareInfo } from '@turbine/graphql/types/boardingTypes';
import {
  type ReplaceDatesWithISO,
  replaceDatesWithISO,
} from '@turbine/helpers/replaceDatesWithISO';
import {
  OffboardingSchedulingType,
  type YesNoOptions,
} from '@turbine/lib/xboarding/constants';
import { parseEmployeeInfoOffBoardingData } from './parseOffboardingForEdit';
import { type SelectOption } from '@turbine/types/SelectOption';
import { type Device } from './taskListSlice';
import { fetchOffboardingForEdit } from './editNewOffboardingActions';
import { type IdentityProviderUsers } from '@turbine/graphql/queries/identityProvidersUsers';
import { resetNewOffboarding } from './newOffboardingThunks';
import { getNextAvailableOffboardTime } from './helpers/getNextAvailableOffboardTime';

export type ScheduleFormValues = {
  type: string;
  date?: Date;
  time?: SelectOption<Date>;
  timeZone?: SelectOption<string>;
};

export type EmployeeInformationFormValues = {
  selectedEmployee?: IdentityProviderUsers;
  firstName: string;
  lastName: string;
  scheduledAt?: string;
  employeeEmail: string;
  managerEmail: string;
  department?: SelectOption<string>;
  alertBeforeOffboarding: {
    selected: boolean;
    email?: string | undefined;
  };
  notifyWhenOffboardingComplete: {
    selected: boolean;
    email?: string | undefined;
  };
  scheduling: ScheduleFormValues | undefined;
  deprovisionDevice: {
    selected: YesNoOptions | undefined;
    name?: string;
    serialNumber?: string;
    devices?: Device[];
  };
  hardware?: {
    selectedHardware: HardwareInfo[];
  };
};

type Schedule = {
  type: string;
  date?: string;
  time?: SelectOption<string>;
  timeZone?: { label: string; value?: string };
};

export type EmployeeInformation = {
  selectedEmployee?: IdentityProviderUsers;
  firstName: string;
  lastName: string;
  scheduledAt?: string;
  employeeEmail: string;
  managerEmail: string;
  department?: SelectOption<string>;
  alertBeforeOffboarding: {
    selected: boolean;
    email?: string | undefined;
  };
  notifyWhenOffboardingComplete: {
    selected: boolean;
    email?: string | undefined;
  };
  scheduling: Schedule | undefined;
  deprovisionDevice: {
    selected: YesNoOptions | undefined;
    name?: string;
    serialNumber?: string;
    devices?: Device[];
  };
};

const initialState: EmployeeInformation = {
  selectedEmployee: undefined,
  firstName: '',
  lastName: '',
  scheduledAt: undefined,
  department: undefined,
  employeeEmail: '',
  managerEmail: '',
  alertBeforeOffboarding: {
    selected: false,
    email: undefined,
  },
  notifyWhenOffboardingComplete: {
    selected: false,
    email: undefined,
  },
  scheduling: {
    type: '',
    date: new Date().toISOString(),
  },
  deprovisionDevice: {
    selected: undefined,
    name: '',
    serialNumber: '',
  },
};

const employeeInformation = createSlice({
  name: 'employeeInformation',
  initialState,
  reducers: {
    employeeInformationUpdated: {
      prepare(payload: EmployeeInformationFormValues) {
        return { payload: replaceDatesWithISO(payload) };
      },
      reducer(
        state,
        action: PayloadAction<
          ReplaceDatesWithISO<EmployeeInformationFormValues>
        >
      ) {
        const schedulingType = action.payload.scheduling?.type;
        if (schedulingType === OffboardingSchedulingType.ASAP) {
          action.payload.scheduledAt =
            getNextAvailableOffboardTime().toISOString();
        }

        return (state = action.payload);
      },
    },
    resetEmployeeInformation: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(resetNewOffboarding, (_state, action) => ({
      ...initialState,
      ...action?.payload?.[employeeInformation.name],
    }));
    builder.addCase(fetchOffboardingForEdit.fulfilled, (state, action) => {
      if (action?.payload) {
        const payload = action?.payload;
        const employeeInformation = parseEmployeeInfoOffBoardingData(payload);
        return employeeInformation;
      }
      return initialState;
    });
  },
});

export const { employeeInformationUpdated, resetEmployeeInformation } =
  employeeInformation.actions;
export default employeeInformation.reducer;
