import { DangerModalComponent } from './DangerModal/DangerModalComponent';
import { SuccessModalComponent } from './SuccessModal/SuccessModalComponent';
import RevokeUserModalContainer from './RevokeUser/RevokeUserModalContainer';
import OffboardEmployeeModalContainer from './OffboardEmployee/OffboardEmployeeModalContainer';
import ConfirmModalContainer from './ConfirmModal/ConfirmModalContainer';

const MODAL_COMPONENTS = {
  CONFIRM: ConfirmModalContainer,
  DANGER: DangerModalComponent,
  OFFBOARD_EMPLOYEE: OffboardEmployeeModalContainer,
  REVOKE_USER: RevokeUserModalContainer,
  SUCCESS: SuccessModalComponent,
};

const InnerModal = ({ modalType, ...innerSidePaneProps }) => {
  if (!modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...innerSidePaneProps} />;
};

export default InnerModal;
