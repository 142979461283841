export const { NODE_ENV: nodeEnv } = process.env;

const envVarsLocation = nodeEnv === 'test' ? process.env : window._env_;
export const {
  GOOGLE_WORKSPACE_ADMIN_URL,
  MICROSOFT_365_ADMIN_URL,
  REACT_APP_API_APPLICATIONS_URL,
  REACT_APP_API_DOMAIN,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_CONNECTION,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_LOGOUT_URL: auth0LogoutUrl,
  REACT_APP_AUTH0_REDIRECT_URI,
  REACT_APP_AUTH0_RESPONSE_TYPE,
  REACT_APP_AUTH0_SCOPE,
  REACT_APP_BUGSNAG_API_KEY: bugsnagKey,
  REACT_APP_DD_APPLICATION_ID,
  REACT_APP_DD_CLIENT_ID,
  REACT_APP_END_USER_APPLICATION_URL,
  REACT_APP_END_USER_ROUTING,
  REACT_APP_INCLUDE_RELICX,
  REACT_APP_KUSTOMER_BRAND_ID,
  REACT_APP_LAUNCH_DARKLY_CLIENTSIDE_ID: launchDarklyClientSideID,
  REACT_APP_PROXY_FLARE_PUBLIC_URL,
  REACT_APP_PROXY_FLARE_URL,
  REACT_APP_RMM_URL,
  REACT_APP_TURBINE_USER_ROLE_ID: turbineUserRoleID,
  REACT_APP_VERSION: appVersion,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_ELECTRIC_API_URL,
} = envVarsLocation || {};

export const auth = {
  domain: REACT_APP_AUTH0_DOMAIN,
  clientID: REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: REACT_APP_AUTH0_REDIRECT_URI,
  audience: REACT_APP_AUTH0_AUDIENCE,
  responseType: REACT_APP_AUTH0_RESPONSE_TYPE,
  scope: REACT_APP_AUTH0_SCOPE,
  connection: REACT_APP_AUTH0_CONNECTION,
};
