import { gql } from '@apollo/client';

export const REQUEST_DEVICE_ASSIGNMENT = gql`
  mutation RequestDeviceAssignment(
    $customerId: String!
    $employeeIds: [String]!
  ) {
    requestDeviceAssignment(
      customer_id: $customerId
      employee_ids: $employeeIds
    ) {
      ok
    }
  }
`;

export const CREATE_WINDOWS_DEVICE_LOCK_ACTION = gql`
  mutation CreateWindowsDeviceLockAction(
    $customerId: String!
    $deviceId: String!
    $requestedBy: String!
  ) {
    createWindowsDeviceLockAction(
      customer_id: $customerId
      device_id: $deviceId
      requested_by: $requestedBy
    ) {
      ok
    }
  }
`;

export const CREATE_WINDOWS_DEVICE_UNLOCK_ACTION = gql`
  mutation CreateWindowsDeviceUnlockAction(
    $customerId: String!
    $deviceId: String!
    $requestedBy: String!
  ) {
    createWindowsDeviceUnlockAction(
      customer_id: $customerId
      device_id: $deviceId
      requested_by: $requestedBy
    ) {
      ok
    }
  }
`;

export const CREATE_DARWIN_DEVICE_LOCK_ACTION = gql`
  mutation CreateDarwinDeviceLockAction(
    $customerId: String!
    $deviceId: String!
    $requestedBy: String!
  ) {
    createDarwinDeviceLockAction(
      customer_id: $customerId
      device_id: $deviceId
      requested_by: $requestedBy
    ) {
      ok
    }
  }
`;

export const RESERVE_INVENTORY_DEVICE = gql`
  mutation ReserveInventoryDevice(
    $customerId: String!
    $assetId: String!
    $assignedTo: String!
    $electricOnboardingId: String
  ) {
    reserve_asset(
      customer_id: $customerId
      asset_id: $assetId
      assigned_to: $assignedTo
      electric_onboarding_id: $electricOnboardingId
    ) {
      asset {
        hardware
        status
        electric_asset_tag
        electric_onboarding_id
        condition
        secondary_condition
        accessories
        disk_space
        ram
        processor
        warranty_status
        location
        serial
        assigned_employee
        date_added
        electric_onboarding_id
      }
    }
  }
`;

export const RELEASE_INVENTORY_DEVICE = gql`
  mutation ReleaseInventoryDevice($customerId: String!, $assetId: String!) {
    release_asset(customer_id: $customerId, asset_id: $assetId) {
      asset {
        hardware
        status
        electric_asset_tag
        electric_onboarding_id
        condition
        secondary_condition
        accessories
        disk_space
        ram
        processor
        warranty_status
        location
        serial
        assigned_employee
        date_added
      }
    }
  }
`;
