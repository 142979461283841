import type * as React from 'react';
import { Pane, Spinner } from '@electricjs/arc';
import styled from 'styled-components';

type LoadingScreenProps = {
  testId?: string;
};

const LoadingScreenComponent: React.FC<LoadingScreenProps> = ({
  testId = 'loading-sreen',
}) => {
  return (
    <Pane withPadding pt="20%" horizontal="center" testId={testId}>
      <Spinner />
    </Pane>
  );
};

export const LoadingScreen = styled(LoadingScreenComponent)``;
