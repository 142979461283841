import { createAsyncThunk } from '@reduxjs/toolkit';
import { type AppThunkConfigExtra } from '@turbine/redux/store';
import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client';
import {
  FETCH_ALL_CUSTOMER_AND_SOFTWARE_APPLICATIONS,
  FETCH_CUSTOMER_APPLICATIONS_BY_STATE,
  GET_APP_ACCOUNT,
  type CustomerChatAccount,
} from '@turbine/graphql/queries/selectApplicationsQueries';
import {
  ADD_CUSTOMER_SOFTWARE_APPLICATION,
  UPDATE_CUSTOMER_SOFTWARE_APPLICATION,
} from '@turbine/graphql/mutations';
import {
  addApplicationLocally,
  removeApplicationLocally,
  type SoftwareApplication,
  type AddCustomerAppResponse,
  type UpdateCustomerAppResponse,
} from './applicationsSlice';

export type getAllSoftwareAppsParams = {
  customerID: string;
  states: string[];
};

export type getAppAccountParams = {
  customerID: string;
};

export type UpdateCustomerSoftwareAppsParams = {
  customerId: string;
  id: string;
  state: string;
  supported: boolean;
  requesterId: string;
  applicationAdminId: string;
  isPreApproved: boolean;
  refetchOnSuccess?: boolean;
};

export type AddCustomerSoftwareAppsParams = {
  softwareName: string;
  softwareUrl: string;
  softwareInternal: boolean;
} & UpdateCustomerSoftwareAppsParams;

type AddCustomerSoftwareAppParams = {
  client: () => ApolloClient<NormalizedCacheObject>;
  rejectWithValue: (value: unknown) => unknown;
} & AddCustomerSoftwareAppsParams;

type UpdateCustomerSoftwareAppParams = {
  client: () => ApolloClient<NormalizedCacheObject>;
  rejectWithValue: (value: unknown) => unknown;
} & UpdateCustomerSoftwareAppsParams;

const addCustomerSoftwareApp = async ({
  customerId,
  id,
  state,
  supported,
  requesterId,
  applicationAdminId,
  softwareName,
  softwareUrl,
  softwareInternal,
  isPreApproved,
  client,
  rejectWithValue,
}: AddCustomerSoftwareAppParams) => {
  const addCustomerSoftwareAppsMutation = {
    mutation: ADD_CUSTOMER_SOFTWARE_APPLICATION,
    variables: {
      customerId,
      id,
      state,
      supported,
      requesterId,
      applicationAdminId,
      softwareName,
      softwareUrl,
      softwareInternal,
      isPreApproved,
    },
  };
  try {
    return await client().mutate(addCustomerSoftwareAppsMutation);
  } catch (error) {
    window?.DD_RUM?.addError(error);
    return rejectWithValue('Failed to add customer software application');
  }
};

const updateCustomerSoftwareApp = async ({
  customerId,
  id,
  state,
  supported,
  requesterId,
  applicationAdminId,
  isPreApproved,
  client,
  rejectWithValue,
}: UpdateCustomerSoftwareAppParams) => {
  const updateCustomerSoftwareAppsMutation = {
    mutation: UPDATE_CUSTOMER_SOFTWARE_APPLICATION,
    variables: {
      customerId,
      id,
      state,
      supported,
      requesterId,
      applicationAdminId,
      isPreApproved,
    },
  };
  try {
    return await client().mutate(updateCustomerSoftwareAppsMutation);
  } catch (error) {
    window?.DD_RUM?.addError(error);
    return rejectWithValue('Failed to update customer software application');
  }
};

export const addCustomerSoftwareAppThunk = createAsyncThunk(
  'applications/addCustomerSoftwareApps',
  async (params: AddCustomerSoftwareAppParams, thunkConfig: any) => {
    const { customerId, refetchOnSuccess = false } = params;
    const { extra, dispatch, rejectWithValue, getState } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const states: string[] = ['requested', 'fulfilled']; // all getCustomerApps queries use these two values

    const result = await addCustomerSoftwareApp({
      ...params,
      client,
      rejectWithValue,
    }).then(res => {
      const app = (res as AddCustomerAppResponse).data
        .createCustomerSoftwareApplication.customer_software_applications[0];
      const softwareAppData = getState().applications.allAppsData.find(
        (applicationWithData: SoftwareApplication) =>
          applicationWithData.name === app.software_application[0].name
      );
      if (softwareAppData) app.software_application[0] = softwareAppData;

      dispatch(addApplicationLocally(app));
    });
    // Refetch customer software applications if needed
    refetchOnSuccess &&
      dispatch(getCustomerApps({ customerID: customerId, states }));
    return result;
  }
);

export const updateCustomerSoftwareAppThunk = createAsyncThunk(
  'applications/updateCustomerSoftwareApps',
  async (params: UpdateCustomerSoftwareAppParams, thunkConfig: any) => {
    const { customerId, refetchOnSuccess = false } = params;
    const { extra, dispatch, rejectWithValue } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const states: string[] = ['requested', 'fulfilled']; // all getCustomerApps queries use these two values

    const result = await updateCustomerSoftwareApp({
      ...params,
      client,
      rejectWithValue,
    }).then(res => {
      if (params.state === 'canceled') {
        const app = (res as UpdateCustomerAppResponse).data
          .updateCustomerSoftwareApplication.customer_software_applications[0];
        dispatch(removeApplicationLocally(app));
      }
    });
    // Refetch customer software applications if needed
    refetchOnSuccess &&
      dispatch(getCustomerApps({ customerID: customerId, states }));
    return result;
  }
);

export const getAllSoftwareApps = createAsyncThunk(
  'apps/getAllSoftwareApps',
  async ({ customerID, states }: getAllSoftwareAppsParams, thunkConfig) => {
    const { extra } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const allCustomerAndSoftwareAppsQuery = {
      query: FETCH_ALL_CUSTOMER_AND_SOFTWARE_APPLICATIONS,
      variables: {
        customerId: customerID,
        states,
      },
    };
    try {
      const result = await client().query(allCustomerAndSoftwareAppsQuery);
      return {
        allApps: result.data?.software_applications,
        customerSoftwareApps: result.data?.customer_software_applications,
      };
    } catch (error) {
      window?.DD_RUM?.addError(error);
    }
  }
);

export const getCustomerApps = createAsyncThunk(
  'apps/getCustomerApps',
  async ({ customerID, states }: getAllSoftwareAppsParams, thunkConfig) => {
    const { extra } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const customerAppsQuery = {
      query: FETCH_CUSTOMER_APPLICATIONS_BY_STATE,
      variables: {
        customerId: customerID,
        states,
      },
    };
    try {
      const result = await client().query(customerAppsQuery);
      return result.data?.customer_software_applications;
    } catch (error) {
      window?.DD_RUM?.addError(error);
    }
  }
);

export const getAppAccount = createAsyncThunk(
  'apps/getAppAccount',
  async ({ customerID }: getAppAccountParams, thunkConfig) => {
    const { extra } = thunkConfig;
    const { client } = extra as AppThunkConfigExtra;
    const appAccountQuery = {
      query: GET_APP_ACCOUNT,
      variables: {
        customerId: customerID,
      },
    };
    try {
      const result = await client().query(appAccountQuery);
      //If a customer has a chat application,
      //the is_chat_source field is set to PRIMARY
      //and the discarded_at field is not empty
      return (
        result.data?.customer_app_accounts.filter(
          (account: CustomerChatAccount) =>
            account.is_chat_source == 'PRIMARY' && !account.discarded_at
        ).length > 0
      );
    } catch (error) {
      window?.DD_RUM?.addError(error);
    }
  }
);
