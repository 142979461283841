// Search users by role
export const SEARCH_USERS_BY_ROLE_FETCHED = 'SEARCH_USERS_BY_ROLE_FETCHED';
export const SEARCH_USERS_BY_ROLE_FETCH_FAILED =
  'SEARCH_USERS_BY_ROLE_FETCH_FAILED';
export const SEARCH_USERS_BY_ROLE_FETCH_SUCCEEDED =
  'SEARCH_USERS_BY_ROLE_FETCH_SUCCEEDED';

// Search users by email
export const SEARCH_USERS_BY_EMAIL_FETCHED = 'SEARCH_USERS_BY_EMAIL_FETCHED';
export const SEARCH_USERS_BY_EMAIL_FETCH_FAILED =
  'SEARCH_USERS_BY_EMAIL_FETCH_FAILED';
export const SEARCH_USERS_BY_EMAIL_FETCH_SUCCEEDED =
  'SEARCH_USERS_BY_EMAIL_FETCH_SUCCEEDED';
export const RESET_SEARCH_USERS_BY_EMAIL = 'RESET_SEARCH_USERS_BY_EMAIL';

// Selected role ID set in store
export const SELECTED_ROLE_ID_SET = 'SELECTED_ROLE_ID_SET';
