import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  type FetchOffboarding,
  FETCH_OFFBOARDING,
} from '@turbine/graphql/queries/offboardingQueries';
import { type AppThunkConfig } from '@turbine/redux/store';
import { type OffboardingDataProps } from '@turbine/graphql/types/boardingTypes';

export type FetchOffboardingParams = {
  customerID: string;
  offboardingId: string;
};
export const fetchOffboardingForEdit = createAsyncThunk<
  OffboardingDataProps,
  FetchOffboardingParams,
  AppThunkConfig
>(
  'newOffboarding/fetchOffboardingForEdit',
  async ({ customerID, offboardingId }, { extra: { client } }) => {
    try {
      const response = await client().query<FetchOffboarding>({
        query: FETCH_OFFBOARDING,
        variables: {
          customerId: customerID,
          id: offboardingId,
        },
      });

      return response.data.offboarding;
    } catch (err) {
      window?.DD_RUM?.addError(err);
      throw err;
    }
  }
);
