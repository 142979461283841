import { createSelector } from 'reselect';
import { get, castArray } from 'lodash';
import { getOr } from 'lodash/fp';
import createDeepEqualSelector from './createDeepEqualSelector';
import {
  mapCalendarsData,
  mapCalendarsDataToIds,
} from '@turbine/helpers/hooks/applicationResources/helpers';

/**
 * Get the policy id from the store
 * @param {object} state - Parameter description.
 * @return {string|undefined} The policy id
 */
export const getPolicyId = state => get(state, 'policyId');

/**
 * Gets the current policy from the store.
 *
 * Takes the usual lodash `get` arguments and prefixes the path with the correct
 * path to the policy.
 *
 * @param {object} state The redux store state
 * @param {Array|string} [path] The path to get from the policy.
 * @param {*} [defaultValue] The value returned for `undefined` resolved values.
 * @return {*} Returns the policy or the resolved policy value at the given path.
 * @example
 *
 * getPolicy(state)
 * getPolicy(state, 'data')
 * getPolicy(state, 'permissions', {})
 */

export const getPolicy = (state, path = [], defaultValue) =>
  get(state, ['policy', getPolicyId(state), ...castArray(path)], defaultValue);

export const policyDataSelector = createSelector(
  state => state.policy,
  state => state.policyId,
  (policy, policyId) => getOr({}, [policyId, 'data'], policy)
);

export const policyDataDeepSelector = createDeepEqualSelector(
  state => state.policy,
  state => state.policyId,
  (policy, policyId) => {
    const data = getOr({}, [policyId, 'data'], policy);
    return {
      ...data,
      default: {
        ...data.default,
        calendars: mapCalendarsDataToIds(data.default?.calendarsData),
        calendarsData: mapCalendarsData(data.default?.calendarsData),
      },
      departments: data.departments?.map(
        ({ calendarsData, ...restCalendarProps }) => ({
          ...restCalendarProps,
          calendars: mapCalendarsDataToIds(calendarsData),
          calendarsData: mapCalendarsData(calendarsData),
        })
      ),
    };
  }
);

export const selectIsPolicyUpdating = createSelector(
  state => state.policy,
  state => state.policyId,
  (policy, policyId) => !!policy?.[policyId]?.pendingUpdate
);
