import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type BoardingNotification = {
  shouldShowNotification?: boolean;
  intent: string;
  message: string;
  title: string;
};
export type PeopleState = {
  notification: BoardingNotification;
};

const initialState: PeopleState = {
  notification: {
    shouldShowNotification: false,
    intent: '',
    message: '',
    title: '',
  },
};

const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    showBoardingNotification: (
      state,
      action: PayloadAction<BoardingNotification>
    ) => {
      state.notification = action?.payload;
      state.notification.shouldShowNotification = true;
    },
    hideBoardingNotification: state => {
      state.notification = initialState.notification;
    },
  },
});

export const { showBoardingNotification, hideBoardingNotification } =
  peopleSlice.actions;
export default peopleSlice.reducer;
