import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import omit from 'lodash/omit';
import { timezoneOptions } from '@turbine/constants/timezones';
import { type HardwareInfo } from '@turbine/graphql/types/boardingTypes';
import { CALENDAR_PERMISSIONS } from '@turbine/helpers/hooks/applicationResources/useGoogleWorkspaceAutomation';
import { tryJSONParse } from '@turbine/helpers/tryJSONParse';
import {
  COUNTRIES,
  REMOTE_ADDRESS_OPTION,
} from '@turbine/lib/addresses/constants';
import {
  YesNoOptions,
  HardwareProcurementType,
  ProvisioningLocationTypes,
  ELECTRIC_SHIPPING_ADDRESS,
  CREATE_NEW_EMAIL_OPTION,
} from '@turbine/lib/xboarding/constants';
import {
  getIsoDateFromDateAndTime,
  getSafeDate,
} from '@turbine/lib/xboarding/utils';
import { convertSnakeKeysToCamelCase } from '@turbine/helpers/convertSnakeKeysToCamelCase';
import {
  type Address,
  type EmployeeInformationFormValues,
  employeeInformationInitialState,
  type SaasApps,
  type Software,
  type WelcomeEmailFormValues,
} from '@turbine/redux/newOnboarding';
import {
  type AddressProps,
  type EmployeeInfoReturnValue,
  type TimeZoneOption,
  type WelcomeEmailProps,
} from './parseOnBoardingData';
import { compareStrings } from '@turbine/helpers/compareStrings';
import { getOptionProperty } from './getOptionProperty';
import { createEmail } from '@turbine/lib/xboarding/helpers';
import { type FormDraftData } from '../hooks/useSaveDraft';

const WELCOME_EMAIL_TIME_FORMAT = 'h:mm a';
const DEFAULT_ZIP_CODE = '00000';
const DEFAULT_STATE = '-';

/** Since `hasWelcomeEmail` value is not persisted, we check if any welcome email value exists to determine if the fields shold be displayed */
const getHasWelcomeEmail = (
  welcomeEmail: WelcomeEmailProps | undefined
): EmployeeInfoReturnValue['hasWelcomeEmail'] =>
  welcomeEmail?.email || welcomeEmail?.email_date || welcomeEmail?.timezone
    ? YesNoOptions.Yes
    : YesNoOptions.No;

const getSaasApps = (softwares: Software[]): SaasApps => {
  const saasApps = { status: 'initial', softwares: {} } as SaasApps;
  softwares?.forEach(software => {
    if (!software) return;
    saasApps.softwares[software.id] = convertSnakeKeysToCamelCase(software);
  });

  return saasApps;
};

type CalendarPermission = {
  id: string;
  name: string;
};

const getCalendarPermissions = (
  calendarPermissions: string
): CalendarPermission => {
  const calendar_permissions =
    CALENDAR_PERMISSIONS.find(
      permission => permission.name === calendarPermissions
    ) || CALENDAR_PERMISSIONS[0];

  return calendar_permissions;
};

const getWelcomeEmailTime = (welcomeEmail: WelcomeEmailProps): string => {
  const time = welcomeEmail?.email_date
    ? format(
        utcToZonedTime(welcomeEmail?.email_date, welcomeEmail?.timezone),
        WELCOME_EMAIL_TIME_FORMAT
      ) || ''
    : '';

  return time;
};

const getTimezone = (
  welcomeEmail: WelcomeEmailProps
): TimeZoneOption | undefined =>
  timezoneOptions.find(tz => tz.value === welcomeEmail?.timezone);

const getAddress = (
  address: string | undefined,
  remoteAddress: Address | undefined
): Address | undefined => {
  const addressValue = tryJSONParse(address)?.value;
  if (addressValue === REMOTE_ADDRESS_OPTION.value) {
    return remoteAddress;
  }

  return addressValue;
};

type Addresses = {
  address: string;
  remoteAddress: Address;
};

const getAddresses = (addressProps: AddressProps): Addresses => {
  const data = omit(addressProps, '__typename') || {};
  const {
    street_address_1: streetAddress1,
    street_address_2: streetAddress2,
    office_id: officeId,
    country,
    zip,
    state,
    city,
    name,
    phone,
  } = data;

  const defaultRemoteAddress = {
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    country: COUNTRIES.USA,
    officeId: '',
    name: '',
    phone: '',
  };

  const fullAddress = {
    streetAddress1,
    streetAddress2,
    officeId,
    zip,
    state,
    country,
    city,
    name,
    phone,
  };
  const isAddressEmpty = !Object.values(data).some(Boolean);

  const address = officeId
    ? JSON.stringify({ label: fullAddress?.name, value: fullAddress })
    : isAddressEmpty
    ? employeeInformationInitialState.address
    : REMOTE_ADDRESS_OPTION.radioValue;

  const remoteAddress = officeId
    ? defaultRemoteAddress
    : omit(fullAddress, 'officeId');

  return { address, remoteAddress };
};

type WelcomeEmail = {
  email: WelcomeEmailFormValues['email'];
  emailDate: WelcomeEmailFormValues['date'];
  timezone: string;
} | null;

const getWelcomeEmail = (
  welcomeEmail: WelcomeEmailFormValues | undefined,
  hasWelcomeEmail: EmployeeInformationFormValues['hasWelcomeEmail'] | undefined
): WelcomeEmail => {
  const hasWelcomeEmailData = hasWelcomeEmail === YesNoOptions.Yes;
  if (!hasWelcomeEmailData) return null;

  if (welcomeEmail?.date) {
    const welcomeEmailTime = parse(
      welcomeEmail?.time?.label || '',
      WELCOME_EMAIL_TIME_FORMAT,
      new Date()
    );
    const welcomeEmailDate = getIsoDateFromDateAndTime(
      getSafeDate(welcomeEmail?.date),
      welcomeEmailTime,
      welcomeEmail?.timeZone?.value
    );

    welcomeEmail = {
      ...welcomeEmail,
      date: welcomeEmailDate,
    };
  }

  return {
    email: welcomeEmail?.email,
    emailDate: welcomeEmail?.date,
    timezone: getOptionProperty(welcomeEmail?.timeZone),
  };
};

const getProfessionalEmail = (data: FormDraftData = {}): string => {
  const {
    professionalEmail,
    professionalEmailFormat,
    professionalEmailDomain,
  } = data;

  const professionalEmailValue = getOptionProperty(professionalEmail);

  if (CREATE_NEW_EMAIL_OPTION.isOption(professionalEmailValue)) {
    return createEmail(professionalEmailFormat, professionalEmailDomain?.value);
  }

  return professionalEmailValue;
};

const getIsRemote = (address: string): boolean => {
  const addressValue = tryJSONParse(address)?.value;
  return addressValue === REMOTE_ADDRESS_OPTION.value;
};

const getIsChecked = (radioOption: string, value: string): boolean => {
  if (!radioOption || !value) return false;

  const radioAddress = tryJSONParse(radioOption)?.value;
  const address = tryJSONParse(value)?.value;

  const locationOfficeId = radioAddress?.officeId;
  const optionOfficeId = address?.officeId;

  return (
    compareStrings(locationOfficeId, optionOfficeId) ||
    compareStrings(radioOption, value)
  );
};

const getISOStringFromTime = (time: string): string => {
  if (!time) return '';
  const date = parse(time, WELCOME_EMAIL_TIME_FORMAT, new Date());
  return isValid(date) ? date.toISOString() : time;
};

const checkShippingScenario = (
  deviceLocations: HardwareInfo[],
  provisionType: ProvisioningLocationTypes,
  finalDestion: string
): boolean => {
  if (finalDestion == ELECTRIC_SHIPPING_ADDRESS.name) return false;
  if (provisionType == ProvisioningLocationTypes.AtElectric) return true;
  const device = deviceLocations.find(
    location => location.procurementType == HardwareProcurementType.Serial
  );
  if (device) return true;
  return false;
};

const getTrayAppsIDs = (
  departmentName: string,
  trayIOSolutionsInstances: any,
  allSoftwareIds: string[]
): string[] => {
  const trayIOAppsIds: string[] = [];

  trayIOSolutionsInstances?.forEach((solutionInstance: any) => {
    // Get the list of departments that the solution instance is assigned to
    const departmentList = solutionInstance.departments?.map(
      (department: any) => department.toLowerCase()
    );

    // If the solution instance is for the selected department and is included
    // in the list of all software ids, add it to the list of trayIOAppsIds
    solutionInstance.tags?.forEach((tag: any) => {
      if (
        (departmentList.includes(departmentName.toLowerCase()) ||
          departmentList.includes('any')) &&
        allSoftwareIds.includes(tag)
      ) {
        trayIOAppsIds.push(tag);
      }
    });
  });

  return trayIOAppsIds;
};

export {
  checkShippingScenario,
  getAddress,
  getAddresses,
  getCalendarPermissions,
  getHasWelcomeEmail,
  getIsChecked,
  getIsRemote,
  getISOStringFromTime,
  getSaasApps,
  getTimezone,
  getWelcomeEmail,
  getWelcomeEmailTime,
  DEFAULT_ZIP_CODE,
  DEFAULT_STATE,
  getTrayAppsIDs,
  getProfessionalEmail,
};
