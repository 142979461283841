// TODO: https://electricops.atlassian.net/browse/IA1-3058
import {
  type ApolloClient,
  type MutationOptions,
  type NormalizedCacheObject,
} from '@apollo/client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Roles } from '@turbine/constants/roles';
import { UPDATE_EMPLOYEE_ROLES_V2 } from '@turbine/graphql/mutations';
import {
  type UpdateEmployeeRolesV2MutationParams,
  type UpdateEmployeeRolesV2Response,
} from '@turbine/graphql/mutations/employeeMutations';
import { updateObjectInArray } from '@turbine/helpers/arrayHelpers';
import {
  ONE_USER_UPDATED,
  USER_ROLES_UPDATE_RESET,
} from '@turbine/redux/userRoles/userRolesTypes';
import { type AsyncReturnType } from 'type-fest';
import {
  type AppDispatch,
  type AppThunkConfig,
  type RootState,
} from '../store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const oneUserUpdated = (users: any[], filteredUsers: any) => ({
  type: ONE_USER_UPDATED,
  payload: { users, filteredUsers },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateOneUser =
  (user: any) => (dispatch: AppDispatch, getState: () => RootState) => {
    const users = getState().users;
    const usersArr = users.users;
    const filteredUsersArr = users.emailFilter.filteredUsers;

    const newUsers = updateObjectInArray(usersArr, {
      item: user,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      index: usersArr.findIndex((obj: any) => obj.email === user.email),
    });
    const newFilteredUsers = updateObjectInArray(filteredUsersArr, {
      item: user,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      index: filteredUsersArr.findIndex((obj: any) => obj.email === user.email),
    });

    dispatch(oneUserUpdated(newUsers, newFilteredUsers));
  };

type UpdateUserRolesV2Params = UpdateEmployeeRolesV2MutationParams & {
  superAdminId?: string;
};

export const updateUserRolesV2API = async ({
  employeeId,
  roles,
  client,
  dispatch,
}: UpdateUserRolesV2Params & {
  client: () => ApolloClient<NormalizedCacheObject>;
  dispatch: AppDispatch;
}) => {
  const employeesMutation: MutationOptions<
    UpdateEmployeeRolesV2Response,
    UpdateEmployeeRolesV2MutationParams
  > = {
    mutation: UPDATE_EMPLOYEE_ROLES_V2,
    variables: {
      employeeId,
      roles,
    },
  };

  try {
    const employeeResult = await client().mutate(employeesMutation);
    const updatedEmployee = employeeResult?.data?.updateEmployeeV2?.result;

    dispatch(updateOneUser(updatedEmployee));

    return updatedEmployee;
  } catch (error) {
    window?.DD_RUM?.addError(error);
    throw error;
  }
};

export type UpdateUserRolesV2ThunkResponse = AsyncReturnType<
  typeof updateUserRolesV2API
>;

export const updateUserRolesV2 = createAsyncThunk<
  UpdateUserRolesV2ThunkResponse,
  UpdateUserRolesV2Params,
  AppThunkConfig
>(
  'users/updateUserRolesV2',
  async (params: UpdateUserRolesV2Params, { extra, dispatch, getState }) => {
    const { employeeId, roles } = params;

    const { client } = extra;

    // eslint-disable-next-line no-useless-catch
    try {
      const result = await updateUserRolesV2API({
        employeeId,
        roles,
        client,
        dispatch,
      });
      return result;
    } catch (error) {
      throw error;
    }
  }
);

export const userRolesUpdateReset = createAction(USER_ROLES_UPDATE_RESET);
