interface RelicxIdentify {
  userId: string;
  email: string;
}

const identifyRelicxUser = ({
  userId = '',
  email = '',
}: RelicxIdentify): void => {
  if (window.relicxSDK) {
    return window.relicxSDK.identify(userId, {
      email,
    });
  }
};

export default identifyRelicxUser;
