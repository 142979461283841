import { createSelector } from '@reduxjs/toolkit';
import { type Device } from '@turbine/types/DeviceManagement';
import { arrangeNonReportingDevices } from '@turbine/pages/DeviceManagement/DevicesTable/helpers';
import { extractDataFromAPIDevices } from '@turbine/helpers/itScoreCardV2';
import { type RootState } from '../../store';

export const selectDevicesLoading = (state: RootState): boolean =>
  state.enrolledDevices.loading;
export const selectDevicesFailed = (state: RootState): boolean =>
  state.enrolledDevices.failed;
export const selectDevicesData = (state: RootState) =>
  state.enrolledDevices.data;
export const selectSelectedDeviceID = (state: RootState): string | null =>
  state.enrolledDevices.selectedDeviceID;
export const selectUnAssignmentLoading = (state: RootState): boolean =>
  state.enrolledDevices.unAssignmentLoading;
export const selectUnAssignmentFailed = (state: RootState): boolean =>
  state.enrolledDevices.unAssignmentFailed;
export const selectAssignmentLoading = (state: RootState): boolean =>
  state.enrolledDevices.assignmentLoading;
export const selectAssignmentFailed = (state: RootState): boolean =>
  state.enrolledDevices.assignmentFailed;
export const selectLockWindowsLoading = (state: RootState): boolean =>
  state.enrolledDevices.lockWindowsLoading;
export const selectLockWindowsFailed = (state: RootState): boolean =>
  state.enrolledDevices.lockWindowsFailed;
export const selectLockMacLoading = (state: RootState): boolean =>
  state.enrolledDevices.lockMacLoading;
export const selectLockMacFailed = (state: RootState): boolean =>
  state.enrolledDevices.lockMacFailed;
export const selectUnlockWindowsLoading = (state: RootState): boolean =>
  state.enrolledDevices.unlockWindowsLoading;
export const selectUnlockWindowsFailed = (state: RootState): boolean =>
  state.enrolledDevices.unlockWindowsFailed;
export const selectEnrolledDevicesToast = (state: RootState) =>
  state.enrolledDevices.toast;

export const selectAffectedDevicesState = createSelector(
  selectDevicesLoading,
  selectDevicesFailed,
  selectDevicesData,
  (loading, failed, devices) => {
    const devicesData = extractDataFromAPIDevices(devices);
    let affectedDevices = null;
    if (devicesData !== null) {
      const { pc, mac } = devicesData;
      const totalDevices = pc.stats.total + mac.stats.total;
      const nonReportingPCs = devicesData.pc.stats.warning;
      const nonReportingMacs = devicesData.mac.stats.warning;
      const nonReporting = nonReportingMacs + nonReportingPCs;
      affectedDevices = {
        firewall:
          totalDevices - pc.stats.firewall - mac.stats.firewall - nonReporting,
        firewallMacs: mac.stats.total - mac.stats.firewall - nonReportingMacs,
        firewallPCs: pc.stats.total - pc.stats.firewall - nonReportingPCs,
        gatekeeper: mac.stats.total - mac.stats.gatekeeper - nonReportingMacs,
        encryption:
          totalDevices -
          pc.stats.bitlocker -
          mac.stats.filevault -
          nonReporting,
        encryptionMacs:
          mac.stats.total - mac.stats.filevault - nonReportingMacs,
        encryptionPCs: pc.stats.total - pc.stats.bitlocker - nonReportingPCs,
        os: totalDevices - pc.stats.os - mac.stats.os - nonReporting,
        osMacs: mac.stats.total - mac.stats.os - nonReportingMacs,
        osPCs: pc.stats.total - pc.stats.os - nonReportingPCs,
        nonReporting,
        nonReportingMacs,
        nonReportingPCs,
        ram: totalDevices - pc.stats.ram - mac.stats.ram - nonReporting,
        ramMacs: mac.stats.total - mac.stats.ram - nonReportingMacs,
        ramPCs: pc.stats.total - pc.stats.ram - nonReportingPCs,
        space: totalDevices - pc.stats.disk - mac.stats.disk - nonReporting,
        spaceMacs: mac.stats.total - mac.stats.disk - nonReportingMacs,
        spacePCs: pc.stats.total - pc.stats.disk - nonReportingPCs,
        pcDevices: pc.stats.total,
        macDevices: mac.stats.total,
      };
    }

    return {
      loading,
      failed,
      devices: affectedDevices,
    };
  }
);

// This is a helper selector that allows you to grab related state together.
// It's composed of primitive selectors so is memoized affectively.
export const selectDevicesState = createSelector(
  selectDevicesLoading,
  selectDevicesFailed,
  selectDevicesData,
  (loading, failed, devices) => ({
    loading,
    failed,
    devices,
  })
);

export const selectAssignmentState = createSelector(
  selectAssignmentFailed,
  selectAssignmentLoading,
  (assignmentFailed, assignmentLoading) => ({
    assignmentFailed,
    assignmentLoading,
  })
);

export const selectUnAssignmentState = createSelector(
  selectUnAssignmentFailed,
  selectUnAssignmentLoading,
  (unAssignmentFailed, unAssignmentLoading) => ({
    unAssignmentFailed,
    unAssignmentLoading,
  })
);

export const selectSelectedDevice = createSelector(
  selectDevicesData,
  selectSelectedDeviceID,
  (devices, selectedDeviceID) => {
    if (!devices || !selectedDeviceID) {
      return null;
    }
    return devices.find((device: Device) => {
      return device.id === selectedDeviceID;
    });
  }
);
export const selectDevicesTotal = createSelector(
  selectDevicesData,
  devices => devices?.length
);

export const selectDevicesDataArrangedByReportingStatus = createSelector(
  selectDevicesData,
  devices => (devices ? arrangeNonReportingDevices(devices) : null)
);

export const selectDevicesDictionary = createSelector(
  selectDevicesData,
  (devices: Device[] | null): Record<string, Device> | null => {
    if (devices === null) {
      return null;
    }
    return devices.reduce(
      (acc: Record<string, RootState>, device: any) => ({
        ...acc,
        [device.assigned_to]: device,
      }),
      {}
    );
  }
);

export const selectMultipleDevicesDictionary = createSelector(
  selectDevicesData,
  (devices: Device[] | null): Record<string, Device[]> | null => {
    if (devices === null) {
      return null;
    }
    return devices.reduce((acc: Record<string, Device[]>, device: any) => {
      if (acc[device.assigned_to]) {
        return {
          ...acc,
          [device.assigned_to]: [...acc[device.assigned_to], device],
        };
      } else {
        return {
          ...acc,
          [device.assigned_to]: [device],
        };
      }
    }, {});
  }
);

export const selectUnassignedDevices = createSelector(
  selectDevicesData,
  (devices: Device[] | null): Device[] | null => {
    if (devices === null) {
      return null;
    }
    return devices.filter(device => !device.assigned_to);
  }
);
