import * as types from '../types/customerEnrollmentTypes';

const initialState = {
  loaded: false,
};

export function customerEnrollment(state = initialState, action) {
  switch (action.type) {
    case types.SET_CUSTOMER_ENROLLMENT:
      return {
        loaded: true,
        customerEnrollmentCompleted: action.payload,
      };
    default:
      return state;
  }
}
