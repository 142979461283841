// Onboarder and offboarder are handled by only Onboarder role and permissions (named Onoffboarder internally for clarity), combining their values in the backend but still keeping it separated for future-proof concerns
import { PLANS } from './customerPlans';

export enum ElevatedRoles {
  SuperAdmin = 'Owner',
  OnOffboarder = 'Onboarder',
  Reporter = 'Reporter',
  Admin = 'Admin',
  ElectricAdmin = 'ELECTRIC_ADMIN',
}

export type ElevatedRoleLabels = Readonly<Record<string, string | undefined>>;

export const ELEVATED_ROLE_LABELS: ElevatedRoleLabels = {
  [ElevatedRoles.SuperAdmin]: 'Super admin',
  [ElevatedRoles.OnOffboarder]: 'On/Offboarder',
  [ElevatedRoles.Reporter]: 'Reporter',
  [ElevatedRoles.Admin]: 'Admin',
  [ElevatedRoles.ElectricAdmin]: 'Electric Admin',
};

export enum EndUserRoles {
  HubUser = 'Turbine User',
}

type EndUserRoleLabels = Readonly<Record<string, string | undefined>>;

export const END_USER_ROLE_LABELS: EndUserRoleLabels = {
  [EndUserRoles.HubUser]: 'Hub User',
};

export enum ElectricRoles {
  PrimaryApprover = 'Primary Approver',
  Approver = 'Approver',
  VIP = 'VIP',
}

type ElectricRoleLabels = Readonly<Record<string, string | undefined>>;

export const ELECTRIC_ROLE_LABELS: ElectricRoleLabels = {
  [ElectricRoles.PrimaryApprover]: 'Primary Approver',
  [ElectricRoles.Approver]: 'Approver',
  [ElectricRoles.VIP]: 'VIP',
};

export const Roles = {
  ...ElevatedRoles,
  ...EndUserRoles,
} as const;

type RoleLabels = Readonly<Record<string, string | undefined>>;

export const ROLE_LABELS: RoleLabels = {
  ...ELEVATED_ROLE_LABELS,
  ...END_USER_ROLE_LABELS,
} as const;

export const ALL_ROLES_LABELS: RoleLabels = {
  ...ROLE_LABELS,
  ...ELECTRIC_ROLE_LABELS,
};

export type Roles = typeof Roles;

// This map is the same as the one above, just flipped so we can
// use it to lookup a role by name
type RolesMap<T> = Record<string, keyof T | undefined>;

export const ELEVATED_ROLES_MAP: RolesMap<typeof ElevatedRoles> =
  Object.entries(ElevatedRoles).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [value]: key,
    }),
    {}
  );

export const ELECTRIC_ROLES_MAP: RolesMap<typeof ElectricRoles> =
  Object.entries(ElectricRoles).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [value]: key,
    }),
    {}
  );

export const ROLES_MAP: RolesMap<typeof Roles> = Object.entries(Roles).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key,
  }),
  {}
);

export const ROLES_QUOTA = {
  [PLANS.BASIC]: { [Roles.SuperAdmin]: 1, [Roles.Admin]: 4 },
} as const;
