import PropTypes from 'prop-types';
import { bemify } from '@turbine/helpers/bemClassHelper';

const modalHeaderClasses = bemify('modal-header');

const ModalHeader = ({
  CloseButton,
  closeButtonProps,
  modifierClass,
  title,
}) => (
  <div className={modalHeaderClasses('header', modifierClass)}>
    <h3 className={modalHeaderClasses('title', modifierClass)}>{title}</h3>
    <CloseButton {...closeButtonProps} />
  </div>
);

ModalHeader.defaultProps = {
  closeButtonProps: {},
  modifierClass: '',
};

ModalHeader.propTypes = {
  closeButtonProps: PropTypes.object,
  CloseButton: PropTypes.func.isRequired,
  modifierClass: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ModalHeader;
