import { differenceInMinutes, isBefore, parseISO } from 'date-fns';
import filter from 'lodash/filter';
import isPlainObject from 'lodash/isPlainObject';

import { getSafeDate } from '@turbine/lib/xboarding/utils';
import { type ShippingAddress } from '@turbine/redux/newOffboarding';
import { XboardingStatusValues } from './constants';
import { type Nullish } from '@turbine/types/utils';
import { type SelectOption } from '@turbine/types/SelectOption';

const isEmptyValue = (value: unknown): boolean =>
  value === undefined ||
  value === null ||
  value === '' ||
  (typeof value === 'object' && Object.keys(value!).length === 0);

export const isEmptyObject = (values: unknown): boolean => {
  if (Array.isArray(values)) {
    return values.every(item => isEmptyObject(item));
  } else if (isPlainObject(values)) {
    const plainObjValues = values as Record<string, unknown>;
    return Object.keys(plainObjValues).every(key =>
      isEmptyObject(plainObjValues[key])
    );
  } else {
    return isEmptyValue(values);
  }
};

export const formatKebabCase = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/[^a-zA-Z0-9\s]+/g, '')
    .replace(/\s+/g, '-');
};

export const formatSaasAppName = (text: string | undefined): string => {
  if (text) {
    return `${formatKebabCase(text)}`;
  }

  return '';
};

export const isBeforeStartDate = (date: string): boolean =>
  isBefore(new Date(), parseISO(date));

const ALLOWED_TIME_FOR_CANCELLING = 15; // minutes

const remainingMinutes = (date: Date): number =>
  differenceInMinutes(date, new Date());

export const isCurrentDateInRestrictedTime = (date: unknown): boolean => {
  const scheduledDate = getSafeDate(date);

  if (!scheduledDate) {
    return false;
  }

  return remainingMinutes(scheduledDate) < ALLOWED_TIME_FOR_CANCELLING;
};

export const isBoardingLive = ({
  date = '',
  status,
}: isBoardingLiveParams): boolean =>
  status !== XboardingStatusValues.Canceled &&
  !!date &&
  isBeforeStartDate(date);

type isBoardingLiveParams = {
  date: string | null;
  status: string;
};

type AddressTextProps = {
  state?: string;
  city?: string;
  zip?: string;
};
export const getAddressText = ({
  state,
  city,
  zip,
}: AddressTextProps): string => {
  const maybeComma = city && (state || zip) ? ',' : '';
  return `${city}${maybeComma} ${state} ${zip}`;
};

type FullStreetProps = Partial<
  Pick<ShippingAddress, 'streetAddress1' | 'streetAddress2'>
>;
export const getFullStreet = ({
  streetAddress1 = '',
  streetAddress2 = '',
}: FullStreetProps): string =>
  `${streetAddress1}${streetAddress2 && `, Apt ${streetAddress2}`}`;

// TS: NonNullable checks K type is not null or undefined, but adding Required also checks the value is present before assigning K type to returning value
export function asSelectOptions<T extends string, K = unknown>(
  label: Nullish<T>,
  value?: K
) {
  return label
    ? ({ label, value: value || label } as SelectOption<
        K extends NonNullable<Required<K>> ? K : T,
        T
      >)
    : null;
}

export const isValidEmail = (email: string | undefined): boolean => {
  const regex =
    /(?=^.{1,64}@)^[_'-]?[a-zA-Z0-9]+([._'-]?[a-zA-Z0-9])*[_'-]?@\w+([.-]?\w+)(\.\w{2,63})+$/;
  if (typeof email === 'string' && regex.test(email)) {
    return true;
  }
  return false;
};

export function createEmail(format: Nullish<string>, domain: Nullish<string>) {
  const cleanedDomain = domain?.replaceAll('@', '');
  const email = filter([format, cleanedDomain]).join('@').toLowerCase();

  if (isValidEmail(email)) {
    return email;
  }

  return '';
}

export const isBoardingActive = (status: XboardingStatusValues) =>
  [XboardingStatusValues.InProgress, XboardingStatusValues.Scheduled].includes(
    status
  );

export const isValidUrl = (urlString: string) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};
