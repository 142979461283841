import { Link, Text } from '@electricjs/arc';

export default [
  {
    startDate: new Date('2021-03-30T09:00:00'),
    endDate: new Date('2021-04-07T18:00:00'),
    message: () => (
      <>
        <Text mb={3}>
          Your request dashboard has a new look & feel! Check out our brief
          video to learn why & its new benefits. (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://electric-ai.wistia.com/medias/nm2qccq9sy">
            Learn More
          </Link>
          ).
        </Text>
        <Text>
          For the best experience, please view this dashboard using Google
          Chrome.
        </Text>
      </>
    ),
    id: 'dashboardUpdated',
  },
];
