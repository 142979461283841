import get from 'lodash/get';
import {
  type CustomerSoftwareApplication,
  type SoftwareApplication,
} from '../queries/selectApplicationsQueries';

export const customerApplicationsSelector = (
  data: unknown
): CustomerSoftwareApplication[] =>
  get(data, 'customer_software_applications', []);

export const applicationsSelector = (data: unknown): SoftwareApplication[] =>
  get(data, 'software_applications', []);
