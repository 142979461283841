import { FETCH_DASHBOARD } from '../actions/dashboardActions';

export type DashboardState = {
  urls?: string[] | null;
  chatTranscriptUrl?: string | null;
  message?: string;
  fetching?: boolean;
};

const initialState: DashboardState = {
  urls: null,
  chatTranscriptUrl: null,
  message: '',
  fetching: false,
};

export default function dashboard(
  state = initialState,
  { type, payload = [] }: { type: string; payload: string[] }
): DashboardState {
  switch (type) {
    case `${FETCH_DASHBOARD}/pending`:
      return {
        ...state,
        fetching: true,
      };
    case `${FETCH_DASHBOARD}/rejected`:
      return {
        ...state,
        message:
          'The dashboard is currently unavailable. Please try back later.',
        fetching: false,
      };
    case `${FETCH_DASHBOARD}/fulfilled`:
      return {
        ...state,
        urls: payload || null,
        // TODO look into this: Looks like chatTranscript will always be null since payload is built to just return an array of length 1 (see dashboardActions.ts)
        chatTranscriptUrl: payload[1] || null,
        message: '',
        fetching: false,
      };
    default:
      return state;
  }
}
