import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import cssVars from 'css-vars-ponyfill';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, BreakpointProvider } from '@electricjs/arc';
import QUERIES from '@turbine/constants/queries';
import store from './redux/store';

import { Auth0Provider } from './helpers/hooks';
import ErrorBoundary from './components/ErrorBoundary';
import { StorefrontProvider } from '@electricjs/app-modules';
import App from './App';

import './services/datadog/init';
import './helpers/loadPolyfills';
import './services/i18n/init';
import '@electricjs/arc/styles/globalStyles.scss';
import './styles/index.scss';

cssVars();

type CypressWindow = {
  store: any;
  Cypress: any;
} & Window &
  typeof globalThis;

ReactDOM.render(
  <StrictMode>
    <ThemeProvider>
      <BreakpointProvider queries={QUERIES}>
        <Provider store={store}>
          <Auth0Provider>
            <ErrorBoundary>
              <StorefrontProvider>
                <App />
              </StorefrontProvider>
            </ErrorBoundary>
          </Auth0Provider>
        </Provider>
      </BreakpointProvider>
    </ThemeProvider>
  </StrictMode>,

  document.getElementById('root')
);

const cypressWindow = window as CypressWindow;
// expose store when run in Cypress
if (cypressWindow.Cypress) {
  cypressWindow.store = store;
}
