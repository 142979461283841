import { gql } from '@apollo/client';

export const CREATE_PROCUREMENT_REQUEST = gql`
  mutation CreateProcurementRequest(
    $customerId: String!
    $customerSfId: String!
    $requesterId: String!
    $shippingEmail: String!
    $address: AddressInput!
    $selectedHardware: [HardwareData]!
    $requestId: String
    $requestOrigin: String
    $customerName: String
    $employeeName: String
    $requesterEmail: String
    $requesterName: String
    $workItemId: String
    $managerEmail: String
    $requestDate: String
    $additionalNotes: String
    $startDate: String
    $department: String
    $welcomeEmail: String
  ) {
    createProcurementRequest(
      customer_id: $customerId
      customer_sf_id: $customerSfId
      requester_id: $requesterId
      shipping_email: $shippingEmail
      address: $address
      selected_hardware: $selectedHardware
      request_id: $requestId
      request_origin: $requestOrigin
      customer_name: $customerName
      employee_name: $employeeName
      requester_email: $requesterEmail
      requester_name: $requesterName
      work_item_id: $workItemId
      manager_email: $managerEmail
      request_date: $requestDate
      additional_notes: $additionalNotes
      start_date: $startDate
      department: $department
      welcome_email: $welcomeEmail
    ) {
      status_code
      details
    }
  }
`;
