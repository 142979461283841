import { createSlice } from '@reduxjs/toolkit';
import {
  getITScoreCardConfiguration,
  getITScoreCardOffice365Data,
  getITScoreCardGSuiteData,
  getITScoreCardGSuiteCSVData,
  getInProgressFirewallRequests,
} from './itScoreCardSliceThunks';

export type ITScoreCardConfiguration = {
  google_workspace_enabled: boolean;
  o365_enabled: boolean;
};

export type ITScoreCardGSuiteData = {
  authenticated: boolean;
  active_users: number;
  recent_logins: number;
  team_storage: boolean;
  total_users: number;
  users_with_two_step_verification: number;
  g_suite_business: boolean;
  is_2fa_available: boolean;
};

export type ITScoreCardOffice365Data = {
  authenticated: boolean;
  total_users: number;
  users_with_two_step_verification: number;
  licensed_users: number;
  total_licenses: number;
  available_licenses: number;
};

export type ITScoreCardGSuiteCSVUser = {
  email: string;
  first_name: string;
  last_name: string;
  last_sign_in: string;
  email_usage_in_gb: number;
  drive_usage_in_gb: number;
  total_storage_in_gb: number;
  is_active: boolean;
  is_2sv_enrolled: boolean;
};

export type ITScoreCardGSuiteCSVData = {
  users: ITScoreCardGSuiteCSVUser[];
};

export type UserRequestData = {
  customer_id: string;
  device_id: string;
  serial_number: string;
  hostname: string;
  requester_id: string;
  request_key: string;
  request_type: string;
  request_status: string;
};

type ITScoreCardState = {
  itScoreCardConfigurationLoading: boolean;
  itScoreCardConfigurationFailed: boolean;
  itScoreCardConfigurationData: ITScoreCardConfiguration | null;
  itScoreCardGSuiteLoading: boolean;
  itScoreCardGSuiteFailed: boolean;
  itScoreCardGSuiteData: ITScoreCardGSuiteData | null;
  itScoreCardOffice365Loading: boolean;
  itScoreCardOffice365Failed: boolean;
  itScoreCardOffice365Data: ITScoreCardOffice365Data | null;
  itScoreCardGSuiteCSVLoading: boolean;
  itScoreCardGSuiteCSVFailed: boolean;
  itScoreCardGSuiteCSVData: null;
  inProgressFirewallRequestLoading: boolean;
  inProgressFirewallRequestFailed: boolean;
  inProgressFirewallRequestData: UserRequestData[] | null;
};

const initialState: ITScoreCardState = {
  itScoreCardConfigurationLoading: false,
  itScoreCardConfigurationFailed: false,
  itScoreCardConfigurationData: null,
  itScoreCardGSuiteLoading: false,
  itScoreCardGSuiteFailed: false,
  itScoreCardGSuiteData: null,
  itScoreCardOffice365Loading: false,
  itScoreCardOffice365Failed: false,
  itScoreCardOffice365Data: null,
  itScoreCardGSuiteCSVLoading: false,
  itScoreCardGSuiteCSVFailed: false,
  itScoreCardGSuiteCSVData: null,
  inProgressFirewallRequestLoading: false,
  inProgressFirewallRequestFailed: false,
  inProgressFirewallRequestData: null,
};

export const itScoreCardSlice = createSlice({
  name: 'itScoreCard',
  initialState,
  reducers: {
    resetITScoreCardState: _state => initialState,
    setItScoreCardConfigurationData: (state, action) => {
      if (state.itScoreCardConfigurationData) {
        state.itScoreCardConfigurationData.google_workspace_enabled =
          action.payload.google_workspace_enabled;
        state.itScoreCardConfigurationData.o365_enabled =
          action.payload.o365_enabled;
      }
    },
    setFirewallEnablementRequestData: (state, action) => {
      const inProgressFirewallRequestData =
        state.inProgressFirewallRequestData?.concat(action.payload) || null;
      state.inProgressFirewallRequestData = inProgressFirewallRequestData;
    },
  },
  extraReducers: builder => {
    builder.addCase(getITScoreCardConfiguration.fulfilled, (state, action) => {
      state.itScoreCardConfigurationLoading = false;
      state.itScoreCardConfigurationFailed = false;
      state.itScoreCardConfigurationData = action.payload;
    });
    builder.addCase(getITScoreCardConfiguration.pending, state => {
      state.itScoreCardConfigurationLoading = true;
      state.itScoreCardConfigurationFailed = false;
    });
    builder.addCase(getITScoreCardConfiguration.rejected, state => {
      state.itScoreCardConfigurationLoading = false;
      state.itScoreCardConfigurationFailed = true;
    });
    builder.addCase(getITScoreCardGSuiteData.fulfilled, (state, action) => {
      state.itScoreCardGSuiteLoading = false;
      state.itScoreCardGSuiteFailed = false;
      state.itScoreCardGSuiteData = action.payload;
    });
    builder.addCase(getITScoreCardGSuiteData.pending, state => {
      state.itScoreCardGSuiteLoading = true;
      state.itScoreCardGSuiteFailed = false;
    });
    builder.addCase(getITScoreCardGSuiteData.rejected, state => {
      state.itScoreCardGSuiteLoading = false;
      state.itScoreCardGSuiteFailed = true;
    });
    builder.addCase(getITScoreCardOffice365Data.fulfilled, (state, action) => {
      state.itScoreCardOffice365Loading = false;
      state.itScoreCardOffice365Failed = false;
      state.itScoreCardOffice365Data = action.payload;
    });
    builder.addCase(getITScoreCardOffice365Data.pending, state => {
      state.itScoreCardOffice365Loading = true;
      state.itScoreCardOffice365Failed = false;
    });
    builder.addCase(getITScoreCardOffice365Data.rejected, state => {
      state.itScoreCardOffice365Loading = false;
      state.itScoreCardOffice365Failed = true;
    });
    builder.addCase(getITScoreCardGSuiteCSVData.fulfilled, (state, action) => {
      state.itScoreCardGSuiteCSVLoading = false;
      state.itScoreCardGSuiteCSVFailed = false;
      state.itScoreCardGSuiteCSVData = action.payload;
    });
    builder.addCase(getITScoreCardGSuiteCSVData.pending, state => {
      state.itScoreCardGSuiteCSVLoading = true;
      state.itScoreCardGSuiteCSVFailed = false;
    });
    builder.addCase(getITScoreCardGSuiteCSVData.rejected, state => {
      state.itScoreCardGSuiteCSVLoading = false;
      state.itScoreCardGSuiteCSVFailed = true;
    });
    builder.addCase(
      getInProgressFirewallRequests.fulfilled,
      (state, action) => {
        state.inProgressFirewallRequestLoading = false;
        state.inProgressFirewallRequestFailed = false;
        state.inProgressFirewallRequestData = action.payload;
      }
    );
    builder.addCase(getInProgressFirewallRequests.pending, state => {
      state.inProgressFirewallRequestLoading = true;
      state.inProgressFirewallRequestFailed = false;
    });
    builder.addCase(getInProgressFirewallRequests.rejected, state => {
      state.inProgressFirewallRequestLoading = false;
      state.inProgressFirewallRequestFailed = true;
    });
  },
});

export const { resetITScoreCardState } = itScoreCardSlice.actions;
