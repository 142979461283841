import * as types from '../types/userSearchTypes';
import { turbineUserRoleID } from '@turbine/config';
import { EMPLOYEES_FROM_API_ORGS } from '@turbine/graphql/queries/employeeQueries';

export const searchUsersByRoleFetched = {
  type: types.SEARCH_USERS_BY_ROLE_FETCHED,
};

export const searchUsersByRoleFetchFailed = {
  type: types.SEARCH_USERS_BY_ROLE_FETCH_FAILED,
};

export const searchUsersByRoleFetchSucceeded = payload => ({
  type: types.SEARCH_USERS_BY_ROLE_FETCH_SUCCEEDED,
  payload,
});

export const selectedRoleIDSet = payload => ({
  type: types.SELECTED_ROLE_ID_SET,
  payload,
});

export const fetchUsersByRoleFromProxyFlare =
  ({ roleID = null, customerID, accessToken }) =>
  async (dispatch, _getState, { client }) => {
    dispatch(selectedRoleIDSet({ roleID }));
    dispatch(searchUsersByRoleFetched);

    let users = null;
    const response = await client(accessToken)
      .query({
        query: EMPLOYEES_FROM_API_ORGS,
        variables: {
          customerId: customerID,
          includeDiscarded: false,
        },
      })
      .then(({ data: { employees } }) => employees)
      .catch(error => {
        window?.DD_RUM?.addError(error);
        return [];
      });
    //we only want employees who have the Turbine User role
    users = response.filter(employee =>
      employee.roles.find(role => role.id === turbineUserRoleID)
    );

    if (!users) return dispatch(searchUsersByRoleFetchFailed);
    dispatch(searchUsersByRoleFetchSucceeded({ users, roleID }));
  };
