import { gql } from '@apollo/client';

export const UPDATE_EMPLOYEE_AUTHO_ID = gql`
  mutation updateEmployee($employee_id: String!, $auth0_id: String!) {
    updateEmployee(employee_id: $employee_id, auth0_id: $auth0_id) {
      employee {
        id
        auth0_id
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_AUTHO_ID_V2 = gql`
  mutation updateEmployeeV2($employee_id: String!, $auth0_id: String!) {
    updateEmployeeV2(employee_id: $employee_id, auth0_id: $auth0_id) {
      employee: result {
        id
        auth0_id
      }
    }
  }
`;

export const SEND_MULTIPLE_REGISTRATION_INVITES = gql`
  mutation sendMultipleRegistrationInvites(
    $employees: [EmployeeToInvite]
    $inviter: String
  ) {
    sendMultipleRegistrationInvites(employees: $employees, inviter: $inviter) {
      employees {
        email
      }
    }
  }
`;

export const SEND_MULTIPLE_REGISTRATION_INVITES_V2 = gql`
  mutation sendMultipleRegistrationInvitesV2($employees: [EmployeeToInvite]) {
    sendMultipleRegistrationInvitesV2(employees: $employees) {
      employees {
        email
      }
    }
  }
`;

export const SELF_SERVICE_SIGN_UP = gql`
  mutation SelfServiceSignUp(
    $accountType: AccountTypeEnum!
    $name: String!
    $plan: CustomerPlanEnum!
    $employee: EmployeeSignUp
  ) {
    selfServiceSignUp(
      name: $name
      account_type: $accountType
      plan: $plan
      employee: $employee
    ) {
      message
    }
  }
`;
