export const timezoneOptions = [
  {
    label: 'US Eastern',
    value: 'America/New_York',
  },
  {
    label: 'US Central',
    value: 'America/Chicago',
  },
  {
    label: 'US Mountain',
    value: 'America/Denver',
  },
  {
    label: 'US Pacific',
    value: 'America/Los_Angeles',
  },
];
