import { gql } from '@apollo/client';

export const FETCH_CUSTOMER_POLICY = gql`
  query CustomerPolicy($customerId: String!) {
    xboarding_policy(customer_id: $customerId, exclude_addresses: true) {
      id
      data {
        default {
          approvers
          calendars
          chat_application
          email_groups
          hardware {
            link
            name
          }
          slack_channels
          slack_groups
          softwares {
            id
            name
            requires_account
            requires_installation
            notes {
              question_id
              title
              answer
              children {
                question_id
                title
                answer
              }
            }
            additional_notes
          }
          sso_provider
        }
        departments {
          approvers
          calendars
          email_groups
          name
          slack_channels
          slack_groups
          softwares {
            id
            name
            requires_account
            requires_installation
            notes {
              question_id
              title
              answer
              children {
                question_id
                title
                answer
              }
            }
            additional_notes
          }
          selected_hardware {
            id
            link
            name
            selected
          }
        }
        email_formats {
          prefix
          suffix
          domain
          syntax
        }
      }
    }
  }
`;

export const normalizeCustomerPolicyResult = policy => ({
  ...policy,
  customerId: policy?.customer_id,
  data: {
    default: {
      approvers: policy?.data?.default?.approvers,
      calendars: policy?.data?.default?.calendars,
      hardware: policy?.data?.default?.hardware,
      chatApplication: policy?.data?.default?.chat_application,
      emailGroups: policy?.data?.default?.email_groups,
      slackChannels: policy?.data?.default?.slack_channels,
      slackGroups: policy?.data?.default?.slack_groups,
      softwares: policy?.data?.default?.softwares,
      ssoProvider: policy?.data?.default?.sso_provider,
    },
    departments: policy?.data?.departments?.map(department => ({
      approvers: department?.approvers,
      calendars: department?.calendars,
      emailGroups: department?.email_groups,
      name: department?.name,
      slackChannels: department?.slack_channels,
      slackGroups: department?.slack_groups,
      softwares: policy?.data?.default?.softwares,
      selectedHardware: department?.selected_hardware,
    })),
    emailFormats: policy?.data?.email_formats,
  },
});
