export const OS_TYPE = {
  MAC: 'darwin',
  WINDOWS: 'windows',
};

export const SOURCE = {
  JAMF: 'jamf',
  KASEYA: 'kaseya',
};

export const SESSION_KEY_FOR_EMPLOYEES = 'alertedEmployees';

export const LOCK_STATUS = {
  LOCKED: 'LOCKED',
  PENDING_LOCK: 'PENDING_LOCK',
  FAILED_LOCK: 'FAILED_LOCK',
  UNLOCKED: 'UNLOCKED',
  PENDING_UNLOCK: 'PENDING_UNLOCK',
  FAILED_UNLOCK: 'FAILED_UNLOCK',
};
