import { gql } from '@apollo/client';

export const GET_LOOKER_REPORTS = gql`
  query LookerReportByCustomer(
    $customerId: String!
    $employeeId: String!
    $viewChat: Boolean!
    $dashboard: DashboardEnum!
    $isDevelopment: Boolean!
  ) {
    looker_url(
      customer_id: $customerId
      can_view_chat_transcript: $viewChat
      employee_id: $employeeId
      dashboard: $dashboard
      is_development: $isDevelopment
    ) {
      url
    }
  }
`;
