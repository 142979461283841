import has from 'lodash/has';
import isPlainObject from 'lodash/isPlainObject';
import { type SelectOption } from '@turbine/types/SelectOption';
import { type Nullish } from '@turbine/types/utils';

type OptionObjKey = 'value' | 'label';

const getOptionProperty = (
  option: Nullish<SelectOption>,
  keyName: OptionObjKey = 'value'
): string => {
  if (!option) {
    return '';
  }
  if (typeof option === 'string') {
    return option;
  }
  if (isPlainObject(option) && has(option, keyName)) {
    return option[keyName];
  }
  return '';
};

export { getOptionProperty };
