import type * as React from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import SVG from 'react-inlinesvg';
import { Box } from '@electricjs/arc';
import NavItem from './NavItem';
import notificationCircle from '@turbine/assets/images/notification-circle.svg';
import { type NavItemProps } from './types';

const NavItemWrapper = styled(Box)<{
  $addBottomMargin: boolean;
  $isInsideDropdown: boolean;
}>`
  width: 100%;
  padding: ${ifProp('$isInsideDropdown', '0', '0 1.6rem')};
  a {
    padding: ${ifProp('$isInsideDropdown', '0 3rem 0 1.5rem')};
    margin-bottom: ${ifProp('$addBottomMargin', '2rem')};
    border-radius: ${ifProp('$isInsideDropdown', '0', '0.8rem')};
    height: ${ifProp('$isInsideDropdown', '3rem')};
    > span {
      padding-left: ${ifProp('$isInsideDropdown', '1.2rem')};
      font-size: ${ifProp('$isInsideDropdown', '1.3rem')};
      &:not([data-testid='nav-item']) {
        display: flex;
        justify-content: center;
        min-width: 26px;
        & > svg {
          fill: white;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;

const StyledSVG = styled(SVG)`
  position: absolute;
  left: 240px;
  margin-top: 19px;
  @media (max-width: 1023px) {
    left: 30px;
    margin-top: 11px;
    z-index: 10;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const NavItemWithNotification = ({
  shouldShowNotification = false,
  addBottomMargin = false,
  isInsideDropdown = false,
  ...otherProps
}: NavItemProps): React.ReactElement => {
  return (
    <NavItemWrapper
      $addBottomMargin={addBottomMargin}
      $isInsideDropdown={isInsideDropdown}>
      {shouldShowNotification && <StyledSVG src={notificationCircle} />}
      <NavItem {...otherProps} />
    </NavItemWrapper>
  );
};

export default NavItemWithNotification;
