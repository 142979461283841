import get from 'lodash/get';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_LOOKER_REPORTS } from '@turbine/graphql/queries/dashboardQueries';
import { hasSomePermission } from '@turbine/helpers/hasPermissionHelper';
import { type AppThunkConfig } from '@turbine/redux/store';
import { DashboardEnum } from '@turbine/pages/Dashboard/dashboardEnum';
import {
  type ApolloClient,
  type NormalizedCacheObject,
  type QueryOptions,
  NetworkStatus,
} from '@apollo/client';
import { nodeEnv } from '@turbine/config';
export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';
const MALWAREBYTES_LIVE_DEMO_DASH_URL =
  'https://electric.cloud.looker.com/embed/dashboards/external_malwarebytes_endpoints::malwarebytes';

type FetchDashboardParams = {
  customerId: string;
  employeeId: string;
  dashboards: DashboardEnum[];
};

type FetchDashboardsArgs = {
  queryObjects: QueryOptions[];
  client: () => ApolloClient<NormalizedCacheObject>;
};

const fetchDashboards = async ({
  queryObjects,
  client,
}: FetchDashboardsArgs) => {
  try {
    // Loop through our query Objects and fire them off.
    // We wrap this in a Promise.all so we can wait for all
    // requests to complete.
    const responses = await Promise.all(
      queryObjects.map(queryObject => {
        // Short circuit the request if the dashboard we need is the
        // hardcoded live demo dash for Malwarebytes.
        if (
          queryObject?.variables?.dashboard ===
          DashboardEnum.MalwarebytesLiveDemo
        ) {
          return Promise.resolve({
            data: {
              looker_url: {
                url: MALWAREBYTES_LIVE_DEMO_DASH_URL,
              },
            },
            loading: false,
            networkStatus: NetworkStatus.ready,
          });
        }
        return client().query(queryObject);
      })
    );

    // Map through our responses to return an array of just the URLs
    return responses.map(response => response?.data.looker_url?.url);
  } catch (error) {
    window?.DD_RUM?.addError(error);
  }
};

export const fetchDashboardFromProxyFlare = createAsyncThunk<
  object,
  FetchDashboardParams,
  AppThunkConfig
>(
  FETCH_DASHBOARD,
  async ({ customerId, employeeId, dashboards }, { getState, extra }) => {
    const isDevelopment = nodeEnv === 'development';
    const { client } = extra;
    const userPermissions = get(getState(), 'userPermissions.permissions', {});
    const showChatTranscript = hasSomePermission(Object.keys(userPermissions), [
      'turbine:admins:create',
      'turbine:reports:read',
    ]);

    // Construct the query params for each dashboard request.
    const queryObjects: QueryOptions[] = dashboards.map(dashboard => {
      return {
        query: GET_LOOKER_REPORTS,
        fetchPolicy: 'network-only',
        variables: {
          customerId,
          employeeId,
          viewChat: showChatTranscript,
          isDevelopment,
          dashboard,
        },
      };
    });
    return fetchDashboards({
      queryObjects,
      client,
    });
  }
);

export default fetchDashboardFromProxyFlare;
