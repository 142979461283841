import { useSelector, shallowEqual } from 'react-redux';
import { currentCustomerSelector } from '@turbine/redux/selectors/customersSelector';
import { useSession } from '@turbine/helpers/hooks';

const useCustomer = () => {
  const { customerId } = useSession();
  const customer = useSelector(
    currentCustomerSelector(customerId),
    shallowEqual
  );

  return customer;
};

export { useCustomer };
