import { RoundShape, TextRow } from 'react-placeholder/lib/placeholders';
import { useBreakpoint } from '@electricjs/utils/hooks';
import { Pane, ColorsV2 } from '@electricjs/arc';
import { useColor } from '@turbine/@arc/recipes/useColor';

const SideNavPlaceholder = () => {
  const placeholderColor = useColor(ColorsV2.DISABLED);
  const { lg } = useBreakpoint();
  const textWidth = Math.floor(Math.random() * 50) + 80;
  const collapsedPaneProps = {
    ml: '1.1rem',
  };
  const expandedPaneProps = {
    pr: 6,
    pl: '2.6rem',
  };
  const paneProps = {
    mb: '2rem',
    vertical: 'center',
    height: '4.5rem',
    ...(lg ? expandedPaneProps : collapsedPaneProps),
  };

  return (
    <Pane withPadding {...paneProps}>
      <RoundShape
        color={placeholderColor}
        style={{ width: 24, height: 24, marginRight: '1.5rem' }}
      />
      {lg && (
        <TextRow
          color={placeholderColor}
          style={{ marginTop: 0, width: textWidth }}
        />
      )}
    </Pane>
  );
};

export default SideNavPlaceholder;
