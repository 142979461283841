import merge from 'lodash/merge';
import reduxCrud from 'redux-crud';
import {
  FETCH_CUSTOMERS,
  FETCH_SINGLE_CUSTOMER,
} from '@turbine/graphql/queries/customerQueries';

const baseActionCreators = reduxCrud.actionCreatorsFor('customers');

const actionCreators = {
  fetch(accessToken) {
    return function (dispatch, _getState, { client }) {
      const action = baseActionCreators.fetchStart();
      dispatch(action);

      return client(accessToken)
        .query({
          query: FETCH_CUSTOMERS,
        })
        .then(({ data: { customers } }) => {
          const successAction = baseActionCreators.fetchSuccess(customers);
          dispatch(successAction);
        })
        .catch(error => {
          const errorAction = baseActionCreators.fetchError(error);
          dispatch(errorAction);
          window?.DD_RUM?.addError(error.toString());
        });
    };
  },
  fetchSingleCustomer(accessToken, customerId) {
    return function (dispatch, _getState, { client }) {
      const action = baseActionCreators.fetchStart();
      dispatch(action);

      return client(accessToken)
        .query({
          query: FETCH_SINGLE_CUSTOMER,
          variables: {
            customer_id: customerId,
          },
        })
        .then(({ data: { customers } }) => {
          const successAction = baseActionCreators.fetchSuccess(customers);
          dispatch(successAction);
        })
        .catch(error => {
          const errorAction = baseActionCreators.fetchError(error);
          dispatch(errorAction);
          window?.DD_RUM?.addError(error.toString());
        });
    };
  },
};

export default merge(baseActionCreators, actionCreators);
