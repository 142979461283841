import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  POLICY_FETCH,
  POLICY_UPDATE,
  POLICY_CREATE,
} from '../types/policyTypes';
import {
  FETCH_CUSTOMER_POLICY,
  FETCH_CUSTOMER_APP_ACCOUNTS,
  normalizeCustomerPolicyResult,
} from '@turbine/graphql/queries/policyQueries';
import {
  CREATE_POLICY,
  UPDATE_POLICY,
} from '@turbine/graphql/mutations/policyMutations';

import {
  FETCH_CUSTOMER_POLICY as FETCH_CUSTOMER_POLICY_WITH_SOFTWARE,
  normalizeCustomerPolicyResult as normalizeCustomerPolicyResultWithSoftware,
} from '@turbine/graphql/queries/policyQueriesWithSoftware';
import {
  CREATE_POLICY as CREATE_POLICY_WITH_SOFTWARE,
  UPDATE_POLICY as UPDATE_POLICY_WITH_SOFTWARE,
} from '@turbine/graphql/mutations/policyMutationsWithSoftware';

import { permissionsSelector } from '../selectors/userPermissionsSelector';

const formatPolicySucceeded = (payload, getState, chatApp = '') => ({
  ...{
    ...payload,
    data: {
      ...payload.data,
      default: {
        ...payload.data.default,
        chatApplication: chatApp,
      },
    },
  },
  permissions: permissionsSelector(getState()),
});

export const fetchPolicy = createAsyncThunk(
  POLICY_FETCH,
  ({ customerID, hasSaasAppNotes }, { getState, extra }) => {
    const { client } = extra;

    // Fetching Policies
    const policyFetch = client()
      .query({
        query: hasSaasAppNotes
          ? FETCH_CUSTOMER_POLICY_WITH_SOFTWARE
          : FETCH_CUSTOMER_POLICY,
        variables: {
          customerId: customerID,
        },
        fetchPolicy: 'no-cache',
      })
      .then(data => data)
      .catch(error => {
        window?.DD_RUM?.addError(error);
        throw error;
      });

    // Fetching Customer Chat App
    const chatAppFetch = client()
      .query({
        query: FETCH_CUSTOMER_APP_ACCOUNTS,
        variables: {
          customerId: customerID,
        },
        fetchPolicy: 'no-cache',
      })
      .then(data => data)
      .catch(error => {
        window?.DD_RUM?.addError(error);
        throw error;
      });
    // Returning the merge of both responses
    const response = Promise.all([policyFetch, chatAppFetch])
      .then(([policyResponse, chatAppResponse]) => {
        const {
          data: { xboarding_policy: payload },
        } = policyResponse;
        const {
          data: {
            customer_app_accounts: [app],
          },
        } = chatAppResponse;
        return formatPolicySucceeded(
          hasSaasAppNotes
            ? normalizeCustomerPolicyResultWithSoftware(payload)
            : normalizeCustomerPolicyResult(payload),
          getState,
          app?.application_name
        );
      })
      .catch(error => {
        window?.DD_RUM?.addError(error);
        throw error;
      });
    return response;
  }
);

export const updatePolicy = createAsyncThunk(
  POLICY_UPDATE,
  ({ policy, customerId, hasSaasAppNotes, showToast }, { extra }) => {
    const { client } = extra;
    const { id, data } = policy;
    const response = client()
      .mutate({
        mutation: hasSaasAppNotes ? UPDATE_POLICY_WITH_SOFTWARE : UPDATE_POLICY,
        variables: {
          id: id,
          customerId,
          data: JSON.stringify(data),
        },
      })
      .then(
        ({
          data: {
            updateXboardingPolicy: { xboarding_policy: payload },
          },
        }) => {
          showToast('Policy updated');
          return normalizeCustomerPolicyResult(payload);
        }
      )
      .catch(error => {
        window?.DD_RUM?.addError(error);
        showToast({ intent: 'error', message: 'Error updating policy' });
        throw error;
      });
    return response;
  }
);

// Note: It looks like this is no longer used, it may be able to be removed.
export const createPolicy = createAsyncThunk(
  POLICY_CREATE,
  ({ policy, customerId, hasSaasAppNotes }, { extra }) => {
    const { client } = extra;

    const response = client()
      .mutate({
        mutation: hasSaasAppNotes ? CREATE_POLICY_WITH_SOFTWARE : CREATE_POLICY,
        variables: {
          customerId,
          data: JSON.stringify(policy.data),
        },
      })
      .then(
        ({
          data: {
            createXboardingPolicy: { xboarding_policy: payload },
          },
        }) => {
          return payload;
        }
      )
      .catch(error => {
        window?.DD_RUM?.addError(error);
        throw error;
      });
    return response;
  }
);
