const MIN_CHECK_WAIT = 60000; // 1 minute - minimum time until a check will commence again
const FETCH_INTERVAL = 120000; // 2 minutes - browser focus based interval
const FORCED_FETCH_INTERVAL = 900000; // 15 minutes - background continous interval

/**
 * Using fetch, this function will retrieve a file from the URL provided,
 * and will parse the text of that file looking specifically for this keyword:
 * REACT_APP_VERSION: "..."
 *
 * If a new version has already been detected previously,
 * or if it's being fetched again within 2 minutes, it will not fetch again.
 */
const fetchAppVersion =
  (url: string, lastCheckTimeMinimum = MIN_CHECK_WAIT) =>
  async () => {
    const lastCheckTimeUnderMin =
      !!window._env_?.LAST_CHECK_DATE &&
      new Date().getTime() - window._env_?.LAST_CHECK_DATE <
        lastCheckTimeMinimum;

    // If a new version has already been detected,
    // or if the last check was less than "lastCheckTimeMinimum"
    if (window._env_?.NEW_VERSION || lastCheckTimeUnderMin) return;

    try {
      // Fetch the latest version file using the current time to break the cache
      window._env_.LAST_CHECK_DATE = new Date().getTime();
      const response = await fetch(
        `${url}?d=${window._env_.LAST_CHECK_DATE}`
      ).then(response => {
        if (response?.status !== 200) {
          throw new Error('Device is offline.');
        }
        return response.text();
      });

      const versionRegex = /REACT_APP_VERSION:\s["'](.+?)["']/;
      const latestVersion = response.match(versionRegex)?.[1];
      const currentVersion = window._env_.REACT_APP_VERSION;
      const newVersion = latestVersion && currentVersion !== latestVersion;

      if (newVersion) {
        window._env_.NEW_VERSION = latestVersion;
        window.DD_RUM?.addAction('new_version_detected', {
          currentVersion,
          latestVersion,
        });
      }
    } catch (e) {
      window.DD_RUM?.addError(e);
    }
  };

/**
 * Initiates two separate version check intervals and
 * attaches listeners for the user's browser focus.
 */
const startFetchIntervals = () => {
  const fetchTurbineVersion = fetchAppVersion('/env/env-config.js');
  // This interval is dependent on the user's browser being in focus
  let versionInterval: NodeJS.Timeout | null = setInterval(
    fetchTurbineVersion,
    FETCH_INTERVAL
  );
  // Force a version fetch regardless of the user's browser state
  setInterval(fetchTurbineVersion, FORCED_FETCH_INTERVAL);

  // Sets or unsets the shorter interval based on browser focus
  const handlePageFocus = () => {
    if (document.visibilityState === 'hidden' && versionInterval) {
      clearInterval(versionInterval);
      versionInterval = null;
      return;
    }
    if (document.visibilityState === 'visible' && !versionInterval) {
      // immediately fetch the new version upon the tab returning focus, then set the timer again.
      fetchTurbineVersion();
      versionInterval = setInterval(fetchTurbineVersion, FETCH_INTERVAL);
    }
  };

  // Listen to visibilitychange and focus for app version fetching
  // Note: This only affects the shorter interval
  if (window?.addEventListener) {
    window.addEventListener('visibilitychange', handlePageFocus, false);
    window.addEventListener('focus', handlePageFocus, false);
  }
};

export { fetchAppVersion, startFetchIntervals };
