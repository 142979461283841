import { gql } from '@apollo/client';

// TODO: define types https://electricops.atlassian.net/browse/IA1-2456
export type CustomerPolicy = Record<string, any>;

export const FETCH_CUSTOMER_POLICY = gql`
  query CustomerPolicy($customerId: String!) {
    xboarding_policy(customer_id: $customerId, exclude_addresses: true) {
      id
      data {
        default {
          approvers
          calendars
          calendars_data {
            id
            name
          }
          chat_application
          email_groups
          hardware {
            link
            name
          }
          slack_channels
          slack_groups
          saas_applications
          sso_provider
        }
        departments {
          approvers
          calendars
          calendars_data {
            id
            name
          }
          email_groups
          name
          slack_channels
          slack_groups
          saas_applications
          selected_hardware {
            id
            link
            name
            selected
          }
        }
        email_formats {
          prefix
          suffix
          domain
          syntax
        }
      }
    }
  }
`;

export const FETCH_CUSTOMER_POLICY_WITHOUT_DEPARTMENT_SELECTED_HARDWARE = gql`
  query CustomerPolicy($customerId: String!) {
    xboarding_policy(customer_id: $customerId, exclude_addresses: true) {
      id
      data {
        default {
          approvers
          calendars
          calendars_data {
            id
            name
          }
          chat_application
          email_groups
          hardware {
            link
            name
          }
          slack_channels
          slack_groups
          saas_applications
          sso_provider
        }
        departments {
          approvers
          calendars
          calendars_data {
            id
            name
          }
          email_groups
          name
          slack_channels
          slack_groups
          saas_applications
        }
        email_formats {
          prefix
          suffix
          domain
          syntax
        }
      }
    }
  }
`;

export type FetchCustomerAppAccountsData = {
  customer_app_accounts: Array<{
    application_name: string;
    application_id: string;
  }>;
};

export const FETCH_CUSTOMER_APP_ACCOUNTS = gql`
  query CUSTOMER_APP_ACCOUNTS($customerId: String!) {
    customer_app_accounts(customer_id: $customerId, is_chat_source: "PRIMARY") {
      application_name
      application_id
    }
  }
`;

export const normalizeCustomerPolicyResult = (policy: any) => ({
  ...policy,
  customerId: policy?.customer_id,
  data: {
    default: {
      approvers: policy?.data?.default?.approvers,
      calendars: policy?.data?.default?.calendars,
      calendarsData: policy?.data?.default?.calendars_data,
      hardware: policy?.data?.default?.hardware,
      chatApplication: policy?.data?.default?.chat_application,
      emailGroups: policy?.data?.default?.email_groups,
      slackChannels: policy?.data?.default?.slack_channels,
      slackGroups: policy?.data?.default?.slack_groups,
      saasApplications: policy?.data?.default?.saas_applications,
      ssoProvider: policy?.data?.default?.sso_provider,
    },
    departments: policy?.data?.departments?.map((department: any) => ({
      approvers: department?.approvers,
      calendars: department?.calendars,
      calendarsData: department?.calendars_data,
      emailGroups: department?.email_groups,
      name: department?.name,
      slackChannels: department?.slack_channels,
      slackGroups: department?.slack_groups,
      saasApplications: department?.saas_applications,
      selectedHardware: department?.selected_hardware,
    })),
    emailFormats: policy?.data?.email_formats,
  },
});
