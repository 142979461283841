import { createSelector } from 'reselect';
import { PLANS } from '@turbine/constants/customerPlans';
import { ELECTRIC_ROLES_MAP, Roles, ROLES_MAP } from '@turbine/constants/roles';
import { customerPlanSelector } from '../selectors/customerPlanSelector';

// Changed the logic to save all roles and filter roles based on selectors

const selectCurrentUserRoles = createSelector(
  state => state.userRoles,
  ({ roles, ...restProps }) => {
    // If we have end user support turned on, we want to show all of the
    // roles possible. If not only show the list of "elevated" roles.
    const allowedRolesMap = ROLES_MAP;
    const filteredRoles = roles.filter(({ name }) => !!allowedRolesMap[name]);
    return { ...restProps, roles: filteredRoles };
  }
);

export const userRolesSelector = createSelector(
  [selectCurrentUserRoles],
  ({ roles = [], fetching, failed } = {}) => ({
    roleInfo: roles,
    fetching,
    failed,
  })
);

export const userRolesByCustomerPlanSelector = createSelector(
  [selectCurrentUserRoles, customerPlanSelector],
  ({ roles = [], fetching, failed } = {}, plan) => {
    let filteredRoles = roles;
    const excludedRoles = [];

    if (plan === PLANS.LITE) {
      excludedRoles.push(Roles.OnOffboarder);
    }
    if (plan === PLANS.BASIC) {
      excludedRoles.push(Roles.Reporter, Roles.OnOffboarder);
    }
    filteredRoles = roles.filter(role => !excludedRoles.includes(role.name));

    return {
      roleInfo: filteredRoles,
      fetching,
      failed,
    };
  }
);

export const updateUserRolesSelector = createSelector(
  [state => state.modal, userRolesSelector, state => state.users],
  (
    { modalProps: { roles, id } = {} } = {},
    { roleInfo } = [],
    { updateUserRoles: { fetching, failed } = {} }
  ) => {
    const currentRoles = {};
    const initialRoles = {};
    roleInfo.forEach(({ id, name }) => {
      const role = roles?.find(role => role.id === id);

      if (role) {
        initialRoles[name] = true;
        currentRoles[id] = true;
      } else {
        initialRoles[name] = false;
        currentRoles[id] = false;
      }
    });

    return {
      userID: id,
      currentRoles,
      initialRoles,
      fetching,
      failed,
    };
  }
);

const getRoleName = (_, { specifiedRoleName }) => specifiedRoleName;

export const findRoleIDSelector = createSelector(
  [getRoleName, selectCurrentUserRoles],
  (roleName, { roles = [] }) => {
    const role = roles.find(role => role.name === roleName) || {};
    return role.id;
  }
);

export const roleIDSelector = createSelector(
  state => state.users,
  ({ selectedRoleID }) => selectedRoleID
);

export const selectElectricRoles = createSelector(
  state => state.userRoles,
  ({ roles, fetching, failed }) => ({
    fetching,
    failed,
    roleInfo: roles
      .filter(({ name }) => ELECTRIC_ROLES_MAP[name])
      .map(({ id, name }) => ({
        label: name,
        value: id,
      })),
  })
);
