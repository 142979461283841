import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import unionBy from 'lodash/unionBy';
import { FETCH_APPLICATION_RESOURCES } from '@turbine/graphql/queries/applicationResourcesQueries';
import { useSession } from '@turbine/helpers/hooks';
import { type ApplicationResourcesResponse } from './types/applicationResources';
import { useGoogleAutomationStatus } from './useGoogleAutomationStatus';

const GOOGLE_GROUPS_PATH = 'groups';
const GOOGLE_CALENDARS_PATH = 'calendars';
const GOOGLE_CALENDAR_ROLES_PATH = 'calendar_roles.roles';

const calendarPermissionOptions = [
  {
    label: 'Make changes & manage sharing',
    value: 'Make changes & manage sharing',
  },
  { label: 'Make changes', value: 'Make changes' },
  { label: 'View all event details', value: 'View all event details' },
  { label: 'Hide event details', value: 'Hide event details' },
];

export const CALENDAR_PERMISSIONS = [
  { id: 'freeBusyReader', name: 'Hide event details' },
  { id: 'reader', name: 'View all event details' },
  { id: 'writer', name: 'Make changes' },
  { id: 'owner', name: 'Make changes & manage sharing' },
];

type GoogleAutomationResources =
  | typeof GOOGLE_GROUPS_PATH
  | typeof GOOGLE_CALENDARS_PATH
  | typeof GOOGLE_CALENDAR_ROLES_PATH;

type SelectOptions = { label: string; value: string };

type GoogleAutomationConfig = {
  /**
   * Resolves data to a string to be displayed as value
   */
  optionValue: string;
  /**
   * Resolves data to a string to be displayed as label
   */
  optionLabel: string;
  /**
   * Merge with data property
   */
  mergeDataWith?: any[];
  /**
   * Merges with array of options
   * */
  mergeOptionsWith?: SelectOptions[];
  /**
   * Fallback options when google automation is off
   */
  fallbackOptions?: SelectOptions[];
};

function useGoogleWorkspaceAutomation(
  resource = '' as GoogleAutomationResources,
  config: GoogleAutomationConfig = { optionValue: 'id', optionLabel: 'name' }
) {
  const { customerId } = useSession();
  const { isGoogleAutomationOn, loading: isLoadingGA } =
    useGoogleAutomationStatus();
  const {
    data,
    loading: isLoadingApplicationResources,
    error,
  } = useQuery<ApplicationResourcesResponse>(FETCH_APPLICATION_RESOURCES, {
    variables: { customerId },
    skip: !isGoogleAutomationOn,
    fetchPolicy: 'cache-and-network',
  });

  let resources = get(data, `application_resources.google.${resource}`, []);

  // https://electricops.atlassian.net/browse/IA1-2756
  // TODO: Remove mock data when BE is ready
  if (resource === GOOGLE_CALENDAR_ROLES_PATH) {
    resources = CALENDAR_PERMISSIONS;
  }

  const mergedData = unionBy(
    resources,
    config?.mergeDataWith,
    config.optionValue
  );

  const selectOptions = mergedData.map(resource => ({
    label: resource[config.optionLabel],
    value: resource[config.optionValue],
  }));

  const mode = isGoogleAutomationOn ? 'multiple' : 'tags';

  const returnProps = {
    loading: isLoadingApplicationResources || isLoadingGA,
    data: mergedData,
    error,
    isGoogleAutomationOn,
    mode,
    options: isGoogleAutomationOn
      ? unionBy(selectOptions, config?.mergeOptionsWith, 'value')
      : config?.fallbackOptions,
    findOne: (id: string | number, identity = config.optionValue) => {
      return (
        mergedData?.find(option => option[identity] === id) ?? {
          [config.optionValue]: '',
          [config.optionLabel]: id,
        }
      );
    },
    findAll: (ids = [], identity = config.optionValue) => {
      return ids?.map(id => returnProps.findOne(id, identity));
    },
  };

  return returnProps;
}

function useGoogleGroups(config?: Partial<GoogleAutomationConfig>) {
  return useGoogleWorkspaceAutomation(GOOGLE_GROUPS_PATH, {
    optionLabel: 'email',
    optionValue: 'email',
    ...config,
  });
}

function useGoogleCalendars(config?: Partial<GoogleAutomationConfig>) {
  return useGoogleWorkspaceAutomation(GOOGLE_CALENDARS_PATH, {
    optionLabel: 'name',
    optionValue: 'id',
    ...config,
  });
}

function useGetGoogleCalendarsNames(
  calendars: string[] = [],
  calendarsData: { id: string; name: string }[] = []
): string[] {
  const { isGoogleAutomationOn } = useGoogleAutomationStatus();
  const calendarNames = isGoogleAutomationOn
    ? calendarsData?.map(({ name }) => name)
    : calendars;

  return calendarNames;
}

function useCalendarPermissions(config?: Partial<GoogleAutomationConfig>) {
  return useGoogleWorkspaceAutomation(GOOGLE_CALENDAR_ROLES_PATH, {
    optionLabel: 'name',
    optionValue: 'id',
    // when google automation is off return harcoded options
    fallbackOptions: calendarPermissionOptions,
    ...config,
  });
}

export {
  useCalendarPermissions,
  useGoogleCalendars,
  useGoogleGroups,
  useGetGoogleCalendarsNames,
};
