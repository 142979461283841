export enum DashboardEnum {
  HardwareRequests = 'HARDWARE_REQUESTS',
  Malwarebytes = 'MALWAREBYTES',
  ElectricWrapper = 'ELECTRIC_WRAPPED',
  MalwarebytesLiveDemo = 'MALWAREBYTES_LIVE_DEMO',
  XBoardings = 'XBOARDINGS',
  SupportedEmployees = 'SUPPORTED_EMPLOYEES',
  RequestInsights = 'REQUEST_INSIGHTS',
  RequestTables = 'REQUEST_TABLES',
}
