import PropTypes from 'prop-types';
import { Button } from '@electricjs/arc';
import ModalHeader from '@turbine/components/common/Modal/ModalTypes/common/ModalHeader';

export const DangerModalComponent = props => {
  const { bemClasses, CloseButton, modalProps, handleCloseModal } = props;
  const { onClose, title, checkMark, xMark, message, buttonText } = modalProps;
  const dangerModalComponentClasses = (modifier = '') =>
    bemClasses('danger-modal', modifier);

  const handleOnCloseModal = () => {
    if (onClose) {
      onClose();
    }
    handleCloseModal();
  };

  return (
    <div className={dangerModalComponentClasses('inner')}>
      <ModalHeader
        CloseButton={CloseButton}
        closeButtonProps={{ onClick: handleOnCloseModal }}
        title={title()}
      />
      <div className={dangerModalComponentClasses('body')}>
        <div className={dangerModalComponentClasses('message-container')}>
          {checkMark && (
            <span className={dangerModalComponentClasses('check')}>
              &#10003;
            </span>
          )}
          {xMark && (
            <span className={dangerModalComponentClasses('x')}>&#10007;</span>
          )}
          {message()}
        </div>

        <div className={dangerModalComponentClasses('sticky-bottom')}>
          <Button
            onClick={handleOnCloseModal}
            intent="danger"
            variant="outline"
            {...modalProps.buttonProps}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

DangerModalComponent.defaultProps = {
  modalProps: {
    buttonProps: {},
    checkMark: false,
    xMark: false,
  },
};

DangerModalComponent.propTypes = {
  bemClasses: PropTypes.func.isRequired,
  CloseButton: PropTypes.func.isRequired,
  modalProps: PropTypes.shape({
    buttonProps: PropTypes.object,
    checkMark: PropTypes.bool,
    xMark: PropTypes.bool,
    title: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    message: PropTypes.func.isRequired,
  }).isRequired,
};
