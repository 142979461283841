import { gql } from '@apollo/client';

export const FETCH_TRAY_URL = gql`
  query (
    $app: String!
    $customer_id: String!
    $department_id: String!
    $on_boarding_type: Boolean!
  ) {
    solution_instances_triggers(
      app: $app
      customer_id: $customer_id
      department_id: $department_id
      on_boarding_type: $on_boarding_type
    ) {
      succeeded
      failed
    }
  }
`;

export const FETCH_SOLUTION_INSTANCES = gql`
  query (
    $app: String
    $customer_id: String!
    $department_id: String
    $on_boarding_type: Boolean
  ) {
    solution_instances(
      app: $app
      customer_id: $customer_id
      department_id: $department_id
      on_boarding_type: $on_boarding_type
    ) {
      id
      name
      rule
      departments
      tags
      trigger_url
    }
  }
`;

export const FETCH_SOLUTION = gql`
  query ($customer_id: String!, $app: String!, $is_on_boarding: Boolean!) {
    solution(
      customer_id: $customer_id
      app: $app
      is_on_boarding: $is_on_boarding
    ) {
      id
      title
      description
      tags
    }
  }
`;
