import { isString } from 'lodash/fp';

export const tryGetArrayFromString = (value: unknown) => {
  if (!value) return null;
  if (
    isString(value) &&
    ['[', ']'].every(arraySymbol => value.includes(arraySymbol))
  ) {
    try {
      // remove array brackets then split elements
      // and remove leading and trailing quotes from each one
      const arr = value
        ?.replace(/(\[|\])/g, '')
        .split(/, |,/g)
        .map(val => val.replace(/(^('|")|('|")+$)/g, ''));
      return arr;
    } catch (_) {
      return value;
    }
  }
  return value;
};
