import { gql } from '@apollo/client';

const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    name
    street_address_1
    street_address_2
    city
    state
    zip
    country
    office_id
    shippable
    phone
  }
`;

/**
 * https://electricops.atlassian.net/browse/IA1-2755
 * TODO: review which fields are still in use
 * after xboardings 1.0 code was removed
 * */
const ONBOARDING_DATA_FRAGMENT = gql`
  fragment OnboardingDataFragment on OnboardingData {
    draft_step_progress
    first_name
    last_name
    job_title
    manager_id
    start_date
    employment_type
    department
    address {
      ...AddressFragment
    }
    email_formats
    email_formats_notes
    welcomeEmail {
      email
      email_date
      additional_notes
      timezone
    }
    welcome_email_date
    personal_email
    notify_when_onboarding_complete
    selected_hardware {
      id
      link
      name
      serial
      selected
      kind
      custom
      quantity
      procurementType: procurement_type
      provisioningType: provisioning_type
      shippingTo {
        ...AddressFragment
      }
    }
    additional_instructions
    email_groups
    email_groups_notes
    calendars
    calendar_permissions
    calendar_additional_notes
    calendars_data {
      id
      name
      permission {
        id
        name
      }
    }
    slack_account_type
    slack_channels
    slack_groups
    chat_application_notes
    saas_applications
    softwares {
      id
      name
      requires_account
      requires_installation
      notes {
        question_id
        title
        answer
        children {
          question_id
          title
          answer
        }
      }
      additional_notes
    }
    approvers
    request_date_time
    request_timezone
  }
  ${ADDRESS_FRAGMENT}
`;

const TASK_FRAGMENT = gql`
  fragment TasksFragment on Task {
    id
    kind
    title
    issue_id
    taskable_type
    taskable_id
    created_at
    updated_at
    status
    seq
    systemFields {
      id
      issue_id
      name
      label
      value
      created_at
      updated_at
    }
  }
`;

const ONBOARDING_FRAGMENT = gql`
  fragment OnboardingFragment on Onboarding {
    status
    id
    customer_id
    requester_email
    canceled_by {
      email
      name
    }
    canceled_at
    tasks {
      ...TasksFragment
    }
    internal_tasks {
      ...TasksFragment
    }
    data {
      ...OnboardingDataFragment
    }
  }
  ${TASK_FRAGMENT}
  ${ONBOARDING_DATA_FRAGMENT}
`;

export { ONBOARDING_FRAGMENT };
