import { createAction, handleActions } from 'redux-actions';
import { uniq } from 'lodash';
import { get } from 'lodash/fp';

export const panelAdd = createAction('ADDRESS_PANELS/ADD');
export const panelUpdate = createAction('ADDRESS_PANELS/UPDATE');

export const addField = (key, push) => dispatch => {
  push();
  dispatch(panelAdd(key));
};

const initialState = [];

export const reducer = handleActions(
  {
    [panelAdd]: (state, { payload }) => uniq([payload, ...state]),
    [panelUpdate]: (_, { payload }) => payload,
  },
  initialState
);

export const selector = get('openAddressPanels');
