import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApolloProvider } from '@apollo/client';

import { updatePage } from '@turbine/redux/actions/historyActions';
import {
  initApolloWithToken,
  type ApolloClientInstance,
} from '@turbine/graphql';

import { useSession } from './helpers/hooks';
import identifyRelicxUser from './services/relicx/identify';
import Router from './routes/router';

import { setThemeCustomCssVars, useCurrentThemeSet } from '@electricjs/arc';
import { startFetchIntervals } from './helpers/fetchTurbineAppVersion';

function setBaseHref() {
  const base = document.querySelector('base');
  if (base) {
    base.setAttribute('href', '');
  }
  return true;
}

/** Fetch App Version Interval Setup */
startFetchIntervals();

const App = () => {
  /**
   * Start custom css color setup
   */
  const currentThemeColors = useCurrentThemeSet({
    tokenSet: 'colors',
    filter: 'colorV2',
  });
  setThemeCustomCssVars({ currentThemeMap: currentThemeColors });
  /* End custom css color setup */

  const dispatch = useDispatch();
  const { loading, user, userId, token, isAuthenticated } = useSession();
  const [apolloClient, setApolloClient] = useState<ApolloClientInstance>(null);

  // App's componentDidMount
  useEffect(() => {
    setBaseHref();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when session is loaded, initialize Apollo
  useEffect(() => {
    if (!loading) {
      if (!isAuthenticated || token) {
        initApolloWithToken(token, setApolloClient);
      }
    }
  }, [loading, token, isAuthenticated]);

  // if a user exists/changes, identify user
  useEffect(() => {
    if (!loading && user) {
      identifyRelicxUser({
        userId: userId as string,
        email: user.email as string,
      });
      dispatch(updatePage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, user]);

  if (loading || !apolloClient) {
    return null;
  }
  // else render App
  return (
    <ApolloProvider client={apolloClient}>
      <Router />
    </ApolloProvider>
  );
};

export default App;
