import { gql } from '@apollo/client';

// This is going to benefit a lot from fragments...

export const CREATE_POLICY = gql`
  mutation CreateXboardingPolicy($customerId: String!, $data: JSONString!) {
    createXboardingPolicy(
      xboarding_policy: { customer_id: $customerId, data: $data }
    ) {
      xboarding_policy {
        id
        data {
          email_formats {
            prefix
            suffix
            domain
            syntax
          }
          default {
            approvers
            calendars
            calendars_data {
              id
              name
            }
            email_groups
            slack_channels
            saas_applications
            hardware {
              link
              name
            }
            sso_provider
            chat_application
            slack_groups
          }
          departments {
            approvers
            calendars
            calendars_data {
              id
              name
            }
            email_groups
            slack_channels
            saas_applications
            name
            selected_hardware {
              id
              link
              name
              selected
            }
            slack_groups
          }
        }
      }
    }
  }
`;

export const UPDATE_POLICY = gql`
  mutation UpdateXboardingPolicy(
    $customerId: String!
    $data: JSONString!
    $id: String!
  ) {
    updateXboardingPolicy(
      id: $id
      xboarding_policy: { customer_id: $customerId, data: $data }
    ) {
      xboarding_policy {
        id
        data {
          email_formats {
            prefix
            suffix
            domain
            syntax
          }
          default {
            approvers
            calendars
            calendars_data {
              id
              name
            }
            email_groups
            slack_channels
            saas_applications
            hardware {
              link
              name
            }
            sso_provider
            chat_application
            slack_groups
          }
          departments {
            approvers
            calendars
            calendars_data {
              id
              name
            }
            email_groups
            slack_channels
            saas_applications
            name
            selected_hardware {
              id
              link
              name
              selected
            }
            slack_groups
          }
        }
      }
    }
  }
`;
