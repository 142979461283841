import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from '../store';

export const selectAllAppsLoading = (state: RootState): boolean =>
  state.applications.allAppsLoading;
export const selectAllAppsFailed = (state: RootState): boolean =>
  state.applications.allAppsFailed;
export const selectAllAppsData = (state: RootState) =>
  state.applications.allAppsData;

export const selectCustomerSoftwareAppsLoading = (state: RootState): boolean =>
  state.applications.customerSoftwareAppsLoading;
export const selectCustomerSoftwareAppsFailed = (state: RootState): boolean =>
  state.applications.customerSoftwareAppsFailed;
export const selectCustomerSoftwareAppsData = (state: RootState) =>
  state.applications.customerSoftwareAppsData;

export const selectHasChatAppLoading = (state: RootState): boolean =>
  state.applications.hasChatAppLoading;
export const selectHasChatAppFailed = (state: RootState): boolean =>
  state.applications.hasChatAppFailed;
export const selectHasChatAppData = (state: RootState) =>
  state.applications.hasChatApp;

export const selectAllAppsState = createSelector(
  selectAllAppsLoading,
  selectAllAppsFailed,
  selectAllAppsData,
  (loading, failed, allApps) => ({
    loading,
    failed,
    allApps,
  })
);

export const selectCustomerSoftwareApplicationsState = createSelector(
  selectCustomerSoftwareAppsLoading,
  selectCustomerSoftwareAppsFailed,
  selectCustomerSoftwareAppsData,
  (loading, failed, customerSoftwareApps) => ({
    loading,
    failed,
    customerSoftwareApps,
  })
);

export const selectHasChatAppState = createSelector(
  selectHasChatAppLoading,
  selectHasChatAppFailed,
  selectHasChatAppData,
  (loading, failed, hasChatApp) => ({
    loading,
    failed,
    hasChatApp,
  })
);
